import Ax from "axios";

// Fetch Advertisers data
export const getUsersData = (req) => {
  return async (dispatch) => {
    dispatch({
      type: "DX_GET_USERS_LOADING",
      payload: {
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/dashboardx-users`;
      const response = await Ax.get(apiUrl);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      // dispatch({
      //   type: 'NETWORKS_LOADING_FAILED'
      // })
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
    }
  };
};

export const createUser = (userDetails) => {
  return async (dispatch) => {
    let payload = null;
    try {
      const apiUrl = `/api/users/create`
      const response = await Ax.post(apiUrl, userDetails);
      const payload = response.data;


      
      // dispatch(authenticateAction(payload));
      // console.info({ response, payload })
      dispatch(payload)
      return true;

    } catch (e) {

      console.info(e)
      //   const { error, message } = e.response.data;
      //   dispatch({
      //     type: 'NOTIFICATION',
      //     payload: {
      //       status: 'error',
      //       message,
      //       title: error,
      //     }
      //   })
      

      // dispatch(deAuthenticateAction());
      return false
    }
  };
};

export const updateUser = (userDetails) => {
  return async (dispatch) => {
    let payload = null;
    try {
      const apiUrl = `/api/users/update`
      const response = await Ax.post(apiUrl, userDetails);
      const payload = response.data;


      
      // dispatch(authenticateAction(payload));
      // console.info({ response, payload })
      dispatch(payload)
      return true;

    } catch (e) {

      console.info(e)
      //   const { error, message } = e.response.data;
      //   dispatch({
      //     type: 'NOTIFICATION',
      //     payload: {
      //       status: 'error',
      //       message,
      //       title: error,
      //     }
      //   })
      

      // dispatch(deAuthenticateAction());
      return false
    }
  };
};



export const updateTrackRCUser = (action) => {
  return async (dispatch) => {
    try {
      const apiUrl = `/api/users/track-users-update`
      const response = await Ax.post(apiUrl, action);
      const payload = response.data;


      dispatch(payload)
      return true;

    } catch (e) {
      console.info(e)
      return false
    }
  };
};

export default {
    getUsersData,
    createUser,
    updateUser,
    updateTrackRCUser
};
