import React, { Component, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import AdvertisersActions from "../../actions/AdvertisersActions";
import ExtOffersActions from "../../actions/ExtOffersActions";
import AppBarActions from "../../actions/AppBarActions";
import { CopyToClipboard } from 'react-copy-to-clipboard';

// import FieldsGrouper from "../../components/xComponents/FieldsGrouper";
// import PopupDialog from "../../components/xComponents/PopupDialog";
import OffersFilters from "./ExtOffersFilters";
import _ from "lodash";
// grommet
import { Box, Button, DropButton, Text, Heading, Layer, Select, Tip } from "grommet";
import { FormSearch as SearchIcon } from 'grommet-icons';

import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";
import Pagination from "../../components/PaginationComponent";
import ActionDropButton from "../../components/DropButton";
import { debounce } from "debounce";

function getRowProps(data) {
  const internals = data
    .filter((o) => o.internal !== "active")
    .map((o) => o.id);
  const closed = data
    .filter((o) => o.status === "Closed" || o.status === "Not Active")
    .map((o) => o.id);
  // console.info({ internals, closed })

  let rowProps = {};

  internals.forEach(
    (oid) => (rowProps[oid] = { background: "rgb(236, 208, 75, 0.9)" })
  );
  closed.forEach((oid) => (rowProps[oid] = { background: "#f2dedd" }));

  return rowProps;
}


function LinksRenderer(props) {
  const [copied, setCopied] = useState(false)
  return (
    <Box
      padding={"none"}
      align="center"
      justify="start"
      gap="8px"
      direction="row"
    >
      <DropButton
        label="Links"
        plain
        alignSelf="center"
        margin={{ vertical: 'small' }}
        dropContent={
          <Box
            pad="small"
            gap="small"
            background="light-2">
            {
              [{ name: 'TRACKING', prop: 'tracking_url' }, { name: "PREVIEW", prop: 'preview_url' }].map(({ name, prop }) => (
                <Box key={name}>
                  <Box>
                    <CopyToClipboard
                      text={props[prop]}
                      onCopy={() => setCopied(props[prop])}
                    >
                      <Box>
                        <Box direction='row' gap='large' style={{ fontWeight: 'bold' }}>
                          <Box >
                            {name}
                          </Box>
                          {copied === props[prop] ? <Box >
                            copied
                            </Box> : null}
                        </Box>
                        <Box>{props[prop]}</Box>
                      </Box>
                    </CopyToClipboard>
                  </Box>
                </Box>
              ))
            }
          </Box>
        }
        dropProps={{ align: { top: 'bottom', right: "left" } }}
      >
        {/* <MenuItem /> */}
      </DropButton>
    </Box>
  )

}
// let i = 0;

function AffilitestRenderer(props) {
  const [show, setShow] = useState(false)
  // if (i < 1) {
  //   // console.info(props.redirections, props.redirections.replace(/\s/g, ' ').split(" "))
  //   setShow(true)
  // }
  // i++;

  return (
    <Box
      padding={"none"}
      align="center"
      justify="start"
      gap="8px"

    >
      <Box
        direction="row"
        align='center'
        justify="start"
      >
        <Box style={{ maxHeight: '20px' }} round="5px" pad="xsmall" align="center" background={!props.affilitest_error ? "#5cb85b" : "#f0ad4e"}>
          <Text size="8px" color="white">{!props.affilitest_error ? "SUCCESS" : "ERROR"}</Text>
        </Box>
        <Box>
          <Button
            style={{ padding: '2px' }}
            icon={<SearchIcon size='medium' />}
            onClick={() => setShow(true)}
          />
        </Box>
      </Box>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <Box
            pad='small'
            justify='center'
            gap="small"
            style={{ minWidth: '700px', maxWidth: '90vw' }}
          >
            <Box direction='row' gap='small' align='center'>
              <Box>
                <Heading level={3} margin="none">Affilitest Info</Heading>
              </Box>
              <Box flex align='end'>
                <Button label="close" plain onClick={() => setShow(false)} />
              </Box>
            </Box>
            <Box pad="small" gap="medium">
              <Box
                direction='row'
                gap="small"
              >
                <Text weight="bold">Last Update</Text>
                <Text>{Moment.utc(props.affilitest_last_update).format("YYYY-MM-DD HH:mm:SS")}</Text>
              </Box>
              <Box gap='medium' style={{ overflow: 'scroll', maxHeight: '700px' }}>
                <Text weight="bold">Redirects</Text>
                {
                  props.redirections ? props.redirections.replace(/\s/g, ' ').split(" ").map((url, i) => (
                    <Box
                      flex
                      direction='row'
                      gap='small'
                      align='start'
                      key={`${props.id}_r_${i}`}>
                      <Text size='small'>{i + 1}</Text>
                      <Text size='small'>{props.status_codes.split(',')[i]}</Text>
                      <Text size='xsmall' style={{ display: 'block' }}>{url}</Text >
                    </Box>
                  )) : <Box> NONE</Box>
                }
              </Box>


            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )

}

function getTableColumns() {

  const defaultRenderer = (
    propName,
    type = "",
    color = "",
    background = ""
  ) => (props) => {

    let extraStyle = {};
    let value = props[propName.toLowerCase()];
    // 05/08/2020 13:38
    if (type === "numeral") {
      value = Numeral(value).format("0,0");
    } else if (type === "currency") {
      value = Numeral(value).format("0,0$");
    } else if (type === "date") {
      value = Moment(value).format("DD/MM/YY HH:mm");
    }
    if (propName === 'daily_cap') {
      value = `${props.remaining_daily_cap}/${value}`
    } else if (propName === 'redirects') {
      const { affilitest_num_redirects: num, status_codes } = props;
      if (!num) {
        return ''
      } else {
        const [, ...statuses] = status_codes.split(',').reverse()
        if (statuses.indexOf('200') > -1) {
          value = `(${num}) Mixed with 200`
        } else {
          value = `(${num}) 302 Only`

        }
      }
    } else if (propName === 'incent') {
      value = value ? "YES" : "NO"
    }
    // else if (propName === "click_cap") {
    //   const [used, quota] = value.split("/");
    //   // console.info({used , quota})
    //   const newLine = used.length + quota.length > 10 ? `\n` : ``;
    //   value = `${+used}${newLine}/${+quota}`;
    // }

    return (
      <Cell
        start={"true"}
        flex
        color={color}
        type={type}
      // background={'background'}
      >
        <Tip
          plain
          content={
            <Box border round="small" background={"#E6E6E6"} pad="xsmall">
              <Text size="xsmall">
                {propName} - {value}
              </Text>
            </Box>
          }
        >
          <Box>{value}</Box>
        </Tip>
      </Cell>
    );
  };

  const columns = [
    {
      property: "status",
      header: "STATUS",
      render: (props) => (
        <Box
          padding={"none"}
          align="center"
          justify="start"
          gap="8px"
          direction="row"
        >
          {<Box round="5px" pad="xsmall" align="center" background={props.status ? "#5cb85b" : "#d9534e"}>
            <Text size="8px" color="white">{props.status ? "ON" : "OFF"}</Text>
          </Box>}
          {props.is_new ? <Box round="5px" pad="xsmall" align="center" background={"#5bc0de"}>
            <Text size="8px" color="white">{"NEW"}</Text>
          </Box> : null}
          {/* 5bc0de */}
        </Box>
      ),
    },
    {
      property: "created",
      header: "created",
      render: defaultRenderer("created", "date"),
    },
    {
      property: "network",
      header: "Network",
      render: defaultRenderer("network"),
    },
    {
      property: "offer_id",
      header: "OID",
      render: defaultRenderer("offer_id"),
    },
    {
      property: "external_campaign_id",
      header: "EXT OID",
      render: defaultRenderer("external_campaign_id"),
    },
    {
      property: "name",
      header: "Name",
      render: defaultRenderer("name"),
    },
    {
      property: "countries",
      header: "GEOS",
      render: defaultRenderer("countries"),
    },
    {
      property: "start_time",
      header: "start_time",
      render: defaultRenderer("start_time", "date"),
    },
    {
      property: "end_time",
      header: "end_time",
      render: defaultRenderer("end_time", "date"),
    },
    {
      property: "platform",
      header: "OS",
      render: defaultRenderer("platform"),
    },
    {
      property: "incent",
      header: "INCENT",
      render: defaultRenderer("incent"),
    },
    {
      property: "type",
      header: "TYPE",
      render: defaultRenderer("type"),
    },
    {
      property: "daily_cap",
      header: "Daily Cap",
      render: defaultRenderer("daily_cap"),
    },
    {
      property: "price",
      header: "Price",
      render: defaultRenderer("price"),
    },
    {
      property: "redirects",
      header: "redirects",
      render: defaultRenderer("redirects"),
    },
    {
      property: "tracking_url",
      header: "URLS",
      render: LinksRenderer,
    },
    {
      property: "affilitest_error",
      header: "AFFILITEST",
      render: AffilitestRenderer,
    },


    // {
    //   property: "adv_offer_id",
    //   header: "Ext Id",
    //   render: defaultRenderer("adv_offer_id"),
    //   primary: true
    // },
    // {
    //   property: "placments",
    //   header: "Placments",
    //   render: defaultRenderer("placements"),
    // },

    // {
    //   property: "rank",
    //   header: "Rank",
    //   render: defaultRenderer("rank"),
    // },

    // {
    //   property: "conversion_cap",
    //   header: "Conv Cap",
    //   render: defaultRenderer("conversion_cap"),
    // },
    // {
    //   property: "click_cap",
    //   header: "Click Cap",
    //   render: defaultRenderer("click_cap"),
    // },
    // {
    //   property: "internal",
    //   header: "Internal",
    //   render: defaultRenderer("internal"),
    // },

    {
      property: "actions",
      header: "",
      render: (props) => <ActionDropButton />,
    },
  ];

  return columns;
}

const DEFAULT_SORT = { property: "start_time", direction: "desc" };

function ExtOffers(props) {
  const { browser, advertisers, fetchExtOffersData } = props;
  const {
    offers,
    loading,
    totalPages,

    advertiser,
  } = props.offers;

  const [filtersVisible, toggleFilterVisibility] = useState(true);
  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.page || 1);
  const [pageSize, setCurrentPageSize] = useState(props.pageSize || 50);
  const [queryCriteria, setQueryCriteria] = useState({});

  let sort = sortSettings;
  if (["id", "price", "name", "created", "start_time"].indexOf(sort.property) === -1) {
    sort = DEFAULT_SORT;
  }

  console.info({fetchExtOffersData}, props)
  useEffect(() => {
    async function getData() {
      await fetchExtOffersData({
        ...queryCriteria,
        page,
        pageSize,
        sort,
      });
    }
    const debounced = debounce(getData, 1000);
    debounced();
  }, [page, pageSize, queryCriteria, sort, fetchExtOffersData]);

  console.info({ queryCriteria, pageSize });

  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}

      <OffersFilters
        data={offers}
        sort={sortSettings}
        direction={!browser.greaterThan.medium && "column"}
        advertiser={advertiser}
        advertisers={advertisers}
        handleSubmit={setQueryCriteria}
        open={filtersVisible}
        setOpen={toggleFilterVisibility}
      />

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading Offers data...">
        <Box style={{ flex: 13, overflowX: "auto" }}>
          <DataTable
            pin
            key={offers.length}
            rowProps={getRowProps(offers)}
            background={{
              header: "dark-3",
              body: ["#dff0d8"],
              footer: "dark-3",
            }}
            sortable
            onSort={({ direction, property }) =>
              setSortSettings({
                property,
                direction,
              })
            }
            data={offers}
            columns={getTableColumns()}
            sort={sortSettings}
          />
          <Box
            pad="6px"
            background={"light-1"}
            direction="row"
            align={"center"}
            style={{ position: "fixed", bottom: 0, width: "100%" }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, nextPage) => setCurrentPage(nextPage)}
            />
            <Box style={{ maxWidth: "100px" }}>
              <Select
                size={"small"}
                options={[50, 100, 200, 250, 500]}
                value={pageSize}
                onChange={({ option: nextPageSize }) =>
                  setCurrentPageSize(nextPageSize)
                }
              />
            </Box>
          </Box>
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const offers = state.extOffers;
  const browser = state.browser;
  const mainSidebar = state.mainSidebar;
  const advertisers = state.advertisers.advertisers;
  return { offers, mainSidebar, advertisers, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AdvertisersActions, ExtOffersActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtOffers);
