import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { FormClose } from 'grommet-icons';
import { Box, Button, Grommet, Keyboard, Text, TextInput } from 'grommet';
import { grommet } from 'grommet/themes';


const Tag = ({ children, onRemove, ...rest }) => {
  const tag = (
    <Box
      direction="row"
      align="center"
      background="brand"
      pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
      margin={{ vertical: 'xxsmall' }}
      round="xsmall"
      {...rest}
    >
      <Text size="xsmall" margin={{ right: 'xxsmall' }}>
        {children}
      </Text>
      {onRemove && <FormClose size="small" color="white" />}
    </Box>
  );

  if (onRemove) {
    return <Button onClick={onRemove}>{tag}</Button>;
  }
  return tag;
};

const TagInput = ({ value = [], onAdd, onChange, onRemove, ...rest }) => {
  const [currentTag, setCurrentTag] = React.useState('');
  const [box, setBox] = React.useState();
  const boxRef = React.useCallback(setBox, []);

  const updateCurrentTag = event => {
    setCurrentTag(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const onAddTag = tag => {
    if (onAdd) {
      onAdd(tag);
    }
  };

  const onEnter = () => {
    if (currentTag.length) {
      onAddTag(currentTag);
      setCurrentTag('');
    }
  };

  const renderValue = () =>
    value.map((v, index) => (
      <Tag
        margin="xxsmall"
        key={`${v}${index + 0}`}
        onRemove={() => onRemove(v)}
      >
        {v}
      </Tag>
    ));

  return (
    <Keyboard onEnter={onEnter}>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: 'xsmall' }}
        border="all"
        ref={boxRef}
        wrap
      >
        {value.length > 0 && renderValue()}
        <Box flex style={{ minWidth: '120px' }}>
          <TextInput
            type="search"
            plain
            dropTarget={box}
            {...rest}
            onChange={updateCurrentTag}
            value={currentTag}
            onSuggestionSelect={event => onAddTag(event.suggestion)}
          />
        </Box>
      </Box>
    </Keyboard>
  );
};

export const WithTags = (props) => {
    const { name, handleChange, value } = props;
  const [selectedTags, setSelectedTags] = useState(value || []);


  useEffect(() => {
    // console.info('useEffect', value, selectedTags )

    value.length !== selectedTags.length &&
    handleChange && handleChange({  [name]: selectedTags})

  },[selectedTags, handleChange, name, value])

  if (!name) {
    return null
}
  const onRemoveTag = tag => {
    const removeIndex = selectedTags.indexOf(tag);
    const newTags = [...selectedTags];
    if (removeIndex >= 0) {
      newTags.splice(removeIndex, 1);
    }
    setSelectedTags(newTags);
  };

  const onAddTag = tag => {
    const cleaned = tag.replace(/\s/g, '')
    if (props.regex && !props.regex.test(cleaned)) return;
     
     setSelectedTags([...selectedTags, cleaned]);
    };


  return (
      <Box className={props.className}>
        <TagInput
          placeholder={props.placeholder || "Search for aliases..."}
          value={selectedTags}
          onRemove={onRemoveTag}
          onAdd={onAddTag}
        />
      </Box>
  );
};


const StyledTagsInput = styled(WithTags)`
    background: white;
    & > div {
        border-radius: 5px;
        min-width: 300px;
        min-width: 336px;
        margin-left: 4px;
    }
`
export default StyledTagsInput