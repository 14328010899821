import React, { useMemo, Component, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import AppsFlyerActions from "../../actions/AppsFlyerCombinedActions";
import AppBarActions from "../../actions/AppBarActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MomentTZ from "moment-timezone";
import FiltersV2 from "../../components/Filters2/Filters";
import _ from "lodash";
// grommet
import {
  Box,
  Button,
  DropButton,
  Text,
  Heading,
  CheckBox,
  Layer,
  Select,
  Tip,
} from "grommet";

import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";

import {
  getRowProps,
  getTableColumns,
  getGroupByOpts,
} from "./AppsFlyerCombinedFuncs";
import SearchInput from "../../components/xComponents/SearchInput";

const DEFAULT_SORT = { property: "revenue", direction: "desc" };

function getIPublishersOpts({ publishers, ipublishers }) {
  // console.info(publishers, ipublishers)
  const options = ipublishers.map(({ name: label, publishers: pubids }) => {
    return {
      label,
      pubids,
    };
  });
  // console.info(options)
}

const ALL_PUB_GROUPS = "All Pub Groups";

function AppsFlyerAgency(props) {
  const { browser, advertisers, fetchCombinedData, isAgencyUser } = props;
  const {
    loading,
    options,
    reports,
    adn_reports,
    ipublishers,
    agency_reports,
    agency_geo_reports,
    geo_reports,
  } = props.appsflyer;
  getIPublishersOpts(props.appsflyer);
  // const [filtersVisible, toggleFilterVisibility] = useState(true);
  const [dataFilters, setDataFilters] = useState({});
  const [groupBy, setGroupBy] = useState("id");
  const [firstLoad, setFirstLoad] = useState(true);
  // const [fromDate, setFromDate ] = useState(Moment.utc().subtract(24,'hours'));

  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.page || 1);
  const [pageSize, setCurrentPageSize] = useState(props.pageSize || 50);
  const [showPcnt, setShowPcnt] = useState(false);
  const [showBlockedEvents, setShowBlockedEvents] = useState(false);
  const [networkOptions, setNetworkOptions] = React.useState([]);
  const [networkValue, setNetworkValue] = React.useState(undefined);

  const [partnerOptions, setPartnerOptions] = React.useState([]);
  const [partnerValue, setPartnerValue] = React.useState(undefined);

  const [ipublisherOptions, setIPublisherOptions] = React.useState([]);
  const [ipublisherValue, setIPublisherValue] = React.useState("");

  const [filteredData, setFilteredData] = useState([]);

  const [queryCriteria, setQueryCriteria] = useState({
    reportsSource: "Combined",
  });
  const reportsSourceMap = useMemo(
    () => ({
      Combined: reports,
      Agency: agency_reports,
      "Ad Network": adn_reports,
      // "Agency Geo": agency_geo_reports,
      // "Combined Geo": geo_reports,
    }),
    [reports, agency_reports, adn_reports]
  );

  // console.info({ dataFilters })
  let sort = sortSettings;
  if (
    ["id", "price", "name", "created", "start_time"].indexOf(sort.property) ===
    -1
  ) {
    sort = DEFAULT_SORT;
  }
  const isMobile = !browser.greaterThan.medium;
  const dataTableKey = groupBy + reports.length;
  const groupByProp = ["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {};

  // console.info({ queryCriteria, firstLoad })
  useEffect(() => {
    async function getData() {
      await fetchCombinedData({
        ...queryCriteria,
        page,
        pageSize,
        // sort,
      });
    }
    // const debounced = debounce(getData, 1000);
    // // debounced();
    if (!firstLoad) getData();
  }, [page, pageSize, queryCriteria, fetchCombinedData, firstLoad]);

  const reportsSource = useMemo(
    () => reportsSourceMap[queryCriteria.reportsSource] || [],
    [reportsSourceMap, queryCriteria.reportsSource]
  );
  const hasGroupBy = ["id", "none"].indexOf(groupBy) === -1;

  useEffect(() => {
    let filteredData = reportsSource.filter((r) => {
      const filters = Object.keys(dataFilters);
      // console.info(filters, 'filters')
      if (!filters.length) {
        return r;
      }
      // console.info(filters.map(filter => dataFilters[filter].map(({value}) => value)))
      return (
        filters
          .map((filter) => {
            const values = dataFilters[filter].map(({ value }) =>
              value.toUpperCase()
            );
            if (!values.length) return true;
            // console.info({filter, values}, r[filter])
            const resp = values.indexOf(r[filter].toUpperCase()) > -1;
            // if (resp)
            //   console.info(resp, r)
            return resp;
          })
          .filter((o) => o).length === filters.length
      );
    });

    // Network filter
    filteredData = filteredData.filter((fd) => {
      const networkFilter =
        (fd.isAdn &&
          ((Array.isArray(networkValue) && networkValue.includes(fd.pid)) ||
            !networkValue)) ||
        (!networkValue && !fd.isAdn);

      const partnerFilter =
        (fd.isAdn &&
          Array.isArray(partnerValue) &&
          partnerValue.includes(fd.partner.toUpperCase())) ||
        (!fd.isAdn &&
          Array.isArray(partnerValue) &&
          partnerValue.includes(fd.agency.toUpperCase())) ||
        !partnerValue ||
        partnerValue === "All Partners";

      // if (fd.isAdn)
      //   console.info(networkFilter, networkValue, fd, fd.adn_name)

      const ipublisherFilter =
        !ipublisherValue || ipublisherValue.includes(fd.publisher_group);
      if (ipublisherFilter) {
        // console.info(fd.publisher_group, ipublisherValue)
      }
      return networkFilter && partnerFilter && ipublisherFilter;
    });

    if (hasGroupBy) {
      let groupByVals = _.groupBy(filteredData, groupBy);
      let groupByValsSums = {};
      const isPercentage = sortSettings.property.indexOf("_p") > -1;

      Object.keys(groupByVals).forEach((gb) => {
        if (isPercentage) {
          groupByValsSums[gb] =
            _.sumBy(groupByVals[gb], sortSettings.property) /
              (_.sumBy(groupByVals[gb], sortSettings.property.split("_p")[0]) *
                _.sumBy(groupByVals[gb], sortSettings.property)) || 0;
        } else {
          groupByValsSums[gb] = _.sumBy(groupByVals[gb], sortSettings.property);
        }
      });

      filteredData = _.orderBy(
        filteredData.map((fd) => {
          let groupByValue = groupByValsSums[fd[groupBy]];
          // if (isPercentage) {
          //   groupByValue = groupByValue / groupByVals[fd[groupBy]].length
          // }
          return {
            ...fd,
            groupByValue,
          };
        }),
        ["groupByValue"],
        [sortSettings.direction]
      );

      // KPM FILTER
    }

    // KPM FILTER
    // if (!isAgencyUser && partnerValue !== "KPMBROMEDIA") {
    //   filteredData = filteredData.filter((r) => r.agency !== "KPMBROMEDIA");
    // }

    setFilteredData(filteredData);

    setNetworkOptions(_.uniq([...reportsSource.map((o) => o.pid)]));
    setPartnerOptions(
      _.uniq([
        // "All Partners",
        ...reportsSource.map(
          (o) => (o.partner || o.agency).toUpperCase() //|| "All Partners"
        ),
      ])
    );
    setIPublisherOptions(_.uniq([...ipublishers.map((ip) => ip.name)]));
  }, [
    reportsSource,
    dataFilters,
    networkValue,
    ipublisherValue,
    partnerValue,
    ipublishers,
    hasGroupBy,
    groupBy,
    sortSettings,
  ]);

  const reportsData = filteredData;

  // console.info({filteredData, dataFilters, reports})
  const sumClicks = reportsData.length
    ? reportsData.map((o) => o.clicks).reduce((a, b) => a + b)
    : 0;

  const groupByOpts = getGroupByOpts(groupBy);
  //
  // console.info({hasGroupBy, groupBy, sortSettings})
  const onDataFilter = ({ option, value: nextValue, ...rest }) => {
    const { type } = option || {};
    if (nextValue.length === 1 && typeof nextValue[0] === "undefined") {
      nextValue = [option];
    }
    // console.info({dataFilters, type, nextValue})
    setDataFilters({
      ...dataFilters,
      [type]: nextValue,
    });
  };

  console.info({ reportsData }, reportsData.length);
  // console.info({ find: groupBy });
  return (
    <Box
      flex
      fill
      // width="100%"
      margin='none'
      overflowY='auto'
      alignSelf='center'
    >
      {/* FILTERS  */}
      <FiltersV2
        fields={[
          {
            name: "reportsSource",
            type: "select",
            multiple: false,
            options: [
              "Combined",
              "Agency",
              "Ad Network",
              "Agency Geo",
              "Combined Geo",
            ],
            placeholder: `Reports Source`,
          },
          {
            name: "fromDate",
            type: "date",
            // value: fromDate,
            placeholder: `from date`,
          },
          {
            name: "toDate",
            type: "date",
            // value: toDate,
            placeholder: `to date`,
          },
          {
            name: "dateRange",
            type: "dateSelect",
            defaultRange: "today",
            // value: toDate,
            // placeholder: `to date`
          },
          {
            name: "groupByFilter",
            type: "afGroupByFilter",
            options: groupByOpts,
            value: groupBy === "id" ? undefined : groupBy,
            external: true,
            onChange: ({ option }) => {
              let groupBy = option.value;

              // console.info({option})
              if (groupBy === "none") {
                groupBy = "id";

                if (sortSettings.external) {
                  setSortSettings({
                    ...sortSettings,
                    external: false,
                  });
                }
              }

              // const groupBy = option !== "none" ? option : "id";

              // this.setState(updateObj, this.applyDataFilters)
              setGroupBy(groupBy);
            },
          },
          ...[
            // 'app_bundle',
            "publisher",
            "country",
            "campaign",
          ].map((name) => {
            return {
              name,
              options: options[name],
              type: "selectFilterData",
              value: dataFilters[name],
              onChange: onDataFilter,
              external: true,
            };
          }),
          {
            name: "app_bundle",
            type: "selectFilterData",
            external: true,
            options: options.app_bundle,
            value: dataFilters["app_bundle"],
            onChange: onDataFilter,
          },
          {
            name: "reportDownload",
            type: "reportDownloadButton",
            external: true,
            reportData: reportsData,
          },
        ]}
        queryCriteria={queryCriteria}
        handleSubmit={setQueryCriteria}
        firstLoad={firstLoad}
        onFirstLoad={() => setFirstLoad(false)}
      >
        <Box pad={{ horizontal: "small" }}>
          <Select
            id='select'
            name='select'
            placeholder='All PIDs'
            multiple
            clear
            value={networkValue}
            onChange={({ value: nextValue }) => {
              let value = nextValue;

              if (!nextValue.length && Array.isArray(nextValue)) {
                value = undefined;
              }
              setNetworkValue(value);
            }}
            options={networkOptions}
          />
        </Box>
        <Box
          pad={{ horizontal: "small" }}
          // TODO:
          // This style is specific for the mobile.
          // Why we did it ?
          // All this fields that are children of the FilterV2 function
          // was added later. We need to refactor the code for better integration
          style={isMobile ? { width: "100%", padding: 0 } : {}}
        >
          <Select
            id='select'
            name='select'
            placeholder='All Partners'
            multiple
            clear
            value={partnerValue}
            onChange={({ value: nextValue }) => {
              // console.info({nextValue})
              // let value = nextValue;

              // if (value.length === 1 && value.includes("All Partners")) {
              //   value = "All Partners"
              // }
              let value = nextValue;

              if (!nextValue.length && Array.isArray(nextValue)) {
                value = undefined;
              }
              setPartnerValue(value);
            }}
            options={partnerOptions}
          />
        </Box>
        {/* <Box pad={{ horizontal: 'small' }}>
          <Select
            id="select"
            name="select"
            placeholder="IPublisher"
            value={ipublisherValue}
            onChange={({ value: nextValue }) => {setIPublisherValue(nextValue)}}
            options={ipublisherOptions}
          />
        </Box> */}

        <Box
          pad={{ horizontal: "small" }}
          style={isMobile ? { width: "100%", padding: 0 } : {}}
        >
          {ipublishers.length ? (
            <SearchInput
              id='select'
              name='select'
              placeholder='Pub Group'
              multiple
              clear
              messages={{
                multiple: ipublisherValue
                  ? `${ipublisherValue.length} Pub Groups`
                  : "",
              }}
              value={ipublisherValue}
              onChange={({ value: nextValue, option }) => {
                // console.info("PUBG", nextValue, option);
                setIPublisherValue(nextValue);
              }}
              options={ipublishers.map((p) => p.name)}
            />
          ) : null}
        </Box>
        <Box pad={{ horizontal: "small" }}>
          <CheckBox
            checked={showPcnt}
            onChange={() => setShowPcnt((v) => !v)}
            label='show pcnt'
            toggle
          />
        </Box>
        <Box pad={{ horizontal: "small" }}>
          <CheckBox
            checked={showBlockedEvents}
            onChange={() => setShowBlockedEvents((v) => !v)}
            label='show blocked events'
            toggle
          />
        </Box>
      </FiltersV2>
      {/* <OffersFilters
        data={offers}
        sort={sortSettings}
        direction={!browser.greaterThan.medium && "column"}
        advertiser={advertiser}
        advertisers={advertisers}
        handleSubmit={setQueryCriteria}
        open={filtersVisible}
        setOpen={toggleFilterVisibility}
      /> */}

      {/* DATA TABLE  */}
      <Loader loading={loading} text='Loading AppsFlyer data...'>
        <Box style={{ flex: 13, overflowX: "auto" }}>
          <DataTable
            pin
            // fill
            key={groupBy + reportsData.length + showBlockedEvents}
            rowProps={getRowProps(reportsData)}
            background={{
              header: "dark-3",
              // body: ["#dff0d8"],
              footer: "light-3",
            }}
            sortable
            onSort={({ direction, property }) => {
              const notExternal = [
                "id",
                "app_bundle",
                "app_nae",
                "campaign",
                "country",
                "date",
                "publisher",
              ];
              const external =
                hasGroupBy && notExternal.indexOf(property) === -1;
              setSortSettings({
                property,
                direction,
                external,
              });
            }}
            primaryKey={"id"}
            {...(hasGroupBy ? { groupBy } : {})}
            data={reportsData}
            columns={getTableColumns(
              reportsData,
              groupByProp,
              sumClicks,
              showPcnt,
              showBlockedEvents
            )}
            sort={sortSettings}
            wideColumns={[2]}
          />
          {/* <Box
            pad="6px"
            background={"light-1"}
            direction="row"
            align={"center"}
            style={{ position: "fixed", bottom: 0, width: "100%" }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, nextPage) => setCurrentPage(nextPage)}
            />
            <Box style={{ maxWidth: "100px" }}>
              <Select
                size={"small"}
                options={[50, 100, 200, 250, 500]}
                value={pageSize}
                onChange={({ option: nextPageSize }) =>
                  setCurrentPageSize(nextPageSize)
                }
              />
            </Box>
          </Box> */}
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const isAgencyUser = state.user.isAgencyUser;

  const { appsflyer_combined: appsflyer } = state;

  return { appsflyer, browser, isAgencyUser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AppsFlyerActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsFlyerAgency);
