import React from "react";
import { TextField, MenuItem, Divider } from "@material-ui/core";

export default function SelectField(props) {
  const { options, value, label, size, onChange } = props;

  return (
    <TextField
      select
      label={label}
      value={value}
      variant="outlined"
      size={size || "small"}
      onChange={onChange}
      style={{
        backgroundColor: "white",
      }}
    >
      {options.map((o) => (
        <MenuItem value={o}>
          {o}
          <Divider />
        </MenuItem>
      ))}
    </TextField>
  );
}
