import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useSearchParams,
  useParams,
  useLocation
} from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment';
import Ax from 'axios';
import _ from 'lodash';
import useSWR from 'swr';
// import ConvertActions from '../../actions/AlertsDashboardActions';
import { DEFAULT_FILTERS } from "./AlertsHelpers";
import { DEFAULT_GROUP_BYS } from "./AlertsDashboard";
import {
  Box,
  Grommet,
  Tag,
  CheckBox,
  Heading,
  Button,
  Spinner,
  // Button,
  // Form,
  // List,
  // Tip,
  // Text,
  Layer,
  // Spinner,
  // Heading,
  // CheckBox,
  // TextInput,
  // DataTable,
  Select,
  TextArea,
  FormField,
  Text,
} from "grommet";
import ObjHash from 'object-hash';
import {
  postFetcher,
  postDispatcher,
  useGetDispatch
} from '../../utils/fetch'
import { DateSelect, getDateMaps } from "../../components/FiltersV2";
import { Search, Add, Send, Edit, FormAdd } from 'grommet-icons';
import { minimalTheme } from "../../themes/main-theme";

import {
  CellBox,
  AlertsTable,
  getTableColumns,
  FiltersModal,
  getRowProps,
  CreateAlertModal,
  NewAlertButton
} from "./AlertsHelpers";
import { AlertsGroupBy, AppBundlesSelect, PublishersSelect } from "./AlertsComponents";
import { options } from "@hapi/joi";

const INIT_ALERT = {
  hash: '_NEW_',
  settings: {
    filterBy: [],
    groupBys: [
      'campaign',
      'app_bundle',
      'publisher'
    ],
    appBundles: [],
    publishers: []
  },
  enabled: 1
}

function EditAlertLayer(props) {
  const { data, close, options } = props;
  const [processing, setProcessing] = React.useState(false)
  const [alertState, setAlertState] = React.useState({})
  const [createMode, setCreateMode] = React.useState(false);
  const dispatch = useDispatch()

  React.useEffect(() => {
    setAlertState(data)
    if (data && data.hash === '_NEW_') {
      setCreateMode(true)
    }
  }, [data])

  if (!alertState || !alertState.settings) return null


  // console.info({ alertState, data })

  const hasDiff = ObjHash(alertState) !== ObjHash(data);

  return (
    <Layer
      onEsc={() => close()}
      onClickOutside={() => close()}
      style={{ marginTop: -100 }}

    >
      <Box style={{ minWidth: '50vw' }} size='large' pad='medium'>
        <Heading level='3'> {createMode ? 'Create' : 'Edit'} Alert <Text color='brand' size='xlarge'>{alertState.hash}</Text></Heading>

        <Box pad='small' direction='column' style={{ maxWidth: '500px' }}>
          <FormField label='Enabled'>
            <CheckBox checked={alertState.enabled} onChange={({ target }) => {
              setAlertState(as => ({
                ...as,
                enabled: target.checked
              }))
            }} toggle size='small' />
          </FormField>

          <AlertsGroupBy
            options={DEFAULT_GROUP_BYS}
            onChange={({ value }) => {
              console.info("groupBy", value)
            }}
            value={DEFAULT_GROUP_BYS.filter(dgb => alertState.settings.groupBys.includes(dgb.value))}
          />
          <AppBundlesSelect
            value={alertState.settings.appBundles}
            options={options.app_bundle}
            onChange={({ value: appBundles, option }) => {
              setAlertState(as => ({
                ...as,
                settings: {
                  ...as.settings,
                  appBundles
                }
              })
              )
            }}
          />
          <PublishersSelect
            value={alertState.settings.publishers}
            options={options.publisher}
            onChange={({ value: publishers, option }) => {
              setAlertState(as => ({
                ...as,
                settings: {
                  ...as.settings,
                  publishers
                }
              })
              )
            }}
          />
        </Box>
        <FiltersModal
          alertConfig={alertState}
          onChange={(filterBy) => setAlertState(as => ({
            ...as,
            settings: {
              ...as.settings,
              filterBy
            }
          })
          )}
        />

        <Box direction='row' justify='center'>
          <Button
            size='large'
            primary
            icon={processing ? <Spinner /> : <Send />}
            disabled={processing || !hasDiff}
            onClick={async () => {
              setProcessing(true)
              // console.info(alertState)
              await postDispatcher(`/api/alerts/${createMode ? 'create' : 'edit'}`, alertState, dispatch)
              setProcessing(false)
              close()
            }}
            label='Save Changes' />
        </Box>
      </Box>
    </Layer>
  )


}


function AlertsConfig(props) {
  const { options, config, username } = props;
  const navigate = useNavigate();

  const [alertEdit, setAlertEdit] = React.useState({})

  useGetDispatch("/api/alerts/options")

  return (
    <Grommet theme={minimalTheme} style={{ width: '100%', maxHeight: '100%' }}>

      <Box pad={'small'}
        style={{ maxHeight: '100%' }}
      >
        <EditAlertLayer
          data={alertEdit}
          options={options}
          close={() => setAlertEdit(null)}
        />
        <AlertsTable
          pin
          columns={[
            { header: 'Id', property: 'id' },
            {
              header: 'Hash', property: 'hash', render: ({ hash }) =>
                <Box direction='row'>
                  <Text weight='bold' size='large' color='brand' >{hash}</Text>
                </Box>
            },
            {
              header: 'Filters', property: 'settings', render: ({ settings }) => {
                if (!settings.filterBy) return null
                return (
                  <Box direction='row' gap='small'>
                    {settings.filterBy.map((o,i) => {
                      const val = DEFAULT_FILTERS.find(f => f[0] === o[0]);
                      return (<Tag size='small' key={`df_${i}`} value={`${val[1]} ${val[2]} ${o[1]}`} />)
                    })}
                  </Box>
                )
              }
            },
            {
              header: 'Group Bys', property: 'settings-g', render: ({ settings }) => {
                // console.info("ok")
                if (!settings.groupBys) return null
                return (<Box direction='row' gap='small'>
                  {settings.groupBys.map((o,i) => {
                    const val = DEFAULT_GROUP_BYS.find(g => g.value === o);
                    console.info(o, val)
                    return (<Tag size='small'   key={`gb_${i}`} value={`${val.label}`} />)
                  })}
                </Box>)
              }
            },
            {
              header: 'Enabled', property: 'enabled', render: ({ enabled }) => {
                return <Box><CheckBox checked={Boolean(enabled)} toggle size='small' /></Box>
              }
            },
            {
              header: (<Box direction='row'>
                <Button
                  style={{ borderRadius: 0, padding: '0', fontSize: '18px' }}
                  hoverIndicator
                  pad={'none'}
                  icon={<Add color="brand" size='small' />}
                  plain
                  onClick={() => setAlertEdit(INIT_ALERT)}
                  color='brand'
                  label='Create'
                />
              </Box>)

              , property: 'actions', render: (config) => {
                return (
                  <Box direction='row'>

                    <Button icon={<Search />} onClick={() => navigate(`/alerts/alerts-dashboard/${config.hash}`)} />
                    <Button icon={<Edit />} onClick={() => setAlertEdit(config)} />

                  </Box>
                )
              }
            }

          ]}
          // onClickRow={(event) => setAlertEdit(event.datum)}
          primaryKey={'id'}
          data={config}
        />
      </Box>
    </Grommet >
  );
}


const mapStateToProps = (state, props) => {
  const { alerts, user } = state;
  const { config, options } = alerts
  const { username } = user;
  return {
    options,
    config,
    username
  };
};

export default connect(mapStateToProps, {})(AlertsConfig);

