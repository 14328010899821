import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircle";
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(4),
    "& button": {
      backgroundColor: "#3c8dbc",
    },
    "& button:hover": {
      backgroundColor: "#3c8dbc",
    },
  },
  speedDialAction: {
    backgroundColor: "red",
    "& span > button": {
      backgroundColor: "blue",
    },
    "& button:hover": {
      backgroundColor: "#3c8dbc",
    },
  },
  backdrop: {
    backgroundColor: "rgb(0,0,0,0.6)",
    zIndex: 9,
  },
}));

// Match an icon to action
function setIcon(action) {
  switch (action.name) {
    case "add":
      return (
        <AddCircleOutlineIcon
          style={{
            color: "white",
          }}
        />
      );
    case "filters":
      return (
        <FilterListIcon
          style={{
            color: "white",
          }}
        />
      );

    default:
      break;
  }
}

function SpeedDialCustom(props) {
  const {
    actions,
    browser,
    // By default the component will mount on Desktop & Mobile view
    // There is podsebilty to force viwe only on mobile || desktop
    // By passing viewPort Prop
    viewPort,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //   const [hidden, setHidden] = React.useState(false);

  //   const handleVisibility = () => {
  //     setHidden((prevHidden) => !prevHidden);
  //   };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onActionClick(e, action) {
    handleClose();
    action.action();
  }

  if (
    (viewPort === "desktop" && browser.lessThan.medium) ||
    (viewPort === "mobile" && !browser.lessThan.medium) ||
    !actions ||
    actions.length < 1
  ) {
    return null;
  } else
    return (
      <div>
        <Backdrop open={open} className={classes.backdrop} />
        <SpeedDial
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          ariaLabel="SpeedDial"
          icon={<SpeedDialIcon />}
          className={classes.speedDial}
        >
          {open &&
            actions.map((action) => (
              <SpeedDialAction
                tooltipOpen
                key={action.name}
                icon={setIcon(action)}
                tooltipTitle={action.name}
                className={classes.speedDialAction}
                onClick={(e) => onActionClick(e, action)}
              />
            ))}
        </SpeedDial>
      </div>
    );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  return { browser };
}
export default connect(mapStateToProps)(SpeedDialCustom);
