import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import PublishersActions from "../../actions/PublishersActions";
import AppBarActions from "../../actions/AppBarActions";
import ActionButtons from "../../components/ActionButtons";
import PopupDialog from "../../components/xComponents/PopupDialog";
import FieldsGrouper from "../../components/xComponents/FieldsGrouper";
import _ from "lodash";
import { Box, Button, Tip, Text } from "grommet";
import { Edit, AddCircle } from "grommet-icons";
import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";

class Publishers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableColumns: this.getTableColumns(),
      publisherFields: {
        name: "",
        username: "dev",
        password: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        contactIm: "",
        default_risk: 70,
        rank: 0,
        redirect_type: "",
        default_domain: "",
        pixel_type: "",
        pixel_code: "",
        default_click_cap: "4000000",
        default_conversion_cap: "50",
        keybali: false,
        api_enabled: false,
        events: [""],
      },
      // popup dialog
      popupDialogContent: undefined,
      popupDialogVisible: false,
      // password field visability
      passwordFieldVisible: "password",
    };

    this.handleFieldsChange = this.handleFieldsChange.bind(this);
  }

  /*  CDM  
  Initialize Component data and inject content into the top bar  
  */
  componentDidMount() {
    const { getInitialPublishersData } = this.props;
    //  Load data from API
    getInitialPublishersData();
    // inhect content into the app bar
    this.injectContentIntoAppBar();
  }

  /*  CDU  
  update injected content at the top bar if necessary
  */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { publishers: prevPublishers } = prevProps;
    const { publishers } = this.props;

    if (prevPublishers.length !== publishers.length) {
      this.injectContentIntoAppBar();
    }
  }

  // INJECT CONTENT INTO THE MAIN APPBAR
  injectContentIntoAppBar() {
    const { publishers } = this.props;
    // publishers row length
    const publishersLength = publishers.length;
    // inject message
    const message = publishersLength > 0 ? `${publishers.length} results ` : ``;
    // inhect content into the app bar
    this.props.updateAppBarContent({
      title: "Publishers",
      message,
      buttons: [
        <Tip
          plain
          content={
            <Box round={"5px"} background={"#E6E6E6"} pad="xsmall">
              <Text size="xsmall">Add publisher</Text>
            </Box>
          }
        >
          <Button
            hoverIndicator
            icon={<AddCircle size="18px" />}
            onMouseDown={() => {
              this.setState({
                popupDialogContent: "ADD",
                popupDialogVisible: !this.state.popupDialogVisible,
              });
            }}
          />
        </Tip>,
      ],
    });
  }

  // handle fields change on add publisher layer
  handleFieldsChange({ target, option }) {
    const { publisherFields } = this.state;
    const { value, name, type, checked } = target;

    let newValue = (option && option) || value;

    // Checkbox
    if (type === "checkbox") {
      newValue = checked;
    }

    // console.log(name);
    // console.log(type);
    // console.log(checked);
    // console.log(value);
    // console.log(option);

    // update new values
    let newValues = {
      ...publisherFields,
      ...{ [name]: newValue },
    };

    // in case events values changed
    if (name.search("event") !== -1) {
      // copy events
      let newEventsValues = [...publisherFields.events];
      // get field index
      let index = name.split(/#/)[1];

      // on the first type to the field - add extra field for the next event
      if (newValue.length === 1 && parseInt(index) === newEventsValues.length) {
        // new field content
        newEventsValues.push("");
        // else if the field content was deleted - remove that extra field
      } else if (
        newValue.length === 0 &&
        parseInt(index) === newEventsValues.length - 1
      ) {
        // remove last index
        newEventsValues.splice(newEventsValues.length - 1, 1);
      }

      // make copy for the event(field) that has changed
      let event = { ...newEventsValues[index - 1] };
      // insert new value
      event = newValue;
      // update the event value in the events array
      newEventsValues[index - 1] = event;

      // set new values
      newValues = {
        ...publisherFields,
        // update events values
        events: newEventsValues,
      };
    }

    this.setState({ publisherFields: newValues });
  }

  //
  getTableColumns() {
    const defaultRenderer = (propName, type = "", color = "") => (props) => {
      let value = props[propName.toLowerCase()];
      // 05/08/2020 13:38
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell start={"true"} flex color={color} type={type}>
          {value}
        </Cell>
      );
    };

    const columns = [
      {
        property: "id",
        header: "ID",
        render: defaultRenderer("id"),
      },
      {
        property: "name",
        header: "Name",
        render: defaultRenderer("name"),
        search: true,
      },
      {
        property: "contact_name",
        header: "Contact Name",
        render: defaultRenderer("contact_name"),
      },
      {
        property: "contact_phone",
        header: "Contact Phone",
        render: defaultRenderer("contact_phone"),
      },
      {
        property: "contact_email",
        header: "Contact email",
        render: defaultRenderer("contact_email"),
      },
      {
        property: "username",
        header: "Username",
        render: defaultRenderer("username"),
      },
      // Actions buttons
      {
        property: "actions",
        header: "Actions",
        render: (props) => (
          <ActionButtons
            direction="row"
            buttons={[
              {
                name: "Edit publisher",
                icon: <Edit size="10px" />,
                triger: () => {
                  this.setState({
                    publisherFields: {
                      ...this.state.publisherFields,
                      ...props,
                    },
                    popupDialogContent: "EDIT",
                    popupDialogVisible: !this.state.popupDialogVisible,
                  });
                },
                backgroundColor: "brand",
              },
            ]}
          />
        ),
      },
    ];

    return columns;
  }

  // Set publishers input fields to default values
  restorePublisherFields() {
    this.setState({
      publisherFields: {
        name: "",
        username: "dev",
        password: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        contact_im: "",
        default_risk: 70,
        rank: 0,
        redirect_type: "",
        default_domain: "",
        pixel_type: "",
        pixel_code: "",
        default_click_cap: "4000000",
        default_conversion_cap: "50",
        keybali: false,
        api_enabled: false,
        events: [""],
      },
    });
  }

  //
  getFields() {
    const { publisherFields } = this.state;
    // Props
    const {
      name,
      username,
      password,
      contact_name,
      contact_phone,
      contact_email,
      contact_im,
      default_risk,
      rank,
      redirect_type,
      default_domain,
      pixel_type,
      pixel_code,
      default_click_cap,
      default_conversion_cap,
      keybali,
      api_enabled,
      events,
    } = publisherFields;

    let eventsFields = [];
    for (let i = 0; i < 5; i++) {
      eventsFields[i] = { name: `event#${i + 1}`, value: `event#${i + 1}` };
    }

    const fieldsGroup = [
      {
        fields: [
          {
            name: "name",
            value: name,
          },
          {
            name: "username",
            value: username,
          },
          {
            name: "password",
            value: password,
            type: "password",
          },
          {
            name: "contact_name",
            value: contact_name,
          },
          {
            name: "contact_phone",
            value: contact_phone,
          },
          {
            name: "contact_email",
            value: contact_email,
          },
          {
            name: "contact_im",
            value: contact_im,
          },
          {
            name: "default_risk",
            value: default_risk,
          },
          {
            name: "rank",
            value: rank,
          },
          {
            name: "redirect_type",
            value: redirect_type,
            type: "select",
            options: [
              "javascript (HTTP 200)",
              "permanent (HTTP 301)",
              "temporary (HTTP 302)",
            ],
          },
          {
            name: "default_domain",
            value: default_domain,
            type: "select",
            options: ["None", "New Offers", "Live Offers", "Both New & Live"],
          },
          {
            name: "pixel_type",
            value: pixel_type,
            type: "select",
            options: ["None", "New Offers", "Live Offers", "Both New & Live"],
          },
          {
            name: "pixel_code",
            value: pixel_code,
            type: "textarea",
          },
          {
            name: "default_click_cap",
            value: default_click_cap,
          },
          {
            name: "default_conversion_cap",
            value: default_conversion_cap,
          },
        ],
      },
      {
        fields: [
          {
            name: "keybali",
            value: keybali,
            type: "checkbox",
            disabled: true,
          },
          {
            name: "api_enabled",
            value: api_enabled,
            type: "checkbox",
          },
        ],
      },

      { header: "events", fields: eventsFields },
    ];

    return fieldsGroup;
  }

  /*
   * POPUP DIALOG
   */

  //
  setPopupDialogHeader() {
    const { popupDialogContent } = this.state;

    switch (popupDialogContent) {
      case "ADD":
        return "add publisher";

      case "EDIT":
        return "edit publisher";

      default:
        break;
    }
  }

  //
  setPopupDialogContent() {
    const { popupDialogContent } = this.state;

    switch (popupDialogContent) {
      case "ADD":
      case "EDIT":
        return (
          <FieldsGrouper
            fieldsGroup={this.getFields()}
            onChange={this.handleFieldsChange}
          />
        );

      default:
        break;
    }
  }
  //
  setPopupDialogSubmitAction() {
    const { popupDialogContent } = this.state;

    switch (popupDialogContent) {
      case "ADD":
        return this.onAddNewPublisher();
      case "EDIT":
        return this.onEditPublisher();

      default:
        break;
    }
  }

  //
  onClosePopupDialog() {
    this.restorePublisherFields();
    this.setState({ popupDialogVisible: !this.state.popupDialogVisible });
  }

  // On add new advertiser
  async onAddNewPublisher() {
    if (
      // Sending values to the server from actions
      await this.props.addNewPublisher(this.state.publisherFields)
    ) {
      // hide "add new network" layer
      this.setState({
        popupDialogVisible: !this.state.popupDialogVisible,
      });
    }
  }

  // show confirmation layer on delete advertiser pressed down
  async onEditPublisher() {
    if (
      // Sending values to the server from actions
      await this.props.editPublisher(this.state.advetiserFields)
    ) {
      // hide "add new network" layer
      this.setState({
        popupDialogVisible: !this.state.popupDialogVisible,
      });
    }
  }

  // RENDER

  //
  render() {
    // Component state
    const { tableColumns, popupDialogVisible } = this.state;
    // Redux State
    const { loading, publishers } = this.props;

    return (
      <Box
        flex
        fill
        width="100%"
        margin="none"
        overflowY="auto"
        alignSelf="center"
        width="98%"
      >
        <Loader loading={loading} text="Loading Publishers data...">
          <Box fill style={{ overflowY: "auto", flex: 13 }}>
            <DataTable
              pin
              fill
              sortable
              primaryKey={"id"}
              data={publishers}
              columns={tableColumns}
              sort={{ property: "id", direction: "desc" }}
            />
          </Box>
        </Loader>

        {
          //
          // POPUP DIALOG
          popupDialogVisible && (
            <PopupDialog
              header={this.setPopupDialogHeader(this.state.popupDialogContent)}
              onEsc={() => this.onClosePopupDialog()}
              onSubmit={() => this.setPopupDialogSubmitAction()}
            >
              {this.setPopupDialogContent()}
            </PopupDialog>
          )
        }
      </Box>
    );
  }
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  return state.publishers;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, PublishersActions, AppBarActions),
    dispatch
  );
  //   return bindActionCreators(OffersActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Publishers);
