import _ from "lodash";
import Moment from "moment";

const defaultState = {
  loading: 0,
  options: {
    app_bundle: [],
    publisher: [],
    campaign: [],
    country: [],
  },
  notifications: [],
  config: []
};


// Filter dismissed users


const appsflyerAlerts = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {

    case 'HYDRATE_ALERTS_OPTS': {
      return {
        ...state,
        ...action.payload,
      
      }
    }

    case 'ALERT_CONFIG_UPDATED': {
      return {
        ...state,
        config: state.config.map((obj) => {
          if (obj.hash === action.updated.hash) {
            return action.updated
          }
          return obj
        })
      }
    }
    case 'ALERT_CONFIG_CREATED': {
      return {
        ...state,
        config: [
          ...state.config, 
          action.created
        ]
      }
    }

    default:
      return state;
  }
};




export default appsflyerAlerts;
