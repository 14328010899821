import React, { useEffect } from 'react'
import styled from 'styled-components';
import Moment from 'moment';
import Numeral from "numeral";
import { capitalize } from 'lodash';
import { RgbaColorPicker } from "react-colorful";
import MockData from './MockData'
import { DEFAULT_GROUP_BYS, getGroupBys } from './AlertsDashboard';
import useSWR, { useSWRConfig } from 'swr'

// import ConvertActions from '../../actions/AlertsDashboardActions';
import {
    Box,
    Grommet,
    Text,
    Layer,
    Collapsible,
    DataTable,
    Form,
    FormField,
    TextInput,
    TextArea,
    Spinner,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    RadioButtonGroup,
    Menu,
    Heading,
} from "grommet";
import { FormNext, Add, Close, FormSchedule, FormAdd, FormEdit } from 'grommet-icons'
import {
    usePostDispatch
} from '../../utils/fetch'



export const AckModalRowMock = { mockEdit: true, "row": { "id": 1237042202, "publisher": "TIKTAKMOBI_INT", "campaign": "IN_IOS_CPA", "app_bundle": "ID1497125474", "app_name": "Parimatch_IN_iOS", "regs": "6", "imps": 0, "clicks": 6779647, "installs": 14935, "ftd": 3, "bi": 238, "bp": 8429, "bp_pcnt": 0.5644, "bi_pcnt": 0.0157, "bpftd_pcnt": 0.3333, "brftd_pcnt": 0, "hash": "xxx55", "ack_to": "2022-06-18T08:48:41.000Z", "ack_msg": "not flagged anymore", "user": "Eitan", "no_ack": true }, "username": "nadavbs", "queryState": { "fromDate": "2022-06-13T00:00:00+03:00", "toDate": "2022-06-20T23:59:59+03:00", "groupBys": ["campaign", "publisher", "app_bundle"], "filterBy": [["installs", "5"], ["bp_pcnt", "5"]], "publishers": [], "appBundles": [] }, "alertConfig": { "id": 1, "hash": "xxx55", "settings": { "filterBy": [["installs", "5"], ["bp_pcnt", "5"]], "groupBys": ["campaign", "app_bundle", "publisher"], "appBundles": [], "publishers": [] }, "summary": [{ "key": "yesterday", "name": "YESTERDAY", "acked": 1, "results": 1 }, { "key": "last7", "name": "LAST 7 DAYS", "acked": 35, "results": 54 }, { "key": "mtd", "name": "MONTH TO DATE", "acked": 46, "results": 88 }], "summary_date": "2022-06-20T09:36:02.000Z", "dismissed_users": ["nadavbs"], "created": "2022-05-18T10:58:46.000Z", "updated": "2022-06-20T09:36:02.000Z", "enabled": 1 } };

export const dismissAlert = ({ username, target }, dispatch) => {

}


const RadioGroup = styled(RadioButtonGroup)`
    flex-direction row;
    column-gap: 12px;
    padding: 12px;

    // "& > label": {
    //     min-width: 100px !important;
    //     background: "red";
    // }
`

export const AckInput = ({
    row,
    alertConfig = {},
    queryState,
    username,
    mockEdit = false
}) => {
    const { mutate } = useSWRConfig()

    const [edit, setEdit] = React.useState(mockEdit);
    const [currentRow, updateRow] = React.useState(row)
    const [processing, setProcessing] = React.useState(false)
    // console.info({currentRow})
    const [payload, setPayload] = React.useState({
        publisher: currentRow.publisher,
        campaign: currentRow.campaign,
        app_bundle: currentRow.app_bundle,
        hash: alertConfig.hash,
        ack_msg: currentRow.ack_msg,
        username
    })

    useEffect(() => {
        updateRow(row)
    }, [row])


    usePostDispatch("/api/alerts/ack-msg", payload, processing)
        .then((exec) => {
            setProcessing(false)
            if (exec) {
                // console.info(payload)
                mutate(['/api/alerts/routes', queryState])
                setEdit(false)
            }
        })

    // if (!row.ack_to) {
    //     return <Box></Box>
    // }



    return (
        <Box
            style={{ minWidth: '120px' }}
            align='center'
            justifiy='center'
            direction='row'>
            <Button
                hoverIndicator
                icon={<FormEdit size='24px' />}
                onClick={() => setEdit(true)}
            />
            <Text>{row.ack_msg}</Text>
            {edit ? <Layer
                onEsc={() => setEdit(false)}
                onClickOutside={() => setEdit(false)}
            >
                <Box pad='medium' gap='small'>
                    <Heading margin='none' level='5'>
                        Add ack message
                    </Heading>

                    <TextInput
                        value={currentRow.ack_msg}
                        onChange={({ target }) => updateRow(r => ({ ...r, ack_msg: target.value }))}
                        placeholder={"Type msg here..."}
                    />
                    <Box direction='row' justify='center'>

                        <Button
                            label='save'
                            primary
                            size='small'
                            disabled={processing || currentRow.ac_msg === row.ack_msg}
                            onClick={() => {
                                setPayload(p => ({
                                    ...p,
                                    ack_msg: currentRow.ack_msg
                                }))
                                setProcessing(true)
                            }}
                        />
                    </Box>
                </Box>
            </Layer> : null}


        </Box>
    )

}

export const AckMenu = ({
    row,
    rows,
    username,
    queryState,
    alertConfig
}) => {
    const { mutate } = useSWRConfig()


    const [payload, setPayload] = React.useState({
        rows: (row ? [row] : rows).map(r => ({
            publisher: r.publisher,
            campaign: r.campaign,
            app_bundle: r.app_bundle
        })),
        username,
        hash: alertConfig.hash
    })
    const [processing, setProcessing] = React.useState(false)

    // useEffect(() => {
    //     if (processing) {

    //         console.info("DO SOMETHING", JSON.stringify(payload))
    //     }

    // }, [processing, payload])

    usePostDispatch("/api/alerts/ack-rows", payload, processing)
        .then((exec) => {
            setProcessing(false)
            if (exec) {
                console.info(row, payload)
                mutate(['/api/alerts/routes', queryState])
            }
        })




    return (
        <Menu
            icon={processing ? <Spinner /> : <FormSchedule />}
            disabled={(!row && !rows.length)}
            dropAlign={{
                right: 'left',
                top: 'bottom'
            }}
            items={[
                {
                    label: '1 Day'
                },
                { label: '2 Days' },
                { label: '3 Days' },
                { label: '1 Week' },
                { label: 'End Of Month' },
                { label: 'Reset', color: 'red' },
            ].map(item => ({
                ...item,
                onClick: processing ? undefined : () => {
                    setPayload(p => ({
                        ...p,
                        ack_to: item.label
                    }))
                    setProcessing(true)
                    // mutate(['/api/alerts/routes', queryState])
                }
            }))

            }
        />
    )
}

const ACK_GROUPS = [
    [
        {
            label: '1 Day'
        },
        { label: '2 Days' },
        { label: '3 Days' }
    ],
    [
        { label: '1 Week' },
        { label: 'End Of Month' },
    ],
    [
        { label: 'No Change' },
        { label: 'Reset', color: 'red' },
    ]
];

const CurrentCell = styled(TableCell)`
    letter-spacing: 0.2px;
    font-size: 12px;
    padding: 0;
`
export const AckModal = ({
    row,
    rows,
    username,
    queryState,
    alertConfig,
    mockEdit = false

}) => {
    const { mutate } = useSWRConfig()

    const [edit, setEdit] = React.useState(mockEdit);
    const [message, setMessage] = React.useState(row && row.ack_msg ? row.ack_msg : "");
    const [ackOpt, setAckOpt] = React.useState("No Change");


    const [payload, setPayload] = React.useState({})
    const [processing, setProcessing] = React.useState(false)


    useEffect(() => {
        setPayload({
            rows: (row ? [row] : rows).map(r => ({
                publisher: r.publisher,
                campaign: r.campaign,
                app_bundle: r.app_bundle
            })),
            username,
            hash: alertConfig.hash
        })

    }, [row, rows, username, alertConfig.hash])


    usePostDispatch("/api/alerts/ack-rows", payload, processing)
    .then((exec) => {
        setProcessing(false)
        if (exec) {
            // console.info(row, payload)
            mutate(['/api/alerts/routes', queryState])
            setMessage(message || "")
            setAckOpt("No Change")
            setEdit(false)
        }
    })


    console.info({row}, row && row.ack_msg)




    return (
        <Box
            style={{ minWidth: '240px' }}
            align='center'
            justifiy='center'
            direction='row'>
            <Button
                hoverIndicator
                icon={<FormEdit size='24px' />}
                onClick={() => setEdit(true)}
            />
            <Box
                align='center'
                justifiy='center'
                gap="small"
                direction='row'
            >

                {row && row.ack_to ? <CellBox style={{ lineHeight: '12px' }} align='center'>
                    {Moment(row.ack_to).format('YYYY-MM-DD')}
                    <div>

                        {row.user}
                    </div>
                </CellBox> : null}
                <Text>{row && row.ack_msg}</Text>
            </Box>
            {edit ? <Layer

                onEsc={() => setEdit(false)}
                onClickOutside={() => setEdit(false)}
            >
                <Box pad='medium' gap='small'>
                    <Heading margin='none' level='5'>
                        Update Alert {rows ? `Rows` : `Row`}
                    </Heading>
                    <Form
                    //  onSubmit={({ value }) => { console.info("form", value) }}
                    >

                        <FormField label={`Current ${rows ? 'Rows' : 'Row'}`}>
                            <Box pad='small'>
                                <Table>
                                    <TableBody>

                                        {(rows || [row]).map(row =>
                                            <TableRow key={row.id}>
                                                <CurrentCell>{row.publisher}</CurrentCell>
                                                <CurrentCell>{row.campaign}</CurrentCell>
                                                <CurrentCell>{row.app_bundle}</CurrentCell>
                                            </TableRow>
                                        )}

                                    </TableBody>
                                </Table>
                            </Box>
                        </FormField>
                        <FormField label='Message'>

                            <TextArea
                                name="message"
                                value={message}
                                onChange={({ target }) => setMessage(target.value)}
                                placeholder={"Type msg here..."}
                            />
                        </FormField>
                        <FormField label='Ack'>
                            {
                                ACK_GROUPS.map((ACK_OPTS, i) =>
                                    <RadioGroup
                                        key={`AG_${i}`}
                                        name="doc"
                                        options={ACK_OPTS
                                            .map(({ label, ...rest }) => ({
                                                label: <Box style={{ minWidth: 100, ...rest }}>{label}</Box>,
                                                value: label
                                            }))
                                        }
                                        value={ackOpt}
                                        onChange={(event) => setAckOpt(event.target.value)}
                                    />
                                )
                            }

                            {/* <RadioGroup
                            name="doc"
                            options={ACK_OPTS
                                .filter(({ label }) => !label.includes("Day"))
                                .map(({ label }) => ({ label: <Box style={{minWidth:100}}>{label}</Box>, value: label }))
                            }
                            value={null}
                            onChange={(event) => console.info("ack", event.target.value)}
                        /> */}
                        </FormField>
                        <Box direction='row' justify='center'>

                            <Button
                                label='save'
                                primary
                                size='small'
                                type="submit"
                            disabled={processing || (!message && ackOpt === "No Change")}
                            onClick={() => {

                                setPayload(p => ({
                                    ...p,
                                    ack_msg: message,
                                    ack_to: ackOpt
                                }))

                                setProcessing(true)
                                console.info(
                                    message, 
                                    ackOpt,
                                    payload
                                )
                                // setProcessing(true)

                            }}
                            />
                        </Box>
                    </Form>

                </Box>
            </Layer> : null}


        </Box>
    )
}

export const AlertsTable = styled(DataTable)``;

export const CellBox = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  letter-spacing: -0.6px;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
//   min-width: ${props => props.minWidth ? props.minWidth : '50px'};

  & div {
      color: rgba(0,0,0,0.6);
      font-size: 10px;
     letter-spacing: 0.2px;
  };
`

const Header = styled.div`
    // border-left: ${props => props.bl ? '1px solid black' : ""}
`

const renderers = {
    default: (field) => (props) => {
        // console.info({field})
        return <CellBox
            maxWidth={field === 'campaign' ? '120px' : '100%'}
            title={props[field]}
        >{props[field]}</CellBox>
    },
    appBundle: () => (props) => {
        // console.info('appBundle', props)
        return <CellBox minWidth='220px' title={props.app_name}>
            {props.app_name}
            <div>{props.app_bundle}</div>
        </CellBox>

    },
    date: () => ({ date }) => { return Moment(date).format("DD-MM-YY") },
    number: (field) => (props) => {
        // console.info(props)
        return (
            <CellBox>
                {Numeral(props[field]).format("0,0")}
            </CellBox>
        )
    },
    percent: (field, override = null) => (props) => {

        // if (!props[field]) {
        //     console.info("empty", field, props)
        // }
        // if (override) {
        //     console.info({ field, override, props },)
        //     return (<CellBox onClick={() => alert("xl")}>
        //         Numeral(props[field]).format("0.[0]%")
        //     </CellBox>)

        // }

        return (
            <CellBox>
                {Numeral(props[field]).format("0.[0]%")}
            </CellBox>
        )
    },
}


const COLUMN_DEFS = [
    ['id', null, 'id'],
    ['date', null, 'date'],
    ['publisher', 'Pub'],
    ['campaign', null, null, '120px'],
    ['app_bundle', 'Bundle', 'appBundle', '220px'],
    ['imps', null, 'number'],
    ['regs', 'regs', 'number'],
    ['clicks', null, 'number'],
    ['installs', null, 'number'],
    ['bi', 'BI', 'number'],
    ['bi_pcnt', '%', 'percent'],
    ['bp', 'BP', 'number'],
    ['bp_pcnt', '%', 'percent'],
    ['ftd', 'FTD', 'number'],

]

export function getFooterData(data) {

    const sumFields = ['clicks', 'imps', 'installs', 'ftd', 'bi', 'bp']

    const sums = data
        .map((o) => {
            const obj = {};
            sumFields.forEach(f => obj[f] = +o[f])
            // console.info(obj)
            return obj
        }).reduce((a, b) => {
            const obj = {};
            sumFields.forEach(f => obj[f] = +a[f] + +b[f])
            return obj

        }, {
            clicks: 0,
            imps: 0,
            installs: 0,
            ftd: 0,
            bi: 0,
            bp: 0,
            bp_pcnt: 0,
            bi_pcnt: 0
        })


    return {
        ...sums,
        bp_pcnt: sums.bp / sums.installs,
        bi_pcnt: sums.bi / (sums.installs + sums.bi),

    }
}

export function getTableColumns({ data = null, groupBys, alertConfig, queryState, selectedRows, username }) {
    const footerData = getFooterData(data)
    // console.info({ footerData })
    return [
        ...COLUMN_DEFS.map(
            ([property, header, type, size]) => {
                const render = !type || !renderers[type] ?
                    renderers.default(property) :
                    renderers[type](property)

                // if (type === 'percent' && footerData[property]) {
                //     console.info(property, footerData[property], { [property]: footerData[property] })

                // }

                return ({
                    property,
                    header: <Header> {header || capitalize(property)}</Header>,
                    render,
                    size: size || '100px',
                    aggregate: type === 'number' ? "sum" : undefined,
                    footer: type === 'number' ? { aggregate: true }
                        : type === 'percent' && footerData[property] ?
                            Numeral(footerData[property]).format("0.[0]%")
                            // footerData[property]
                            : undefined,
                })
            }
        ).filter((c, i) => {
            if (i < 5 && !groupBys.includes(c.property)) {
                return false
            }
            return true
        }),
        !alertConfig.settings ? null :
            {
                property: 'actions',
                header: <Header>
                    <AckModal {...{
                        username,
                        queryState,
                        alertConfig,
                        rows: data.filter(r => selectedRows.includes(r.id)),
                    }} />
                </Header>,
                render: (row) => {
                    return (
                        <Box
                            style={{ minWidth: '120px' }}
                            align='center'
                            direction='row'>
                            <AckModal {...{
                                row,
                                username,
                                queryState,
                                alertConfig
                            }} />
                            {/* {row.ack_to ? <CellBox style={{ lineHeight: '12px' }} align='center'>
                                {Moment(row.ack_to).format('YYYY-MM-DD')}
                                <div>

                                    {row.user}
                                </div>
                            </CellBox> : null} */}
                        </Box>
                    )
                }
            },
        // !alertConfig.settings ? null :
        //     {
        //         property: 'ack_msg',
        //         header: "",
        //         render: (row) => {
        //             return (

        //                 <AckInput {...{ 
        //                     row, 
        //                     alertConfig, 
        //                     queryState,
        //                     username,

        //                  }} />

        //             )
        //         }
        //     }
    ].filter(o => o)

}

const FiltersModalRoot = styled(Box)`
    min-height: 150px;
    margin-bottom: 10px;
`
const FiltersCollapse = styled(Collapsible)`
    // min-height: 150px;

`

export const DEFAULT_FILTERS = [
    ['imps', 'Imps', '>', undefined],
    ['clicks', 'Clicks', '>', undefined],
    ['installs', 'Installs', '>', undefined],
    ['bi', 'BI Inst.', '>', undefined],
    ['bi_pcnt', 'BI Inst. %', '>', undefined],
    ['bp', 'BP Inst.', '>', undefined],
    ['bp_pcnt', 'BP Inst. %', '>', undefined],
];

export const getRowProps = (data, alertHash) => {
    let rowProps = {}
    // console.info({ data })
    if (alertHash && data) {
        data.rows.forEach(row => {
            if (row.hash && !row.no_ack) {
                rowProps[row.id] = {
                    background: 'rgba(0,0,0,0.1)'
                }
            }
        })
    };

    return rowProps

}

export const FiltersModal = ({ onChange, alertConfig }) => {

    const [filters, updateFilters] = React.useState(DEFAULT_FILTERS)

    // use alertconfig
    useEffect(() => {
        if (alertConfig.settings) {
            updateFilters(fs => fs.map(f => {
                const ex =
                    alertConfig.settings.filterBy.find(ef => ef[0] === f[0])

                if (ex) {
                    // console.info(ex, f, [...f.slice(-1), ex[1]])
                    return [...f.slice(0, 3), ex[1]]
                }
                return f
            }))
        }
    }, [alertConfig])


    return (
        <FiltersCollapse open>

            <FiltersModalRoot

                pad='small'
                gap='small'
                direction='row'
            >
                {filters.map(([field, fn, op, val]) => {
                    return (
                        <FormField label={`Min ${fn}`}>
                            <TextInput name={field} value={val} onChange={({ target }) => {
                                const number = target.value.replace(/\D/g, '');

                                const newFilters = filters.map(fa => {
                                    const isPcnt = fa[1].includes("%");

                                    if (fa[0] === target.name) {
                                        return [fa[0], fa[1], fa[2], isPcnt && +number > 100 ? 100 : number]
                                    }
                                    return fa
                                })

                                const newFilterBy = newFilters
                                    .filter(f => f[3])
                                    .map(f => ([f[0], f[3]]))

                                onChange(newFilterBy)
                                updateFilters(newFilters)



                            }} />
                        </FormField>
                    )
                })}
            </FiltersModalRoot>

        </FiltersCollapse>
    )

}


export const CreateAlertModal = ({ visible = true, toggle }) => {
    const [color, setColor] = React.useState({ r: 200, g: 150, b: 35, a: 0.5 });

    if (!visible) {
        return null
    }

    return (
        <Layer
            onEsc={() => toggle()}
            onClickOutside={() => toggle()}

            visible={visible}
            style={{ marginTop: -100 }}
            position='center'>
            <Box style={{ minWidth: '50vw' }} size='large' pad='small'>
                <Box direction='row' flex>
                    <Box flex>
                        <Heading level={2}>Create New Alert</Heading>
                    </Box>
                    <Box round="full" overflow="hidden"
                        style={{ position: 'absolute', right: 12, width: 50, height: 50 }}>
                        <Button icon={<Close />} hoverIndicator onClick={() => toggle()} />
                    </Box>
                </Box>
                <RgbaColorPicker color={color} onChange={setColor} />
                <AlertsTable
                    size='small'
                    rowProps={{ 1190685172: { background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` } }}
                    primaryKey={'id'}
                    columns={
                        getTableColumns({
                            data: MockData,
                            ...getGroupBys(DEFAULT_GROUP_BYS)
                        })}
                    data={MockData}
                />
            </Box>
        </Layer>
    )
}

export const NewAlertButton = ({ onClick }) => {
    return (
        <Box round="full" overflow="hidden" background="brand"
            style={{ position: 'absolute', right: 12, width: 50, height: 50 }}>
            <Button icon={<Add />} hoverIndicator onClick={onClick} />
        </Box>
    )
}


