import React from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: "100%",
    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.35)",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    // height: "100%",
  },
  chart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "100%",
    width: "100%",
    borderRadius: "5px",

    // margin: "7% 0%",
    backgroundColor: "white",
    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
  },
  chartHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "7%",
    backgroundColor: "#3c8dbc",
    padding: "5px 1px",
    color: "white",
    borderRadius: "5px 5px 0  0",
    // boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.35)",
    // zIndex: 100,
  },
  name: {
    // marginLeft: "1%",
    fontWeight: "400",
    fontSize: "14px",
    textTransform: "uppercase",
    marginLeft: "10px",
  },
  info: {
    // marginRight: "1%",
  },
}));

function ChartContainer(props) {
  const classes = useStyles();

  const { children, name } = props;

  return (
    <div className={classes.root}>
      <div className={classes.chartHeader}>
        <div className={classes.name}>{name}</div>
        {/* <InfoIcon className={classes.info} /> */}
      </div>
      <div className={classes.chartContainer}>
        <div className={classes.chart}>{children}</div>
      </div>
    </div>
  );
}

const Container = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "100%")};
  height: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.panel};
  margin: 10px;
  border: 1px solid ${({ theme }) => theme.border};
`;

export function NewChartContainer(props) {
  const { children, isMobile } = props;

  return <Container isMobile={isMobile}>{children}</Container>;
}

export default ChartContainer;
