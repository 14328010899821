import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SelectField from "./SelectField";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import TuneIcon from "@material-ui/icons/Tune";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#3c8dbc",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid lightgray",
    flexWrap: "wrap",
    padding: "0.2% 0",
    // height: "5%",
  },
  filtersButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filtersButton: {
    border: "1px solid lightgray",
    padding: "1%",
    borderRadius: "5px",
    margin: "0 2%",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#3c8dbc",
      color: "white",
    },
  },
  mobileFiltersContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // justifyContent: "flex-start",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FiltersDialog = (props) => {
  const classes = useStyles();

  const { open, setOpen, fields } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Filter
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.mobileFiltersContainer}>
          {fields &&
            fields.map((field) => (
              <SelectField options={field.options} label={field.label} />
            ))}
        </div>
      </Dialog>
    </div>
  );
};

function Filters(props) {
  const classes = useStyles();
  const { browser, fields } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.filtersContainer}>
      <FiltersDialog open={open} setOpen={setOpen} fields={fields} />
      {browser.width > 1201 ? (
        <div>
          {fields &&
            fields.map((field) => (
              <SelectField options={field.options} label={field.label} />
            ))}
        </div>
      ) : (
        <div className={classes.filtersButtonContainer}>
          <SelectField options={fields[0].options} label={fields[0].label} />
          <TuneIcon
            className={classes.filtersButton}
            onClick={() => setOpen(true)}
          />
        </div>
      )}
    </div>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  return { browser };
}

export default connect(mapStateToProps)(Filters);
