import React from "react";
import _ from "lodash";
import { Box, Button, Layer, Text } from "grommet";

function PopupDialog(props) {
  // Get props
  const {
    onEsc,
    header,
    content,
    footer,
    children,
    visible = true,
    onSubmit,
    extraButtons,
  } = props;


  // Set Component local state
  //
  //
  const [scrolled, setScrolled] = React.useState();
  // indicate if content is scrolled when scroll is enable - this is used for UI manipolation
  const [isScrolled, setIsScrolled] = React.useState(false);
  if (!visible) {
    return null
  }
  // On content panel is s
  const onScrolled = (e) => {
    if (!scrolled) {
      setScrolled(e.target.firstChild.getBoundingClientRect().top);
    }
    if (scrolled > e.target.firstChild.getBoundingClientRect().top) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <Layer
      style={{ width: "45%", height: "65%", borderRadius: "10px" }}
      onEsc={() => onEsc()}
    >
      <Box fill flex justify="between" round="medium">
        {/*  HEADER  */}
        <Box
          height="10%"
          align="center"
          pad="medium"
          direction="row"
          gap="small"
          elevation={isScrolled ? "large" : "none"}
          border={
            !isScrolled
              ? {
                  color: "#d1d1d1",
                  size: "xsmall",
                  side: "bottom",
                }
              : ""
          }
          style={{ zIndex: "20" }}
        >
          <Text size="20px" color="47484a" style={{ fontWeight: "500" }}>
            {_.capitalize(header)}
          </Text>
        </Box>

        {/*  CONTENT  */}
        <Box
          flex
          justify="between"
          style={{ overflowY: "auto" }}
          fill
          onScroll={(e) => onScrolled(e)}
        >
          {children}
          <Box
            height="10%"
            margin={{ bottom: "small" }}
            border={{ color: "#d1d1d1", size: "xsmall", side: "bottom" }}
          >
            {
              // this box is used for a UI
            }
          </Box>
        </Box>

        {/*  FOOTER  */}
        <Box
          height="10%"
          fill="horizontal"
          direction="row"
          justify="between"
          pad={{ right: "large" }}
        >
          <Box gap="medium" direction="row" margin={{ left: "large" }}>
            {extraButtons}
          </Box>
          <Box gap="large" pad="small" direction="row" align="center">
            <Button
              onClick={() => onEsc()}
              color="brand"
              hoverIndicator
              style={{ fontWeight: "500" }}
            >
              Cancel
            </Button>
            <Button
              style={{ fontWeight: "500" }}
              hoverIndicator
              onClick={() => {
                onSubmit();
                onEsc();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
}

export default PopupDialog;
