import Ax from "axios";

// Fetch skadnRedirects data
export const fetchConversionsData = (params = "") => {
  return async (dispatch, getState) => {
    dispatch({
      type: "CONVERSIONS_DATA_LOADING",
      payload: {
        loading: 1,
      },
    });
    const { advertisers, publishers } = getState()

    const hasAdvertisers = advertisers.advertisers.length;
    const hasPublishers = publishers.publishers.length;

    try {
      const apiUrl = `/api/conversions`;
      let parameters = {
        ...params,
        hasAdvertisers,
        hasPublishers
      }



      const response = await Ax.post(apiUrl, parameters);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
    }
  };
};

//
export const filterOffers = (params) => {
  console.log(params);
  return async (dispatch) => {
    dispatch({
      type: "CONVERSIONS_FILTERED",
      payload: {
        ...params,
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/offers`;
      const response = await Ax.post(apiUrl, params);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
      console.info(e.name);
    }
  };
};

export default {
  fetchConversionsData,
  filterOffers,
};
