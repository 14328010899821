import React from "react";
import { Button, Box, TextInput } from "grommet";
import { Search, AddCircle } from "grommet-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function Filters(props) {
  const {
    handleFiltersChange,
    open,
    direction,
    setOpen,
    fieldsRows,
    onChange,
    buttons,
    browser,
  } = props;

  return (
    <Box
      flex
      gap="10px"
      align="center"
      fill="horizontal"
      pad="2px"
      background="light-3"
      direction="row"
      wrap
    >
      {/*  ROW  */}
      {fieldsRows.map((fieldsRow) => {
        return fieldsRow.map((field) => {
          return field.field;
        });
      })}
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;

  return { browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
