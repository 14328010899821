import React from "react";
import { CheckBox, Box, Select, Text, TextInput, TextArea } from "grommet";
import ReadField from "../../components/ReadField";
import PasswordInput from "../../components/PasswordInput";
import _ from "lodash";

function fieldsGrouper(props) {
  const { fieldsGroup, onChange } = props;

  // field's label Style
  const readFieldStyle = {
    fontSize: "14px",
    fontWeight: "400",
    minWidth: "155px",
    height: "auto",
    color: "#878787",
  };

  // field's label Style
  const textInputStyle = {
    border: "1px solid #a3a3a3",
    fontWeight: "100",
    width: "100%",
    borderRadius: "1px",
    height: "3vh",
    color: "#878787",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "24px 48px",
      }}
    >
      <Box fill gap="none">
        {fieldsGroup.map((group) => {
          return (
            <Box gap="xsmall" margin={{ bottom: "10%" }}>
              <Text>{group.header}</Text>
              {group.fields.map((field, index) => {
                return (
                  <ReadField
                    fill={false}
                    fontSize="16px"
                    styleExtend={{ ...readFieldStyle }}
                    key={"publisher_field_" + field.name + "_" + index}
                    name={
                      field.label ||
                      _.capitalize(field.name).split("_").join(" ")
                    }
                  >
                    {!field.type ? (
                      <TextInput
                        disabled={field.disabled}
                        plain
                        required
                        name={field.name}
                        // autocomplete="nope"
                        // placeholder={field.name}
                        size="small"
                        value={field.value}
                        style={textInputStyle}
                        onChange={(event) => onChange(event)}
                      />
                    ) : field.type === "checkbox" ? (
                      <CheckBox
                        toggle
                        disabled={field.disabled}
                        label={_.capitalize(field.label).split("_").join(" ")}
                        name={field.name}
                        value={field.value}
                        onChange={(event) => onChange(event)}
                      />
                    ) : field.type === "textarea" ? (
                      <TextArea
                        disabled={field.disabled}
                        label={_.capitalize(field.label).split("_").join(" ")}
                        name={field.name}
                        value={field.value}
                        onChange={(event) => onChange(event)}
                      />
                    ) : field.options ? (
                      <Select
                        // plain
                        disabled={field.disabled}
                        size="small"
                        name={field.name}
                        // placeholder={field.name}
                        value={field.value}
                        options={field.options}
                        onChange={(event) => onChange(event)}
                        style={{
                          ...textInputStyle,
                          border: "none",
                          borderRight: "1px solid #d1d1d1",
                        }}
                      />
                    ) : (
                      <PasswordInput
                        disabled={field.disabled}
                        required
                        name={field.name}
                        // autocomplete="nope"
                        // placeholder={field.name}
                        size="small"
                        height="3vh"
                        value={field.value}
                        border={true}
                        // style={textInputStyle}
                        onChange={(event) => onChange(event)}
                      />
                    )}
                  </ReadField>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

export default fieldsGrouper;
