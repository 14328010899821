import React from "react";
import { Box, Button } from "grommet";
// import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "moment";
import { Close } from "grommet-icons";
import styled from "styled-components";
import FilterField from "./FilterField";

function MobileButton(props) {
  const MButton = styled(Button)`
    border-radius: 0;
  `;
  return (
    <Box fill="horizontal">
      <MButton style={{ height: 40 }} onClick={props.onClick} primary>
        <Box direction="row" pad="6px" gap="6px">
          <Box style={{ fontSize: 14, fontWeight: 700 }}>{props.label}</Box>
          <Box style={{ fontSize: 12, fontStyle: "italic" }}>{props.desc}</Box>
        </Box>
      </MButton>
    </Box>
  );
}

function Filters(props) {
  //
  // props
  //
  const { browser, handleSubmit, fields, firstLoad, onFirstLoad } = props;
  //
  // Component local state
  //
  const [mobileMenu, toggleMobileMenu] = React.useState(false);
  const [fieldRows, setFieldsRows] = React.useState();
  const [criteria, setCriteria] = React.useState({
    fromDate: Moment.utc().startOf("day").format(),
    toDate: Moment.utc().format(),
  });

  // Mobile ?
  const isMobile = !browser.greaterThan.medium;

  // Component did mount
  React.useEffect(() => {
    //
    //
    // Create criteria
    //
    let newCriteria = {};

    // For every field row
    fields.forEach((fieldRow) => {
      // For every field def in field row...
      fieldRow
        .filter((f) => !f.external)
        .forEach((field) => {
          if (field.value) {
            newCriteria[field.name] = field.value;
          }
        });
    });

    if (Object.keys(newCriteria).length) {
      newCriteria = {
        ...criteria,
        ...newCriteria,
      };
      setCriteria(newCriteria);
    }

    // Add toggle filters button for hiding filters from view
    if (!fieldRows && toggleMobileMenu) {
      const [firstRow, ...restRows] = fields;
      let f = [
        [
          {
            name: "closeFilters",
            type: "button",
            onClick: () => toggleMobileMenu(false),
            icon: <Close size="10px" color="white" />,
            mobile: true,
            margin: "10px 10px 10px 10px",
          },
          ...firstRow,
        ],
        ...restRows,
      ];
      setFieldsRows(f);
    }
  }, [
    fields,
    criteria,
    setCriteria,
    handleSubmit,
    firstLoad,
    onFirstLoad,
    isMobile,
    fieldRows,
  ]);

  //
  // When View on mobile
  // hide filters by default and In their place
  // render toggle button to expose them
  // if (isMobile && !mobileMenu) {
  //   return (
  //     <MobileButton
  //       onClick={() => toggleMobileMenu(!mobileMenu)}
  //       label="FILTERS"
  //       desc={`from: ${Moment(criteria.fromDate).format(
  //         "YYYY-MM-DD"
  //       )} | to: ${Moment(criteria.toDate).format("YYYY-MM-DD")}`}
  //     />
  //   );
  // }
  if (isMobile && !mobileMenu) {
    return null;
  }

  // Handle filters change
  function handleFiltersChange(e, valueObj) {
    let newValues = {};
    let newValue = null;

    if (valueObj) {
      // DIRECT STATE UPDATE (Value Obj)
      newValues = {
        ...criteria,
        ...valueObj,
      };
    } else {
      //  HANDLE EVENT
      const { value, name, type, checked } = e.target;

      newValue = (e.option && e.option) || e.value ? e.value : value;

      if (type === "checkbox") {
        newValue = checked;
      }

      if (typeof newValue === "object" && newValue.label) {
        newValue = newValue.value;
      }

      if (name === "rank") {
        newValue = e.value || [];
      }

      if (["placements", "internalStatus"].indexOf(name) > -1) {
        newValue = e.value.value || 0;
      }

      if (
        ["advertiser", "countries"].indexOf(name) > -1 &&
        typeof newValue !== "object"
      ) {
        newValue = [];
      }

      if (name === "offerSearch") {
        return setCriteria(newValues);
      }
      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };
    }

    setCriteria(newValues);
    handleSubmit(newValues);
  }

  //
  //
  // Determine fields style
  // by the viewport size
  //
  // Field's width
  const fieldWidth =
    browser.width < 450 ? "25%" : browser.width < 1201 ? "8vw" : "5vw";
  // Field's justify
  const justifyFields = browser.width < 770 ? "center" : "start";

  return (
    <Box width="100%" pad="2px 5px">
      {fieldRows &&
        fieldRows.map((fieldRow, i) => {
          return (
            <Box
              key={`field_row_${i}`}
              fill
              wrap
              // gap="5px"
              align="start"
              direction="row"
              // justify={justifyFields}
            >
              {fieldRow.map((field, index) => {
                // If screen width is larger than 1201
                // filter fields/buttons that need to appear only on mobile
                if (browser.width < 1201 || !field.mobile) {
                  // field container style
                  // For unity view
                  const fieldContainerStyle = {
                    margin: "2px",
                    width: field.width,
                    // push the last button right
                    marginLeft: index === fieldRow.length - 1 ? "auto" : "",
                  };
                  return (
                    <Box key={`field_${index}`} style={fieldContainerStyle}>
                      <FilterField
                        criteria={criteria}
                        handleFiltersChange={handleFiltersChange}
                        field={field}
                      />
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          );
        })}
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;

  return { browser };
}

// Mapping Action functions into React Component Props.
// function mapDispatchToProps(dispatch) { }

export default connect(mapStateToProps, null)(Filters);
