
import React, { useEffect, useState } from "react";

import Numeral from "numeral";

import {
    Box,

} from "grommet";


export function getFormattedNumeral(value, symbol = "", fraction = "") {
    return Numeral(value).format(`0,0${fraction}${symbol}`);

}


export function TableCell({ primary, secondary, color }) {

    const style = {
        ...(color ? { color } : {})
    }

    return (<Box style={style}>
        {primary}
        {secondary ? <Box
            style={{
                maxWidth: "110px",
                overflow: "hidden",
                fontSize: 12,
                lineHeight: 1,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "inline",
                color: 'gray'
            }}
        >
            {secondary}
        </Box> : null}
    </Box>)
}

const exports = {
    getFormattedNumeral,
    TableCell
}

export default exports