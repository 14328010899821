import { Button as ButtonGrom } from "grommet";
import styled from "styled-components";

const Button = styled(ButtonGrom)`
  // SIZES
  min-width: ${(props) => props.width || "30px"};
  min-height: ${(props) => props.height || "10px"};

  // TEXT COLOR
  color: ${(props) =>
    props.color ? props.color : props.primary ? "white" : "white"};

  //
  //BACKGROUND COLOR
  //
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.primary
      ? "#3c8dbc"
      : "#3c8dbc"};

  //
  // FONT
  //
  font-size: ${(props) => props.fontSize || "10px"};
  font-weight: ${(props) => props.fontWeight || "100"};
  text-align: center;
  line-height: 18px;

  //
  // BOX
  //
  padding: ${(props) => props.padding || "2px"};
  //   margin-right: 5px;

  //
  //BORDER
  //
  border: ${(props) =>
    props.border ||
    `2px solid ${props.borderColor ? props.borderColor : "#3c8dbc"}`};
  border-radius: ${(props) => props.borderRadius || "5px"};

  //
  // BOX SHADOW
  //
  //   -webkit-box-shadow: ${(props) =>
    props.xBoxShadow ? "1px 3px 16px -1px rgba(0, 0, 0, 0.35)" : ""};
  // box-shadow: ${(props) =>
    !props.xBoxShadow ? "1px 2px 4px 1px rgba(0, 0, 0, 0.5)" : ""};

  // TRANSACTIONS
  transition: 0.3s;

  // ON ACTIVE
  &:active {
    transform: scale(0.9);
  }

  // HOVER EFFECT
  &:hover {
    border: ${(props) => props.borderHover || "2px solid #286182"};
    background-color: ${(props) => props.backgroundColorHover || "#286182"};
    color: ${(props) => props.colorHover || "white"};
    box-shadow: inset 0px 0px 2px 1px
      ${(props) => props.borderHover || " #286182"}; //0px 0px 3px 1px rgba(105, 191, 240);
  }

  // ON FOCUS
  &:focus {
    box-shadow: 0px 0px 2px 1px rgba(105, 191, 240);
  }
`;

export default Button;
