import React from "react";

import { Box, Text } from "grommet";

const ReadField = (props) => {
  const {
    name,
    value,
    desc,
    children,
    fontSize,
    labelMinWidth,
    styleExtend,
    fontWeight,
    fill,
  } = props;
  if (!name || (!value && !children)) {
    return null;
  }

  const toFill = fill && false;

  return (
    <Box
      fill={toFill}
      direction="row"
      gap="xxsmall"
      align="start"
      pad="xsmall"
      // alignItems='start'
    >
      <Text
        style={{
          fontWeight: fontWeight || "bold",
          minWidth: labelMinWidth || "160px",
          fontSize: fontSize || "18px",
          ...styleExtend,
        }}
      >{`${name} `}</Text>
      {value ? (
        <Box align="center" gap="small" direction="row">
          <Text>{value}</Text>
          {desc ? <Text size="small">{desc}</Text> : null}
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default ReadField;
