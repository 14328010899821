import React from "react";

import Numeral from "numeral";

import MomentTZ from "moment-timezone";
import _ from "lodash";

import Cell from "../../components/Cell";

export function getTableColumns(
  reportData,
  { groupBy },
  sumClicks,
  showPercents = false,
  showBlockedEvents = false
) {

  // For FOOTER Percentage FIX
  const isChangeColumn = reportData.filter((o) => o.change_p).length;

  const checkFooterRow = (props) => sumClicks === props.clicks;

  // FOR GROUPBY percentage FIX

  let groupBySum = null;

  if (groupBy) {
    groupBySum = _.groupBy(reportData, groupBy);
    for (const groupEntity in groupBySum) {
      groupBySum[groupEntity] = groupBySum[groupEntity]
        .map((o) => o.clicks)
        .reduce((a, b) => a + b);
    }
  }

  const isGroupByRow = (props) => {
    const { clicks } = props;
    const groupVal = props[groupBy];
    const isGroupRow = groupBySum[groupVal] === clicks;
    return isGroupRow;
  };

  const defaultRenderer =
    (propName, type = "", color = "") =>
      (props) => {
        let value = props[propName.toLowerCase()];
        let extraStyle = {};
        const isFooterRow = checkFooterRow(props);
        if (propName === "change_p") {
          type = "change_p";
        }

        if (type === "percent") {
          if (isFooterRow || (groupBy && isGroupByRow(props))) {
            const [numeralField] = propName.split("_p");
            const realPercent = props[numeralField] / props.ti;
            value = realPercent;
          }
          if (value === 1)
            extraStyle = Object.assign(extraStyle, { fontWeight: 700 });
          // FOOTER PERCENT FIX
        }

        if (type === "numeral") {
          value = Numeral(value).format("0,0");
        } else if (type === "currency") {
          extraStyle = Object.assign(extraStyle, {
            fontWeight: 500,
            fontSize: "11px",
            letterSpacing: "1px",
          });
          value = Numeral(value).format("0,0.0$");
        }

        if (propName === "campaign" && value && value.length > 25) {
          value = value.slice(0, 22) + "...";
        } else if (type === "decimalp") {
          // console.info({decimal: value})
          value = Numeral(value).format(value > 0 ? "0.[000]" : "0");
        } else if (type === "percent") {
          value = Numeral(value).format(value > 0 ? "0.[0]%" : "0");
        }

        if (isFooterRow) {
          extraStyle = { fontSize: "11px", fontWeight: 600 };
        }

        if (propName === "change_p") {
          if (!value) value = 0;
          let isNegative = value < 0;
          if (isNegative) {
            value = value * -1;
            extraStyle = { color: "red" };
          } else if (value > 0) {
            extraStyle = { color: "green" };
          }
          const fmt = Numeral(value).format("0,0.[0]%");
          // console.info(value, isNegative, fmt)
          value = fmt;
        }

        let percentStyle = { paddingLeft: 4 };
        let changePercent = props[`${propName}_chg_pcnt`];
        if (changePercent) {
          if (!changePercent) changePercent = 0;
          let isNegative = changePercent < 0;
          if (isNegative) {
            changePercent = changePercent * -1;
            percentStyle.color = "red";
          } else if (changePercent > 0) {
            percentStyle.color = "green";
          }
          const fmt = Numeral(changePercent).format("0,0.[0]%");
          // console.info(changePercent, isNegative, fmt)
          changePercent = fmt;
        }

        if (propName === 'f360_cc') {
          if (value === "500") {
            // console.info(propName, value, +value)
            color = "red";
            extraStyle = {...extraStyle, fontWeight:500}
          } else {
            color = ""
          }
        } 
        return (
          <Cell
            flex
            color={color}
            direction="row"
            {...extraStyle}
            type={type}
            align="start"
          >
            <p>{value}</p>
            {showPercents && changePercent ? (
              <sup style={percentStyle}>
                {/* {percentStyle.color === "green" ? "↑" : "↓"} */}
                {changePercent}
              </sup>
            ) : null}
          </Cell>
        );
      };

  const namer = (propName) => {
    switch (propName) {
      case "impressions":
        return "IMPS";
      case "registrations":
        return "REG";
      case "installs":
        return "INST";
      case "publisher":
        return "PUB";
      case "profit":
        return "NET";
      case "revenue":
        return "REV";
      case "country":
        return "GEO";
      case "ftd":
        return "FTD";
      case "bt_p":
        return "BT%";
      case "bi_p":
        return "BI%";
      case "bp_p":
        return "BP%";
      case "bp":
      case "bt":
      case "bi":
        return propName.toUpperCase();
      case "ti":
        return "TOTAL";
      case "ber_ftd":
        return 'BRF'
      case "bep_ftd":
        return 'BPF'
      case "ber_reg":
        return 'BRR'
      case "bep_reg":
        return 'BPR'
      case "f360_bc":
        return 'BC'
      case "f360_cc":
        return 'CC'

      default:
        return propName.toUpperCase();
    }
  };

  const simpleColumns = [
    //   "publisher",
    "campaign",
    "country",
  ];


  const blockedEventsCol = showBlockedEvents ? [
    "registrations",
    "ber_reg",
    "bep_reg",
    "ftd",
    "ber_ftd",
    "bep_ftd",
  ] : [
    "registrations",
    "ftd",
  ]

  // console.info({showBlockedEvents, blockedEventsCol})
  let numeralColumns = [
    "impressions",
    "clicks",
    "f360_bc",
    "f360_cc",
    "ti",
    "installs",
    "bi",
    "bi_p",
    "bp",
    "bp_p",
    "bt",
    "bt_p",
    ...blockedEventsCol,
    // "registrations",
    // "ftd",
  ];

  // console.info({numeralColumns})
  // if (isChangeColumn) {
  //   numeralColumns.splice(2, 0, "change_p")
  // }

  const currencyColumns = [
    {
      prop: "revenue",
      color: "green",
    },
    {
      prop: "cost",
      color: "red",
    },
    {
      prop: "profit",
      color: "blue",
    },
  ];

  const columnDefs = [
    {
      property: "date",
      header: "DATE",
      render: ({ date }) => (
        <Cell style={{ whiteSpace: "pre" }}>
          {date && MomentTZ(date).format("YYYY-MM-DD")}
        </Cell>
      ),
    },
    {
      property: "app_bundle",
      header: "BUNDLE",
      render: ({ app_bundle, app_name }) => {
        // console.info({app_name})
        return (

          <Cell style={{ whiteSpace: "pre", fontWeight: 500 }}>
            {app_name}
            <Cell
              style={{
                maxWidth: "110px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "inline",
              }}
            >
              {app_bundle}
            </Cell>
          </Cell>
        )
      }
    },
    {
      property: 'partner',
      header: "AG. / Partner",
      render: ({ isAdn, partner, agency, publisher }) => {

        let value = partner || agency

        if (isAdn) {
          // console.info("agency", value, partner, agency, publisher)
          if (
            value === publisher
            || publisher.toLowerCase() === 'restricted'
          ) {
            value = ""
          }
        }


        return <Cell
          style={{
            color: "black",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >{value}</Cell>
      }
    },
    {
      property: "publisher_group",
      header: "PUB GROUP",
      render: ({ publisher_group }) => {
        return <Cell
          style={{
            color: "black",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >{publisher_group}</Cell>
      }
    },
    {
      property: "publisher",
      header: "PUBLISHER",
      render: ({ publisher, pid, isAdn }) => {
        const isGroup = !publisher;
        const label = isGroup ? "Group" : isAdn ? "AD" : "AG";
        const color = isGroup ? "black" : isAdn ? "red" : "blue";

        const isRestricted = (publisher || "").toLowerCase() === 'restricted';
        return (
          <Cell
            style={{
              color: "white",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Cell
              style={{
                paddingLeft: "2px",
                color: "black",
                fontSize: '10px'
              }}
            >
              {isRestricted ? `${pid}` : (publisher || "").toUpperCase()}
              {isRestricted ? <span
                style={{
                  fontSize: '8px',
                  lineHeight: '8px',
                  position: 'relative',
                  marginTop: '-5px',
                  letterSpacing: '0.5px',
                  fontStyle: 'italic',
                }}

              >restricted</span> : null}
            </Cell>
            <div
              style={{
                backgroundColor: color,
                borderRadius: "50%",
                height: "12px",
                width: "12px",
                fontSize: "7px",
                marginLeft: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {label}
            </div>
          </Cell>
        );
      },
    },
    ...simpleColumns.map((propName) => ({
      property: propName,
      header: namer(propName),
      render: defaultRenderer(propName),
      // footer: "x"
    })),
    ...numeralColumns.map((propName) => {
      const isPercentage = propName.indexOf("_p") > -1;
      return {
        property: propName,
        header: namer(propName),
        render: defaultRenderer(propName, isPercentage ? "percent" : "numeral"),
        aggregate: "sum",
        footer: { aggregate: isPercentage ? true : true },
      };
    }),
    ...currencyColumns.map(({ prop, color }) => ({
      property: prop,
      header: namer(prop),
      render: defaultRenderer(prop, "currency", color),
      aggregate: "sum",
      footer: { aggregate: true },
    })),
  ];
  // const model = {}
  // columnDefs.forEach(o => model[o.property] = 1 / columnDefs.length);
  // console.info(JSON.stringify(model))
  return columnDefs;
}

export function getRowProps(data) {
  let rowProps = {
    123456789: {
      background: "black",
    },
  };

  return rowProps;
}

export function getGroupByOpts(groupBy) {
  return [
    (groupBy !== "id" ? { label: "None", value: "id" } : undefined),
    { label: "App Bundle", value: "app_bundle" },
    { label: "App Name", value: "app_name" },
    { label: "AG. / PID", value: "agency" },
    { label: "Campaign", value: "campaign" },
    { label: "Publisher", value: "publisher" },
    { label: "Pub Group", value: "publisher_group" },
    { label: "Date", value: "date" },
    { label: "Counry", value: "country" },


    // // "partner",
    // "date",
    // "country",
  ].filter((s) => s);
}

const functions = {
  getRowProps,
  getTableColumns,
  getGroupByOpts,
};
export default functions;
