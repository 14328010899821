import React, { PureComponent, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Numeral from "numeral";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = [
  "#54478c",
  "#2c699a",
  "#048ba8",
  "#0db39e",
  "#16db93",
  "#83e377",
  "#b9e769",
  "#efea5a",
  "#f1c453",
  "#f29e4c",
];
const getColor = (index) => (index < 9 ? COLORS[index] : COLORS[9]);
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name: index,
    startAngle,
    endAngle,
    payload,
    percent,
    value,
  } = props;

  const fill = getColor(index);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  // console.info({ payload });
  // const text = payload.publisher ? payload.publisher :
  const text = payload.publisher ? payload.publisher : payload.app_name;
  const subtext = payload.publisher ? null : payload.app_bundle;
  return (
    <g>
      {/* <g>
        <text
          fontSize="14px"
          fontWeight="700"
          x={"15%"}
          y={"10%"}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {text}
          {subtext ? (
            <tspan x={"20%"} y={"10%"} dy={24} fontSize="12px">
              {subtext}
            </tspan>
          ) : null}
        </text>
      </g>
      <filter x="0" y="0" width="1" height="1" id="solid">
        <feFlood flood-color="rgba(255, 255, 255, 1)" result="bg" />
        <feMerge>
          <feMergeNode in="bg" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      /> */}
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <g>
        <text
          // x={"15%"}
          // y={"55%"}
          filter="url(#solid)"
          textAnchor={textAnchor}
          fill="#4F6272"
        >
          {Numeral(value).format("$0,0")}
        </text>
        <text
          x={"85%"}
          y={"58%"}
          dy={18}
          filter="url(#solid)"
          textAnchor={textAnchor}
          fill="#43AA8B"
        >
          {Numeral(payload.revenue).format("$0,0")}
        </text>
        <text
          x={"85%"}
          y={"61%"}
          dy={36}
          filter="url(#solid)"
          textAnchor={textAnchor}
          fill="#EF3054"
        >
          {Numeral(payload.cost).format("$0,0")}
        </text>
        <text
          x={"85%"}
          y={"64%"}
          dy={54}
          filter="url(#solid)"
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g> */}
    </g>
  );
};

export default function ActivePieChart(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [overed, setOverd] = React.useState({});
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
    console.log(_);
    setOverd(_);
  };

  const { profitPub, profitBundle } = props;

  React.useEffect(() => {
    if (profitPub && profitPub.length > 0) {
      setOverd(profitPub[0]);
    }
    if (profitBundle && profitBundle.length > 0) {
      setOverd(profitBundle[0]);
    }
  }, [profitPub, profitBundle]);

  const data = props.profitPub || props.profitBundle;

  // process.exit(0);
  return (
    // <ResponsiveContainer width={"100%"} height={400}>
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          // backgroundColor: "RGB(227, 227, 227, 0.2)",
          // border: "1px solid lightgray",
          borderRadius: "5px 0 0 0",
          padding: "10px 12px",
          position: "absolute",
          left: "0",
          bottom: "0",
          display: "flex",
          flexDirection: "column",
          zIndex: 10,
        }}
      >
        <div
          style={{
            color: overed && overed.fill,
            fontWeight: "600",
            fontSize: props.maxWidth < 1500 ? "10px" : "13px",
          }}
        >
          {overed && overed.app_name ? overed.app_name : overed.publisher}
        </div>
        <div
          style={{
            color: overed && overed.fill,
            fontWeight: "600",
            fontSize: props.maxWidth < 1500 ? "9px" : "11px",
          }}
        >
          {overed && overed.publisher ? null : overed.app_bundle}
        </div>

        {/* <div>  {`${(percent * 100).toFixed(2)}%`}</div> */}
      </div>

      {Object.keys(overed).length > 0 && (
        <div
          style={{
            // backgroundColor: "RGB(227, 227, 227, 0.2)",
            border: "1px dashed lightgray",
            borderRadius: "5px",
            minWidth: "10%",
            padding: props.maxWidth < 1500 ? "3px 7px" : "4px 6px",
            position: "absolute",
            right: 5,
            bottom: 5,
            display: "flex",
            flexDirection: "column",
            fontWeight: props.maxWidth < 1500 ? "500" : "400",
            alignItems: "center",
            width: props.maxWidth < 1500 ? "25%" : "25%",
            fontSize: props.maxWidth < 1500 ? "7px" : "10px",
            zIndex: 100,
            backgroundColor: "RGB(255, 255, 255, 0.5)",
          }}
        >
          <div
            style={{
              color: "#43AA8B",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>REVENUE</div>
            <div>{`${Numeral(overed.revenue).format("$ 0,0")}`}</div>
          </div>
          <div
            style={{
              color: "red",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>COST</div>
            <div> {`$ ${overed.cost}`}</div>
          </div>
          <div
            style={{
              color: "#2c699a",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>PROFIT</div>
            <div>{`${Numeral(overed.profit).format("$ 0,0")}`}</div>
          </div>
          {/* <div>  {`${(percent * 100).toFixed(2)}%`}</div> */}
        </div>
      )}

      <PieChart
        width={props.maxWidth < 1500 ? 200 : 400}
        height={props.maxWidth < 1500 ? 200 : 300}
        onClick={() => console.info(data[activeIndex])}
      >
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx='50%'
          cy='50%'
          innerRadius={props.maxWidth < 1500 ? 30 : 60}
          outerRadius={props.maxWidth < 1500 ? 70 : 110}
          fill='#8884d8'
          dataKey='profit'
          onMouseEnter={onPieEnter}
          onMouseOver={(e) => setOverd(e)}
        >
          {data.map((entry, index) => {
            return (
              <Cell
                id={entry.app_bundle}
                key={`cell-${index}`}
                fill={getColor(index)}
              />
            );
          })}
        </Pie>
      </PieChart>
    </div>
  );
}
