import React, { PureComponent, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Scatter,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  ScatterChart,
  ComposedChart,
  Area,
  Bar,
  Line,
  BarChart,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  Brush,
} from "recharts";
import moment from "moment";
import Numeral from "numeral";
import _ from "lodash";
import Panel from "./ActivePieChartPanel";
import styled from "styled-components";
import {
  BarChart as BarChartIcon,
  Analytics as LineChartIcon,
  Paint as PaintIcon,
} from "grommet-icons";
import { connect } from "react-redux";
import { colors } from "@material-ui/core";

// const COLORS = ["#646464", "#575757", "#9c9c9c", "#d1d1d1", "#949494"];
const getColor = (index, colors) => (index < 4 ? colors[index] : colors[4]);
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name: index,
    startAngle,
    endAngle,
    payload,
    percent,
    value,
    darkTheme,
    colors,
  } = props;

  const fill = getColor(index, colors);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  // console.info({ payload });
  // const text = payload.publisher ? payload.publisher :
  const text = payload.app_bundle;
  const subtext = payload.app_name;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor='middle'
        fill={"#FFF"}
        fontSize='9px'
        fontWeight={"700"}
      >
        {/* {payload.publisher || payload.app_name}
        {payload.app_bundle && payload.app_bundle} */}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke={darkTheme ? "rgb(255,255,255,0.8)" : "rgb(0,0,0,0.2)"}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={darkTheme ? "rgb(255,255,255,0.2)" : "rgb(0,0,0,0.2)"}
        // stroke={"#FFF"}
      />
    </g>
  );
};

//
function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
};

//
const CustomTooltip = (props) => {
  const { active, payload, label, color } = props;
  const dateName = moment(label).format("D MMMM YYYY");
  console.log(payload);
  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <p
          className='label'
          style={{ textAlign: "center", fontWeight: 500, fontSize: 16 }}
        >
          <div>{payload[0].payload.app_name}</div>
          <div style={{ textAlign: "center", fontWeight: 300, fontSize: 12 }}>
            {payload[0].payload.app_bundle}
          </div>
        </p>
        {payload.map((p) => (
          <div
            key={p.dataKey}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <DotColorTooltip
                color={p.dataKey === "profit" ? p?.stroke : p?.fill}
              />
              <p className='label'>{`${_.capitalize(p.dataKey)}`}</p>
            </div>
            <p className='label'>{`${p.value}`}</p>
          </div>
        ))}
      </ToolTipContainer>
    );
  }

  return null;
};

//
const CustomizedYAxisTick = ({ x, y, stroke, payload, theme }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-12}
        y={0}
        dy={0}
        textAnchor='end'
        fill={theme === "dark" ? "#efefef" : "#323232"}
        fontSize={10}
        fontWeight={500}
      >
        {convertToInternationalCurrencySystem(payload.value)}
      </text>
    </g>
  );
};

//
const CustomizedAxisTick = ({ x, y, stroke, payload, theme }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={-10}
        dy={22}
        textAnchor='middle'
        fill={theme === "dark" ? "#efefef" : "#323232"}
        fontSize={10}
      >
        {payload.value}
      </text>
    </g>
  );
};

function ActivePieChart(props) {
  // const [overed, setOverd] = useState({});
  const { profitPub, profitBundle, title, theme, isMobile } = props;
  const [dataDisplay, setDataDisplay] = React.useState("pie");
  const [activeIndex, setActiveIndex] = useState(0);
  const [overed, setOverd] = React.useState({});
  const [isPainted, setIsPainted] = React.useState(true);

  React.useEffect(() => {
    if (profitPub && profitPub.length > 0) {
      setOverd(profitPub[0]);
    }
    if (profitBundle && profitBundle.length > 0) {
      setOverd(profitBundle[0]);
    }
  }, [profitPub, profitBundle]);

  // Data for the charts
  const data =
    props.profitPub ||
    props.profitBundle.sort(function (a, b) {
      return b.profit - a.profit;
    });

  // On chart's slice mouse hover
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
    setOverd(_);
  };

  // Bars or Lines Chart
  const setChartView = (entity) => {
    setDataDisplay(dataDisplay === "pie" ? "combined" : "pie");
  };

  const DARK_COLORS = ["#424242", "#616161", "#757575", "#9e9e9e", "#bdbdbd"];
  const LIGHT_COLORS = ["#757575", "#8a8a8a", "#9e9e9e", "#bdbdbd", "#c9c9c9"];
  const PAINT_COLORS = [
    "#54478c",
    "#2c699a",
    "#048ba8",
    "#0db39e",
    "#16db93",
    "#83e377",
    "#b9e769",
    "#efea5a",
    "#f1c453",
    "#f29e4c",
    // "#db6a5e",
    // "#517dc9",
    // "#75cc50",
    // "#51c492",
    // "#5abf4d",
    // "#d9db60",
    // "#b65ed1",
    // "#5e96d1",
    // "#db5ec9",
    // "#715edb",
  ];

  const COLORS = isPainted
    ? PAINT_COLORS
    : props.theme === "light"
    ? LIGHT_COLORS
    : DARK_COLORS;
  const getColor = (index) =>
    index < (isPainted ? 9 : 4) ? COLORS[index] : COLORS[isPainted ? 9 : 4];

  // icon for the chart display button
  // bars or lines
  const buttonIcon =
    dataDisplay === "pie" ? (
      <BarChartIconCustom size='18px' />
    ) : (
      <LineChartIconCustom size='18px' />
    );

  return (
    <>
      <ButtonsContainer>
        <Title>{title && _.capitalize(title)}</Title>
        <Buttons>
          {/* <Button s\ */}
          <Button selected={true} onClick={() => setChartView()}>
            {buttonIcon}
          </Button>
        </Buttons>
      </ButtonsContainer>
      <ContentContainer mobile={isMobile}>
        <ChartContainer isMobile={isMobile}>
          <ResponsiveContainer width={"95%"} height={300}>
            {dataDisplay === "pie" ? (
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx='50%'
                  cy='50%'
                  innerRadius={isMobile ? 70 : 90}
                  outerRadius={isMobile ? 110 : 130}
                  fill='#8884d8'
                  dataKey='profit'
                  onMouseEnter={onPieEnter}
                  onMouseOver={(e) => setOverd(e)}
                >
                  {data.map((entry, index) => {
                    return (
                      <Cell
                        id={entry.app_bundle}
                        key={`cell-${index}`}
                        fill={getColor(index)}
                        colors={COLORS}
                        stroke={
                          props.theme === "light"
                            ? "rgb(0,0,0,0.2)"
                            : "rgb(255,255,255,0.4)"
                        }
                        darkTheme={props.theme !== "light"}
                      />
                    );
                  })}
                </Pie>
              </PieChart>
            ) : (
              //   <BarChart width={500} data={data}>
              //   <Bar dataKey='cost' onClick={(index) => {alert()}}>
              //     {data.map((entry, index) => (
              //       <Cell
              //         cursor='pointer'
              //         fill={"#8884d8"}
              //         key={`cell-${index}`}
              //       />
              //     ))}
              //   </Bar>
              // </BarChart>

              <ComposedChart
                // layout='vertical'
                width={500}
                data={data}
                // style={{ padding: "10px 0" }}
              >
                {/* <CartesianGrid stroke='#f5f5f5' /> */}
                <XAxis tick={<CustomizedAxisTick theme={theme} />} />
                {/* <YAxis tick={<CustomizedYAxisTick theme={theme} />} /> */}
                <CartesianGrid strokeDasharray='3 3' />
                <Brush
                  dataKey='name'
                  height={isMobile ? 25 : 15}
                  stroke='#72ABCC'
                  startIndex={1}
                  endIndex={20}
                  fill={theme === "dark" ? "#303030" : "#ededed"}
                  // y={200}
                />
                {/* <Tooltip
                  content={
                    <CustomTooltip theme={theme} colors={props.colors} />
                  }
                /> */}
                {/* <Area
                  dataKey='cost'
                  fill={
                    props.colors[
                      props.colors.findIndex((c) => c.name === "cost")
                    ].color
                  }
                  // stroke='#8884d8'
                /> */}

                <Bar
                  dataKey='profit'
                  onMouseOver={(e) => setOverd(e)}
                  onMouseEnter={onPieEnter}
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  stackId='a'
                >
                  {data.map((entry, index) => (
                    <Cell
                      cursor='pointer'
                      fill={"#2c699a"}
                      key={`cell-${index}`}
                    />
                  ))}
                </Bar>
                <Bar
                  dataKey='cost'
                  onMouseOver={(e) => setOverd(e)}
                  onMouseEnter={onPieEnter}
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  stackId='a'
                >
                  {data.map((entry, index) => (
                    <Cell
                      cursor='pointer'
                      fill={"#ff1c1c"}
                      key={`cell-${index}`}
                    />
                  ))}
                </Bar>

                {/* {data.map((entry, index) => {
                    return (
                      <Cell
                        id={entry.app_bundle}
                        key={`cell-${index}`}
                        fill={getColor(index)}
                        colors={COLORS}
                        stroke={
                          props.theme === "light"
                            ? "rgb(0,0,0,0.2)"
                            : "rgb(255,255,255,0.4)"
                        }
                        darkTheme={props.theme !== "light"}
                      />
                    );
                  })} */}
                {/* <Bar
                  onMouseEnter={onPieEnter}
                  onMouseOver={(e) => setOverd(e)}
                  dataKey='revenue'
                  barSize={10}
                  fill={
                    props.colors[
                      props.colors.findIndex((c) => c.name === "revenue")
                    ].color
                  }
                /> */}
                {/* <Line
                  dataKey='profit'
                  stroke={
                    props.colors[
                      props.colors.findIndex((c) => c.name === "profit")
                    ].color
                  }
                  strokeWidth={2}
                /> */}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        </ChartContainer>
        <Panel overed={overed} isMobile={isMobile} />
      </ContentContainer>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const { dashboard, browser } = state;
  const colors = dashboard.colors;
  const isMobile = browser.width < 800;

  return {
    colors,
    isMobile,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActivePieChart);

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.primary};
`;

const Title = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text};
  font-size: 18px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 10px;
`;

const DotColor = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid gray;
`;

const DotColorTooltip = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${(props) => props.color};
  border: 1px solid gray;
`;

const Button = styled.div`
  display: flex;
  justifey-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  padding: 6px 10px;
  opacity: ${(props) => (props.selected ? 1 : 0.9)};
  background-color: ${(props) =>
    !props.selected ? props.theme.contrast : props.theme.body};
  color: ${({ theme }) => theme.icon1};
  margin: 0 3px;
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.13);

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.background};
  }

  &:active {
    box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.17);
    background-color: ${({ theme }) => theme.body};
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
  // margin: 10px;
`;

const ChartContainer = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "70%")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isMobile ? "auto" : "350px")};
  margin: 10px;
`;

const PanelContainer = styled.div`
  width: ${(props) => props.width || "100%"};
  height: 100%;
  display: flex;
  alignitems: center;
  padding: 10;
`;

const BarChartIconCustom = styled(BarChartIcon)`
  stroke: ${({ theme }) => theme.icon1};
`;

const LineChartIconCustom = styled(LineChartIcon)`
  stroke: ${({ theme }) => theme.icon1};
`;

const PaintIconCustom = styled(PaintIcon)`
  stroke: ${({ theme }) => theme.icon1};
`;

const ToolTipContainer = styled.div`
  background-color: ${({ theme }) => theme.body};
  border: 1px solid black;
  padding: 4px 16px;
  border-radius: 5px;
  min-width: 12vw;
`;

const CustomBrush = styled(Brush)``;
