import React, { Component, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppBarActions from "../../actions/AppBarActions";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MomentTZ from "moment-timezone";
import FiltersV2 from '../../components/FiltersV2'
import _ from "lodash";
// grommet
import { Box, Button, DropButton, Text, Heading, Layer, Select, Tip } from "grommet";
import { FormSearch as SearchIcon } from 'grommet-icons';

import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";
import Pagination from "../../components/PaginationComponent";
import ActionDropButton from "../../components/DropButton";
import { debounce } from "debounce";

function getRowProps(data) {
  const internals = data
    .filter((o) => o.internal !== "active")
    .map((o) => o.id);
  const closed = data
    .filter((o) => o.status === "Closed" || o.status === "Not Active")
    .map((o) => o.id);
  // console.info({ internals, closed })

  let rowProps = {};

  internals.forEach(
    (oid) => (rowProps[oid] = { background: "rgb(236, 208, 75, 0.9)" })
  );
  closed.forEach((oid) => (rowProps[oid] = { background: "#f2dedd" }));

  return rowProps;
}


function getTableColumns(reportData, { groupBy }, sumClicks) {

  // For FOOTER Percentage FIX


  const checkFooterRow = (props) => sumClicks === props.clicks;


  // FOR GROUPBY percentage FIX

  let groupBySum = null;

  if (groupBy) {
    groupBySum = _.groupBy(reportData, groupBy)
    for (const groupEntity in groupBySum) {
      groupBySum[groupEntity] = groupBySum[groupEntity]
        .map(o => o.clicks)
        .reduce((a, b) => a + b);
    }
  }

  const isGroupByRow = (props) => {
    const { clicks } = props;
    const groupVal = props[groupBy];
    const isGroupRow = groupBySum[groupVal] === clicks;
    return isGroupRow
  }




  const defaultRenderer = (propName, type = "", color = "") => (props) => {
    let value = props[propName.toLowerCase()];
    let extraStyle = {};
    const isFooterRow = checkFooterRow(props)

    if (
      type === 'percent'
    ) {
      if (
        isFooterRow ||
        (groupBy && isGroupByRow(props))
      ) {

        const [numeralField] = propName.split('_p')
        const realPercent = props[numeralField] / props.ti;
        value = realPercent
      }
      if (value === 1)
        extraStyle = Object.assign(extraStyle, { fontWeight: 700 })
      // FOOTER PERCENT FIX

    }

    if (type === "numeral") {
      value = Numeral(value).format("0,0");
    } else if (type === "currency") {
      extraStyle = Object.assign(extraStyle, { fontWeight: 700, fontSize: '11px', letterSpacing: '1px' })
      value = Numeral(value).format("0,0.0$");
    }

    if (propName === "campaign" && value && value.length > 25) {
      value = value.slice(0, 22) + "...";
    } else if (type === "decimalp") {
      // console.info({decimal: value})
      value = Numeral(value).format(value > 0 ? "0.[000]" : "0");
    } else if (type === 'percent') {
      value = Numeral(value).format(value > 0 ? "0.[0]%" : "0");
    }


    if (isFooterRow) {
      extraStyle = { fontSize: '11px', fontWeight: 600}
    }

    return (
      <Cell flex color={color}  {...extraStyle} type={type} align="start">
        {value}
      </Cell>
    );
  };

  const namer = (propName) => {
    switch (propName) {
      case "impressions":
        return "IMPS";
      case "registrations":
        return "REG";
      case "installs":
        return "INST";
      case "publisher":
        return "PUB";
      case "profit":
        return "NET";
      case "revenue":
        return "REV";
      case "country":
        return "GEO";
      case "ftd":
        return "FTD";
      case "bt_p":
        return "BT%";
      case "bi_p":
        return "BI%";
      case "bp_p":
        return "BP%";
      case "bp":
      case "bt":
      case "bi":
        return propName.toUpperCase();
      case "ti":
        return "TOTAL"
      default:
        return propName.toUpperCase();
    }
  };

  const simpleColumns = [
    "publisher",
    "campaign",
    "country"];

  const numeralColumns = [
    "impressions",
    "clicks",
    "ti",
    "installs",
    "bi",
    "bi_p",
    "bp",
    "bp_p",
    "bt",
    "bt_p",
    "registrations",
    "ftd",
  ];

  const currencyColumns = [
    {
      prop: "revenue",
      color: "green",
    },
    {
      prop: "cost",
      color: "red",
    },
    {
      prop: "profit",
      color: "blue",
    },
  ];

  const columnDefs = [
    {
      property: "date",
      header: "DATE",
      render: ({ date }) => (
        <Cell style={{ whiteSpace: "pre" }}>
          {date && MomentTZ(date).format("YYYY-MM-DD")}
        </Cell>
      ),
    },
    {
      property: "app_bundle",
      header: "BUNDLE",
      render: ({ app_bundle, app_name }) => (
        <Cell style={{ whiteSpace: "pre", fontWeight: 500 }}>
          {app_name}
          <Cell style={{
            maxWidth: '110px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'inline',
          }}>{app_bundle}</Cell>
        </Cell>
      ),
    },
    ...simpleColumns.map((propName) => ({
      property: propName,
      header: namer(propName),
      render: defaultRenderer(propName),
      // footer: "x"
    })),
    ...numeralColumns.map((propName) => {
      const isPercentage = propName.indexOf('_p') > -1;
      return ({
        property: propName,
        header: namer(propName),
        render: defaultRenderer(propName, isPercentage ? "percent" : "numeral"),
        aggregate: "sum",
        footer: { aggregate: isPercentage ? true : true },
      })
    }),
    ...currencyColumns.map(({ prop, color }) => ({
      property: prop,
      header: namer(prop),
      render: defaultRenderer(prop, "currency", color),
      aggregate: "sum",
      footer: { aggregate: true },
    })),
  ];
  // const model = {}
  // columnDefs.forEach(o => model[o.property] = 1 / columnDefs.length);
  // console.info(JSON.stringify(model))
  return columnDefs;
}


const DEFAULT_SORT = { property: "revenue", direction: "desc" };

function getGroupByOpts(groupBy) {
  return [
    ...[groupBy !== "id" ? "none" : undefined],
    "app_bundle",
    "app_name",
    "campaign",
    "publisher",
    "date",
    "country",
  ].filter((s) => s);
}

function AppsFlyerAgency(props) {
  const { browser, advertisers, fetchAppsFlyerAgencyData } = props;
  const {
    loading,
    reports,

  } = props.appsflyer;

  // const [filtersVisible, toggleFilterVisibility] = useState(true);
  const [dataFilters, setDataFilters] = useState({})
  const [groupBy, setGroupBy] = useState('id');
  const [firstLoad, setFirstLoad] = useState(true);
  // const [fromDate, setFromDate ] = useState(Moment.utc().subtract(24,'hours'));

  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.page || 1);
  const [pageSize, setCurrentPageSize] = useState(props.pageSize || 50);

  const [queryCriteria, setQueryCriteria] = useState({});

  let sort = sortSettings;
  if (["id", "price", "name", "created", "start_time"].indexOf(sort.property) === -1) {
    sort = DEFAULT_SORT;
  }

  const dataTableKey = groupBy + reports.length;
  const groupByProp =
    ["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {};


  // console.info({ queryCriteria, firstLoad })
  useEffect(() => {
    async function getData() {
      await fetchAppsFlyerAgencyData({
        ...queryCriteria,
        page,
        pageSize,
        // sort,
      });
    }
    // const debounced = debounce(getData, 1000);
    // // debounced();
    if (!firstLoad)
      getData()
  }, [page, pageSize, queryCriteria, fetchAppsFlyerAgencyData, firstLoad]);


  const reportsData = reports.filter(r => {
    const filters = Object.keys(dataFilters)
    // console.info(filters, 'filters')
    if (!filters.length) {
      return r
    }
    // console.info(filters.map(filter => dataFilters[filter].map(({value}) => value)))
    return filters.map(filter => {
      const values = dataFilters[filter]
      if (!values.length) return true
      const resp = values.map(({ value }) => value).indexOf(r[filter]) > -1
      // if (resp)
      //   console.info(resp, r)
      return resp
    }).filter(o => o).length === filters.length
  })


  const sumClicks = reportsData.length ? reportsData
    .map(o => o.clicks)
    .reduce((a, b) => a + b) : 0;

  const groupByOpts = getGroupByOpts(groupBy)

  const onDataFilter = ({ option, value: nextValue, ...rest }) => {
    const { type } = option || {};
    if (nextValue.length === 1 && typeof nextValue[0] === "undefined") {
      nextValue = [option];
    }
    // console.info({dataFilters, type, nextValue})
    setDataFilters({
      ...dataFilters,
      [type]: nextValue
    })
  }




  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}
      <FiltersV2

        fields={[
          [
            {
              name: 'fromDate',
              type: 'date',
              // value: fromDate,
              placeholder: `from date`
            },
            {
              name: 'toDate',
              type: 'date',
              // value: toDate,
              placeholder: `to date`
            },
            {
              name: 'dateRange',
              type: 'dateSelect',
              defaultRange: 'today',
              // value: toDate,
              // placeholder: `to date`
            },
            {
              name: 'reportDownload',
              type: 'reportDownloadButton',
              external: true,
              reportData: reportsData
            },
          ],
          [
            {
              name: 'groupByFilter',
              type: 'afGroupByFilter',
              options: groupByOpts,
              value: groupBy,
              external: true,
              onChange: ({ option }) => {
                const groupBy = option !== "none" ? option : "id";

                // this.setState(updateObj, this.applyDataFilters)
                setGroupBy(groupBy);
              }
            },
            ...[
              // 'app_bundle',
              "publisher",
              "country",
              "campaign",
            ].map((name) => {
              const options = _.uniq(
                reports
                  .filter((o) => o)
                  .map((o) => o[name]))
                .map((value) => ({
                  value,
                  label: value,
                  type: name,
                }))

              return ({
                name,
                options,
                type: 'selectFilterData',
                value: dataFilters[name],
                onChange: onDataFilter,
                external: true,
              })
            }),
            {
              name: 'app_bundle',
              type: 'selectFilterData',
              external: true,
              options: _.uniqBy(reports, "app_bundle").map((o) => ({
                value: o.app_bundle,
                label: `${o.app_bundle} - ${o.app_name}`,
                type: "app_bundle",
              })),
              value: dataFilters['app_bundle'],
              onChange: onDataFilter
            },
          ]
        ]}
        handleSubmit={setQueryCriteria}
        firstLoad={firstLoad}
        onFirstLoad={() => setFirstLoad(false)}


      />
      {/* <OffersFilters
        data={offers}
        sort={sortSettings}
        direction={!browser.greaterThan.medium && "column"}
        advertiser={advertiser}
        advertisers={advertisers}
        handleSubmit={setQueryCriteria}
        open={filtersVisible}
        setOpen={toggleFilterVisibility}
      /> */}

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading Offers data...">
        <Box style={{ flex: 13, overflowX: "auto" }}>
          <DataTable
            pin
            fill
            key={groupBy + reportsData.length}
            // rowProps={getRowProps(reportsData)}
            background={{
              header: "dark-3",
              // body: ["#dff0d8"],
              footer: "light-3",
            }}
            sortable
            onSort={({ direction, property }) =>
              setSortSettings({
                property,
                direction,
              })
            }
            primaryKey={"id"}
            {...(["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {})}
            data={reportsData}
            columns={getTableColumns(reportsData, groupByProp, sumClicks)}
            sort={sortSettings}
            wideColumns={[2]}
          />
          {/* <Box
            pad="6px"
            background={"light-1"}
            direction="row"
            align={"center"}
            style={{ position: "fixed", bottom: 0, width: "100%" }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, nextPage) => setCurrentPage(nextPage)}
            />
            <Box style={{ maxWidth: "100px" }}>
              <Select
                size={"small"}
                options={[50, 100, 200, 250, 500]}
                value={pageSize}
                onChange={({ option: nextPageSize }) =>
                  setCurrentPageSize(nextPageSize)
                }
              />
            </Box>
          </Box> */}
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const { appsflyer } = state;

  return { appsflyer, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AppsFlyerActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsFlyerAgency);
