import React, { Component, useEffect } from "react";
import { TextInput, Box, DateInput, Select, Grommet } from "grommet";
import { Down, Up } from "grommet-icons";
import _ from "lodash";
import ColorPickerButton from "./ColorPickerButton";

import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import styled from "styled-components";
import DatePresets from "../../DatePresets";
import {
  Moon as MoonIcon,
  Sun as SunIcon,
  Paint as PaintIcon,
} from "grommet-icons";
import DashboardFiltersMobile from "./DashboardFiltersMobile";

const THEME = {
  global: {
    colors: {
      brand: "#3c8dbc" /*main color code*/,
      color: "gray",
    },
    font: {
      family: "Roboto",
      size: "15px",
      height: "20px",
    },
  },
  textInput: {
    extend: {
      backgroundColor: "white",
      fontWeight: 500,
      color: "gray",
    },
  },
  button: {
    default: {
      font: {
        weight: 500,
      },
      padding: {
        vertical: "8px",
        horizontal: "0px",
      },
      border: {
        // color: {
        //   light: "rgba(0,0,0,0.33)",
        // },
        width: "1px",
        radius: "5px",
      },
      extend: {
        backgroundColor: "white",
        color: "gray",
      },
    },
  },
};

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "long",
  day: "numeric",
});

const ValueLabel = ({ label }) => {
  return (
    <div
      style={{
        width: "100%",
        fontSize: 16,
        minWidth: 100,
        padding: "4px 11px",
      }}
    >
      {label}
    </div>
  );
};

function DashboardFilters(props) {
  const {
    allPublishers,
    allBundles,
    isMobile,
    filterFields,
    setFiltersFields,
    filters,
    setFilters,
    bundlesSelected,
    setBundlesSelected,
    publisherOpts,
    setPublisherOpts,
    publisherSelected,
    setPublisherSelected,
    appBundlesOpts,
    setAppBundlesOpts,
    themeToggler,
    theme,
  } = props;

  const buttonIcon =
    theme === "light" ? (
      <MoonIconCustom size='18px' />
    ) : (
      <SunIconCustom size='18px' />
    );

  return !isMobile ? (
    <Container isMobile={isMobile}>
      <Fields>
        <DateInputCustomContainer fill>
          <DateInputCustom
            value={[filters.startDate, filters.endDate]}
            format='yyyy/mm/dd - yyyy/mm/dd'
            buttonProps={{
              label: `${dateFormat.format(
                new Date(filters.startDate)
              )} - ${dateFormat.format(new Date(filters.endDate))}`,
            }}
            onChange={({ value }) =>
              setFilters({
                ...filters,
                startDate: value[0],
                endDate: value[1],
                datePreset: false,
              })
            }
          />
        </DateInputCustomContainer>

        <SelectCustomContainer
          align={isMobile ? "stretch" : "center"}
          isMobile={isMobile}
          // pad='small'
        >
          <DatePresets
            width='100%'
            datePreset={filters.datePreset}
            handleChange={(dates) =>
              setFilters((f) => ({
                ...f,
                ...dates,
                datePreset: true,
              }))
            }
          />
        </SelectCustomContainer>

        {/* Select Publishers  */}
        {filterFields && (
          <SelectCustomContainer
            align={isMobile ? "stretch" : "center"}
            // pad='small'
          >
            <SelectCustom
              clear
              options={_.orderBy(
                publisherOpts,
                (publisher) => {
                  return publisherSelected.indexOf(publisher) > -1;
                },
                "desc"
              )}
              value={publisherSelected.length ? publisherSelected : ""}
              placeholder={"Publishers"}
              onChange={({ value }) => setPublisherSelected(value)}
              closeOnChange={false}
              onSearch={(text) => {
                if (!text) {
                  setPublisherOpts(allPublishers);
                  return true;
                }
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                const exp = new RegExp(escapedText, "i");
                setPublisherOpts(allPublishers.filter((o) => exp.test(o)));
              }}
              multiple
              messages={{
                multiple: `${publisherSelected.length} publishers`,
              }}
            />
          </SelectCustomContainer>
        )}

        {/* Select App Bundle  */}
        {filterFields && (
          <SelectCustomContainer
            align={isMobile ? "stretch" : "center"}
            // pad='small'
          >
            <SelectCustom
              clear
              options={_.orderBy(
                appBundlesOpts,
                ({ app_bundle }) => {
                  return (bundlesSelected || []).find(
                    (b) => b.app_bundle === app_bundle
                  );
                },
                "asc"
              )}
              value={bundlesSelected.length ? bundlesSelected : ""}
              placeholder={"App bundle"}
              onChange={({ value }) => setBundlesSelected(value)}
              closeOnChange={false}
              onSearch={(text) => {
                if (!text) {
                  setAppBundlesOpts(allBundles);
                  return true;
                }
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                const exp = new RegExp(escapedText, "i");
                setAppBundlesOpts(
                  allBundles.filter(
                    (o) => exp.test(o.app_name) || exp.test(o.app_bundle)
                  )
                );
              }}
              multiple
              messages={{
                multiple: `${bundlesSelected.length} bundles`,
              }}
              valueKey={({ app_bundle }) => {
                return app_bundle;
              }}
              valueLabel={
                <ValueLabel
                  label={
                    bundlesSelected.length === 1
                      ? bundlesSelected[0].app_name
                      : `${
                          bundlesSelected.length ? bundlesSelected.length : ""
                        } Bundles`
                  }
                />
              }
              labelKey={({ app_name, app_bundle }) => {
                return (
                  <Box>
                    <Box>{app_name}</Box>
                    <Box style={{ fontSize: "12px", fontWeight: 500 }}>
                      {app_bundle}
                    </Box>
                  </Box>
                );
              }}
            />
          </SelectCustomContainer>
        )}
      </Fields>
      <Buttons>
        <ButtonCustom onClick={themeToggler}>{buttonIcon}</ButtonCustom>
        <ColorPickerButton />
      </Buttons>
    </Container>
  ) : (
    <DashboardFiltersMobile {...props} />
  );
}

export default DashboardFilters;

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.panel};
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

const Fields = styled.div`
  width: 80%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  align-items: center;
  justify-content: start;
  padding: 5px 10px;
`;

const Buttons = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 10px;
`;

const MoonIconCustom = styled(MoonIcon)`
  stroke: ${({ theme }) => theme.text};
`;

const SunIconCustom = styled(SunIcon)`
  stroke: ${({ theme }) => theme.text};
`;

const PaintIconCustom = styled(PaintIcon)`
  stroke: ${({ theme }) => theme.icon2};
`;

const SelectCustomContainer = styled(Box)`
  padding: 8px 0;
  padding-left: 10px;
  width: ${(props) => (props.isMobile ? "100%" : "20%")};
  & > button {
    background-color: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.border};
    padding: 7px;
  }

  & > button > div > div:first-child > div > input {
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    border: none;
  }
`;

const SelectCustom = styled(Select)`
  background-color: ${({ theme }) => theme.body};
`;

const Button = styled.div`
  display: flex;
  justifey-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  margin: 0 8px;
  padding: 10px 10px;
  //   opacity: ${(props) => (props.selected ? 1 : 0.4)};
`;

const DateInputCustomContainer = styled(Box)`
  width: 40%;
  font-size: 9px;
  color: ${({ theme }) => theme.text};
  font-weight: 300;
  & > button {
    background-color: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.border};
    padding: 5px;
  }
`;

const DateInputCustom = styled(DateInput)`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  font-weight: 300;
  font-size: 17px;
  padding: 11px;
  border: 1px solid ${({ theme }) => theme.border};
  & > div > div {
    background-color: blue;
  }
`;

const ButtonCustom = styled.button`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  font-weight: 300;
  padding: 7px 11px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border};
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 10px;
`;
