import React, { useEffect } from "react";
import { connect } from "react-redux";
import ActivePieChart from "../Charts/ActivePieChart/ActivePieChartNew";
import ActiveBars from "../Charts/ActivePieChart/ActiveBars";
import Performance from "../Charts/ActivePieChart/Performance";
import DashboardFilters from "../Charts/ActivePieChart/DashboardFilters";
import { NewChartContainer as ChartContainer } from "../Charts/ChartContainer";
import Moment from "moment";
import DashboardActions from "../../actions/DashboardActions";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { Dots } from "loading-animations-react";

const dashboardColors = [
  {
    revenue: "",
  },
  {
    profit: "",
  },
  {
    cost: "",
  },
  {
    clicks: "",
  },
  {
    installs: "",
  },
  {
    registrations: "",
  },
  {
    ftd: "",
  },
];

export const lightTheme = {
  body: "#e6e6e6",
  text: "#363537",
  background: "rgb(255,255,255,0.3)",
  border: "#bebdbf",
  panel: "#f2f2f2",
  icon1: "#8c8b8b",
  icon2: "#858585",
  hover: "#a3a3a3",
  contrast: "rgb(255,255,255,0.3)",
  primary: "rgb(60, 141, 188, 0.7)",
};
export const darkTheme = {
  body: "#262626",
  text: "#FAFAFA",
  background: "#333333",
  border: "#6e6d6d",
  panel: "#000",
  icon1: "#bdbdbd",
  icon2: "#8c8b8b",
  hover: "#4a4a4a",
  primary: "rgb(78, 184, 245, 0.5)",
  contrast: "rgb(0, 0, 0, 0.3)",
};

function HomePage(props) {
  const {
    summary,
    profitPub,
    performance,
    profitBundle,
    getDashboardData,
    allPublishers,
    allBundles,
    browser,
    loading,
    reports,
  } = props;

  const [theme, setTheme] = React.useState("light");
  const isMobile = browser.width < 800;
  const [filterFields, setFiltersFields] = React.useState(!isMobile);
  const maxWidth = browser.width;
  const [publisherOpts, setPublisherOpts] = React.useState(allPublishers);
  const [publisherSelected, setPublisherSelected] = React.useState([]);
  const [appBundlesOpts, setAppBundlesOpts] = React.useState(allBundles);
  const [bundlesSelected, setBundlesSelected] = React.useState([]);
  const [filters, setFilters] = React.useState({
    startDate: Moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
    endDate: Moment().endOf("day").format("YYYY-MM-DD"),
    datePreset: false,
    groupBy: "publisher",
    publishers: [],
    bundles: [],
  });

  useEffect(() => {
    setPublisherOpts(allPublishers);
    setAppBundlesOpts(allBundles);
  }, [allPublishers, allBundles]);

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      publishers: publisherSelected,
      bundles: (bundlesSelected || [])
        .filter((b) => b)
        .map((b) => b.app_bundle),
    }));
  }, [publisherSelected, bundlesSelected, setFilters]);

  useEffect(() => {
    // setFilters({
    //   ...filters,
    //   publishers: publisherSelected
    // })
    // console.info({summary})
    // if (!summary.length)
    getDashboardData(filters);
  }, [filters, getDashboardData]);

  // Toggle theme
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      {summary.length < 1 ? (
        <LoadingContainer>
          <DotsCustom
            text='Initializing Dashboard...'
            dotColors={[
              "rgb(60, 141, 188)",
              "rgb(113, 189, 235)",
              "rgb(148, 211, 235)",
              "rgb(182, 209, 219)",
              "rgb(176, 203, 212)",
              "rgb(192, 205, 209)",
            ]}
          />
        </LoadingContainer>
      ) : (
        <Container>
          <DashboardFilters
            allPublishers={allPublishers}
            allBundles={allBundles}
            isMobile={isMobile}
            filterFields={filterFields}
            setFiltersFields={setFiltersFields}
            filters={filters}
            setFilters={setFilters}
            bundlesSelected={bundlesSelected}
            setBundlesSelected={setBundlesSelected}
            publisherOpts={publisherOpts}
            setPublisherOpts={setPublisherOpts}
            publisherSelected={publisherSelected}
            setPublisherSelected={setPublisherSelected}
            appBundlesOpts={appBundlesOpts}
            setAppBundlesOpts={setAppBundlesOpts}
            themeToggler={themeToggler}
            theme={theme}
          />
          <Rows mobile={isMobile}>
            <Row mobile={isMobile}>
              <Performance performance={performance} />
            </Row>
            <Row mobile={isMobile}>
              <ChartContainer isMobile={isMobile}>
                <ActivePieChart
                  loading={loading}
                  maxWidth={maxWidth}
                  profitBundle={profitPub}
                  theme={theme}
                  title={"profit by publisher"}
                />
              </ChartContainer>
              <ChartContainer isMobile={isMobile}>
                <ActivePieChart
                  theme={theme}
                  loading={loading}
                  maxWidth={maxWidth}
                  profitBundle={profitBundle}
                  title={"profit by Bundle"}
                />
              </ChartContainer>
              {/* <ChartContainer>
                <ActivePieChart
                  title={"profit by bundle"}
                  theme={theme}
                  loading={loading}
                  maxWidth={maxWidth}
                  profitBundle={profitBundle}
                />
              </ChartContainer> */}
            </Row>
            <Row mobile={isMobile} style={{ marginBottom: 40 }}>
              <ChartContainer isMobile={isMobile}>
                <ActiveBars
                  loading={loading}
                  data={summary}
                  maxWidth={maxWidth}
                  theme={theme}
                  isMobile={isMobile}
                />
              </ChartContainer>
            </Row>
            <div
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: 14,
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src='https://cygobel.com/wp-content/uploads/2021/02/cygologo.png'
                height={45}
                width={45}
                style={{
                  opacity: theme === "dark" ? 0.3 : 1,
                }}
              />
              <p style={{ opacity: 0.1, marginTop: -2 }}>CYGOBEL</p>
            </div>
          </Rows>
        </Container>
      )}
    </ThemeProvider>
  );
}

const mapStateToProps = (state, props) => {
  const { user, dashboard, browser, appsflyer_combined } = state;
  const username = user.name;
  const summary = dashboard.summary.joined;
  const summaryAcency = dashboard.summary.agency;
  const summaryAdn = dashboard.summary.adn;
  const profitPub = dashboard.profitPub.joined;
  const profitBundle = dashboard.profitBundle.joined;
  const allPublishers = dashboard.allPublishers.joined;
  const allBundles = dashboard.allBundles.joined;
  const loading = dashboard.loading;
  const reports = appsflyer_combined.reports;

  const performance = [];

  const reducedSummary = summary.reduce(
    (a, b) => ({
      cost: a.cost + b.cost,
      revenue: a.revenue + b.revenue,
      profit: a.profit + b.profit,
    }),
    { cost: 0, revenue: 0, profit: 0 }
  );

  Object.keys(reducedSummary).forEach((name) =>
    performance.push({ name, value: reducedSummary[name] })
  );

  return {
    username,
    reports,
    summary,
    performance,
    profitPub,
    profitBundle,
    allPublishers,
    browser,
    allBundles,
    loading,
    summaryAcency,
    summaryAdn,
  };
};

const mapDispatchToProps = {
  ...DashboardActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.body};
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const DotsCustom = styled(Dots)`
  width: 20%;
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  font-size: 18px;
  & ._index__dots {
    padding-top: 15%;
  }
  & ._index__dot {
    // padding-top: 12%;
  }
`;

const Container = styled.div`
  // display: flex;
  flex-direction: column;
  // padding: 10px;
  overflow-y: auto;
  // position: relative;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.body};
  width: 100%;
  font-weight: 100;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  justifey-contect: space-between;
  padding: 5px 0;
  width: 100%;
`;
