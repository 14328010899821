const appbarInitial = { content: undefined };

export default function (state = appbarInitial, action) {
  switch (action.type) {
    case "APPBAR_CONTENT_UPDATE":
    case "APPBAR_CONTENT_INJECTED":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
