import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppsFlyerCombinedActions from "../../actions/AppsFlyerCombinedActions";
import SelectSearchInput from "../../components/xComponents/SearchInput";
import AppBarActions from "../../actions/AppBarActions";
import Moment from "moment";
// import MomentTZ from 'moment-timezone';
import { DataTableV2 as DataTable } from "../../components/DataTable";
// import ActionButtons from "../../components/ActionButtons";
import { Text, Box, Tip } from "grommet";
import Loader from "../../components/Loader";
import _ from "lodash";
import ReadSelect from "../../components/ReadSelectField";
import Numeral from "numeral";
import Cell from "../../components/Cell";
import { Aed, Search } from "grommet-icons";
import {
  TextInput,
  Select,
  Button,
  Layer,
  Heading,
  // DataTable,
  RadioButtonGroup,
} from "grommet";
import ReadField from "../../components/ReadField";
// import _ from "lodash";
import CountryJSON from "../../constants/country-codes.json";
import { NumberInput as GrommetNumInput } from "grommet-controls";
import { useNavigate } from "react-router-dom";
import { EVENTS_TYPE_OPTS } from "./AppsFlyerCombinedConstants";
import {
  //  Alert, Add,
  Edit,
  Duplicate,
} from "grommet-icons";
import AppsflyerEntityLabel from "../../components/AppsflyerEntityLabel";

// import // ADN_OPTS,
// // ADN_ID_MAP,
// // CONFIG_TYPE_OPTS
// "./AppsFlyerCombinedConstants";
let ADN_OPTS, ADN_ID_MAP;
/*
// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
 */

const GEO_OPTS = CountryJSON.map((o) => o["alpha-2"]);

// console.info(GEO_OPTS)
const NumberInput = (props) => (
  <Box style={{ maxWidth: "200px" }}>
    <GrommetNumInput {...props} />
  </Box>
);

const defaultRenderer =
  (propName, type = "", color = "") =>
    (props) => {
      let value = props[propName.toLowerCase()];
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell
          start={"true"}
          flex
          color={color}
          type={type}
        // background={'background'}
        >
          <Tip
            plain
            content={
              <Box border round='small' background={"#E6E6E6"} pad='xsmall'>
                <Text size='xsmall'>
                  {propName} - {value}
                </Text>
              </Box>
            }
          >
            <Box>{value}</Box>
          </Tip>
        </Cell>
      );
    };

const getInAppEventsOpts = (app_bundle, isAdn, config, agencyId = 0) => {
  console.log(config);

  if (!app_bundle)
    return {
      app_name: "",
      inAppEventsOpts: [],
    };
  const { in_app_events_json, app_name } =
    config.find(
      (c) =>
        c.app_bundle.toLowerCase() === app_bundle.toLowerCase() &&
          (c.isAdn === isAdn ||
        c.agency_id === agencyId)
    ) || {};

  const default_app_event_hooks = ["INSTALLS"];

  console.log({in_app_events_json});

  try {
    return {
      app_name,
      inAppEventsOpts: [
        ...default_app_event_hooks,
        ...(in_app_events_json || []).map((o) => (typeof o === "object" ? o.key : o).toUpperCase()),
      ],
    };
  } catch (getInAppEventsError) {
    // console.info({ getInAppEventsError });
    return {
      app_name,
      inAppEventsOpts: [default_app_event_hooks],
    };
  }
};

const AddEditAppEventLayer = (props) => {
  const {
    visible,
    // appBundleId,
    config,
    // updateAppBundleId,
    combinedAppBundleOpts,
    createAppBundleEvent,
    // updateAppBundleEvent,
    publishers,
    partners,
    adnNetworks,
    agencies,
  } = props;
  const bundleTypeLabels = ["Agency", "AdNetwork"];

  const defaultState = {
    isEdit: props.isEdit,
    isDuplicate: props.isDuplicate,
    processing: false,
    appEvent: props.appEvent || {},
    filteredGeoOpts: null,
    bundleType: ["Agency", "AdNetwork"][Number(props.appEvent.isAdn)],
    isAdn: false,
    adnId: 0,
    filteredBundleOptions: null,
  };
  const [state, setState] = React.useState(defaultState);

  React.useEffect(() => {
    // console.info({props})
    const event_geos = (props.appEvent.event_geos || "").split(",");

    const geoOptions = [
      ...(event_geos || []),
      ...(GEO_OPTS || []).filter((g) => (event_geos || []).indexOf(g) === -1),
    ];

    setState((state) => ({
      ...state,
      isEdit: props.isEdit,
      appEvent: {
        ...props.appEvent,
        event_geos,
      },
      bundleType: ["Agency", "AdNetwork"][Number(props.appEvent.isAdn)],
      isAdn: props.appEvent.isAdn || false,
      adnId: props.appEvent.adn_id,
      agencyId: props.appEvent.agency_id,
      filteredBundleOptions: null,
      geoOptions,
    }));
  }, [props.appEvent, props.isEdit, props.isDuplicate]);

  const { appEvent, processing } = state;
  let appBundleOptions = //_.uniqBy(
    combinedAppBundleOpts[Number(state.isAdn)].filter((opt) => {
      if (state.isAdn) {
        return opt.adnId === state.adnId;
      } else {
        return opt.agencyId === state.agencyId;
      }
      return true;
    });
  // ,"value")

  console.info({ appBundleOptions, combinedAppBundleOpts });
  const {
    id,
    app_bundle,
    app_name,
    event_name,
    event_key,
    publisher,
    partner,
    event_value,
    event_currency,
    event_type,
    event_geos,
    isAdn,
    adn_id: adnId,
  } = appEvent || {};

  console.info(agencies);

  if ((props.isEdit && !app_bundle) || !visible) {
    return null;
  }

  const isEdit = props.isEdit;
  const isDuplicate = props.isDuplicate;
  // console.info({ isEdit, isDuplicate })
  const title = `${isDuplicate ? "Duplicate" : isEdit ? "Edit" : "Add"
    } App Bundle Event`;
  const spinningMessage = `${isDuplicate ? "Duplicating" : isEdit ? "Updating" : "Creating"
    } App Bundle Event`;

  // console.info({ state });
  let [appBundleOption] = appBundleOptions.filter(
    (o) => o.value === (app_bundle || "").toLowerCase()
  );

  console.info(appBundleOption);

  const disabled =
    (!isEdit && !app_bundle) || (!isEdit && app_bundle && !event_type);

  const {
    inAppEventsOpts,
    // app_name
  } = getInAppEventsOpts(app_bundle, isAdn, config, state.agencyId);
  // console.info({ inAppEventsOpts, isAdn, config })

  // console.info({ appBundleOption, appBundleOptions, app_bundle });
  return (
    <Layer>
      <Box pad='medium'>
        <Heading level='6' pad='none' margin='none'>
          {isAdn ? "AdNetwork" : "Agency"}
        </Heading>
        <Heading
          level='3'
          margin={{ vertical: "small" }}
          style={{ fontWeight: "bold" }}
        >
          {title}
        </Heading>
        {/* {Object.keys(appEvent).join(', ')} */}

        <Box pad='medium'>
          <ReadField name='Event ID' value={isDuplicate ? "00" : id} />
          <ReadField
            name='App Bundle Type'
            value={isEdit ? state.bundleType : null}
          >
            <RadioButtonGroup
              name='Bundle Type'
              options={["Agency", "AdNetwork"]}
              value={state.bundleType}
              onChange={(event) => {
                const isAdn = !!bundleTypeLabels.indexOf(event.target.value);
                setState({
                  ...state,
                  bundleType: event.target.value,
                  isAdn,
                  appEvent: {
                    ...state.appEvent,
                    isAdn,
                  },
                });
              }}
            />
          </ReadField>
          {isAdn ? (
            <ReadField
              name='AdNetwork ID'
              value={isEdit ? adnNetworks.ID_MAP[state.adnId].name : null}
            >
              <Select
                name='Adn ID'
                options={adnNetworks.OPTS}
                labelKey='label'
                valueKey={{ key: "adnId", reduce: true }}
                value={state.adnId}
                onChange={({ value: nextAdnId }) => {
                  setState((s) => ({
                    ...s,
                    adnId: nextAdnId,
                  }));
                }}
              />
            </ReadField>
          ) : null}
          {!isAdn ? (
            <ReadField
              name='Agency ID'
              value={isEdit ? agencies.ID_MAP[state.agencyId]?.name : null}
            >
              <Select
                name='Agency ID'
                options={agencies.OPTS}
                labelKey='label'
                valueKey={{ key: "id", reduce: true }}
                value={state.agencyId}
                onChange={({ value: nextAgencyId }) => {
                  setState((s) => ({
                    ...s,
                    agencyId: nextAgencyId,
                  }));
                }}
              />
            </ReadField>
          ) : null}
          {console.log(app_bundle)}
          <ReadField
            name='App Bundle ID'
            value={(isEdit || isDuplicate) && app_bundle}
            desc={app_name}
          >
            <Select
              options={state.filteredBundleOptions || appBundleOptions}
              // multiple={false}
              children={(option, index, options) => (
                <Box pad='small'>
                  {option.label} <Box>{option.value}</Box>
                </Box>
              )}
              onSearch={(text) => {
                // console.info(text)
                if (!text) {
                  setState({
                    ...state,
                    filteredBundleOptions: null,
                  });
                }
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                const exp = new RegExp(escapedText, "i");
                // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]
                setState({
                  ...state,
                  filteredBundleOptions: appBundleOptions.filter(
                    (o) => exp.test(o.label) || exp.test(o.value)
                  ),
                });
              }}
              searchPlaceholder={`Search Bundle`}
              emptySearchMessage={`No Bundle found`}
              labelKey='label'
              valueKey='value'
              value={appBundleOption}
              onChange={({ option, value }) => {
                // console.info({ value, option });
                const { value: app_bundle } = option;
                setState({
                  ...state,
                  appEvent: {
                    ...appEvent,
                    app_bundle,
                  },
                });
              }}
            />
          </ReadField>
          <ReadField name='Event Type'>
            <Select
              options={["revenue", "cost", "general", "ftd", "registration"]}
              value={event_type}
              onChange={({ option: event_type }) => {
                let event = {
                  ...appEvent,
                  event_type,
                };

                if (["registration", "ftd"].indexOf(event_type) > -1) {
                  event = {
                    ...event,
                    event_value: 0,
                  };
                }

                setState({
                  ...state,
                  appEvent: event,
                });
              }}
            />
          </ReadField>
          <ReadField
            name='Event Name'
          // value={event_name}
          >
            <TextInput
              // options={['revenue', 'cost', 'general']}
              value={event_name}
              onChange={({ target }) => {
                setState({
                  ...state,
                  appEvent: {
                    ...appEvent,
                    event_name: target.value,
                  },
                });
              }}
            />
          </ReadField>
          <ReadField
            name='Event Key'
          // value={event_key}
          >
            <Select
              disabled={disabled}
              options={inAppEventsOpts}
              value={event_key}
              onChange={({ option: event_key }) => {
                setState({
                  ...state,
                  appEvent: {
                    ...appEvent,
                    event_key,
                  },
                });
              }}
            />
          </ReadField>
          {isAdn ? (
            <ReadSelect
              disabled={disabled}
              value={partner}
              name={"Partners"}
              onChange={({ option }) => {
                const partner = option === appEvent.partner ? "" : option;
                setState({
                  ...state,
                  appEvent: {
                    ...appEvent,
                    partner,
                  },
                });
              }}
              options={[...partners]}
            />
          ) : (
            <ReadField
              name='Publisher'
            // value={event_key}
            >
              <SelectSearchInput
                disabled={disabled}
                key='publisher-select'
                value={publisher}
                name={"publisher"}
                // type={field.type}
                // placeholder={field.placeholder || field.name}
                onChange={({ option }) => {
                  const publisher = option === appEvent.publisher ? "" : option;
                  setState({
                    ...state,
                    appEvent: {
                      ...appEvent,
                      publisher,
                    },
                  });
                }}
                options={[...publishers].map((o) => o.publisher)}
              />
            </ReadField>
          )}
          <ReadField name='Event Currency' value={event_currency} />
          <ReadField name='Event Value'>
            <NumberInput
              step={0.1}
              disabled={
                disabled || ["registration", "ftd"].indexOf(event_type) > -1
              }
              decimals={3}
              value={
                ["registration", "ftd"].indexOf(event_type) > -1
                  ? 0
                  : event_value
              }
              onChange={({ target }) => {
                if (target.value >= 0) {
                  setState({
                    ...state,
                    appEvent: {
                      ...appEvent,
                      event_value:
                        ["registration", "ftd"].indexOf(event_type) > -1
                          ? 0
                          : target.value,
                    },
                  });
                }
              }}
            />
          </ReadField>
          {console.log({ event_type: event_type })}

          <ReadField name='Event Geos'>
            <Box gap='small' align='center' direction='row'>
              <Select
                disabled={
                  disabled || ["registration", "ftd"].indexOf(event_type) > -1
                }
                size='small'
                placeholder='All GEOs'
                value={event_geos}
                closeOnChange={false}
                options={(
                  state.filteredGeoOpts ||
                  state.geoOptions ||
                  []
                ).filter((o) => o)}
                multiple
                onChange={({ value: event_geos }) => {
                  setState({
                    ...state,
                    appEvent: {
                      ...appEvent,
                      event_geos,
                    },
                    filteredGeoOpts: null,
                  });
                }}
                onClose={() =>
                  setState({
                    ...state,
                    filteredGeoOpts: null,
                  })
                }
                onSearch={(text) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  if (text.length > 0) {
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      "\\$&"
                    );

                    const exp = new RegExp(escapedText, "i");

                    const filteredGeoOpts = state.geoOptions.filter((o) =>
                      exp.test(o)
                    );
                    // console.info({escapedText, filteredAppEventsGeoOpts })
                    setState({
                      ...state,
                      filteredGeoOpts,
                    });
                  } else {
                    setState({
                      ...state,
                      filteredGeoOpts: null,
                    });
                  }
                }}
              />
              <Text size='small'>
                {typeof event_geos !== "string"
                  ? (event_geos || []).join(" | ")
                  : event_geos}
              </Text>
            </Box>
          </ReadField>
        </Box>
        <Box align='center' flex>
          <Loader text={spinningMessage} processing={processing}>
            <Box direction='row' flex pad='small' fill>
              <Box align='start' flex>
                <Button
                  label='Cancel'
                  onClick={(e) => {
                    // e.preventDefault()
                    // setEventEdit(null)
                    // router.push('/appsflyer/events', undefined, { shallow: true })
                    // console.info({setState})
                    props.closeModal();
                  }}
                />
              </Box>
              <Box align='end'>
                <Button
                  primary
                  label='Save'
                  disabled={false}
                  // disabled={!(app_name && app_bundle)}
                  onClick={async () => {
                    // console.info(this.props)
                    // console.info(appEvent)

                    setState({ ...state, processing: true });

                    let {
                      app_name,
                      app_bundle_name,
                      app_bundle_info,
                      isAdn,
                      adnId,
                      ...aeData
                    } = appEvent;
                    Object.assign(aeData, {
                      event_geos: aeData.event_geos.join(","),
                      adn_id: state.adnId,
                      agency_id: state.agencyId,
                      partner: isAdn && !aeData.partner ? "" : aeData.partner,
                    });
                    // console.log("sssssssssssssssssss");
                    // console.info({ aeData, isAdn, isEdit, appEvent, state });

                    const { agency_name, ...dataWithoutAgencyName } = aeData;

                    await createAppBundleEvent(
                      dataWithoutAgencyName,
                      isEdit,
                      isAdn,
                      isDuplicate
                    );
                    // setState({p})
                    setState(defaultState);
                    props.closeModal();

                    // setProcessing(true)
                    // await createAppBundle({
                    //     app_bundle,
                    //     app_name
                    // })
                    // setProcessing(false)
                    // setAddAppBundleVisible(false)
                  }}
                />
              </Box>
            </Box>
          </Loader>
        </Box>
      </Box>
    </Layer>
  );
};

//
// AppsFlyerCombinedEvents
//
function Events(props) {
  // Component props
  const {
    fetchCombinedData,
    appsflyer,
    createAppBundleEvent,
    adnNetworks,
    agencies,
  } = props;
  const {
    loading,
    events,
    config,
    publishers,
    partners,
    combinedAppBundleOpts,
  } = appsflyer;

  // console.info(events.length, publishers)
  // Component state
  const [search, setSearch] = React.useState("");
  const [eventsType, setEventsType] = React.useState(EVENTS_TYPE_OPTS[0].value);
  const [filteredData, setFilteredData] = React.useState(events);
  const [eventFromConfig, setEventFromConfig] = React.useState();
  const [networkValue, setNetworkValue] = React.useState("All");
  const [networkOptions, setNetworkOptions] = React.useState(["All"]);

  const [eventModalConfig, setEventModalConfig] = React.useState({
    visible: false,
    appEvent: {},
  });

  const navigate = useNavigate();
  // const prevCount = usePrevious(eventFromConfig);
  // console.info({ eventModalConfig })

  // USE EFFECT

  React.useEffect(() => {
    async function getData() {
      await fetchCombinedData({});
    }

    if (events.length < 1) {
      getData();
    }

    let filteredData = events.filter((c) => {
      const searchFilter = search
        ? c.app_bundle_name.toLowerCase().indexOf(search) > -1 ||
        c.event_name.toLowerCase().indexOf(search) > -1
        : true;
      // const networkFilter =
      //   (c.isAdn && (c.adn_name === networkValue || networkValue === 'All')) ||
      //   !c.isAdn
      const networkFilter =
        networkValue === "All" ||
        networkValue.includes(c.adn_name || c.agency_name);

      const typeFilter =
        eventsType === 2 ? true : Boolean(c.isAdn) === Boolean(eventsType);
      return searchFilter && typeFilter && networkFilter;
    });

    setFilteredData(filteredData);
    setNetworkOptions(
      _.uniq(config.map((o) => o.adn_name || o.agency_name || "All"))
    );

    // console.log(navigate.location.state && navigate.location.state.app_bundle);
    // console.log(eventFromConfig);
    // console.log(prevCount);

    if (
      navigate.location &&
      navigate.location.state &&
      eventFromConfig !== navigate.location.state.app_bundle
    ) {
      const { app_bundle, isAdn, minimumEvents, app_name } =
        navigate.location.state;
      setEventFromConfig(app_bundle);
      // if (prevCount !== eventFromConfig) {
      // console.log({
      //   test: {
      //     app_bundle: app_bundle,
      //     isAdn: isAdn,
      //     event_type: Object.keys(minimumEvents)[0],
      //     event_name: app_name,
      //   },
      // });
      setEventModalConfig({
        visible: true,
        appEvent: {
          app_bundle: app_bundle,
          isAdn: isAdn,
          event_type: Object.keys(minimumEvents)[0],
          event_name: app_name,
        },
        isEdit: true,
      });
      // }
      // console.log(navigate.location.state);
    }

    // console.log(config);
  }, [
    fetchCombinedData,
    eventFromConfig,
    networkValue,
    events,
    events.length,
    search,
    eventsType,
    navigate.location,
  ]);

  const groupedEvents = filteredData || [];
  // const [groupedEvents, setGroupedEvents] = React.useState(filteredData || []);

  //
  //
  // Duplicate
  /*
  function duplicateBundleEvent(bundleEvent) {
    let arr = events;
    const index = arr.findIndex((bundle) => bundle.id === bundleEvent.id);

    if (index !== -1) {
      let start = index + 1;
      let deleteCount = 0;
      arr.splice(start, deleteCount, bundleEvent);
    }

    arr = arr.filter((c) => {
      const searchFilter = search
        ? c.app_bundle_name.toLowerCase().indexOf(search) > -1 ||
        c.event_name.toLowerCase().indexOf(search) > -1
        : true;

      const typeFilter =
        eventsType === 2 ? true : Boolean(c.isAdn) === Boolean(eventsType);
      return searchFilter && typeFilter;
    });

    // console.log(index);
    // console.log(arr);
    // console.log(events);
    setFilteredData(arr);
    // console.log(filteredData);
  }
*/
  //
  // TABLE COLUMNS
  //
  const fields = [
    // 'id',
    "publisher",
    "partner",
    "event_name",
    "event_key",
    "event_type",
    "event_value",
    "event_geos",
    // 'gen_event_slot'
  ];

  const columns = [
    {
      property: "isAdn",
      header: "TYPE",
      render: ({ isAdn, app_bundle, adn_id, agency_id, app_bundle_info }) => {
        let groupInfo = {};
        if (!app_bundle) {
          const parts = app_bundle_info.replace(/\s/g, "").split(":::");
          const isAdn = Boolean(+parts[2]);
          const entityId = +parts[3];

          groupInfo = {
            isAdn,
            adn_id: isAdn ? entityId : undefined,
            agency_id: !isAdn ? entityId : undefined,
          };
        }
        return (
          <AppsflyerEntityLabel
            {...{
              isAdn,
              adn_id,
              agency_id,
              adnNetworks,
              agencies,
              isGroup: !app_bundle,
              ...groupInfo,
            }}
          />
        );
      },
    },
    {
      property: "actions",
      header: "",
      render: (appEvent) => {
        if (!appEvent.key) return null;
        return (
          <Box pad='none' direction='row'>
            <Button
              size='small'
              style={{ padding: 5 }}
              icon={<Edit size='small' />}
              //   label={`${isAdn}`}
              onClick={(e) =>
                setEventModalConfig({
                  visible: true,
                  appEvent,
                  isEdit: true,
                })
              }
            />
            <Button
              size='small'
              style={{ padding: 5 }}
              icon={<Duplicate size='small' />}
              //   label={`${isAdn}`}
              onClick={(e) => {
                // console.log({ appbundle: appEvent });
                setEventModalConfig({
                  visible: true,
                  appEvent,
                  isDuplicate: true,
                });
              }}
            />
          </Box>
        );
      },
    },
    {
      property: "app_bundle_info",
      header: <Text>App Bundle</Text>,
      render: ({ app_bundle_info, ...rest }) => {
        const parts = app_bundle_info.replace(/\s/g, "").split(":::");
        let [app_name, app_bundle] = [];
        if (parts.length === 4) {
          [app_name, app_bundle] = parts;
        } else {
          app_name = `${parts[0]}::${parts[1]}`;
          app_bundle = parts[2];
        }
        return (
          <Box direction='column' align='start' width='200px'>
            <Text
              size='small'
              style={{ fontWeight: "bold", whiteSpace: "pre" }}
            >
              {app_name}
            </Text>
            {app_bundle ? <Text size='small'>{app_bundle}</Text> : null}
          </Box>
        );
      },
      size: "200px",
    },
    ...fields.map((property) => {
      return {
        property,
        header: property.split("_").join(" "),
        render: defaultRenderer(property),
      };
    }),
  ];

  return (
    <Box
      flex
      fill
      width='100%'
      margin='none'
      overflowY='auto'
      alignSelf='center'
    >
      {/* ADD BUNDLE LAYER  */}
      {/* <AddBundleLayer {...addAppBundleProps} /> */}
      <AddEditAppEventLayer
        {...{
          config,
          publishers,
          partners,
          adnNetworks,
          agencies,
          combinedAppBundleOpts,
          createAppBundleEvent,
          ...eventModalConfig,
          closeModal: () =>
            setEventModalConfig({ visible: false, appEvent: {} }),
        }}
      />
      {/* DATA TABLE  */}
      <Loader loading={loading} text='Loading Offers data...'>
        <Box pad='small' gap='small'>
          <TextInput
            value={search}
            onChange={({ target }) => setSearch(target.value.toLowerCase())}
            icon={<Search />}
            placeholder='search ...'
          />
          <Box direction='row' gap='small' justifyContent='center'>
            <Box width='small'>
              <Select
                id='select'
                name='select'
                placeholder='Select'
                labelKey='label'
                valueKey={{ key: "value", reduce: true }}
                value={eventsType}
                onChange={({ value: nextValue }) => setEventsType(nextValue)}
                options={EVENTS_TYPE_OPTS}
              />
            </Box>
            {!!eventsType ? (
              <Box width='small'>
                <Select
                  id='select'
                  name='select'
                  placeholder='Select Networks/Agencies'
                  multiple
                  closeOnChange={false}
                  messages={{
                    multiple: networkValue !== "All" && networkValue.join(", "),
                  }} // labelKey="label"
                  // valueKey={{ key: "value", reduce: true }}
                  value={networkValue}
                  onChange={({ value: nextValue }) =>
                    // console.info({nextValue})
                    setNetworkValue(nextValue.length === 0 ? "All" : nextValue)
                  }
                  options={networkOptions}
                />
              </Box>
            ) : null}
            <Box width='small'>
              <Button
                style={{ borderRadius: "5px" }}
                primary
                label='Add Bundle Event'
                onClick={() =>
                  setEventModalConfig({
                    visible: true,
                    isEdit: false,
                    appEvent: {},
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            flex: 13,
            overflowX: "auto",
            paddingBottom: "5vh",
          }}
        >
          {/* {events2 && console.log(events2)} */}
          {groupedEvents.length ? (
            <DataTable
              pin
              key={groupedEvents.length}
              background={{
                header: "dark-3",
                footer: "light-3",
              }}
              sortable
              primaryKey={"key"}
              data={filteredData}
              columns={columns}
              wideColumns={[2]}
              groupBy={"app_bundle_info"}
            />
          ) : (
            <></>
          )}
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const { appsflyer_combined: appsflyer } = state;
  const adnNetworks = state.appsflyer_adn.constants;
  const agencies = state.appsflyer.constants;

  ADN_OPTS = adnNetworks.OPTS;
  ADN_ID_MAP = adnNetworks.ID_MAP;

  return { appsflyer, browser, adnNetworks, agencies };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      AppsFlyerActions,
      AppBarActions,
      AppsFlyerCombinedActions
    ),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
