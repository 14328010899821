import _ from "lodash";
import Moment from "moment";

const mockState = {};

const dateToday = Moment.utc();

const GROUP_BY_OPTS = [
  { label: "Date", value: "date" },
  { label: "App Name", value: "app_name" },
  { label: "Publisher", value: "publisher" },
  { label: "Campaign", value: "campaign" },
  { label: "GEO", value: "country" },
  { label: "Platform", value: "platform" },
];

const ADJUST_STATE = {
  filters: {
    datePreset: "Today",
    dateFrom: dateToday.startOf("day").format("YYYY-MM-DD"),
    dateTo: Moment(dateToday).endOf("day").format("YYYY-MM-DD"),
    dateCustom: false,
    groupBy: "",
    groupByOpts: GROUP_BY_OPTS,
    publishers: [],
    campaigns: [],
    platforms: [],
    countries: [],
    apps: [],

  },
  loading: false,
  config: [],
  publishers: [],
  campaigns: [],
  platforms: [],
  countries: [],
  apps: [],
};

const adjustReducer = (state = ADJUST_STATE, action) => {
  switch (action.type) {
    case "NEW_ADJUST_CONFIG":
    case "GET_ADJUST_CONFIG": {
      const { config } = action;
      return {
        ...state,
        config,
      };
    }

    case "GET_ADJUST_EVENTS": {
      const { events, initData } = action;
      return {
        ...state,
        events,
        ...initData
      };
    }

    case "UPDATE_ADJUST_EVENT": {
      const { event, updated } = action;
      if (!updated) return state
      return {
        ...state,
        events: state.events.map(e => e.id === event.id ? event : e)
      };
    }

    case "CREATE_ADJUST_EVENT" : {
      const { event, created } = action;
      if (!created) return state

      return {
        ...state,
        events: [
          event,
          ...state.events,
        ]
      }
    }
    case "GET_ADJUST_ENTITY_DATA": {
      const { entityData } = action;
      return {
        ...state,
        ...entityData,
      };
    }

    case "ADJUST_FILTERS_SYNC":
    case "ADJUST_FILTERS_CHANGE": {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    }
    default:
      return state;
  }
};

export default adjustReducer;
