import React, { useState } from "react";
import { Select } from "grommet";
import _ from "lodash";
function SearchInput(props) {
  const {
    options: defaultOptions,
    placeholder,
    name,
    value: defaultValue,
    onChange,
    onSearch,
    width,
  } = props;

  const [valueMultiple, setValueMultiple] = useState([]);
  const [fieldValue, setFieldValue] = useState();

  //
  const orderedOptions = (unorderedOptions) => {
    if (!valueMultiple.length) return unorderedOptions;

    return _.uniq([
      ...valueMultiple,
      ...unorderedOptions.filter((o) => valueMultiple.indexOf(o) === -1),
    ]);
  };

  const [options, setOptions] = useState(orderedOptions(defaultOptions));
  // console.info( {valueMultiple})
  return (
    <Select
      multiple={true}
      clear
      // width={width}
      closeOnChange={false}
      name={name}
      disabled={props.disabled}
      size={props.size}
      placeholder={placeholder}
      value={valueMultiple}
      options={orderedOptions(options)}
      messages={{
        multiple: (valueMultiple || []).join(","),
      }}
      onChange={(e) => {
        onChange(e);
        setValueMultiple(e.value);
        setOptions(defaultOptions);
      }}
      onClose={() => setOptions(defaultOptions)}
      onSearch={(text) => {
        // console.info(valueMultiple)
        if (!text) {
          setOptions(defaultOptions);
          return;
        }
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, "i");
        setOptions(defaultOptions.filter((o) => exp.test(o)));
      }}
    />
  );
}

export default SearchInput;
