import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import StatisticsActions from "../../actions/StatisticsActions";
import AppBarActions from "../../actions/AppBarActions";
import XButton from "../../components/xComponents/Button";
import _ from "lodash";
import ObjPath from "object-path";
import Fade from "react-reveal/Fade";

// grommet
import {
  Box,
  TextInput,
  DateInput,
  Text,
  Button,
  Collapsible,
  Select,
  CheckBox,
  Tip,
} from "grommet";
import {
  Filter as FilterIcon,
  DocumentDownload,
  TableAdd,
  LinkNext,
  Sort,
  Download,
} from "grommet-icons";

import Loader from "../../components/Loader";
import { DataTableV2 as DataTable } from "../../components/DataTable";
import Cell from "../../components/Cell";
import SearchInput from "../../components/xComponents/SearchInput";

import Filters from "./Filters";
class Statistics extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      tableColumns: this.getTableColumns(),
      displayFilters: true,
      displayExtraColumns: false,
      dataForDisplay: [],
      // Page filters
      filters: {
        pageSize: 50,
        page: 1,
      },
    };

    this.onFilterData = this.onFilterData.bind(this);
    // this.handleFiltersChange = this.handleFiltersChange.bind(this);
  }

  // GET INITIAL DATA
  componentDidMount() {
    // console.info("CDM")
    const {
      // statistics data rows
      rows,
      loading,
      getInitialStatisticsData,
    } = this.props;

    //  Load data from API
    if (!loading && !rows.length) {
      // get placements data
      getInitialStatisticsData({
        fromDate: Moment(new Date().toString()).format("YYYY-MM-DD"),
      });
    }
    // inject content (Header + message + actions ) into AppBar
    this.injectContentIntoAppBar();
    // this.props.injectContentAppBar();
  }

  // component update
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.info("CDU", {prevProps, prevState, props: this.props})

    const { rowsNum: prevRowsNum } = prevProps;
    const { rowsNum } = this.props;

    if (prevRowsNum !== rowsNum) {
      this.injectContentIntoAppBar();
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.info("CWRP", {nextProps, props: this.props})
  }

  // inject content (Header + message + actions ) into AppBar
  injectContentIntoAppBar() {
    // database access duration time
    const queryTime = ObjPath.get(this.props, "queryTime.words");
    // number of rows
    const rowsNum = ObjPath.get(this.props, "rowsNum");
    const groupByDataEntity = ObjPath.get(this.props, "criteria.groupByEntity");
    const fromDateInput = ObjPath.get(this.props, "criteria.fromDate");
    const fromDate = Moment(fromDateInput).format("DD-MM-YYYY");
    const toDateInput = ObjPath.get(this.props, "criteria.toDate");
    const toDate = Moment(toDateInput || fromDateInput).format("DD-MM-YYYY");

    const onTableAdd = () => {
      this.setState({
        displayExtraColumns: !this.state.displayExtraColumns,
      });
    };
    const onFilter = () => {
      this.setState({
        displayFilters: !this.state.displayFilters,
      });
    };

    const title = "Statistics";
    const message = rowsNum
      ? `group by ${groupByDataEntity}, from ${fromDate} to ${toDate}, ${rowsNum} results (${queryTime})`
      : ``;
    // console.info({ toDate, message }, ObjPath.get(this.props, "criteria.toDate"))
    const buttons = (
      <Box
        justify="end"
        direction="row"
        gap="none"
        pad="none"
        style={{ fontSize: "10px" }}
      >
        <XButton
          padding="0 10px"
          borderRadius="0"
          // label="Expand columns"
          height="100%"
          icon={<TableAdd size="15px" />}
          onClick={() => {
            onTableAdd();
          }}
        />
        {/* <XButton
          padding="0 10px"
          borderRadius="0"
          label="Reports"
          height="100%"
          icon={<Sort size="15px" />}
          onClick={() => {
            onTableAdd();
          }}
        />
        <XButton
          padding="0 10px"
          borderRadius="0"
          label="Export"
          height="100%"
          icon={<Download size="15px" />}
          onClick={() => {
            onTableAdd();
          }}
        />
        <XButton
          padding="0 10px"
          borderRadius="0"
          label="Export (BKG)"
          height="100%"
          icon={<DocumentDownload size="15px" />}
          onClick={() => {
            onTableAdd();
          }}
        /> */}
        <XButton
          padding="0 10px"
          borderRadius="0"
          // label="Filters"
          height="100%"
          icon={this.state.displayFilters ? <FilterIcon size="15px" /> : ""}
          onClick={() => onFilter()}
        />
      </Box>
    );

    // inject content into AppBar
    this.props.updateAppBarContent({
      title,
      message,
      buttons,
    });
  }

  // on filter data
  // get data from filters and pass it to the actionCreator for data manupilating
  // this function called from the Filter component
  onFilterData(criteria) {
    const payload = {
      ...criteria,
      fromDate: Moment(criteria.fromDate.toString()).format("YYYY-MM-DD"),
      toDate: Moment(criteria.toDate.toString()).format("YYYY-MM-DD"),
    };

    // const cleanPayload = _.mapKeys(payload, function (value, key) {
    //   if (_.isEmpty(value)) delete payload[key];
    // });
    // console.info({ payload, cleanPayload })
    this.props.filterStatistics(payload);
  }

  getActionBar() {
    const { displayExtraColumns } = this.state;
    return (
      <Box justify="center" direction="row">
        <Button
          hoverIndicator
          icon={<TableAdd size="15px" />}
          onClick={() => {
            this.setState({
              displayExtraColumns: !displayExtraColumns,
            });
          }}
        />
        <Button
          hoverIndicator
          icon={<FilterIcon size="15px" />}
          onClick={() => {
            this.setState({
              displayFilters: !this.state.displayFilters,
            });
          }}
        />
      </Box>
    );
  }

  getTableColumns() {
    const defaultRenderer = (propName, type = "", color = "") => (props) => {
      let value = props[propName.toLowerCase()];

      // 05/08/2020 13:38
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format(value > 0 ? "0,0.[000]$" : "0");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      } else if (type === "decimalp") {
        // console.info({decimal: value})
        value = Numeral(value).format(value > 0 ? "0.[000]" : "0");
      }

      return (
        <Cell
          start={propName === "entity_title" && true}
          flex
          color={color}
          type={type}
          style={{
            fontWeight: `${props.id === "total" && "500"}`,
            padding: `${props.id === "total" && "15px 10px"}`,
            backgroundColor: `${props.id === "total" && "#f7f7f7"}`,
          }}
        >
          <Tip
            plain
            content={
              <Box border round="small" pad="xsmall" background="white">
                <Text size={"xsmall"}>
                  {propName} - {value}
                </Text>
              </Box>
            }
          >
            <Box>{value}</Box>
          </Tip>
        </Cell>
      );
    };

    const getDatabaseColumnName = (propName) => {
      const lastChar = propName.charAt(propName.length - 1);
      switch (lastChar) {
        case "%":
          return `${propName.slice(0, -1)}_p`;
        default:
          return _.capitalize(propName);
      }
    };

    const eventsColumnsSmall = ["e1", "e1%", "er1", "er1%"];
    const eventsColumns = [
      "e1",
      "e1%",
      "er1",
      "er1%",
      "e2",
      "e2%",
      "er2",
      "er2%",
      "e3",
      "e3%",
      "er3",
      "er3%",
      "e4",
      "e4%",
      "er4",
      "er4%",
      "e5",
      "e5%",
      "er5",
      "er5%",
      "e6",
      "e6%",
      "er6",
      "er6%",
      "e7",
      "e7%",
      "er7",
      "er7%",
      "e8",
      "e8%",
      "er8",
      "er8%",
      "e9",
      "e9%",
      "er9",
      "er9%",
    ];

    let col =
      this.state && this.state.displayExtraColumns
        ? eventsColumns
        : eventsColumnsSmall;

    const columns = [
      {
        property: "entity_title",
        header: "Entity",
        render: defaultRenderer("entity_title"),
        pin: true,
      },
      {
        property: "imps",
        header: "Impressions",
        render: defaultRenderer("imps", "numeral"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      {
        property: "clicks",
        header: "Clicks",
        render: defaultRenderer("clicks", "numeral"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      {
        property: "convs",
        header: "Conversions",
        render: defaultRenderer("convs", "numeral"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      {
        property: "rconvs",
        header: "Convs",
        render: defaultRenderer("rconvs", "numeral"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      // events columns
      ...col.map((propName) => ({
        property: propName,
        header: propName,
        render: defaultRenderer(getDatabaseColumnName(propName)),
        aggregate: "sum",
        footer: { aggregate: true },
      })),
      //   {
      //     property: "e1",
      //     header: "E1",
      //     render: defaultRenderer("e1"),
      //     aggregate: "sum",
      //     footer: { aggregate: true },
      //   },
      //   {
      //     property: "e1%",
      //     header: "E1%",
      //     render: defaultRenderer("e1%"),
      //   },
      //   {
      //     property: "er1",
      //     header: "ER1",
      //     render: defaultRenderer("er1"),
      //   },
      //   {
      //     property: "er1%",
      //     header: "ER1%",
      //     render: defaultRenderer("er1%"),
      //   },
      {
        property: "convs_p",
        header: "CR%",
        render: defaultRenderer("convs_p", "decimalp"),
        // aggregate: 'avg',
        // footer: { aggregate: true },
      },
      {
        property: "status",
        header: "CR",
        value: (props) => props.status,
        render: defaultRenderer("status"),
      },
      {
        property: "revenue",
        header: "Revenue",
        render: defaultRenderer("revenue", "currency", "green"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      //   {
      //     property: "revenue_epc",
      //     header: "Revenue EPC",
      //     render: defaultRenderer("revenue_epc", "currency", "green"),
      //   },
      {
        property: "cost",
        header: "Cost ($)",
        render: defaultRenderer("cost", "currency", "red"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      //   {
      //     property: "cost_epc",
      //     header: "Cost EPC",
      //     render: defaultRenderer("cost_epc", "currency", "red"),
      //   },
      {
        property: "profit",
        header: "Profit",
        render: defaultRenderer("profit", "currency", "blue"),
        aggregate: "sum",
        footer: { aggregate: true },
      },
      //   {
      //     property: "profit_epc",
      //     header: "Profit EPC",
      //     render: defaultRenderer("profit_epc", "currency", "blue"),
      //   },
    ];

    return columns;
  }

  render() {
    let { tableColumns, filters, criteria, dataForDisplay } = this.state;
    let { pageSize, page, selected } = filters;

    // const readFieldTheme = {
    //   fontWeight: "400",
    //   fontSize: "10px",
    //   minWidth: "55px",
    // };

    // Redux State
    const { rows, loading } = this.props;
    // console.info("rows", rows.length)
    // const groupByDataEntity = ObjPath.get(this.props, "criteria.groupByEntity");
    // const fromDate = Moment(
    //   ObjPath.get(this.props, "criteria.fromDate")
    // ).format("DD-MM-YYYY");
    // const toDate = Moment(ObjPath.get(this.props, "criteria.toDate")).format(
    //   "DD-MM-YYYY"
    // );

    return (
      <Box fill flex>
        {/* HEADER  */}

        <Filters
          data={this.props.rows}
          open={this.state.displayFilters}
          handleSubmit={this.onFilterData}
        />

        {/* DATA TABLE  */}
        <Box
          flex
          justify="center"
          overflowY="auto"
          alignSelf="center"
          margin="xsmall"
          // border
          width="98%"
        >
          <Loader loading={loading} text="Loading Statistics data...">
            <Box style={{ flex: 13, overflowX: "auto" }}>
              <DataTable
                key={rows.length}
                pin
                sortable
                step={pageSize}
                data={rows}
                columns={this.getTableColumns()}
                sort={{ property: "revenue" }}
                fill
              />
            </Box>
          </Loader>
        </Box>

        {/* FOOTER  */}
        {/* <Footer
          page={page}
          name="Statistics"
          pageSize={pageSize}
          totalRows={this.props.rowsNum}
          handleChange={(event) => this.handleFiltersChange(event)}
        /> */}
      </Box>
    );
  }
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  return state.statistics;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, StatisticsActions, AppBarActions),
    dispatch
  );
  //   return bindActionCreators(OffersActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
