import React, { Component, useState } from "react";
import _ from "lodash";
import { Box, TextInput, Text, Collapsible, Select, CheckBox, DateInput } from "grommet";

import { LinkNext, Search } from "grommet-icons";
import XButton from "../../components/xComponents/Button";
import SelectSearchInput from "../../components/xComponents/SearchInput";
import COUNTRY_CODES from "../../constants/country-codes.json";
import { debounce } from "debounce";
import { RangeSelector, Stack } from 'grommet';
import { NumberInput } from "grommet-controls";

const COUNTRY_OPTS = COUNTRY_CODES.map((c) => c["alpha-2"]);
const OS_OPTS = ['iOS', 'Android'];

const INCENT_OPTS = [
  "Incent", "No Incent"
];

const EXT_STATUS_OPTS = [
  "Active", "Not Active"
];




const INTERNAL_STATUS_OPTS = [
  { label: "New", value: 1 },
  { label: "Created", value: 2 },
  { label: "Pending", value: 3 }
];


const AFFILITEST_STATUS_OPTS = [
  { label: "Success", value: 1 },
  { label: "Failure", value: 2 },
  { label: "API Error", value: 4 },
  { label: "Not Tested", value: 0 },

];
// -- Success - 1, Failure - 2, ERROR -4, NOT _TESTED - 0  */}




class OffersFilters extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      criteria: {
        advertiser: "",
        platform: [],
        incent: [],
        extStatus: [],
        internalStatus: 0,
        minPrice: "",
        maxPrice: "",
        affilitestStatus: "",
        affilitestNumRedirects: "",
        affilitestSearch: "",
        rank: [],
        countries: "",
        media: "",
        hideInactive: false,
        fromDate: "",
      },
    };
    this.debouncedHandleSubmit = _.debounce(props.handleSubmit, 500);

    // this.onFilterData = this.onFilterData.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);

    // this.debouncedHandleFiltersChange = _.debounce(this.handleFiltersChange, 1000);

  }

  // handle fields change on add advertiser layer
  handleFiltersChange(e, valueObj) {
    const { handleSubmit } = this.props;
    const { criteria } = this.state;

    // console.log(name);
    // console.log(type);
    // console.log(e.value);
    let newValue = null
    let newValues = {};
    let debounce = false;
    if (!valueObj) {
      const { value, name, type, checked } = e.target;


      newValue = (e.option && e.option) || e.value ? e.value : value;


      if (type === "checkbox") {
        newValue = checked;
      }

      if (typeof newValue === "object" && newValue.label) {
        newValue = newValue.value;
      }

      if (name === "rank") {
        newValue = e.value || [];
      }

      if (["internalStatus", "affilitestStatus"].indexOf(name) > -1) {
        newValue = e.value.value || 0;

        if (name === 'affilitestStatus') {
          newValue = !newValue ? -1 : newValue
        }
      }
      if (['minPrice', 'maxPrice'].indexOf(name) > -1 ) {
        console.info({newValue})
        if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(newValue)){

          console.info({name, newValue})
          return
        }
        if (!newValue.slice(-1) === '.') {
          newValue = parseFloat(newValue)

        }
        debounce= true

      }

      if (
        ["advertiser", "countries", "platform", "incent"].indexOf(name) > -1 &&
        typeof newValue !== "object"
      ) {
        newValue = [];
      }

      if (name === 'affilitestNumRedirects') {
        newValue = newValue.replace(/\D/g, '');
        if (!newValue || newValue === criteria.affilitestNumRedirects) {
          newValues = {
            ...criteria,
            ...{ [name]: newValue },
          }
          this.setState({ criteria: newValues });

          return
        }

      }

      if (["minPrice", "maxPrice"].indexOf(name) > -1) {
        if (!newValue || newValue === criteria[name]) {
          newValues = {
            ...criteria,
            ...{ [name]: newValue },
          }
          this.setState({ criteria: newValues });
          return
        }


      }
      // console.info({ type, name }, newValue, e.value);
      // update new values
      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };

      if (["offerSearch", "affiltestSearch"].indexOf(name) > -1 || debounce) {
        this.debouncedHandleSubmit(newValues);
        return this.setState({ criteria: newValues });
      }

    } else {
      newValues = {
        ...criteria,
        ...valueObj
      }
    }




    console.info({ newValues });

    handleSubmit(newValues);
    this.setState({ criteria: newValues });
  }

  render() {
    // function for handling filter/submit button
    const {
      handleSubmit,
      open,
      data,
      advertisers,
      advertiser,
      direction,
      setOpen,
    } = this.props;
    // fields values
    const {
      rank,
      platform,
      fromDate,
      countries,
      incent,
      minPrice,
      maxPrice,
      extStatus,
      internalStatus,
      affilitestStatus,
      affilitestNumRedirects

    } = this.state.criteria;




    return (
      <Box>
        <Collapsible open={open}>
          <Box
            direction={direction || "row"}
            pad="6px"
            // gap="medium"

            justify="between"
            background=" #f5f5f5"
          // margin="xsmall"
          // border
          >
            <Box gap="6px" align="center" fill="horizontal">
              {/*  ROW  */}
              <Box direction="row" gap="6px" align="center" fill="horizontal">
                {/*  COLUMN */}
                <Box
                  direction={direction || "row"}
                  gap="6px"
                  align="start"
                  // fill="horizontal"
                  width="566px"
                // margin={{ right: "35px" }}
                >
                  <TextInput
                    icon={<Search size="18" />}
                    // reverse
                    name="offerSearch"
                    type="search"
                    size="small"
                    onChange={(e) => this.handleFiltersChange(e)}
                    placeholder="Search for Network, External / Offer ID, Name"
                  />

                  {/* EXT_STATUS_OPTS */}
                </Box>
                <SelectSearchInput
                  name="extStatus"
                  size="small"
                  value={extStatus}
                  placeholder="External Status"
                  onChange={(e) => this.handleFiltersChange(e)}
                  options={EXT_STATUS_OPTS}
                />

                <Select
                  name="internalStatus"
                  size="small"
                  value={INTERNAL_STATUS_OPTS.filter(
                    (p) => p.value === internalStatus
                  )[0] || ""}
                  labelKey="label"
                  valueKey={{ key: "value" }}
                  clear
                  placeholder="Internal Status"
                  onChange={(e) => this.handleFiltersChange(e)}
                  options={INTERNAL_STATUS_OPTS}
                />


                <Box>

                  <TextInput
                    name="minPrice"
                    // disabled
                    value={minPrice}
                    placeholder="minPrice"
                    onChange={(e) => {
                      this.handleFiltersChange(e)
                    }}
                  />
                </Box>

                <Box>

                  <TextInput
                    name="maxPrice"
                    // disabled
                    value={maxPrice}
                    placeholder="maxPrice"
                    onChange={(e) => this.handleFiltersChange(e)}
                  />
                </Box>

                <Box style={{ maxWidth: '200px' }}>
                  <DateInput
                    size="small"
                    name="fromDate"
                    format="dd/mm/yyyy"
                    clear
                    value={fromDate}
                    inputProps={{ placeholder: `from date`, value: fromDate }}
                    onChange={({ value, nativeEvent, ...rest }) => {
                      if (value) {
                        const dateString = value.toString().slice(0, 10)
                        return this.handleFiltersChange(null, { fromDate: dateString })

                      }

                    }}
                  />
                </Box>
                {/* <Box>
                  <CheckBox
                    label={"Hide Inactive"}
                    name="hideInactive"
                    checked={hideInactive}
                    onChange={(event) => this.handleFiltersChange(event)}
                    style={{ backgroundColor: "green" }}
                  />
                </Box> */}
                {/*  COLUMN */}
              </Box>

              {/*  ROW  */}
              <Box
                fill="horizontal"
                direction={direction || "row"}
                gap="medium"
                align="center"
                fill="horizontal"
              >
                {/*  COLUMN */}
                <Box
                  direction={direction || "row"}
                  gap="6px"
                  align="center"
                  fill="horizontal"
                  justify={"start"}
                // justify="between"
                >

                  <SelectSearchInput
                    name="advertiser"
                    size="small"
                    value={advertiser}
                    placeholder="Advertisers"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={_.uniq(
                      advertisers.map((a) => `${a.id}::${a.name}`)
                    )}
                  />

                  <SelectSearchInput
                    name="countries"
                    size="small"
                    value={countries}
                    placeholder="Countries"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={COUNTRY_OPTS}
                  />
                  <SelectSearchInput
                    name="platform"
                    size="small"
                    value={platform}
                    placeholder="OS"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={OS_OPTS}
                  />

                  <SelectSearchInput
                    name="incent"
                    size="small"
                    value={incent}
                    placeholder="INCENT"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={INCENT_OPTS}
                  />

                  <Select
                    name="affilitestStatus"
                    size="small"
                    value={AFFILITEST_STATUS_OPTS.filter(
                      (p) => p.value === affilitestStatus
                    )[0] || ""}
                    labelKey="label"
                    valueKey={{ key: "value" }}
                    clear
                    placeholder="Affilitest Status"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={AFFILITEST_STATUS_OPTS}
                  />
                  <Box
                    align='center'
                    width={'200px'}
                    justify='center'>

                    <TextInput
                      name="affilitestNumRedirects"
                      value={affilitestNumRedirects}
                      placeholder="Aff. Max Redirects"
                      onChange={(e) => this.handleFiltersChange(e)}
                    />
                  </Box>
                  <Box>

                    <TextInput
                      icon={<Search size="18" />}
                      // reverse
                      name="affilitestSearch"
                      type="search"
                      size="small"
                      onChange={(e) => this.handleFiltersChange(e)}
                      placeholder="Search Affilitest Logs"
                    />
                  </Box>
                </Box>

                {/*  COLUMN */}
                {/* <Box
                  direction="row"
                  gap="6px"
                  align="center"
                  justify="start"
                  width="20%"
                > */}

                {/* </Box>  */}
              </Box>
            </Box>

            {/* SUBMIT BUTTON  */}
            {/* <Box
              align="center"
              gap="6px"
              justify="around"
              direction={direction ? "row" : "column"}
              // background="brand"
            >
              {direction ? (
                <XButton
                  primary
                  label="Close"
                  height="100%"
                  width={direction ? "50%" : "auto"}
                  //   style={{ width: "100px", height: "50%" }}
                  onClick={() => setOpen()}
                />
              ) : (
                <XButton
                  primary
                  icon={<LinkNext size="18px" />}
                  height="100%"
                  width={direction ? "50%" : "auto"}
                  //   style={{ width: "100px", height: "50%" }}
                  onClick={() => handleSubmit(this.state.criteria)}
                />
              )}
            </Box> */}
          </Box>
        </Collapsible>
      </Box>
    );
  }
}

export default OffersFilters;
