import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Filters from "./charts/Filters";
import NewsPapers from "./charts/NewsPaper";
import Messages from "./charts/Messages";
import Chart from "./charts/Chart";
import ChartB from "./charts/ChartB";
import ChartC from "./charts/ChartC";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
    backgroundColor: "#f5f5f5",
    padding: "0 2%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

//
// NewsPapers are used for quick summery
// Every Newspaper has a title and columns for displaying,
// it can handle with multiple columns or one but the ultimate is two columns
// every culmn has a name, value and subname/subheader (not mendatory)
const newsPapers = [
  {
    title: "Title 1",
    newsPaperColumns: [
      { name: "installs", value: 25, sub: 2 },
      { name: "clicks", value: 1225, sub: "34%" },
    ],
  },
  {
    title: "Title 2",
    newsPaperColumns: [
      { name: "installs", value: 25, sub: 2 },
      { name: "clicks", value: 1225, sub: "34%" },
    ],
  },
  {
    title: "Title 3",
    newsPaperColumns: [
      { name: "installs", value: 25, sub: 2 },
      { name: "clicks", value: 1225, sub: "34%" },
    ],
  },
  {
    title: "Title 4",
    newsPaperColumns: [
      { name: "installs", value: 25, sub: 2 },
      { name: "clicks", value: 1225, sub: "34%" },
    ],
  },
  {
    title: "Title 5",
    newsPaperColumns: [
      { name: "installs", value: 25, sub: 2 },
      { name: "clicks", value: 1225, sub: "34%" },
    ],
  },
  {
    title: "Title 6",
    newsPaperColumns: [
      { name: "installs", value: 25, sub: 2 },
      { name: "clicks", value: 1225, sub: "34%" },
    ],
  },
];

const fields = [
  {
    label: "Field 1",
    options: [1, 2, 3],
    value: "",
  },
  {
    label: "Field 2",
    options: [1, 2, 3],
    value: "",
  },
  {
    label: "Field 3",
    options: [1, 2, 3],
    value: "",
  },
  {
    label: "Field 4",
    options: [1, 2, 3],
    value: "",
  },
];

//
// Message will appear at the top of the page,
// if there is no message this component will not render
const message =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec faucibus feugiat justo ut vehicula. Nunc ipsum elit, lacinia sit amet dolor at, mattis lobortis nibh. Nulla nulla erat, mollis id sapien ut";

export default function AppsFlyerCombinedCharts(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Filters fields={fields} />
      <Messages message={message} title={"title"} />
      <NewsPapers newsPapers={newsPapers} />
      <Chart />
      <ChartB />
      <ChartC />
    </div>
  );
}
