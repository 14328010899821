import React, { useEffecrt, useEffect, useState, useCallback } from "react";
import useSWR from "swr";
import Numeral from "numeral";

import Ax from "axios";
import { connect, useDispatch } from "react-redux";
import {
  postFetcher,
  postDispatcher,
} from '../../utils/fetch'
import { CSVLink } from "react-csv";

import ConvertActions from "../../actions/ConvertClicksActions";
import { debounce } from "lodash";
import { NumberInput } from "grommet-controls";
import styled from "styled-components";
import FiltersV4 from "../../components/FiltersV4";


import {
  Box,
  Grommet,
  Button,
  Form,
  List,
  Tip,
  Text,
  Layer,
  Spinner,
  Heading,
  CheckBox,
  TextInput,
  Select,
  TextArea,
  FormField,
  DataTable,
  DateInput,
} from "grommet";
import { Search, Add, Send, Copy, View, Download, Filter } from "grommet-icons";
import { minimalTheme } from "../../themes/main-theme";
import Moment from "moment";
// import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";
import { useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";




const agencies = {
  ALL: -1,
  CYGOBELMEDIA: 0,
  MOBLICA: 1,
  CLEARPIERPER: 2,
  KPMBROMEDIA: 3,
  VIDOMO: 4,
  LOPOEMEDIA: 5,
};

const agenciesOpts = [
  { label: "CYGOBELMEDIA", value: "0" },
  { label: "MOBLICA", value: "1" },
  { label: "CLEARPIER", value: "2" },
  { label: "KPMBROMEDIA", value: "3" },
  { label: "VIDOMO", value: "4" },
  { label: "LOPOEMEDIA", value: "5" }
]

function AfapiRawData(props) {
  const { afRawAcq } = props;
  const {
    dateFrom,
    dateTo,
    datePreset,
    publishers,
    agencies,
    pubgroups,
    countries,
    platforms,
    campaigns,
    channels,
    apps,
    withValue,
    hideInstalls
  } = afRawAcq.filters;
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();


  useEffect(() => {
    // Run! Like go get some data from an API.
    postDispatcher("/api/appsflyer/agency-raw-entity-data", { dateFrom, dateTo, datePreset }, dispatch)
    console.info("======= ONLY ONCE  OR DATE CHANGE======")
  }, [dispatch, dateTo, dateFrom, datePreset]);


  const asyncSearch = (search, query) => {
    postDispatcher("/api/appsflyer/agency-raw-entity-data", { search, query }, dispatch)
  };

  const debouncedaAyncSearch = useCallback(debounce(asyncSearch, 300), [asyncSearch]);


  const { data, error, mutate, isValidating } = useSWR(
    [
      `/api/appsflyer/agency-raw-data${window.location.search}`,
      {
        source: "acq",
        dateFrom,
        dateTo,
        apps,
        publishers,
        agencies,
        pubgroups,
        platforms,
        campaigns,
        channels,
        countries,
        withValue,
        hideInstalls
      },
    ],
    postFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  console.info(isValidating, `afapi_raw_acq_table_${data ? data.length : 0}_${afRawAcq.filters.groupBy}`)
  // useEffect(() => {
  //   searchParams.set("withEventValue", "true");
  //   setSearchParams(searchParams);
  // }, []);

  // useEffect(() => {
  //   if (data && data[0]?.event_value) {
  //     if (tableColumns[6].property !== "event_value") {
  //       tableColumns.splice(6, 0, {
  //         property: "event_value",
  //         header: "Value",
  //         align: "center",
  //         render: ({ event_value }) => {
  //           return <div>{+event_value}</div>;
  //         },
  //       });
  //     }
  //   } else {
  //     if (tableColumns[6].property === "event_value") {
  //       tableColumns.splice(6, 1);
  //     }
  //   }
  // }, [data]);

  // console.info({afRawAcq});

  const updateFilters = (name, value) => {
    if (!value) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    setSearchParams(searchParams);
  };

  return (
    <Grommet
      theme={{
        ...minimalTheme,
        text: {
          extend: 'font-size:12px'
        }
      }}
      style={{ width: '100%', maxHeight: '100%', overflow: 'scroll' }}

    >
      <Box>
        <FiltersV4
          state={afRawAcq.filters}
          // noDateInputs
          onFilterChange={(filters) => {
            // console.info({ filters })
            dispatch({
              type: "AFAPI_RAW_ACQ_FILTERS_CHANGE",
              filters
            })
            // setFilters(f => ({ ...f, ...filters }))
          }}
          dynamicFilters={[
            { name: "Apps", opts: afRawAcq.apps, vals: afRawAcq.filters.apps || [] },
            { name: "Agencies", opts: agenciesOpts },
            {
              name: "Publishers", opts: afRawAcq.publishers, onAsyncSearch: ({ val }) => debouncedaAyncSearch("publishers", val)
            },
            { name: "PubGroups", opts: afRawAcq.pubgroups },
            { name: "Campaigns", opts: afRawAcq.campaigns },
            { name: "Channels", opts: afRawAcq.channels },
            { name: "Platforms", opts: afRawAcq.platforms },
            { name: "Countries", opts: afRawAcq.countries },

          ]}
          dynamicBooleans={[
            { name: "With Value", key: "withValue", value: withValue },
            { name: "Hide Installs", key: "hideInstalls", value: hideInstalls },

          ]}
          dataInfo={data ? `${Numeral(data.length).format('0,0')} rows` : undefined}
        />




        {
          data && data.length ? <DataTable
            pin
            primaryKey="id"
            key={`afapi_raw_acq_table_${data ? data.length : 0}_${afRawAcq.filters.groupBy}`}

            columns={tableColumns}
            // paginate
            placeholder={
              (data && !data.length && "No data found") ||
              (isValidating && <Box><Spinner size="medium" message="Loading..." /></Box>)

            }
            pad={{
              horizontal: "medium",
            }}
            background={{
              header: "dark-2",
              body: ["white", "light-2"],
              footer: { dark: "light-2", light: "dark-3" },
            }}
            sortable
            data={data}
            groupBy={afRawAcq.filters.groupBy || undefined}

          /> : (isValidating ? <Box pad="small"><Spinner size="medium" message="Loading..." /></Box> : undefined)


        }
        {/* </Box>


        </Loader> */}
      </Box>

    </Grommet>
  );
}

const mapStateToProps = (state, props) => {
  const { afRawAcq } = state;

  return {
    afRawAcq
  };
};

export default connect(mapStateToProps, { ...ConvertActions })(AfapiRawData);

/*
Raw data report 

Date = Event Time
Filter: Event type = Reg or FTD
Partner = Agency name 
Media Source = Publisher (PID) 
Channel = Channel (PubID_cost)
Column - Publisher - take the PubID from the Channel. check TrackRC who is the Pub -> write the pub name
Campaign = Campaign
Country Code
Platform (IOS & Android)
ClickID Starts with 11e

 Events: Reg & FTD 
Revenue 
Cost 
Profit 


*/
const tableColumns = [
  // {
  //   property: 'id',
  //   header: "ID",
  //   // primary: true,
  //   // footer: 'Total',
  // },
  {
    property: "event_time",
    header: "Event",
    render: ({ event_time }) => {
      return (
        <Cell style={{ width: "max-content" }}>
          {Moment(event_time).format("MM-DD-YY HH:mm")}
        </Cell>
      );
    },
  },
  {
    property: "app_bundle",
    header: "App Bundle",
    render: ({ app_bundle }) => {
      return <Cell start>{app_bundle}</Cell>;
    },
  },
  {
    property: "app_name",
    header: "App Name",
    render: ({ app_name }) => {
      return <Cell start>{app_name}</Cell>;
    },
  },
  {
    property: "partner",
    header: "Agency",
    render: ({ partner }) => {
      return <Cell start>{partner}</Cell>;
    },
  },
  {
    property: "pid",
    header: "PID",
    render: ({ pid }) => {
      return <Cell start>{pid}</Cell>;
    },
  },
  {
    property: "channel",
    header: "Channel",
    render: ({ channel }) => {
      return <Cell start>{channel}</Cell>;
    },
  },
  {
    property: "ch_pub",
    header: "PubGroup",
    render: ({ ch_pub, ch_pubid }) => {
      return <Cell style={{ textTransform: "uppercase" }} start>{ch_pub}</Cell>;
    },
  },
  {
    property: "country_code",
    header: "Country",
    align: "center",
    render: ({ country_code }) => {
      return <Cell>{country_code}</Cell>;
    },
  },
  {
    property: "platform",
    header: "Platform",
    align: "center",
    render: ({ platform }) => {
      return <Cell style={{ textTransform: "uppercase" }}>{platform}</Cell>;
    },
  },
  {
    property: "event_name",
    header: "Event Key",
    render: ({ event_name }) => {
      return (
        <Cell start style={{ width: "max-content" }}>
          {event_name}
        </Cell>
      );
    },
  },
  {
    property: "event_type",
    header: "Event Type",
    render: ({ event_type }) => {
      return <Cell start>{event_type}</Cell>;
    },
  },
  {
    property: "det_clickid",
    header: "CID",
    align: "center",
    render: ({ det_clickid }) => {
      return (
        <div style={{ display: "flex" }}>
          <Tip content={det_clickid}>
            <Button disabled={!det_clickid} icon={<View size='small' />} />
          </Tip>
          <Button
            disabled={!det_clickid}
            icon={<Copy size='small' />}
            onClick={() => navigator.clipboard.writeText(det_clickid)}
          />
        </div>
      );
    },
  },
  {
    property: "revenue",
    header: "Revenue",
    align: "center",
    aggregate: "sum",
    footer: { aggregate: true },
    render: ({ revenue }) => {
      return (
        <Cell type color='green'>
          {revenue ? +revenue : "-"}
        </Cell>
      );
    },
  },
  {
    property: "cost",
    header: "Cost",
    align: "center",
    aggregate: "sum",
    footer: { aggregate: true },
    render: ({ cost }) => {
      return (
        <Cell style={{ color: cost ? "red" : "" }}>{cost ? +cost : "-"}</Cell>
      );
    },
  },
  {
    property: "profit",
    header: "Profit",
    align: "center",
    aggregate: "sum",
    footer: { aggregate: true },
    render: ({ profit }) => {
      return (
        <Cell
          // onCopy={(c) => console.info(c.target.innerText)}
          style={{ color: profit ? "blue" : "" }}>
          {
            // Numeral(
            profit ? +profit : "0"
            // ).format("0,0$")
          }
        </Cell>
      );
    },
  },

  // {
  //   property: "campaign",
  //   header: "Campaign",
  //   render: ({ campaign }) => {
  //     return <Cell start>{campaign}</Cell>;
  //   },
  // },

];
