import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppBarActions from "../../actions/AppBarActions";
import Moment from "moment";
// import MomentTZ from 'moment-timezone';
import { DataTableV2 as DataTable } from "../../components/DataTable";
import ActionButtons from "../../components/ActionButtons";
import FiltersV3 from "../../components/Filters/Filters";
import { Text, Box, CheckBox, Tip } from "grommet";

import _ from "lodash";
import Loader from "../../components/Loader";
import Numeral from "numeral";
import Cell from "../../components/Cell";
import SpeedDial from "../../components/SpeedDial/SpeedDial";

const defaultRenderer = (propName, type = "", color = "") => (props) => {
  let value = props[propName.toLowerCase()];
  if (type === "numeral") {
    value = Numeral(value).format("0,0");
  } else if (type === "currency") {
    value = Numeral(value).format("0,0$");
  } else if (type === "date") {
    value = Moment(value).format("DD/MM/YY HH:mm");
  }

  return (
    <Cell
      start={"true"}
      flex
      color={color}
      type={type}
      // background={'background'}
    >
      <Tip
        plain
        content={
          <Box border round="small" background={"#E6E6E6"} pad="xsmall">
            <Text size="xsmall">
              {propName} - {value}
            </Text>
          </Box>
        }
      >
        <Box>{value}</Box>
      </Tip>
    </Cell>
  );
};

const columns = [
  {
    property: "id_",
    header: "ID",
    render: defaultRenderer("id"),
  },
  {
    property: "enabled",
    header: "Enabled",
    render: ({ enabled, app_bundle, first_setup }) => {
      return (
        <Cell flex>
          <CheckBox
            style={{
              height: "2px",
            }}
            size="small"
            plain
            toggle
            checked={Boolean(enabled)}
            //   onClick={(e) =>
            //     onToggleEnabled(
            //       e,
            //       app_bundle,
            //       Boolean(first_setup),
            //       props
            //     )
            //   }
          />
        </Cell>
      );
    },
  },
  {
    property: "app_bundle",
    header: "App Bundle",
    render: defaultRenderer("app_bundle"),
  },
  {
    property: "app_name",
    header: "App Name",
    render: defaultRenderer("app_name"),
  },
  {
    property: "modified",
    header: "Modified At",
    render: defaultRenderer("modified"),
  },
  {
    property: "created",
    header: "Created At",
    render: defaultRenderer("created"),
  },
  {
    property: "last_to",
    header: "Updated Till",
    render: defaultRenderer("last_to"),
  },
  {
    property: "actions",
    header: "",
    render: (props) => (
      <ActionButtons
        direction="row"
        buttons={[
          {
            name: "Edit placement",
            icon: "Edit",
            triger: "",
            backgroundColor: "brand",
          },
          {
            name: "Toggle api",
            icon: "History",
            triger: "",
            backgroundColor: "brand",
          },
        ]}
      />
    ),
  },
];

function Config(props) {
  const { fetchAppsFlyerAgencyData, appsflyer, browser } = props;
  const { loading, reports, config } = appsflyer;
  // mobile ?
  const isMobile = !browser.greaterThan.medium;

  const [firstLoad, setFirstLoad] = React.useState(true);
  const [queryCriteria, setQueryCriteria] = React.useState({});

  React.useEffect(() => {
    async function getData() {
      await fetchAppsFlyerAgencyData({});
    }

    if (reports.length < 1) {
      getData();
    }

    // console.log(config);
  }, []);

  //
  //
  //
  const speedDialActions = [
    {
      name: "add",
      action: () => {},
    },
  ];

  //
  //
  if (isMobile) {
    speedDialActions.unshift({
      name: "filters",
      action: () => {},
    });
  }
  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading Offers data...">
        <SpeedDial actions={speedDialActions} />
        <FiltersV3
          fields={[
            [
              {
                name: "fromDate",
                type: "search",
                width: "40%",
                placeholder: `Search for app bundle or name`,
              },
              {
                name: "addBundle",
                type: "button",
                label: "Add Bundle",
              },
            ],
          ]}
          handleSubmit={setQueryCriteria}
          firstLoad={firstLoad}
          onFirstLoad={() => setFirstLoad(false)}
        />
        <Box
          style={{
            flex: 13,
            overflowX: "auto",
            paddingBottom: "5vh",
            // overflowY: "hidden",
          }}
        >
          <DataTable
            pin
            fill
            // key={groupBy + reportsData.length}
            // rowProps={getRowProps(reportsData)}
            background={{
              header: "dark-3",
              // body: ["#dff0d8"],
              footer: "light-3",
            }}
            sortable
            // onSort={({ direction, property }) =>
            //   setSortSettings({
            //     property,
            //     direction,
            //   })
            // }
            primaryKey={"id"}
            // {...(["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {})}
            data={config}
            columns={columns}
            // sort={sortSettings}
            wideColumns={[2]}
          />
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const { appsflyer } = state;

  return { appsflyer, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AppsFlyerActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Config);
