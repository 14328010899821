import Ax from "axios";

// Fetch skadnRedirects data
export const getInitialData = (req) => {
  return async (dispatch) => {
    dispatch({
      type: "SKADN_REDIRECTS_DATA_LOADING",
      payload: {
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/skadn-redirects`;
      const response = await Ax.get(apiUrl);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
      console.info(e.name);
    }
  };
};

export default {
  getInitialData,
};
