import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const useStyles = makeStyles({
  messageContainer: {
    margin: (props) => (props.browser.width < 1201 ? "2%" : "0"),
    borderBottom: "1px solid lightgray",
    padding: "1% 1%",
  },
  messageHeader: {
    color: "gray",
    fontWeight: "500",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.1%",
    textTransform: "uppercase",
  },
  messageDismiss: {
    color: "blue",
    fontWeight: "200",
    fontSize: "11px",
    textDecoration: "underline",
    marginBottom: "0.1%",
    marginRight: "1%",
    "&:hover": {
      cursor: "pointer",
      color: "darkblue",
    },
  },
  message: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    color: "gray",
    fontSize: "13px",
    fontWeight: "200",
    // padding: theme.spacing(1),
  },
});

function Messages(props) {
  const classes = useStyles(props);
  const { message, title } = props;
  const [showMessage, setShowMessage] = React.useState(true);

  return message ? (
    <div className={classes.messageContainer}>
      <div className={classes.messageHeader}>
        {title}
        <div
          className={classes.messageDismiss}
          onClick={() => setShowMessage(!showMessage)}
        >
          {showMessage ? "Dismiss" : "Show"}
        </div>
      </div>
      {showMessage && <div className={classes.message}>{message}</div>}
    </div>
  ) : null;
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  return { browser };
}

export default connect(mapStateToProps)(Messages);
