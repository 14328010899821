import React from "react";

import { Box, TextInput } from "grommet";
import { Search } from "grommet-icons";

import styled from "styled-components";

const SearchInput = styled(TextInput)`
  min-width: 700px;
`;

const SuggestionsTextInput = (props) => {
  //   const [value, setValue] = React.useState("");

  //   const onChange = event => setValue(event.target.value);

  //   const onSelect = event => setValue(event.suggestion);

  let {
    value,
    searchSuggestions,
    searchOnChange,
    searchOnSelect,
    placeholder,
  } = props;

  if (!searchOnSelect || !searchOnChange) {
    return null;
  }

  if (!searchSuggestions) {
    searchSuggestions = [];
  }

  return (
    <Box align="start" justify="start" pad="small">
      <Box width="medium">
        <SearchInput
          placeholder={placeholder}
          icon={<Search size="18" />}
          value={value}
          onChange={searchOnChange}
          onSelect={searchOnSelect}
          suggestions={searchSuggestions}
        />
      </Box>
    </Box>
  );
};

export default SuggestionsTextInput;
