import _ from "lodash";
import Moment from "moment";


const dateToday = Moment.utc();

const GROUP_BY_OPTS = [
    { label: "Date", value: "date" },
    { label: "App Name", value: "app_name" },
    { label: "Publisher", value: "pid" },
    { label: "PubGroup", value: "ch_pub" },
    { label: "Agency", value: "partner" },
    { label: "Campaign", value: "campaign" },
    { label: "GEO", value: "country" },
    { label: "Platform", value: "platform" },
];

const AFAPI_RAW_FINANCE_STATE = {
    filters: {
        datePreset: "Today",
        dateFrom: dateToday.startOf("day").format("YYYY-MM-DD"),
        dateTo: Moment(dateToday).endOf("day").format("YYYY-MM-DD"),
        dateCustom: false,
        groupBy: "",
        groupByOpts: GROUP_BY_OPTS,
        publishers: [],
        agencies: [],
        pubgroups: [],
        campaigns: [],
        channels: [],
        platforms: [],
        countries: [],
        apps: [],
        withValue: true,
        hideInstalls: true,
        showDupes: false


    },
    loading: false,
    config: [],
    publishers: [],
    campaigns: [],
    channels: [],
    platforms: [],
    countries: [],
    pubgroups: [],
    apps: [],
};

const afapiRawFinanceReducer = (state = AFAPI_RAW_FINANCE_STATE, action) => {
    switch (action.type) {
        case "GET_AFAPI_RAW_FINANCE_ENTITY_DATA": {
            const { entityData } = action;
            return {
                ...state,
                ...entityData,
            };
        }
        case "ADD_AFAPI_RAW_FINANCE_ENTITY_DATA": {
            const { entityData } = action;
            const key = Object.keys(entityData)[0];
           
            return {
                ...state,
                [key]:  _.uniqBy([...entityData[key], ...state[key]], "value"),
            };
        }

        case "AFAPI_RAW_FINANCE_FILTERS_SYNC":
        case "AFAPI_RAW_FINANCE_FILTERS_CHANGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        }
        default:
            return state;
    }
};

export default afapiRawFinanceReducer;
