import _ from "lodash";

const reportModel = {
  id: null,
  date: null,
  pid: null,
  app_bundle: null,
  publisher: null,
  campaign: null,
  country: null,
  impressions: null,
  clicks: null,
  installs: null,
  registrations: null,
  ftd: null,
  revenue: null,
  cost: null,
  profit: null,
  app_name: null,
  // f360_bi: null,
  bp: null,
  bi: null,
  ti: null,
  bt: null,
  bp_p: null,
  bi_p: null,
  bt_p: null,
};

function addAgencyConstants(agencies) {
  let constants = {
    ID_MAP: {},
    OPTS: []
  }
  if (!agencies) {
    return constants
  }


  agencies.forEach(n => {
    constants.ID_MAP[n.agency_id] = {
      name: n.agency_name,
      agencyId: n.agency_id,
      id: n.agency_id

    }

    constants.OPTS.push({
      label: n.agency_name, 
      agencyId: n.agency_id,
      id: n.agency_id

    })
  })

  return { constants }

}
const appsflyer = (state = { loading: 0, configMap: {}, reports: [], events: [], constants: {} }, action) => {
  switch (action.type) {
    case "AF_AGENCY_FETCH_ALL_DATA":
    case "AF_AGENCY_LOADING_DATA": {
      const { reports: raw, ...rest } = action.payload;
      const reports = (raw || []).map((o) => _.pick(o, _.keys(reportModel)));
      return { 
        ...state, 
        ...rest, 
        reports,
        ...addAgencyConstants(rest.agencies)

      };
    }
    case "TRACKRC_CONN_LOST": {
      return Object.assign({}, state, { loading: false });
    }
    case "AF_APP_BUNDLE_CREATED": {
      const { app_bundle } = action.payload;
      return Object.assign({}, state, {
        config: [...state.config, action.payload],
        configMap: Object.assign({}, state.configMap, {
          [app_bundle.toUpperCase()]: action.payload,
        }),
      });
    }
    case "AF_APP_BUNDLE_DELETED":
      return Object.assign({}, state, {
        config: state.config.filter(
          (o) => o.app_bundle !== action.payload.deleted
        ),
        reports: state.reports.filter(
          (o) => o.app_bundle !== action.payload.deleted
        ),
      });
    case "AF_APP_BUNDLE_DATA_RESET":
      return Object.assign({}, state, {
        reports: state.reports.filter(
          (o) => o.app_bundle !== action.payload.app_bundle
        ),
      });
    case "AF_APP_BUNDLE_UPDATED":
      const { updatedAppBundle } = action.payload;
      return Object.assign({}, state, {
        config: state.config.map((o) => {
          if (o.app_bundle === updatedAppBundle.app_bundle) {
            return updatedAppBundle;
          }
          return o;
        }),
      });
    case "AF_APP_BUNDLE_NAME_UPDATED":
      const { app_name, app_bundle } = action.payload;
      return Object.assign({}, state, {
        config: state.config.map((o) => {
          if (o.app_bundle === app_bundle) {
            return Object.assign({}, o, { app_name });
          }
          return o;
        }),
      });
    case "AF_APP_BUNDLE_EVENT_UPDATED":
      return Object.assign({}, state, {
        events: state.events.map((o) => {
          if (o.id === action.payload.id) {
            return action.payload;
          }
          return o;
        }),
      });
    case "AF_APP_BUNDLE_EVENT_CREATED":
      return Object.assign({}, state, {
        events: [...state.events, action.payload],
      });
    // case "AF_APP_BUNDLE_EVENT_CREATED":
    //     return Object.assign({}, state, {
    //         events: state.events.map(o => {
    //             if (o.id === action.payload.id) {
    //                 return action.payload
    //             }
    //             return o
    //         })
    //     });

    default:
      return state;
  }
};

export default appsflyer;
