import _ from "lodash";
import Moment from "moment";

const reportModel = {
  id: null,
  date: null,
  app_name: null,
  app_bundle: null,
  pid: null,
  partner: null,
  agency: null,
  publisher: null,
  campaign: null,
  country: null,
  impressions: null,
  clicks: null,
  installs: null,
  registrations: null,
  ftd: null,
  revenue: null,
  cost: null,
  profit: null,
  bp: null,
  bi: null,
  ti: null,
  bt: null,
  bp_p: null,
  bi_p: null,
  bt_p: null,
  // yClicks: null,
  // pyClicks: null,
  // change_p: null
  clicks_chg_pcnt: null,
  installs_chg_pcnt: null,
  registrations_chg_pcnt: null,
  ftd_chg_pcnt: null,
  revenue_chg_pcnt: null,
  impressions_chg_pcnt: null,
  ber_ftd: null,
  bep_ftd: null,
  ber_reg: null,
  bep_reg: null,
  f360_bc: null,
  f360_cc: null,

};
const defaultState = {
  loading: 0,
  use_geo_reports: true,
  agency_loading: 0,
  adnloading: 0,
  adn_reports: [],
  adn_config: [],
  adn_events: [],
  agency_reports: [],
  agency_geo_reports: [],
  agency_config: [],
  agency_events: [],
  publishers: [],
  ipublishers: [],

  partners: [],
  updateHealth: 0,
  // combined
  reports: [],
  geo_reports: [],
  config: [],
  combinedAppBundleOpts: [[], []],
  events: [],
  options: {
    app_bundle: [],
    publisher: [],
    campaign: [],
    country: [],
  },
};

const formatBundleEvents = (config) => (e) => {


  // console.info({ e, config });
  // if (e.adn_id === 100) {
  //   console.info('event',e)
  // }
  const { isAdn } = e;
  const [foundObj] = config.filter(
    (o) =>
      o.isAdn === isAdn &&
      o.app_bundle.toUpperCase() === e.app_bundle.toUpperCase() &&
      ((isAdn && o.adn_id === e.adn_id) || (!isAdn && o.agency_id === e.agency_id))
  );
  if (e.adn_id === 100) {
    // console.info('event',e, foundObj, config)
  }
  const { app_name } = foundObj || { app_name: "" };
  if (!app_name) {
    return null;
  }

  // ::${Number(e.isAdn)}::${e.adn_id}
  return {
    ...e,
    app_bundle_name: `${app_name}::${e.app_bundle}`,
    key: `${Number(e.isAdn)}_${e.adn_id}_${e.id}`,
    app_bundle_info: `${app_name}:::${e.app_bundle}:::${Number(isAdn)}:::${isAdn ? e.adn_id : e.agency_id}`
    // app_bundle: e.app_bundle.toLowerCase
  };
};

const getCombined = (payload, state, isAdn = false) => {
  const prefix = isAdn ? "adn" : "agency";
  const revPrefix = isAdn ? "agency" : "adn";

  let labels = {};
  const labelNames = ["loading", "reports", "config", "events"];
  labelNames.forEach((labelName) => {
    labels[labelName] = {
      current: `${prefix}_${labelName}`,
      reverse: `${revPrefix}_${labelName}`,
    };
  });

  const loading = payload.loading || state[labels.loading.reverse];

  // const currentReportsLabel = `${prefix}_reports`;
  // const revReportsLabel = `${revPrefix}_reports`;

  // const bundlesLabel = `${prefix}_bundles`;

  const reportFormatter = (r, isAdn) =>
    r
      .map((o) => _.pick(o, _.keys(reportModel)))
      .map((o) => ({
        ...o,
        isAdn,
      }));

  const currentReportsData = reportFormatter(payload.reports || [], isAdn);

  let agencyNamesMap = {};

  const agencyReports = isAdn ? state.agency_reports || [] : payload.reports;
  const adnReports = (isAdn ? payload.reports : state.adn_reports) || [];
  const agencyGeoReports = isAdn
    ? state.agency_geo_reports
    : reportFormatter(payload.geoReports);


  agencyReports.forEach((o) => {
    // console.info("o,",o)
    // FIX DUPLICATE
    agencyNamesMap[`${o.app_bundle.toUpperCase()}::${o.app_bundle.agency}`] = o.app_name;
  });

  const publishers = _.uniq([
    ...state.publishers,
    ...(payload.publishers || []),
  ]);




  const getPublishersGroupMap = () => {

    // console.info("GET PUBLISHERS GROUP MAP")
    let pgMap = {}

    payload.ipublishers.forEach(({ id, name: publisher_group, publishers }) => {

      const publisherArr = publishers.split(',');
      // console.info({publisherArr})
      publisherArr.forEach(publisher => {
        pgMap[publisher] = publisher_group

       })
    })
    return pgMap
  }

  const publishersGroupMap = payload.ipublishers ? getPublishersGroupMap() : {
    ...state.publishersGroupMap
  }

  const reportsReduce = (reportsArr) => {
    const agencyKeys = {};


    return reportsArr.map((o) => {
      const app_bundle = o.app_bundle.toUpperCase();
      const publisher = o.publisher.toUpperCase();
      const campaign = o.campaign.toUpperCase();
      const country = o.country.toUpperCase();
      const app_name = agencyNamesMap[app_bundle] || o.app_name;
      // console.info("pg",publishersGroupMap[publisher])
      // if (app_bundle.toLowerCase() === 'com.hbo.hbonow') {
      //   console.info({
      //     app_bundle,
      //     o
      //   })
      // }

      
      if (!o.isAdn) {
        const key = `${o.date}::${o.publisher}::${o.agency}::${o.campaign}`.toLowerCase()
        agencyKeys[key] = true;
      }
      
      const publisher_group = publishersGroupMap[publisher];
      // console.info("PUBLISHER GROUP", publisher_group, publisher)
      return {
        ...o,
        app_bundle,
        app_name,
        publisher,
        campaign,
        publisher_group,
        country,
      };
    }).filter(o => {
      if (o.isAdn && o.partner === 'cygobelmedia') {
        const key = `${o.date}::${o.publisher}::${o.partner}::${o.campaign}`.toLowerCase()
        // console.info("ADN KEY", key, agencyKeys[key])
        if (agencyKeys[key]) return false
          // console.info("CYGOBEL ADN", o)

      }
      return true
    })
  }


  const reports = {
    [labels.reports.current]: currentReportsData,
    reports: reportsReduce([
      ...currentReportsData,
      ...(state[labels.reports.reverse] || []),
    ]),
    geo_reports: reportsReduce([...agencyGeoReports, ...adnReports]),
    agency_geo_reports: agencyGeoReports,
  };

  console.info({publishersGroupMap})

  const currentConfigData = payload.config.map((o) => ({
    ...o,
    isAdn,
    created: Moment(o.created).format("YYYY-MM-DD HH:mm:ss"),
    modified:
      o.modified &&
      (isAdn
        ? Moment(o.modified).add(3, "hours").format("YYYY-MM-DD HH:mm:ss")
        : Moment(o.modified).format("YYYY-MM-DD HH:mm:ss")),
    last_to: o.last_to && Moment(o.last_to).format("YYYY-MM-DD HH:mm:ss"),
  }));

  const config = {
    [labels.config.current]: currentConfigData,
    config: _.orderBy(
      [...currentConfigData, ...(state[labels.config.reverse] || [])],
      ["app_bundle", "modified"],
      ["desc", "desc"]
    ),
  };

  const updateHealth = config.config
    .filter((c) => c.enabled)
    .map((c) => {
      if (c.isAdn) {
        return Math.abs(Moment(c.last_to).add(3, 'hours').diff(Moment.utc(), "minutes"))

      }
      return Math.abs(Moment(c.modified).add(3, 'hours').diff(Moment.utc(), "minutes"))

    })
    .filter((c) => c > 90).length;

  console.info()

  const currentEventData = payload.events.map((o) => ({
    ...o,
    key: `${Number(isAdn)}_${o.adn_id}_${o.id}`,
    isAdn,
  }));

  const events = {
    [labels.events.current]: currentEventData,
    events: [...currentEventData, ...(state[labels.events.reverse] || [])]
      .map(formatBundleEvents(config.config))
      .filter((o) => o),
  };

  const options = {};

  Object.keys(state.options).forEach((optName) => {
    options[optName] = _.uniqBy(reports.reports, optName).map((o) => {
      const value = o[optName];
      const type = optName;
      // console.info(o)
      const label =
        optName === "app_bundle"
          ? `${o.app_bundle} :: ${(o.app_name || '')
            .replace(/_/g, "-")
            .replace(/ /g, "")}`
          : o[optName];
      return {
        value,
        type,
        label,
      };
    });
  });

  const combinedAppBundleOpts = [
    config.config
      .filter((c) => !c.isAdn)
      .map((c) => ({ value: c.app_bundle, label: c.app_name, agencyId: c.agency_id })),
    config.config
      .filter((c) => c.isAdn)
      .map((c) => ({ value: c.app_bundle, label: c.app_name, adnId: c.adn_id })),
  ];

  const partners = _.uniq([
    ...state.partners,
    ...(payload.partners || []),
  ]);

  const ipublishers = (payload.ipublishers ? payload.ipublishers : state.ipublishers) || [];
  return {
    [labels.loading.current]: payload.loading,
    loading,
    updateHealth,
    configMap: payload.configMap,
    ...reports,
    ...config,
    ...events,
    publishers,
    publishersGroupMap,
    ipublishers,
    partners,
    combinedAppBundleOpts,
    options,
  };
};
const appsflyerAdn = (state = defaultState, action) => {
  const { type, payload } = action;
  const isAdnAction = type.indexOf("ADN") > -1;

  switch (type) {
    case "AF_AGENCY_LOADING_DATA": {
      return {
        ...state,
      };
    }
    case "AF_AGENCY_FETCH_ALL_DATA":
      return {
        ...state,
        ...getCombined(payload, state, false),
      };
    case "AF_ADN_FETCH_ALL_DATA": {
      return {
        ...state,
        ...getCombined(payload, state, true),
      };
    }
    case "AF_ADN_LOADING_DATA": {
      return {
        ...state,
      };
    }
    case "FETCH_AF_COMBINED_DATA": {
      return {
        ...state,
        ...payload,
      };
    }

    case "TRACKRC_CONN_LOST": {
      return Object.assign({}, state, { loading: false });
    }
    case "AF_APP_BUNDLE_CREATED":
    case "AF_ADN_APP_BUNDLE_CREATED": {
      return {
        ...state,
        config: [{
          ...action.payload,
          isAdn: isAdnAction
        }, ...state.config],
      };
    }
    case "AF_APP_BUNDLE_NAME_UPDATED":
    case "AF_ADN_APP_BUNDLE_NAME_UPDATED": {
      const { app_name, app_bundle, adn_id } = action.payload;
      console.info("REDUCER", payload)
      return {
        ...state,
        config: state.config.map((o) => {
          let filter = o.app_bundle === app_bundle && !o.isAdn
          if (isAdnAction) {
            filter = o.isAdn
              && o.adn_id === adn_id
              && o.app_bundle === app_bundle
          }
          if (filter) {
            return {
              ...o,
              app_name,
            };
          }
          return o;
        }),
      };
    }

    case 'ALERTS_OPTIONS_RESP': {
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options
        }
      }
    }

    case "AF_APP_BUNDLE_UPDATED":
    case "AF_ADN_APP_BUNDLE_UPDATED": {
      const { updatedAppBundle } = action.payload;
      const { app_bundle, adn_id, agency_id } = updatedAppBundle;
      return {
        ...state,
        config: state.config.map((o) => {
          let filter = 
          o.app_bundle === app_bundle 
          && !o.isAdn
          && o.agency_id === agency_id;
          
          if (isAdnAction) {
            filter = o.isAdn
              && o.adn_id === adn_id
              && o.app_bundle === app_bundle
          }
          if (filter) {
            return {
              ...updatedAppBundle,
              isAdn: o.isAdn
            }
          }
          return o;
        }),
      };
    }
    case "AF_APP_BUNDLE_EVENT_UPDATED":
    case "AF_ADN_APP_BUNDLE_EVENT_UPDATED": {
      const { id, app_bundle, adn_id } = action.payload;

      return {
        ...state,
        events: state.events.map((o) => {
          let filter = o.app_bundle === app_bundle && o.id === id && !o.isAdn
          if (isAdnAction) {
            filter = o.isAdn
              && o.id === id
              && o.adn_id === adn_id
              && o.app_bundle === app_bundle
          }

          if (filter) {
            return {
              ...o,
              ...action.payload,
            };
          }
          return o;
        }),
      };
    }
    case "AF_APP_BUNDLE_EVENT_CREATED":
    case "AF_ADN_APP_BUNDLE_EVENT_CREATED": {
      return {
        ...state,
        events: [
          ...state.events,
          formatBundleEvents(state.config)({
            ...action.payload,
            isAdn: isAdnAction,
          }),
        ],
      };
    }

    case "AF_ADN_BUNDLE_DUPLICATED": {
      const newConfigs = action.payload.config.map(c => ({
        ...c,
        isAdn: true
      }))
      const formatter = formatBundleEvents(newConfigs)
      return {
        ...state,
        events: [
          ...state.events,
          ...action.payload.events.map(e => formatter({ ...e, isAdn: true }))
        ],
        config: [
          ...state.config,
          ...newConfigs
        ]
      }
    }
    case "AF_BUNDLE_DUPLICATED": {
      const newConfigs = action.payload.config.map(c => ({
        ...c,
        isAdn: false
      }))
      const formatter = formatBundleEvents(newConfigs)
      return {
        ...state,
        events: [
          ...state.events,
          ...action.payload.events.map(e => formatter({ ...e, isAdn: false }))
        ],
        config: [
          ...state.config,
          ...newConfigs
        ]
      }
    }



    default:
      return state;
  }
};

export default appsflyerAdn;
