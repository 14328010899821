import React from "react";
import { Box, Button, Text, Tip } from "grommet";
import _ from "lodash";
import {
  MoreVertical,
  Close,
  Code,
  Select as SelectIcon,
  Edit,
  Duplicate,
  Trash,
  Link,
  History,
} from "grommet-icons";

// get the proper icon for the button
function getIcon(icon) {
  switch (icon) {
    case "Edit":
      return <Edit size="12px" color="black" />;
    case "History":
      return <History size="12px" color="red" />;
    case "Duplicate":
      return <Duplicate size="12px" color="black" />;
    case "Code":
      return <Code size="12px" color="black" />;
    case "Select":
      return <SelectIcon size="12px" color="black" />;
    case "Trash":
      return <Trash size="12px" color="red" />;
    case "Link":
      return <Link size="12px" color="black" />;
    default:
      break;
  }
}

function ActionButtons(props) {
  const { buttons, direction } = props;
  return (
    <Box align="center" justify="center" direction={direction && direction}>
      {buttons.map((btn) => {
        return (
          // Tooltip
          <Tip
            key={btn.name}
            plain
            content={
              !btn.disabled && (
                <Box
                  round={btn.toolTipBorderRadius || "5px"}
                  background={btn.toolTipBackgroundColor || "#E6E6E6"}
                  pad="none"
                >
                  <Text size="xsmall">{btn.name}</Text>
                </Box>
              )
            }
          >
            <Box pad="none">
              <Button
                icon={getIcon(_.capitalize(btn.icon))}
                hoverIndicator
                onMouseDown={(e) => btn.triger(e)}
                disabled={btn.disabled ? true : false}
              />
            </Box>
          </Tip>
        );
      })}
    </Box>
  );
}

export default ActionButtons;
