import {
  Home,
  Analytics,
  PersonalComputer as PublishersIcon,
  System as ManagementIcon,
  Code as ApisIcon,
  BarChart as ReportsIcon,
  Cube,
  Ad,
  Emergency,
  CaretUpFill,
  CaretDownFill,
  Logout as LogoutIcon,
} from "grommet-icons";

export const navigationLinks = [
  {
    src: "",
    name: "dashboard",
    icon: <Home color='white' size='18px' />,
  },
  {
    src: "dashboard-new",
    name: "dashboard new",
    icon: <Home color='white' size='18px' />,
  },
  {
    src: "advertisers",
    icon: <Ad color='white' size='18px' />,
    child: [
      {
        name: "Advertisers",
        src: "advertisers",
        icon: "",
      },
      {
        name: "Offers",
        src: "offers",
        icon: "",
      },
    ],
  },
  {
    src: "publishers",
    icon: <PublishersIcon color='white' size='18px' />,
    child: [
      {
        name: "Publishers",
        src: "publishers",
        icon: "",
      },
      {
        name: "Placements",
        src: "placements",
        icon: "",
      },
    ],
  },

  {
    src: "reports",
    icon: <ReportsIcon color='white' size='18px' />,
    child: [
      {
        name: "Statistics",
        src: "statistics",
        icon: "",
      },
      {
        name: "Conversions",
        src: "conversions",
        icon: "",
      },
    ],
  },
  {
    src: "appsflyer",
    agency: "true",
    icon: <Analytics color='white' size='18px' />,
    child: [
      {
        agency: "true",
        name: "Reports",
        src: "af-combined/reports",
        icon: "",
      },
      {
        agency: "true",
        name: "Config",
        src: "af-combined/config",
        icon: "",
      },
      {
        agency: "true",
        name: "Events",
        src: "af-combined/events",
        icon: "",
      },
      {
        name: "Publisher Groups",
        src: "af-combined/publisher-groups",
        icon: "",
      },
      {
        name: "Agency Raw Acq Data",
        src: "agency/agency-raw-acq-reports",
        icon: "",
      },
      {
        name: "Agency Raw Finance",
        src: "agency/agency-raw-finance",
        icon: "",
      },
      {
        name: "Charts",
        src: "af-combined/charts",
        icon: "",
      },
    ],
  },
  {
    src: "adjust",
    icon: <Analytics color='white' size='18px' />,
    child: [
      {
        name: "Reports",
        src: "adjust/adjust-reports",
        icon: "",
      },
      {
        name: "Config",
        src: "adjust/adjust-config",
        icon: "",
      },
      {
        name: "Events",
        src: "adjust/adjust-events",
        icon: "",
      },
    ],
  },
  {
    src: "api",
    icon: <ApisIcon color='white' size='18px' />,
    child: [
      {
        name: "Networks",
        src: "integrations/networks",
        // icon: <Nodes color="white" />,
      },
      {
        name: "External Offers",
        src: "external-offers",
        // icon: <Nodes color="white" />,
      },
    ],
  },

  {
    src: "management",
    icon: <ManagementIcon color='white' />,
    child: [
      {
        name: "Crons",
        src: "crons",
        icon: "",
      },
      {
        name: "SKADN Redirects",
        src: "skadnRedirects",
        // icon: <Redo color="white" />,
      },
      {
        name: "Users",
        src: "user-management",
        // icon: <Redo color="white" />,
      },
      // {
      //   name: "RC Users",
      //   src: "trackrc-user-management",
      //   // icon: <Redo color="white" />,
      // },
    ],
  },

  {
    src: "Convert Service",
    icon: <Cube color='white' />,
    child: [
      {
        name: "Create Job",
        src: "convert-service/create-job",
        // icon: <Redo color="white" />,
      },

      {
        name: "Jobs",
        src: "convert-service/jobs",
        // icon: <Redo color="white" />,
      },
      {
        name: "Clicks",
        src: "convert-service/clicks",
        // icon: <Redo color="white" />,
      },
    ],
  },
  {
    src: "Alerts Service",
    icon: <Emergency color='white' />,
    child: [
      {
        name: "Dashboard",
        src: "alerts/alerts-dashboard",
        // icon: <Redo color="white" />,
      },
      {
        name: "Alerts Config",
        src: "alerts/alerts-config",
        // icon: <Redo color="white" />,
      },
    ],
  },
];
