import React, { useEffecrt, useEffect, useState } from "react";
import { connect } from "react-redux";
import ConvertActions from '../../actions/ConvertClicksActions';
import { debounce } from 'lodash';
import { NumberInput } from 'grommet-controls'
import styled from 'styled-components';
import {
    Box,
    Grommet,
    Button,
    Form,
    List,
    Tip,
    Text,
    Layer,
    Spinner,
    Heading,
    CheckBox,
    TextInput,
    DataTable,
    Select,
    TextArea,
    FormField,
} from "grommet";
import { Search, Add, Send, FormCheckmark, FormSchedule, FormClose } from 'grommet-icons';
import { minimalTheme } from "../../themes/main-theme";
import Moment from 'moment';



function ConvertClicks(props) {
    const { syncConvertData, jobs, clicks, lastDataFetch } = props;


    useEffect(() => {
        syncConvertData()
    }, [lastDataFetch, syncConvertData])

    return (
        <Grommet theme={minimalTheme} full>

            <Box fill flex>
                {/* <Heading level="3" size='large' margin={{ vertical: "small" }}>Jobs Placements</Heading> */}
                <DataTable
                    columns={[
                        {
                            property: 'id',
                            header: "ID",
                            primary: true,
                            // footer: 'Total',
                        },
                        {
                            property: 'status',
                            header: "Status",
                            render: ({ status }) => { 

                                let StatusIcon = FormSchedule
                                console.info({status})
                                switch (status) {
                                    case "1": {
                                        StatusIcon = FormCheckmark
                                        break;
                                    }
                                    case "2": {
                                        StatusIcon = FormClose;
                                        break;
                                    }
                                    default:{

                                    } 
                                    
                                }

                                return (
                                    <Box justify='center'>
                                        {<StatusIcon size='26px' color='#0e1111'/>}
                                    </Box>
                                )
                             }

                        },
                        // FormCheckmark
                        {
                            property: 'uuid',
                            header: "UUID",
                            render: ({ uuid }) => { return uuid.split("-")[0] }
                        },
                        {
                            property: 'placements',
                            header: "Placements",
                            render: ({ placements }) => { return placements.split(",").join(", ") }
                        },
                        {
                            property: 'clicks_requested',
                            header: "Clicks REQ",
                            render: ({ clicks_requested, uuid, clicks_found }) => {

                                return `${clicks_requested} (${clicks.filter(c => c.uuid === uuid).length})`

                            }
                        },
                        {
                            property: 'clicks_found',
                            header: "Clicks Found",
                            // render: ({clicks_requested}) => { return  clicks.filter(c=> c.uuid === uuid).length}
                        },
                        {
                            property: 'seconds',
                            header: "Duration",
                            render: ({ seconds }) => { return Moment.utc(seconds * 1000).format('HH:mm:ss') }
                        },

                        {
                            property: 'created',
                            header: "Created",
                            render: ({ created }) => { return Moment(created).format("MM-DD HH:mm") }
                        },
                        {
                            property: 'modified',
                            header: "Modified",
                            render: ({ modified }) => { return Moment(modified).format("MM-DD HH:mm") }
                        },

                    ]}
                    data={jobs}
                    rowDetails={(row) => {
           
                        return <Box> {clicks.filter(c => c.uuid === row.uuid).map((c, i) => 
                                <Box pad='small' key={c.click_id}>[ {String(i + 1).padStart('2',0)} ] [ {c.placement_id} ] {c.click_id} </Box>
                            )} </Box>;
                    }}
                />
            </Box>
        </Grommet>
    );
}


const mapStateToProps = (state, props) => {
    const { convertClicks } = state;
    const { jobs, clicks, lastDataFetch } = convertClicks

    return {
        jobs,
        clicks,
        lastDataFetch,
    };
};

export default connect(mapStateToProps, { ...ConvertActions })(ConvertClicks);

