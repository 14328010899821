import _ from "lodash";
import Moment from "moment";

const cronLogs = (state = { loading: 0, cronLogs: [] }, action) => {
  switch (action.type) {
    case "CRON_LOGS_DATA_LOADING": {
      return { ...state, ...action.payload };
    }
    case "CRON_LOGS_DATA_LOADED": {
      const crons = action.payload.cronLogs.map((cron) => {
        return {
          ...cron,
          last_run: Moment(cron.last_run)
            .add(3, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      return { ...state, ...action.payload, cronLogs: crons };
    }
    default:
      return state;
  }
};

export default cronLogs;
