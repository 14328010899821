import React, { Component } from "react";
import _ from "lodash";
import { Box, TextInput, Text, Collapsible, Select, CheckBox } from "grommet";

import { LinkNext } from "grommet-icons";
import XButton from "../../components/xComponents/Button";
import SearchInput from "../../components/xComponents/SearchInput";
import COUNTRY_CODES from "../../constants/country-codes.json";
import styled from 'styled-components';


const StyleCheckbox = styled(CheckBox)`
  flex: 0 1;
  background: red;
  max-width: 50px;
`


const COUNTRY_OPTS = COUNTRY_CODES.map((c) => c["alpha-2"]);
const INTERNAL_STATUS_OPTS = [
  { label: 'All Active', value: 1 },
  { label: 'All Inactive', value: 2 },
  { label: 'Inactive Clicks', value: 3 },
  { label: 'Inactive Conversion', value: 4 }
]
export const Search = (props) => {
  const {
    options: defaultOptions,
    placeholder,
    name,
    value: defaultValue,
    onChange,
    onSearch,
  } = props;

  const [options, setOptions] = React.useState(defaultOptions);
  const [value, setValue] = React.useState("");
  const [valueMultiple, setValueMultiple] = React.useState([defaultValue]);

  return (
    <Select
      name={name}
      size="medium"
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={(...e) => {
        setValue(e.option);
        onChange(...e);
        // setOpts((options))
      }}
      onClose={() => setOptions(defaultOptions)}
      onSearch={(text) => {
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, "i");
        setOptions(defaultOptions.filter((o) => exp.test(o)));
        onSearch(text);
      }}
    />
  );
};

class PlacementsFilters extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      criteria: {
        publisher: [],
        advertiser: [],
        offer: "",
        traffic_allowd: "",
        internalStatus: 0,
        rank: [],
        countries: [],
        offerGroupOnly: false,
        apiOnly: false,
        hideInactive: false,
      },
    };

    this.debouncedHandleSubmit = _.debounce(props.handleSubmit, 500);

    // this.onFilterData = this.onFilterData.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
  }

  // handle fields change on add advertiser layer
  handleFiltersChange(e) {
    const { handleSubmit } = this.props;

    const { criteria } = this.state;
    const { value, name, type, checked } = e.target;


    console.log(name);
    console.log(type);
    console.log(e.value);

    // console.log(name);
    // console.log(type);
    // console.log(e.value);

    let newValue = (e.option && e.option) || e.value ? e.value : value;
    console.log({ newValue });

    if (typeof newValue === 'object' && newValue.label) {
      newValue = newValue.value
    }


    if (type === "checkbox") {
      newValue = checked;
    }
    if (name === 'rank') {
      newValue = e.value || []
    }
    if (['advertiser', 'publisher', 'countries'].indexOf(name) > -1 &&
      typeof newValue !== 'object'
    ) {
      newValue = []
    }

    if (['internalStatus'].indexOf(name) > -1) {
      newValue = e.value.value || 0
    }


    // update new values
    let newValues = {};

    newValues = {
      ...criteria,
      ...{ [name]: newValue },
    };

    if (name === 'placementSearch') {
      this.debouncedHandleSubmit(newValues)
      return this.setState({ criteria: newValues });
    }
    handleSubmit(newValues);
    this.setState({ criteria: newValues });
  }

  render() {
    // function for handling filter/submit button
    const {
      handleSubmit,
      open,
      advertisers,
      publishers,
      data
    } = this.props;
    // fields values
    const {
      publisher,
      offer,
      rank,
      advertiser,
      countries,
      apiOnly,
      offerGroupOnly,
      hasPlacements,
      internalStatus,
      hideInactive,
      // traffic_allowd,
    } = this.state.criteria;



    console.info({ advertisers })
    return (
      <Box>
        <Collapsible open={open}>
          <Box
            direction="row"
            pad="6px"
            gap='6px'
            justify="between"
            background=" #f5f5f5"
          // margin="xsmall"
          // border
          >
            <Box gap="6px" align="center" fill="horizontal">
              {/*  ROW  */}
              <Box direction="row" gap="small" align="start" fill="horizontal">
                {/*  COLUMN */}
                <Box
                  direction="row"
                  gap="small"
                  align="center"
                  justify="start"
                  fill="horizontal"
                >

                  <Box flex >

                    <TextInput
                      name='placementSearch'
                      type="search"
                      onChange={(e) => this.handleFiltersChange(e)}
                      placeholder="Search Placement ID, Name"

                    />
                  </Box>

                  <Box
                    direction="row"
                    // gap="small"
                    // align="start"
                    // justify="start"
                    // width="50%"
                    flex
                  >

                <Box direction ='row' gap='small'>

                      <StyleCheckbox
                        label={"Hide Inactive"}
                        name="hideInactive"
                        checked={hideInactive}
                        onChange={(e) => this.handleFiltersChange(e)}
                        size="small"
                        />


                      <StyleCheckbox
                        name="offerGroupOnly"
                        onChange={(e) => this.handleFiltersChange(e)}

                        label={"Offergroup only"}
                        checked={offerGroupOnly}

                      />
                    <StyleCheckbox
                      name="apiOnly"
                      onChange={(e) => this.handleFiltersChange(e)}
                      label={"Api only"}
                      checked={apiOnly}
                      style={{ width: 'auto' }} 

                    />
                </Box>

                  </Box>



                  {/* <SearchInput
                    name="offer"
                    value={offer}
                    placeholder="Offer"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={_.uniq(
                      data.map((p) => {
                        return p.title;
                      })
                    )}
                  /> */}

                </Box>
                {/*  COLUMN */}
              </Box>

              {/*  ROW  */}
              <Box
                fill="horizontal"
                direction="row"
                gap='6px'
                align="center"
              >
                {/*  COLUMN */}
                <Box
                  direction="row"
                  gap={'6px'}
                  align="center"
                  fill="horizontal"
                  justify="start"
                >

                  <SearchInput
                    name="advertiser"
                    size="small"
                    value={advertiser}
                    placeholder="Advertisers"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={_.uniq(
                      advertisers.map((a) => `${a.id}::${a.name}`)
                    )}
                  />


                  <SearchInput
                    name="publisher"
                    value={publisher}
                    size="small"

                    placeholder="Publishers"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={_.uniq(
                      publishers.map((a) => `${a.id}::${a.name}`)
                    )}
                  />


                  <SearchInput
                    name="countries"
                    // size="small"
                    value={countries}
                    placeholder="Off. Countries"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={COUNTRY_OPTS}
                  />

                  <Box flex={{ shrink: 0 }}>

                    <Select
                      placeholder={"Off. Rank"}
                      value={rank || []}
                      name="rank"
                      clear
                      flex
                      // defaultValue={[]}
                      options={[1, 2, 3, 4, 5, 6]}
                      multiple
                      onChange={(e) => this.handleFiltersChange(e)}

                      messages={{
                        multiple: [...rank].join(',')
                      }}
                    />
                  </Box>

                  <Select
                    placeholder={"Internal Status"}
                    value={INTERNAL_STATUS_OPTS.filter(p => p.value === internalStatus)[0] || ""}
                    labelKey="label"
                    clear
                    onChange={(event) => this.handleFiltersChange(event)}
                    valueKey={{ key: 'value' }}
                    name="internalStatus"
                    options={INTERNAL_STATUS_OPTS}
                  />

                  {/* <Select
                    value={traffic_allowd}
                    // onChange={(event) => this.handleFiltersChange(event)}
                    name="traffic_allowd"
                    options={[
                      "Content locking",
                      "Display",
                      "Incentive",
                      "Non incentive",
                      "Email",
                    ]}
                    placeholder="Has Placements"
                  /> */}

                </Box>
                {/*  COLUMN */}

              </Box>
            </Box>

            {/* SUBMIT BUTTON  */}
            <Box
              align="center"
              gap="small"
              justify="around"
            // background="brand"
            >
              <XButton
                primary
                icon={<LinkNext size="18px" />}
                height="100%"
                //   style={{ width: "100px", height: "50%" }}
                onClick={() => handleSubmit(this.state.criteria)}
              />
            </Box>
          </Box>
        </Collapsible>
      </Box>
    );
  }
}

export default PlacementsFilters;
