import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdjustActions from "../../actions/AdjustActions";
import {
  Box,
  Button,
  List,
  Text,
  Layer,
  Heading,
  CheckBox,
  TextInput,
  DataTable,
  Stack,
} from "grommet";
import { Search, Add, StatusGoodSmall, Duplicate, Edit } from "grommet-icons";
import Moment from "moment";
import Loader from "../../components/Loader";
import ReadField from "../../components/ReadField";

const ActionBar = function ({
  filterText,
  layerVisible,
  onFilterTextChange,
  toggleLayer,
  toggleHideDisabled,
  hideDisabled,
}) {
  return (
    <Box direction='row' justify='between' align='center' pad='small'>
      <Box direction='row' flex>
        <TextInput
          onChange={(e) => onFilterTextChange(e.target.value)}
          placeholder='search ...'
          value={filterText}
          icon={<Search />}
        />
        <CheckBox
          style={{ borderRadius: "5px" }}
          onClick={toggleHideDisabled}
          checked={hideDisabled}
          label='Hide disabled'
          toggle
        />
      </Box>
      <Button
        onClick={() => toggleLayer(!layerVisible)}
        label='Add Bundle'
        primary
      />
    </Box>
  );
};

const ConfigTable = function ({ data, filterText }) {
  const filteredConfig = [];

  data.forEach((event) => {
    const { app_name, app_token } = event;

    if (
      !app_name.toLowerCase().includes(filterText.toLowerCase()) &&
      !app_token.toLowerCase().includes(filterText.toLowerCase())
    ) {
      return;
    }
    filteredConfig.push(event);
  });

  return (
    <Box>
      <DataTable data={filteredConfig} columns={tableColumns(filterText)} />
    </Box>
  );
};

const InAppEventsComponent = (props) => {
  const { app_name, in_app_events_num, in_app_events_json, bundleEvents } =
    props;

  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => setShowModal((s) => !s);

  // console.info(in_app_events_json)
  return (
    <Box justify='center' style={{ maxWidth: "20px" }}>
      {!showModal || !in_app_events_num ? null : (
        <Layer onEsc={toggleModal} onClickOutside={toggleModal}>
          <Box pad='medium'>
            <Heading
              level={3}
            >{`${app_name} events (${in_app_events_json.length})`}</Heading>
            <List
              style={{
                maxHeight: 500,
                overflow: "scroll",
              }}
              data={in_app_events_json}
              primaryKey={(item, index) => {
                return (
                  <Text size='small' weight='bold'>
                    {`${index + 1}  - ${item.name}`}
                  </Text>
                );
              }}
            />
          </Box>
        </Layer>
      )}
      <Stack anchor='top-right' onClick={toggleModal}>
        <StatusGoodSmall
          style={{ paddingTop: in_app_events_num ? "0" : "4px" }}
          color={`status-${
            // if there is events
            in_app_events_num ||
            // or default events set "success color"
            bundleEvents
              ? "ok"
              : // else set "warning color"
                "warning"
          }`}
        />
        {in_app_events_num && (
          <Box background='white' pad={{ horizontal: "3px" }} round>
            <Text size='10px'>{in_app_events_num}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const AddBundleLayer = (props) => {
  const { onClose, onCreate } = props;

  const [newAppBundle, setNewAppBundle] = useState({});
  const [errors, setErrors] = useState();

  function handleChange(e) {
    console.log(newAppBundle);
    const newAppBundleState = {
      ...newAppBundle,
      [e.target.name]: e.target.value,
    };

    setNewAppBundle(newAppBundleState);
  }

  function handleCreate() {
    // Validate required fields
    if (!newAppBundle.app_token) {
      setErrors("App token required");
      return;
    }

    onCreate(newAppBundle);
    setNewAppBundle({});
    onClose();
  }

  return (
    <Layer>
      <Box pad='medium' size='large'>
        <Heading level='3' margin={{ vertical: "small" }}>
          Create App Bundle
        </Heading>
        <Box pad='medium'>
          {errors && errors}
          <ReadField name='Token'>
            <TextInput
              placeholder='Insert app token...'
              onChange={handleChange}
              name='app_token'
            />
          </ReadField>
          <ReadField name='Name'>
            <TextInput
              placeholder='Insert app name...'
              onChange={handleChange}
              name='app_name'
            />
          </ReadField>
        </Box>
        <Box align='center' flex>
          <Loader text='Adding a new App Bundle' processing={false}>
            <Box direction='row' flex pad='small' fill>
              <Box align='start' flex>
                <Button label='Cancel' onClick={onClose} />
              </Box>
              <Box align='end'>
                <Button primary label='Create' onClick={handleCreate} />
              </Box>
            </Box>
          </Loader>
        </Box>
      </Box>
    </Layer>
  );
};

function AdjustConfig(props) {
  const { config, getAdjustConfig, createAdjustConfig } = props;

  const [layerVisibile, setLayerVisible] = useState(false);
  const [hideDisabled, setHideDisabled] = useState(false);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getAdjustConfig(hideDisabled);
  }, [getAdjustConfig, hideDisabled]);

  function create(config) {
    createAdjustConfig(config);
  }

  function toggleAddBundleLayer() {
    setLayerVisible(!layerVisibile);
  }

  function toggleHideDisabled() {
    setHideDisabled(!hideDisabled);
  }

  return (
    <Box
      alignSelf='center'
      overflowY='auto'
      margin='none'
      width='100%'
      flex
      fill
    >
      <ActionBar
        toggleHideDisabled={toggleHideDisabled}
        toggleLayer={setLayerVisible}
        onFilterTextChange={setFilterText}
        layerVisibile={layerVisibile}
        hideDisabled={hideDisabled}
        filterText={filterText}
      />
      <ConfigTable filterText={filterText} data={config} />
      {
        // Here We render the Add Bundle Layer on demand
        layerVisibile && (
          <AddBundleLayer onCreate={create} onClose={toggleAddBundleLayer} />
        )
      }
    </Box>
  );
}

const mapStateToProps = (state, props) => {
  const { adjust } = state;
  const { config } = adjust;

  return {
    config,
  };
};

export default connect(mapStateToProps, { ...AdjustActions })(AdjustConfig);

const tableColumns = (filterText = "") => [
  {
    property: "id",
    header: "ID",
    primary: true,
    // footer: 'Total',
  },
  {
    property: "app_token",
    header: "App Token",
  },
  {
    property: "first_setup",
    header: <Text>InApp Events</Text>,
    render: ({
      app_token,
      in_app_events_num,
      in_app_events_json,
      app_name,
      // bundleEvents,
    }) => {
      return (
        <InAppEventsComponent
          {...{
            app_token,
            in_app_events_json,
            in_app_events_num,
            app_name,
          }}
        />
      );
    },
  },
  {
    property: "app_name",
    header: "App Name",
  },

  {
    property: "created",
    header: "Created",
    render: ({ created }) => {
      return created ? Moment(created).format("MM-DD HH:mm") : " - ";
    },
  },
  {
    property: "modified",
    header: "Modified",
    render: ({ modified }) => {
      return Moment(modified).format("MM-DD HH:mm");
    },
  },
  {
    property: "actions",
    header: "",
    render: ({ app_token, in_app_events_num, in_app_events_json }) => (
      <Box direction='row'>
        <Button size='small' icon={<Edit size='small' />} onClick={(e) => {}} />
        <Button
          size='small'
          icon={<Duplicate size='small' />}
          //   disabled
          onClick={(e) => e}
        />
        {/* <Button
            icon={<History color="status-critical" />}
            onClick={(e) =>
              onResetAppBundleHistory(e, { app_bundle, app_name })
            }
          /> */}
      </Box>
    ),
  },
];
