import Ax from "axios";

// Fetch Advertisers data
export const getInitialPublishersData = (req) => {
  return async (dispatch) => {
    dispatch({
      type: "PUBLISHERS_DATA_LOADING",
      payload: {
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/publishers`;
      const response = await Ax.get(apiUrl);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      // dispatch({
      //   type: 'NETWORKS_LOADING_FAILED'
      // })
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
    }
  };
};

// Add New PUBLISHER
export const addNewPublisher = (updateData) => {
  console.log(updateData);
  return async (dispatch) => {
    dispatch({
      type: "ADD_NEW_PUBLISHER_INPUTS_SUBMITTED",
      payload: {
        // flag on while sending request
        loading: 1,
      },
    });
    try {
      // return 1;
    } catch (e) {
      if (e.response && e.response.data) {
        // extract error's obj
        const { data: error } = e.response;
        dispatch({
          type: "ADD_PUBLISHER_ERROR",
          payload: {
            // flag off
            loading: 0,
          },
          // passing error obj forword for
          // displaying extra data
          error,
        });
      }
    }
  };
};

// Edit PUBLISHER
export const editPublisher = (updateData) => {
  return async (dispatch) => {
    dispatch({
      type: "EDIT_PUBLISHER_INPUTS_SUBMITTED",
      payload: {
        // flag on while sending request
        loadingAdvertiser: 1,
      },
    });
    try {
      // return 1;
    } catch (e) {
      if (e.response && e.response.data) {
        // extract error's obj
        const { data: error } = e.response;
        dispatch({
          type: "EDIT_PUBLISHER_ERROR",
          payload: {
            // flag off
            loadingAdvertiser: 0,
          },
          // passing error obj forword for
          // displaying extra data
          error,
        });
      }
    }
  };
};

export default {
  getInitialPublishersData,
  addNewPublisher,
  editPublisher,
};
