// import Router from 'next/router'
import Ax from 'axios';


// import {RESTORE_AUTH_STATE, AUTHENTICATE, DEAUTHENTICATE} from "./AuthActionConstants";
export const AUTHENTICATE = "AUTHENTICATE";
export const DEAUTHENTICATE = "DEAUTHENTICATE";
export const RESTORE_AUTH_STATE = "RESTORE_AUTH_STATE";
const PROTOCOL = process.env.NODE_ENV === "production" ? "https" : "http";

export const authenticateAction = (user) => {
  return {
    type: AUTHENTICATE,
    payload: user,
  };
};

export const deAuthenticateAction = () => {
  return {
    type: DEAUTHENTICATE,
  };
};

export const restoreState = (authState) => {
  return {
    type: RESTORE_AUTH_STATE,
    payload: authState,
  };
};

export const login = (loginDetails) => {
  return async (dispatch) => {
    let payload = null;
    try {
      const apiUrl = `/api/login`
      const response = await Ax.post(apiUrl, loginDetails);
      const payload = response.data;

      if (!payload.token) {
        return dispatch({
          type: 'NOTIFICATION',
          payload: {
            status: 'error',
            message: payload.msg,
            title: 'Login Failed',
          }
        })
        
      }
      
      dispatch(authenticateAction(payload));
      console.info({ response, payload })
      return true;

    } catch (e) {

      console.info(e)
        const { error, message } = e.response.data;
        dispatch({
          type: 'NOTIFICATION',
          payload: {
            status: 'error',
            message,
            title: error,
          }
        })
      

      dispatch(deAuthenticateAction());
      return false
    }
  };
};




export const signUp = (signUpDetails) => {
  return async (dispatch) => {
    try {
      dispatch(deAuthenticateAction());
      // Signup code. And storing data in result variable
      dispatch(authenticateAction());
    } catch (e) {
      dispatch(deAuthenticateAction());
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(deAuthenticateAction());

  };
};

export const restore = (savedState) => {
  return (dispatch) => {
    dispatch(restoreState(savedState));
  };
};
