import React, { useState } from "react";
// import Moment from 'moment';
// import { useRouter } from 'next/router'

import {
  Text,
  Box,
  DataTable,
  CheckBox,
  Button,
  Layer,
  Heading,
  FormField,
  TextInput,
  Select,
  Form,
} from "grommet";
import { Add, Edit } from "grommet-icons";
import _ from "lodash";
import styled from "styled-components";

// import styled from 'styled-components'
// import { DATA } from "./constants";
import { NumberInput as GrommetNumInput } from "grommet-controls";
// import ObjPath from 'object-path';
import CountryJSON from "../../constants/country-codes.json";
// import { Spinning } from 'grommet-controls'
import Loader from "../Loader";
import ReadField from "../ReadField";

const COUNTRY_CODES = CountryJSON.map((o) => o["alpha-2"]);

const NumberInput = (props) => (
  <Box style={{ maxWidth: "200px" }}>
    <GrommetNumInput {...props} />
  </Box>
);

const fields = [
  // 'id',
  "publisher",
  "event_name",
  "event_key",
  "event_type",
  "event_value",
  "event_geos",
  // 'gen_event_slot'
];

const StyledDataTable = styled(DataTable)`
  tbody {
    max-height: 100%;
  }
`;

export const columns = [
  {
    property: "id",
    header: <Text>ID</Text>,
    primary: true,
  },
  {
    property: "app_bundle_name",
    header: <Text>App Bundle</Text>,
    render: ({ app_bundle_name }) => {
      // console.info({app_bundle_name})
      const [app_name, app_bundle] = app_bundle_name
        .replace(/\s/g, "")
        .split("::");
      return (
        <Box align="start">
          <Text size="small" style={{ fontWeight: "bold", whiteSpace: "pre" }}>
            {app_name}
          </Text>
          {app_bundle ? <Text size="small">{app_bundle}</Text> : null}
        </Box>
      );
    },
    size: "150px",
    // primary: true,
  },
  ...fields.map((property) => {
    return {
      property,
      header: <Text>{property.split("_").join(" ")}</Text>,
      render: (props) => {
        const value = props[property];
        return (
          <Box width="400px" pad={{ horizontal: "small" }}>
            <Text size="small">{value}</Text>
          </Box>
        );
      },
    };
  }),
];

const controlledColumns = columns; //.map(col => ({ ...col }));

const AddEditAppEventLayer = (props) => {
  const {
    appEvent,
    processing,
    setState,
    updateAppEvent,
    configMap,
    // inAppEventsOpts,
    appBundleOptions,
    updateAppBundleId,
    appEventGeosOpts,
    filteredAppEventsGeoOpts,
    createAppBundleEvent,
    updateAppBundleEvent,
    publishers,
  } = props;
  console.info({ appEvent, appBundleOptions });
  const {
    id,
    app_bundle,
    app_name,
    event_name,
    event_key,
    publisher,
    event_value,
    event_currency,
    event_type,
    event_geos,
  } = appEvent;

  const [filteredAppBundleOptions, setFilteredAppBundleOptions] = useState(
    appBundleOptions
  );

  const isEdit = Boolean(id);
  // console.info({ isEdit, appEvent })
  const title = `${isEdit ? "Edit" : "Add"} App Bundle Event`;
  const spinningMessage = `${
    isEdit ? "Updating" : "Creating"
  } App Bundle Event`;

  let [appBundleOption] = appBundleOptions.filter(
    (o) => o.value === app_bundle
  );
  console.info({ appEvent, appBundleOption, app_bundle });
  // console.info({ app_bundle, appBundleOption, appBundleOptions })

  const disabled =
    (!isEdit && !app_bundle) || (!isEdit && app_bundle && !event_type);

  const {
    inAppEventsOpts,
    // app_name
  } = getInAppEventsOpts(app_bundle, configMap);
  // console.info({ inAppEventsOpts })

  const geoOptions = [
    ...(filteredAppEventsGeoOpts || appEventGeosOpts).filter(
      (g) => event_geos.indexOf(g) === -1
    ),
    ...event_geos,
  ];
  // console.info({geoOptions, event_geos})
  return (
    <Layer>
      <Box pad="medium">
        <Heading
          level="3"
          margin={{ vertical: "small" }}
          style={{ fontWeight: "bold" }}
        >
          {title}
        </Heading>
        {/* {Object.keys(appEvent).join(', ')} */}

        <Box pad="medium">
          <ReadField name="Event ID" value={id} />
          <ReadField
            name="App Bundle ID"
            value={isEdit && app_bundle}
            desc={app_name}
          >
            <Select
              options={filteredAppBundleOptions}
              onSearch={(text) => {
                // console.info(text)
                if (!text) {
                  setFilteredAppBundleOptions(appBundleOptions);
                }
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                const exp = new RegExp(escapedText, "i");
                // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

                setFilteredAppBundleOptions(
                  appBundleOptions.filter((o) => exp.test(o.label))
                );
              }}
              searchPlaceholder={`Search Bundle`}
              emptySearchMessage={`No Bundle found`}
              labelKey="label"
              valueKey="value"
              value={appBundleOption}
              onChange={({ option, value }) => {
                // console.info({value, app_bundle, option})
                const { value: app_bundle } = option;
                updateAppBundleId(app_bundle);
              }}
            />
          </ReadField>
          <ReadField name="Event Type">
            <Select
              options={["revenue", "cost", "general", "ftd", "registration"]}
              value={event_type}
              onChange={({ option: event_type }) => {
                updateAppEvent({
                  event_type,
                });
              }}
            />
          </ReadField>
          <ReadField
            name="Event Name"
            // value={event_name}
          >
            <TextInput
              // options={['revenue', 'cost', 'general']}
              value={event_name}
              onChange={({ target }) => {
                updateAppEvent({
                  event_name: target.value,
                });
              }}
            />
          </ReadField>
          <ReadField
            name="Event Key"
            // value={event_key}
          >
            <Select
              disabled={disabled}
              options={inAppEventsOpts}
              value={event_key}
              onChange={({ option: event_key }) => {
                updateAppEvent({
                  event_key,
                });
              }}
            />
          </ReadField>
          <ReadField
            name="Publisher"
            // value={event_key}
          >
            <Select
              disabled={disabled}
              options={["", ...publishers]}
              value={publisher}
              onChange={({ option: publisher }) => {
                updateAppEvent({
                  publisher,
                });
              }}
            />
          </ReadField>
          <ReadField name="Event Currency" value={event_currency} />
          <ReadField name="Event Value">
            <NumberInput
              step={0.1}
              disabled={
                disabled || ["registration", "ftd"].indexOf(event_type) > -1
              }
              decimals={3}
              value={event_value || 0}
              onChange={({ target }) => {
                updateAppEvent({
                  event_value: target.value,
                });
              }}
            />
          </ReadField>
          <ReadField name="Event Geos">
            {/* {console.info(event_geos.split(','))} */}
            <Box gap="small" align="center" direction="row">
              <Select
                disabled={
                  disabled || ["registration", "ftd"].indexOf(event_type) > -1
                }
                size="small"
                placeholder="All GEOs"
                value={event_geos}
                closeOnChange={false}
                options={geoOptions}
                multiple
                onChange={({ value }) => {
                  console.info({ value });
                  updateAppEvent({
                    event_geos: value,
                  });
                }}
                onClose={() =>
                  setState({
                    appEventGeosOpts,
                    filteredAppEventsGeoOpts: null,
                  })
                }
                onSearch={(text) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  if (text.length > 0) {
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      "\\$&"
                    );

                    const exp = new RegExp(escapedText, "i");

                    const filteredAppEventsGeoOpts = appEventGeosOpts.filter(
                      (o) => exp.test(o)
                    );
                    // console.info({escapedText, filteredAppEventsGeoOpts })
                    setState({
                      filteredAppEventsGeoOpts,
                    });
                  } else {
                    setState({
                      filteredAppEventsGeoOpts: null,
                    });
                  }
                }}
              />
              <Text size="small">
                {event_geos.length ? event_geos.join(" | ") : ""}
              </Text>
            </Box>
          </ReadField>
        </Box>
        <Box align="center" flex>
          <Loader text={spinningMessage} processing={processing}>
            <Box direction="row" flex pad="small" fill>
              <Box align="start" flex>
                <Button
                  label="Cancel"
                  onClick={(e) => {
                    // e.preventDefault()
                    // setEventEdit(null)
                    // router.push('/appsflyer/events', undefined, { shallow: true })
                    // console.info({setState})
                    setState({
                      appEvent: {},
                      showAppEventModal: false,
                      // processing: false,
                    });
                  }}
                />
              </Box>
              <Box align="end">
                <Button
                  primary
                  label="Save"
                  disabled={false}
                  // disabled={!(app_name && app_bundle)}
                  onClick={async () => {
                    // console.info(this.props)
                    // console.info(appEvent)

                    setState({ processing: true });

                    let { app_name, app_bundle_name, ...aeData } = appEvent;
                    Object.assign(aeData, {
                      event_geos: aeData.event_geos.join(","),
                    });
                    await createAppBundleEvent(aeData, isEdit);
                    // setState({p})
                    setState({
                      appEvent: {},
                      showAppEventModal: false,
                      processing: false,
                    });
                    // setProcessing(true)
                    // await createAppBundle({
                    //     app_bundle,
                    //     app_name
                    // })
                    // setProcessing(false)
                    // setAddAppBundleVisible(false)
                  }}
                />
              </Box>
            </Box>
          </Loader>
        </Box>
      </Box>
    </Layer>
  );
};

function getTableColumns() {
  return [
    ...controlledColumns,
    {
      property: "actions",
      header: <Text>Actions</Text>,
      primary: true,
      render: (event) => {
        return (
          <Box>
            <Button
              icon={<Edit />}
              onClick={(e) => {
                // console.info({event})
                this.onEventEdit(event);
              }}
            />
          </Box>
        );
      },
    },
  ].map((col) => ({ ...col }));
}

const getInAppEventsOpts = (app_bundle, configMap) => {
  // console.info({app_bundle})
  const appBundleId = String(app_bundle).toUpperCase();

  const { in_app_events_json, app_name } = configMap[appBundleId] || {};
  // console.info({appBundleId, in_app_events_json})
  const default_app_event_hooks = ["INSTALLS"];

  try {
    return {
      app_name,
      inAppEventsOpts: [
        ...default_app_event_hooks,
        ...(in_app_events_json || "[]").map((o) => o.key.toUpperCase()),
      ],
    };
  } catch (getInAppEventsError) {
    console.info({ getInAppEventsError });
    return {
      app_name,
      inAppEventsOpts: [default_app_event_hooks],
    };
  }
};

const appendExtraEventsData = (events, configMap) => {
  return events.map((appEvent) => {
    const { app_bundle } = appEvent;
    const appBundleId = String(app_bundle).toUpperCase();

    let { app_name } = configMap[appBundleId] || {};

    return Object.assign({}, appEvent, {
      app_name,
      app_bundle_name: `${app_name}::${app_bundle}`,
    });
  });
};

const getAppEventGeoOpts = (appEvent, events) => {
  if (!Object.keys(appEvent).length) {
    return {
      appEventGeos: [],
      appEventGeosOpts: COUNTRY_CODES,
    };
  }

  const currentEventTypeGeos = _.flatten(
    events
      .filter((o) => {
        return (
          o.app_bundle === appEvent.app_bundle &&
          o.event_type === appEvent.event_type &&
          o.id !== appEvent.id
        );
      })
      .map((o) => o.event_geos)
      .filter((o) => o)
  );

  const { event_geos } = appEvent;
  const appEventGeos =
    event_geos && !Array.isArray(event_geos)
      ? appEvent.event_geos.split(",")
      : event_geos || [];
  const appEventGeosOpts = _.difference(COUNTRY_CODES, currentEventTypeGeos);
  // console.info({ appEventGeosOpts })
  return {
    appEventGeos,
    appEventGeosOpts,
  };
};

class AppEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      appEvent: {}, // New or Edit
      showAppEventModal: false,
      filteredAppEventsGeoOpts: null,
    };
    this.setState = this.setState.bind(this);
    this.getTableColumns = getTableColumns.bind(this);
  }

  getAppEventParams(rawAppEvent) {
    const { data, configMap } = this.props;
    const {
      inAppEventsOpts,
      // app_name
    } = getInAppEventsOpts(rawAppEvent.app_bundle, configMap);

    const { appEventGeos, appEventGeosOpts } = getAppEventGeoOpts(
      rawAppEvent,
      data
    );
    const appEvent = Object.assign(
      {
        event_value: 0,
      },
      rawAppEvent,
      {
        // app_name,
        event_geos: appEventGeos,
      }
    );
    return {
      appEvent,
      appEventGeos,
      appEventGeosOpts,
      inAppEventsOpts,
    };
  }
  onEventEdit(rawAppEvent) {
    if (!rawAppEvent.app_bundle && rawAppEvent.app_bundle_name) {
      rawAppEvent.app_bundle = rawAppEvent.app_bundle_name.split("::")[1];
    }
    // const { data, configMap } = this.props;
    // const inAppEventsOpts = getInAppEventsOpts(rawAppEvent, configMap);
    // const {
    //     appEventGeos,
    //     appEventGeosOpts,
    //     inAppEventsOpts
    // } =

    this.setState({
      // appEvent,
      // appEventGeosOpts,
      // inAppEventsOpts,
      ...this.getAppEventParams(rawAppEvent),
      showAppEventModal: true,
    });
  }

  getAddEditAppEventLayer() {
    // console.info(this.props)

    const {
      configMap,
      createAppBundleEvent,
      updateAppBundleEvent,
      publishers,
    } = this.props;
    const {
      appEvent,
      processing,
      appEventGeosOpts,
      // inAppEventsOpts,
      showAppEventModal,
      filteredAppEventsGeoOpts,
    } = this.state;

    if (!showAppEventModal) {
      return null;
    }

    const updateAppEvent = (obj) =>
      this.setState({
        appEvent: Object.assign({}, appEvent, obj),
      });

    const updateAppBundleId = (app_bundle) =>
      this.setState({
        ...this.getAppEventParams(Object.assign({}, appEvent, { app_bundle })),
      });

    const appBundleOptions = Object.keys(configMap).map((key) => {
      const { app_name, app_bundle } = configMap[key];

      return {
        // label: (
        //     <Box align='center' gap='small' direction='row'>
        //         <Text>{app_bundle}</Text>
        //         {app_name ? <Text size='small'>{app_name}</Text> : null}
        //     </Box>
        // ),
        label: `${app_bundle} - ${app_name}`,
        value: app_bundle,
      };
    });

    return (
      <AddEditAppEventLayer
        {...{
          configMap,
          processing,
          appEvent,
          publishers,
          appBundleOptions,
          updateAppEvent,
          updateAppBundleId,
          appEventGeosOpts,
          filteredAppEventsGeoOpts,
          // inAppEventsOpts,

          setState: this.setState,
          createAppBundleEvent,
          updateAppBundleEvent,
        }}
      />
    );
  }

  render() {
    const { data, configMap } = this.props;
    if (!data) {
      return null;
    }

    const events = appendExtraEventsData(data, configMap);
    // console.info({ events })
    return (
      <Box align="center" pad="medium" fill flex>
        {this.getAddEditAppEventLayer()}
        <Box align="start" pad="small" fill="horizontal">
          {/* {console.log(this.props)} */}
          <Button
            primary
            label="Add App Bundle Event"
            icon={<Add />}
            onClick={() => this.onEventEdit({})}
          />
        </Box>
        <Box flex style={{ overflow: "scroll" }}>
          <StyledDataTable
            columns={this.getTableColumns()}
            // onClickRow={event => alert(JSON.stringify(event.datum, null, 2))}
            groupBy="app_bundle_name"
            data={events}
            sortable
            size="medium"
          />
        </Box>
      </Box>
    );
  }
}

export default AppEvents; // ControlledDataTable
