import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import NetworksActions from "../actions/NetworksActions";
import AppBarActions from "../actions/AppBarActions";

// import * as Immutable from "object-path-immutable";
import ReadField from "../components/ReadField";
import FieldsGrouper from "../components/xComponents/FieldsGrouper";
import PopupDialog from "../components/xComponents/PopupDialog";

import {
  Box,
  Text,
  Layer,
  Button,
  Select,
  Heading,
  CheckBox,
  TextInput,
  Form,
  Tip,
  Collapsible,
} from "grommet";

import {
  Close,
  Checkmark,
  AddCircle,
  Filter as FilterIcon,
} from "grommet-icons";

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import Cell from "../components/Cell";

// Pure Component
// const NetworkSummary = (props) => {
//   const { total, className, displayStatus } = props;
//   return (
//     <Box {...{ className }}>
//       <Text>Total Networks: {props.total} </Text>
//     </Box>
//   );
// };

// const StyledNetworkSummary = styled(NetworkSummary)`
//   padding: 10px;
//   background: #f2f2f2;
//   flex: 1;
// `;

class Networks extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      addNetworkVisible: false,
      autoLoad: true,
      checkBoxLabel: "Inactive",
      tableColumns: this.getTableColumns(),
      networkFields: {
        id: 0,
        status: 0,
        name: undefined,
        label: undefined,
        type: "Custom",
      },
      // data table filters visiblity flag
      filtersBoxVisible: false,
      // data table filters fields
      filters: {
        advertiser: "",
        advertiserOfferId: "",
        rank: "",
        platform: "",
        hasPlacements: "All",
        internalStatus: "All",
        hideInactive: true,
      },
      // popup dialog
      popupDialogContent: undefined,
      popupDialogVisible: false,
    };
    this.handleFieldsChange = this.handleFieldsChange.bind(this);
    this.addNewNetworkPartner = this.addNewNetworkPartner.bind(this);
  }

  /*  CDM  
  Initialize Component data and inject content into the top bar  
  */
  componentDidMount() {
    const { loading, networks, getInitialData } = this.props;

    if (!loading && !networks.length) {
      //  Load data from API
      getInitialData();
    }
    // inject content (Header + message + actions ) into AppBar
    this.injectContentIntoAppBar();
  }

  /*  CDU  
  update injected content at the top bar if necessary
  */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { networks: prevNetworks } = prevProps;
    const { networks } = this.props;

    if (prevNetworks.length !== networks.length) {
      this.injectContentIntoAppBar();
    }
  }

  // inject content (Header + message + actions ) into AppBar
  injectContentIntoAppBar() {
    const { networks } = this.props;
    console.log("object");
    // page title
    const title = "integrations/networks";
    // extra msg
    const message = networks.length > 0 ? `${networks.length} results ` : ``;

    // Actions
    const buttons = [
      <Tip
        plain
        content={
          <Box round={"5px"} background={"#E6E6E6"} pad="xsmall">
            <Text size="xsmall">Filters</Text>
          </Box>
        }
      >
        <Button
          hoverIndicator
          icon={<FilterIcon size="18px" />}
          onClick={() => {
            this.setState({
              filtersBoxVisible: !this.state.filtersBoxVisible,
            });
          }}
        />
      </Tip>,
      <Tip
        plain
        content={
          <Box round={"5px"} background={"#E6E6E6"} pad="xsmall">
            <Text size="xsmall">Add network</Text>
          </Box>
        }
      >
        <Button
          hoverIndicator
          icon={<AddCircle size="18px" />}
          onMouseDown={() => {
            this.setState({
              popupDialogContent: "ADD",
              popupDialogVisible: !this.state.popupDialogVisible,
            });
          }}
        />
      </Tip>,
    ];

    // inject content into AppBar
    this.props.updateAppBarContent({
      title,
      message,
      buttons,
    });
  }

  getTableColumns() {
    const defaultRenderer = (propName, type = "", color = "") => (props) => {
      let value = props[propName.toLowerCase()];
      // 05/08/2020 13:38
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell start={"true"} flex color={color} type={type}>
          {value}
        </Cell>
      );
    };

    const columns = [
      {
        property: "id",
        header: "ID",
        render: defaultRenderer("id"),
      },
      {
        property: "status",
        header: "Status",
        render: ({ status }) => (
          <Box align="center">
            <CheckBox checked={Boolean(status)} toggle />
          </Box>
        ),
      },
      {
        property: "advertiser",
        header: "Advertiser",
        render: defaultRenderer("advertiser"),
        search: true,
      },
      {
        property: "label",
        header: "Label",
        search: true,
        render: defaultRenderer("label"),
      },
      {
        property: "name",
        header: "Name",
        render: defaultRenderer("name"),
      },
      {
        property: "num_offers",
        header: "Offers #",
        render: defaultRenderer("num_offers"),
      },
      {
        property: "last_fetch",
        header: "Last Fetch",
        render: defaultRenderer("last_fetch", "date"),
      },
    ];

    return columns;
  }

  handleChange(event) {
    const { name, value, type, checked } = event.target;

    type === "checkbox"
      ? this.setState({ [name]: checked })
      : this.setState({ [name]: value });
  }

  // ADD new network partner
  addNewNetworkPartner(network) {
    this.props.addNewNetwork(network);
  }
  // CANCEL new network partner
  cancelNewNetworkPartner() {
    this.toggleNetworkLayer();
    // Restore fields default value
    this.setState({
      networkType: "Custom",
    });
  }

  //
  getFields() {
    const { name, label, status, type } = this.state.networkFields;

    const fieldsGroup = [
      {
        fields: [
          {
            name: "name",
            value: name,
          },
          {
            name: "label",
            value: label,
          },
          {
            name: "type",
            value: type,
            type: "select",
            options: ["Custom", "Affise", "Everflow"],
          },
          {
            name: "status",
            value: status,
            type: "checkbox",
          },
        ],
      },
    ];

    return fieldsGroup;
  }

  /*
   * HANDLERS
   */

  // handle fields change on add network layer
  handleFieldsChange({ target, option }) {
    const { value, name, type, checked } = target;

    const { networkFields } = this.state;
    let newValue = (option && option) || value;

    // Checkbox
    if (type === "checkbox") {
      newValue = checked;
    }

    // console.log(name);
    // console.log(type);
    // console.log(checked);
    // console.log(value);
    // console.log(option);

    // update new values
    let newValues = {
      ...networkFields,
      ...{ [name]: newValue },
    };

    // in case events values changed
    if (name.search("event") !== -1) {
      // copy events
      let newEventsValues = [...networkFields.events];
      // get field index
      let index = name.split(/#/)[1];

      // on the first type to the field - add extra field for the next event
      if (newValue.length === 1 && parseInt(index) === newEventsValues.length) {
        // new field content
        newEventsValues.push("");
        // else if the field content was deleted - remove that extra field
      } else if (
        newValue.length === 0 &&
        parseInt(index) === newEventsValues.length - 1
      ) {
        // remove last index
        newEventsValues.splice(newEventsValues.length - 1, 1);
      }

      // make copy for the event(field) that has changed
      let event = { ...newEventsValues[index - 1] };
      // insert new value
      event = newValue;
      // update the event value in the events array
      newEventsValues[index - 1] = event;

      // set new values
      newValues = {
        ...networkFields,
        // update events values
        events: newEventsValues,
      };
    }

    this.setState({ networkFields: newValues });
  }

  /* set add network fields to the default values */
  restoreNetworkFields() {
    this.setState({
      networkFields: {
        id: 0,
        status: 0,
        name: "",
        label: "",
        type: "Custom",
      },
    });
  }

  /*
   * POPUP DIALOG METHODS
   */

  //
  onClosePopupDialog() {
    this.restoreNetworkFields();
    this.setState({ popupDialogVisible: !this.state.popupDialogVisible });
  }

  render() {
    const { tableColumns, popupDialogVisible } = this.state;

    // Redux State
    const { loading, filteredData } = this.props;

    return (
      <Box
        flex
        fill
        width="100%"
        margin="none"
        overflowY="auto"
        alignSelf="center"
      >
        {/* FILTERS  */}
        {this.filters()}

        <Loader loading={loading} text="Loading Networks data...">
          <Box style={{ flex: 13, overflowX: "auto" }}>
            <DataTable
              key={filteredData.length}
              columns={tableColumns}
              data={filteredData}
              sortable
              pin
              sort={{ property: "id", direction: "desc" }}
              step={50}
              primaryKey={"id"}
              fill
            />
          </Box>
        </Loader>

        {
          //
          // POPUP DIALOG
          popupDialogVisible && (
            <PopupDialog
              header="Add network"
              onEsc={() => this.onClosePopupDialog()}
              onSubmit={() =>
                this.addNewNetworkPartner(this.state.networkFields)
              }
            >
              <FieldsGrouper
                fieldsGroup={this.getFields()}
                onChange={this.handleFieldsChange}
              />
            </PopupDialog>
          )
        }
      </Box>
    );
  }

  filters() {
    const { updateStatusOption, statusOption, statusOptions } = this.props;

    return (
      <Box>
        <Collapsible open={this.state.filtersBoxVisible}>
          <Box
            direction="row"
            pad="small"
            gap="medium"
            justify="between"
            background=" #f5f5f5"
            // margin="xsmall"
            // border
          >
            <Box gap="small" align="center" fill="horizontal">
              {/*  ROW  */}
              <Box direction="row" gap="small" align="center" fill="horizontal">
                <CheckBox
                  // plain
                  label="Auto refresh"
                  reverse
                  toggle
                  name="autoLoad"
                  checked={this.state.autoLoad}
                  onChange={this.handleChange}
                />
                <Select
                  options={statusOptions}
                  value={statusOption}
                  labelKey="fullLabel"
                  valueKey="value"
                  onChange={updateStatusOption}
                  style={{
                    fontWeight: "100",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Collapsible>
      </Box>
    );
  }
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  return state.networks;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, NetworksActions, AppBarActions),
    dispatch
  );
  //   return bindActionCreators(OffersActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Networks);
