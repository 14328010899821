// Affilitest frequency defualt input select value
const getAffilitestFreqOptions = () => {
  let options = ["Never", "Every 1 Hour"];

  for (let i = 2; i < 15; i++) {
    options.push(`Every ${i} Hours`);
  }

  return options;
};

// Affilitest frequency defualt input select value
const getTimezoneValues = () => {
  let options = [];

  for (let i = -12; i < 13; i++) {
    let val = i === 0 ? "" : i === 2 ? "+" + i + " (IL)" : i > 0 ? "+" + i : i;
    options.push(`GMT ${val}`);
  }

  return options;
};

let eventsFields = [];
for (let i = 0; i < 10; i++) {
  eventsFields[i] = { name: `event#${i + 1}`, value: `event#${i + 1}` };
}
//
export function fields(criteria = {}) {
  const {
    name,
    username,
    password,
    timezone,
    contact_name,
    contact_phone,
    contact_email,
    contact_im,
    default_click_cap,
    default_conversion_cap,
    affilitest_frequency,
    affilitest_group,
  } = criteria;

  return [
    {
      groupName: "Advertiser data",
      fields: [
        {
          name: "name",
          value: name || undefined,
        },
        {
          name: "username",
          value: username || undefined,
        },
        {
          name: "password",
          value: password || undefined,
          type: "password",
        },
        {
          name: "timezone",
          value: timezone || undefined,
          //   type: "select",
          options: getTimezoneValues(),
        },
        {
          name: "contact_name",
          value: contact_name,
        },
        {
          name: "contact_phone",
          value: contact_phone,
        },
        {
          name: "contact_email",
          value: contact_email,
        },
        {
          name: "contact_im",
          value: contact_im,
        },
        {
          name: "default_click_cap",
          value: default_click_cap,
        },
        {
          name: "default_conversion_cap",
          value: default_conversion_cap,
        },
        // {
        //   name: "affilitest_frequency",
        //   value: affilitest_frequency,
        //   type: "select",
        //   options: getAffilitestFreqOptions(),
        // },
        {
          name: "affilitest_group",
          value: affilitest_group,
          //   type: "select",
          options: ["None", "New Offers", "Live Offers", "Both New & Live"],
        },
      ],
    },
    // group #2
    {
      groupName: "Events",
      fields: eventsFields,
    },
  ];
}

//
export function generatePixelFields(criteria = {}) {
  return [
    {
      //   groupName: "Advertiser data",
      fields: [
        {
          name: "cid",
          value: criteria.cid,
        },
        {
          name: "httpRequest",
          value: criteria.httpRequest,
        },
        {
          name: "iframe",
          value: criteria.iframe,
        },
        {
          name: "image",
          value: criteria.image,
        },
        {
          name: "javascript",
          value: criteria.javascript,
        },
        {
          name: "events",
          value: criteria.events,
        },
      ],
    },
  ];
}
