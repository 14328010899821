import _ from "lodash";

const publishers = (state = { loading: 0, publishers: [] }, action) => {
  switch (action.type) {
    case "CONVERSIONS_RESPONSE_DATA_LOADED":     
    case "PLACEMENTS_DATA_LOADED": {
        const { publishers } = action.payload;
        if (publishers) {
          return {
            ...state,
            publishers
          }
        }
        return state
      }
    case "PUBLISHERS_DATA_LOADING":
    case "PUBLISHERS_DATA_LOADED": {
      // const reports = (raw || []).map(o => _.pick(o, _.keys(reportModel)))
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default publishers;
