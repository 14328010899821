import Ax from "axios";
import Moment from "moment";
import { throttle } from 'lodash';
import { push } from 'connected-react-router'
const innerFunction = (async (dispatch, placementSearch) => {

    const placements = placementSearch.split(",").filter(p => p)
    const url = `/api/convert-clicks/search-placements`;
    const payload = {
        placements
    };
    const { data: placementResults } = await Ax.post(url, payload);
    dispatch({
        type: "CONVERT_CLICK_PLACEMENTS",
        payload: {
            placements,
            placementResults
        }
    });
    return placementResults

})

const getPlacements = (placementSearch) => dispatch => innerFunction(dispatch, placementSearch);


const includeAllPlacements = () => dispatch => dispatch({
    type: "CC_ADD_ALL_PLACEMENTS"
})

const updatePlacementClicksNum = ({ placement, clicks }) => dispatch =>
    dispatch({
        type: "CC_UPDATE_CLICKS_NUM",
        payload: {
            placement,
            clicks
        }
    })

const sendSearchQuery = () => async (dispatch, getState) => {
    const { convertClicks } = getState()
    const { queryPlacements } = convertClicks;
    const settings = queryPlacements.map(({id, clicks}) => ({id, clicks}));
    const url = `/api/convert-clicks/submit-query`;
    const payload = {
        settings
    };
    const { data } = await Ax.post(url, payload);

    dispatch({
        type: "CC_NEW_JOB"
    })
    await dispatch(syncConvertData());
    await dispatch(push('/convert-service/jobs'))


}


const syncConvertData = () => async(dispatch) => {
    const url = `/api/convert-clicks/get-all-data`;

    const { data } = await Ax.get(url);

    dispatch({
        type: "CC_DATA_FETCH",
        payload: {
            ...data
        }
    })
}



const sendConvertClicks = (clicks) => async(dispatch) => {
    dispatch({
        type: "CC_CLICKS_CONVERT_START",

    })
    const url = `/api/convert-clicks/exec-conversion`;
    const payload = {
        clicks
    };
    const { data } = await Ax.post(url, payload);


    await dispatch(syncConvertData());
    dispatch({
        type: "CC_CLICKS_CONVERT_DONE",

    })
    return true;
}
const exports = {
    getPlacements,
    syncConvertData,
    sendConvertClicks,
    includeAllPlacements,
    updatePlacementClicksNum,
    sendSearchQuery
};

export default exports