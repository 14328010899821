import Moment from "moment";

const initialState = {
  loading: 0,
  users: [],
  publishers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "DX_GET_USERS": {
      const users = action.payload.users.map((user) => {
        return {
          ...user,
          created: Moment(user.created).format("YYYY-MM-DD HH:mm:ss"),
          last_login: Moment(user.last_login).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      return { ...state, ...action.payload, users: users };
    }
    case "DX_GET_USERS_LOADIN":
      return { ...state, ...action.payload };
    case "NEW_USER_CREATED":
      return {
        ...state,
        users: [...state.users, ...action.payload.user],
      };

    case "USER_UPDATED":
      return {
        ...state,
        users: state.users.map((u) => {
          if (u.id === action.payload.user[0].id) {
            return action.payload.user[0];
          }
          return u;
        }),
      };

    default:
      return state;
  }
}
