import Ax from 'axios';
import React, { useEffect, } from "react";
import { useDispatch } from "react-redux";


export const postFetcher = (url, payload = {}) => Ax.post(url, payload).then(({ data }) => {
    // console.info("postFetcher", payload, data)
    return data

})

export const postDispatcher = (url, payload = {}, dispatch = null) => Ax.post(url, payload).then(({ data }) => {
    dispatch(data)
    return data

})

export const useGetDispatch = async (url) => {
    let data = null;
    const dispatch = useDispatch()
    useEffect(() => {

        async function apiCall() {
            const data = await Ax.get(url).then(({ data }) => data)
            dispatch(data)
        }
        apiCall()
    }, [url, dispatch])
    return data
}

export const usePostDispatch = async (url, payload = {}, exec = false) => {
    let data = null;
    const dispatch = useDispatch()
    useEffect(() => {

        async function apiCall() {
            const data = await Ax.post(url, payload).then(({ data }) => data)
            dispatch(data)
        }
        exec && apiCall()
    }, [url, payload, dispatch, exec])
    return exec
}

const exports = {
    postFetcher,
    postDispatcher,
    useGetDispatch,
    usePostDispatch

}

export default exports