import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import PlacementsActions from "../../actions/PlacementsActions";
import AdvertiserActions from "../../actions/AdvertisersActions";
import PublishersActions from "../../actions/PublishersActions";
import AppBarActions from "../../actions/AppBarActions";
import PlacementsFilters from "./PlacementsFilters";
import DropButton from "../../components/DropButton";
import _ from "lodash";
// grommet
import { Box, Button, Text, Tip, Select } from "grommet";
import { Copy } from "grommet-icons";
import Loader from "../../components/Loader";
import { DataTableV2 as DataTable } from "../../components/DataTable";
import Cell from "../../components/Cell";
import Pagination from "../../components/PaginationComponent";
import { debounce } from "debounce";
import ActionButtons from "../../components/ActionButtons";

function getRowProps(data) {
  const internals = data
    .filter((o) => o.internal !== "active")
    .map((o) => o.id);
  const closed = data
    .filter((o) => o.status === "Closed" || o.status === "Not Active")
    .map((o) => o.id);

  let rowProps = {};

  internals.forEach(
    (oid) =>
      (rowProps[oid] = Object.assign({}, rowProps, {
        background: "rgb(236, 208, 75, 0.9)",
      }))
  );
  closed.forEach(
    (oid) =>
      (rowProps[oid] = Object.assign({}, rowProps, { background: "#f2dedd" }))
  );

  return rowProps;
}

function getTableColumns() {
  const defaultRenderer = (
    propName,
    type = "",
    color = "",
    background = ""
  ) => (props) => {
    let value = props[propName.toLowerCase()];
    // 05/08/2020 13:38
    if (type === "numeral") {
      value = Numeral(value).format("0,0");
    } else if (type === "currency") {
      value = Numeral(value).format("0,0$");
    } else if (type === "date") {
      value = Moment(value).format("DD/MM/YY HH:mm");
    }

    return (
      <Cell
        start={"true"}
        flex
        color={color}
        type={type}
        background={background}
      >
        <Tip
          plain
          content={
            <Box border round="small" background={"#E6E6E6"} pad="xsmall">
              <Text size="xsmall">
                {propName} - {value}
              </Text>
            </Box>
          }
        >
          <Box>{value}</Box>
        </Tip>
      </Cell>
    );
  };

  const columns = [
    {
      property: "id",
      header: "ID",
      render: (props) => (
        <Box
          padding={"none"}
          align="center"
          justify="end"
          gap="8px"
          direction="row"
        >
          {props.api_enabled ? (
            <Box round="5px" pad="xsmall" align="center" background="brand">
              <Text size="8px">API</Text>
            </Box>
          ) : null}
          <Text size="xsmall">{props.id}</Text>
        </Box>
      ),
    },
    {
      property: "name",
      header: "Name",
      render: defaultRenderer("name"),
    },
    {
      property: "title",
      header: "Title",
      render: defaultRenderer("title"),
    },
    {
      property: "publisher_name",
      header: "Pub",
      render: defaultRenderer("publisher_name"),
    },
    {
      property: "offer_name",
      header: "Offer",
      render: defaultRenderer("offer_name"),
    },
    {
      property: "advertiser_name",
      header: "Advertiser",
      render: defaultRenderer("advertiser_name"),
    },
    {
      property: "offer_payout",
      header: "O. Payout",
      render: defaultRenderer("offer_payout"),
    },
    {
      property: "payout",
      header: "Payout",
      render: defaultRenderer("payout"),
    },
    // {
    //   property: "traffic",
    //   header: "Traffic allowd",
    //   render: defaultRenderer("traffic"),
    // },
    {
      property: "url",
      header: "Url",
      render: (props) => (
        <Box align="center" justify="between" direction="row">
          <Text
            size="xsmall"
            margin={{ top: "medium" }}
            style={{ whiteSpace: "pre" }}
          >
            {props.url}
          </Text>
          <Tip content={<Text size="xsmall">copy url</Text>}>
            <Button
              icon={<Copy size="15px" color="#6b6b6b" />}
              hoverIndicator
            />
          </Tip>
        </Box>
      ),
    },
    // {
    //   property: "validation",
    //   header: "Validation",
    //   render: defaultRenderer("validation"),
    // },
    {
      property: "status",
      header: "Status",
      value: (props) => props.status,
      render: defaultRenderer("status"),
    },
    {
      property: "internal",
      header: "Internal",
      render: defaultRenderer("internal"),
    },
    {
      property: "risk_management",
      header: "Risk mgmt",
      render: defaultRenderer("risk_management"),
    },
    {
      property: "conversion_cap",
      header: "Conv. cap",
      render: defaultRenderer("conversion_cap"),
    },
    {
      property: "click_cap",
      header: "Click cap",
      render: defaultRenderer("click_cap"),
    },
    {
      property: "",
      header: "",
      render: (props) => (
        <ActionButtons
          direction="row"
          buttons={[
            {
              name: "Edit placement",
              icon: "Edit",
              triger: () => {
                this.setState({
                  placementFields: {
                    ...this.state.placementFields,
                    ...props,
                  },
                  popupDialogContent: "EDIT",
                  popupDialogVisible: !this.state.popupDialogVisible,
                });
              },
              backgroundColor: "brand",
            },
            {
              name: "Toggle api",
              icon: "Code",
              triger: "",
              backgroundColor: "brand",
            },
            {
              name: "Select creative",
              icon: "Select",
              triger: "",
              backgroundColor: "brand",
            },
            {
              name: "Duplicate placement",
              icon: "Duplicate",
              triger: "",
              backgroundColor: "brand",
            },
            {
              name: "Remove Placement",
              icon: "Trash",
              triger: "",
              backgroundColor: "brand",
              disabled: true,
            },
          ]}
        />
      ),
    },
  ];

  return columns;
}
const DEFAULT_SORT = { property: "id", direction: "desc" };

function Placements(props) {
  // Redux State
  const {
    // totalPages,
    advertisers,
    publishers,
    getInitialPlacementsData,
  } = props;
  const { placements, loading, totalPages } = props.placements;

  const [filtersVisible, toggleFilterVisibility] = useState(true);
  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.placements.page || 1);
  const [pageSize, setCurrentPageSize] = useState(
    props.placements.pageSize || 50
  );
  const [queryCriteria, setQueryCriteria] = useState({});

  let sort = sortSettings;
  if (["id", "payout", "name"].indexOf(sort.property) === -1) {
    sort = DEFAULT_SORT;
  }

  useEffect(() => {
    async function getData() {
      await getInitialPlacementsData({
        ...queryCriteria,
        page,
        pageSize,
        sort,
      });
    }
    const debounced = debounce(getData, 1000);
    debounced();
  }, [page, pageSize, queryCriteria, sort, getInitialPlacementsData]);

  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}
      <PlacementsFilters
        data={placements}
        open={filtersVisible}
        handleSubmit={setQueryCriteria}
        advertisers={advertisers}
        publishers={publishers}
      />
      <Loader loading={loading} text="Loading Placements data...">
        <Box style={{ flex: 13, overflowX: "auto" }}>
          <DataTable
            key={placements.length}
            primaryKey={"id"}
            pin
            sortable
            rowProps={getRowProps(placements)}
            data={placements}
            columns={getTableColumns()}
            sort={{ property: "id" }}
            fill
            background={{
              header: "dark-3",
              body: ["#dff0d8"],
              footer: "dark-3",
            }}
          />
        </Box>
        <Box
          pad="6px"
          background={"light-1"}
          direction="row"
          align={"center"}
          style={{ position: "fixed", bottom: 0, width: "100%" }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, nextPage) => setCurrentPage(nextPage)}
          />
          <Box style={{ maxWidth: "100px" }}>
            <Select
              size={"small"}
              options={[50, 100, 200, 250, 500]}
              value={pageSize}
              onChange={({ option: nextPageSize }) =>
                setCurrentPageSize(nextPageSize)
              }
            />
          </Box>
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  const placements = state.placements;
  const advertisers = state.advertisers.advertisers;
  const publishers = state.publishers.publishers;
  const mainSidebar = state.mainSidebar;
  return { placements, mainSidebar, advertisers, publishers };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      PlacementsActions,
      AdvertiserActions,
      PublishersActions,
      AppBarActions
    ),
    dispatch
  );
  //   return bindActionCreators(OffersActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Placements);
