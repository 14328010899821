import React, { Component, useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { Box, TextInput, Text, Collapsible, Select, CheckBox, DateInput } from "grommet";
import Moment from 'moment';
import SelectSearchInput from "../../components/xComponents/SearchInput";
import COUNTRY_CODES from "../../constants/country-codes.json";
import { debounce } from "debounce";
import TagsInput from '../../components/xComponents/TagsInput'
import FilterButton from '../../components/xComponents/FilterButton'

const COUNTRY_OPTS = COUNTRY_CODES.map((c) => c["alpha-2"]);



function DateSelect(props) {
  const { handleChange } = props;
  const options = [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Month To Date",
    "Last Month",
    "This Year"
  ]

  const dateMap = {
    "Today": {
      fromDate: Moment().startOf('day').format(),
      toDate: Moment().endOf('day').format()
    },
    "Yesterday": {
      fromDate: Moment().subtract('1', 'day').startOf('day').format(),
      toDate: Moment().subtract('1', 'day').endOf('day').format()
    },
    "Last 7 Days": {
      fromDate: Moment().subtract('7', 'day').startOf('day').format(),
      toDate: Moment().endOf('day').format()
    },
    "Month To Date": {
      fromDate: Moment().startOf('month').format(),
      toDate: Moment().endOf('day').format()
    },
    "Last Month": {
      fromDate: Moment().subtract('1', 'month').startOf('month').format(),
      toDate: Moment().subtract('1', 'month').endOf('month').format()
    },
    "This Year": {
      fromDate: Moment().startOf('year').format(),
      toDate: Moment().endOf('day').format()
    },
  }
  const [value, setValue] = useState(options[2])

  return (
    <Select
      options={options}
      value={value}
      onChange={({ option }) => {

        if (dateMap[option]) {
          console.info({ option }, dateMap[option])
          // console.info()
          handleChange(null, dateMap[option])
        }
        setValue(option)
      }}

    />
  )
}


class OffersFilters extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      criteria: {
        advertiser: "",
        minPrice: "",
        maxPrice: "",
        tagsFilter: [],
        countries: "",
        offerIds: "",
        ips: "",
        reportedOnly: false,
        placementIds: "",
        fromDate: Moment().subtract('7', 'day').startOf('day').format(),
        toDate: Moment().endOf('day').format()
      },
    };
    this.debouncedHandleSubmit = _.debounce(props.handleSubmit, 500);

    // this.onFilterData = this.onFilterData.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);

    // this.debouncedHandleFiltersChange = _.debounce(this.handleFiltersChange, 1000);

  }

  // handle fields change on add advertiser layer
  handleFiltersChange(e, valueObj) {
    const { handleSubmit } = this.props;
    const { criteria } = this.state;

    // console.log(name);
    // console.log(type);
    let newValue = null
    let newValues = {};
    let debounce = false;
    if (!valueObj) {
      const { value, name, type, checked } = e.target;


      newValue = (e.option && e.option) || e.value ? e.value : value;


      if (type === "checkbox") {
        newValue = checked;
      }

      if (typeof newValue === "object" && newValue.label) {
        newValue = newValue.value;
      }

      if (name === "rank") {
        newValue = e.value || [];
      }

      if (["internalStatus", "affilitestStatus"].indexOf(name) > -1) {
        newValue = e.value.value || 0;

        if (name === 'affilitestStatus') {
          newValue = !newValue ? -1 : newValue
        }
      }
      if (['minPrice', 'maxPrice'].indexOf(name) > -1) {
        console.info({ newValue })
        if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(newValue)) {

          console.info({ name, newValue })
          return
        }
        if (!newValue.slice(-1) === '.') {
          newValue = parseFloat(newValue)

        }
        debounce = true

      }

      if (
        ["advertiser", 'publisher', "countries", "platform", "incent"].indexOf(name) > -1 &&
        typeof newValue !== "object"
      ) {
        newValue = [];
      }

      if (name === 'affilitestNumRedirects') {
        newValue = newValue.replace(/\D/g, '');
        if (!newValue || newValue === criteria.affilitestNumRedirects) {
          newValues = {
            ...criteria,
            ...{ [name]: newValue },
          }
          this.setState({ criteria: newValues });

          return
        }

      }

      if (["minPrice", "maxPrice"].indexOf(name) > -1) {
        if (!newValue || newValue === criteria[name]) {
          newValues = {
            ...criteria,
            ...{ [name]: newValue },
          }
          this.setState({ criteria: newValues });
          return
        }


      }
      // console.info({ type, name }, newValue, e.value);
      // update new values
      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };

      if (["offerSearch", "affiltestSearch"].indexOf(name) > -1 || debounce) {
        this.debouncedHandleSubmit(newValues);
        return this.setState({ criteria: newValues });
      }

    } else {


      newValues = {
        ...criteria,
        ...valueObj
      }
    }




    console.info({ newValues });

    // handleSubmit(newValues);
    this.setState({ criteria: newValues });
  }

  render() {
    // function for handling filter/submit button
    const {
      open,
      advertisers,
      publishers,
      advertiser,
      publisher,
      direction,
      handleSubmit,
      disableSubmit
    } = this.props;
    // fields values
    const {
      fromDate,
      toDate,
      countries,
      offerIds,
      placementIds,
      ips,
      reportedOnly
    } = this.state.criteria;


    console.info({ placeholder: `from date`, value: fromDate })

    return (
      <Box disabled>
        <Collapsible open={open}>
          <Box
            direction={direction || "row"}
            pad="6px"
            // gap="medium"

            justify="between"
            background=" #f5f5f5"
          // margin="xsmall"
          // border
          >
            <Box gap="6px" align="center" fill="horizontal">
              {/*  ROW  */}
              <Box direction="row" gap="6px" align="center" fill="horizontal">
                {/*  COLUMN */}
                <Box
                  direction={direction || "row"}
                  gap="6px"
                  align="start"
                  // fill="horizontal"
                  width="566px"
                // margin={{ right: "35px" }}
                >
                  <Box style={{ maxWidth: '200px' }}>
                    <DateInput
                      key={fromDate}
                      size="small"
                      name="fromDate"
                      format="dd/mm/yyyy"
                      clear
                      value={fromDate}
                      inputProps={{ placeholder: `from date`, value: fromDate }}
                      onChange={({ value }) => {
                        if (value) {
                          return this.handleFiltersChange(null, { fromDate: value })
                        }

                      }}
                    />
                  </Box>
                  <Box style={{ maxWidth: '200px' }}>
                    <DateInput
                      key={toDate}

                      size="small"
                      name="toDate"
                      format="dd/mm/yyyy"
                      clear
                      value={toDate}
                      inputProps={{ placeholder: `to date`, value: toDate }}
                      onChange={({ value }) => {
                        if (value) {
                          return this.handleFiltersChange(null, { toDate: value })

                        }

                      }}
                    />
                  </Box>
                  <DateSelect
                    disabled={disableSubmit}
                    handleChange={this.handleFiltersChange}

                  />


                  {/* EXT_STATUS_OPTS */}
                </Box>
                {/* <SelectSearchInput
                  name="extStatus"
                  size="small"
                  value={extStatus}
                  placeholder="External Status"
                  onChange={(e) => this.handleFiltersChange(e)}
                  options={EXT_STATUS_OPTS}
                /> */}
                {/* 
                <Select
                  name="internalStatus"
                  size="small"
                  value={INTERNAL_STATUS_OPTS.filter(
                    (p) => p.value === internalStatus
                  )[0] || ""}
                  labelKey="label"
                  valueKey={{ key: "value" }}
                  clear
                  placeholder="Internal Status"
                  onChange={(e) => this.handleFiltersChange(e)}
                  options={INTERNAL_STATUS_OPTS}
                /> */}

                {/* 
                <Box>

                  <TextInput
                    name="minPrice"
                    disabled
                    value={minPrice}
                    placeholder="minPrice"
                    onChange={(e) => {
                      this.handleFiltersChange(e)
                    }}
                  />
                </Box>

                <Box>

                  <TextInput
                    name="maxPrice"
                    disabled
                    value={maxPrice}
                    placeholder="maxPrice"
                    onChange={(e) => this.handleFiltersChange(e)}
                  />
                </Box> */}

              </Box>

              {/*  ROW  */}
              <Box
                fill="horizontal"
                direction={direction || "row"}
                gap="medium"
                align="center"
                fill="horizontal"
              >
                {/*  COLUMN */}
                <Box
                  direction={direction || "row"}
                  gap="6px"
                  align="center"
                  fill="horizontal"
                  justify={"start"}
                // justify="between"
                >

                  <SelectSearchInput
                    name="advertiser"
                    size="small"
                    value={advertiser}
                    placeholder="Advertisers"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={_.uniq(
                      advertisers.map((a) => `${a.id}::${a.name}`)
                    )}
                  />

                  <SelectSearchInput
                    name="publisher"
                    size="small"
                    value={publisher}
                    placeholder="Publishers"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={_.uniq(
                      (publishers || []).map((a) => `${a.id}::${a.name}`)
                    )}
                  />

                  <SelectSearchInput
                    name="countries"
                    size="small"
                    value={countries}
                    placeholder="Countries"
                    onChange={(e) => this.handleFiltersChange(e)}
                    options={COUNTRY_OPTS}
                  />
                  <Box>

                    <TextInput
                      // reverse
                      name="offerIds"
                      size="small"
                      value={offerIds}
                      onChange={({ target }) => {
                        const offerIds = target.value.replace(/\s/g, "");
                        if (/^[0-9,]*$/.test(offerIds))
                          this.handleFiltersChange(null, { offerIds })
                      }}
                      placeholder="Offer IDs"
                    />
                  </Box>
                  <Box>

                    <TextInput
                      // reverse
                      name="placementIds"
                      size="small"
                      value={placementIds}
                      onChange={({ target }) => {
                        const placementIds = target.value.replace(/\s/g, "");
                        if (/^[0-9,]*$/.test(placementIds))
                          this.handleFiltersChange(null, { placementIds })
                      }}
                      placeholder="Placement IDs"
                    />
                  </Box>
                  <Box>

                    <TextInput
                      // reverse
                      name="ips"
                      size="small"
                      value={ips}
                      onChange={({ target }) => {
                        const ips = target.value.replace(/\s/g, "");
                        const validate = (ips) => !ips || ips
                          .split(",")
                          .filter(s => 
                              s && s.length < 16 
                              && s.split(".")
                                .filter(num => 
                                    num > 255
                                ).length === 0
                           )
                          .length
                          
                        if (validate(ips))
                          this.handleFiltersChange(null, { ips })
                      }}
                      placeholder="IP (conv, user)"
                    />
                  </Box>
                <Box>
                  <CheckBox
                    label={"Reported Only"}
                    name="reportedOnly"
                    checked={reportedOnly}
                    onChange={(event) => this.handleFiltersChange(event)}
                    style={{ backgroundColor: "green" }}
                  />
                </Box>
                  {/* <TagsInput
                    name="tagsFilter"
                    placeholder="Search Offer, Placement, Tag ids"
                    regex={/^(p|P|o|O|t|T)[0-9]+$/}
                    handleChange={(e) => {
                      console.info(e.tagsFilter, 'tags')
                      this.handleFiltersChange(null, e)
                    }}
                    value={tagsFilter}
                  /> */}

                  <FilterButton
                    onClick={() => handleSubmit(this.state.criteria)}
                    disabled={disableSubmit}
                  />
                </Box>

              </Box>
            </Box>

          </Box>
        </Collapsible>
      </Box>
    );
  }
}

export default OffersFilters;
