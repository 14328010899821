import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Box, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    "& > *": {
      //   marginTop: theme.spacing(2),
    },
    padding: "1px",
  },
  exampleWrapper: {
    position: "absolute",
    // marginTop: theme.spacing(3),
    bottom: "60px",
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "& button ": {
      backgroundColor: "#3c8dbc",
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const icons = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <AddIcon />, name: "Add" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
  { icon: <FavoriteIcon />, name: "Like" },
];

function SpeedDials(props) {
  const { buttons } = props;
  const classes = useStyles();
  // const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  // const [hidden, setHidden] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={""}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction={"up"}
        >
          {buttons &&
            buttons.map((btn) => {
              const icon = icons.filter(
                (i) =>
                  i.name.toLocaleLowerCase() === btn.icon.toLocaleLowerCase()
              )[0].icon;
              return (
                <SpeedDialAction
                  key={btn.name}
                  icon={icon}
                  tooltipTitle={btn.name}
                  onClick={btn.onClick}
                />
              );
            })}
        </SpeedDial>
      </div>
    </div>
  );
}

export default function FotterButtons(props) {
  const { buttons, browser } = props;
  const classes = useStyles();
  //   console.info({paginationProps: props})
  return (
    <div className={classes.root}>
      {!browser ? (
        <SpeedDials buttons={buttons} />
      ) : (
        <div>
          {buttons.map((btn) => {
            return (
              <Button
                variant="contained"
                id={btn.id}
                name={btn.name}
                onClick={btn.onClick}
                startIcon={<AddIcon />}
                style={{ marginLeft: "8px" }}
              >
                {btn.name}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
}
