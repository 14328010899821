import React from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

export default function Field(props) {
  const { field, onFieldChange } = props;

  return (
    <TextField
      // error
      style={{ width: "100%" }}
      id={field.name}
      name={field.name}
      label={_.capitalize(field.name.split("_").join(" "))}
      defaultValue={field.value}
      onChange={onFieldChange}
      variant="outlined"
    />
  );
}
