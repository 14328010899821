import React, { Component } from "react";
// import fetch from "isomorphic-unfetch";
// import Layout from "../containers/Layout";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { login } from "../actions/AuthActions";
import {
    Box,
    Form,
    FormField as GFormField,
    TextInput,
    Heading,
    Text,
    Button,
} from "grommet";
import { PasswordInput as PasswordGrommet } from "grommet-controls";
import styled from "styled-components";
import Loader from '../components/Loader';
// import Notification from '../containers/Notification';

// import { checkDBConnection } from '../store/actions/MiscActions';
// import VersionInfo from '../components/VersionInfo';
// import ConnectionIndicatior from '../containers/ConnectionIndicator';

// const login = (x) => console.info(x,"login")
const FormField = styled(GFormField)`
  margin: 0;
  font-weight: bold;
  & > label {
      margin-left: 0px;
  }
`;
const PasswordInput = styled(PasswordGrommet)`
  border: 0;
  border-radius: 0;
  margin: 0;
  box-shadow: none;
  padding-left: 12 px;
`;

const LoginHeading = styled(Heading)`
  font-size: 36px;
`

function LoginButton(props) {
    const navigate = useNavigate()
    return <Button
        // type="submit"
        primary
        margin="small"
        label="Login"
        onClick={async (e) => {
            const isLoggedIn = await props.onClick(e)
            console.info({isLoggedIn})
            if (isLoggedIn) {
                navigate('/')
            }
        }}
    />

}
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = { processing: false, username: "", password: "", error: "" };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // navigate = useNavigate()


    //   componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.props.isLoggedIn === true) {
    //       Router.push("/");
    //     }
    //   }

    handleChange(event) {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    }

    async handleSubmit(event) {
        const { dispatch } = this.props;
        // console.info('handle', this.props)
        event.preventDefault();
        const { username, password } = this.state;
        this.setState({ processing: true })
        const doLogin = await dispatch(login({ username, password }));
        // console.info({doLogin})
        // if (doLogin) {
        //     this.navigate('/')
        // }
        this.setState({ processing: false })

        return doLogin
    }

    render() {
        const { processing, password, username } = this.state;
        return (
            <Box flex align="center" style={{ marginTop: "-15%" }} justify="center">
                {/* <Box pad='small' align='center'>
                        <Box
                            gap='small'
                            direction='row'
                            align='center'
                        >

                            <LoginHeading level="1" margin='none'>
                                TrackX
            </LoginHeading>
                        </Box>
                        <VersionInfo />

                    </Box> */}
                <Form style={{ minWidth: '250px' }}>
                    <Box
                        border
                        style={{ minWidth: '250px', borderColor: 'white' }}
                        pad={{
                            vertical: "medium",
                            horizontal: "large",
                        }}
                        gap="medium"
                        elevation="small"
                    >
                        <FormField label="USERNAME" name="username">
                            <TextInput
                                onChange={this.handleChange}
                                value={username || ""}
                                name="username"
                                disabled={processing}
                            />
                        </FormField>
                        <FormField
                            label="PASSWORD"
                            value={password || ""}
                            name="password"
                            disabled={processing}
                        >
                            <PasswordInput name="password" onChange={this.handleChange} />
                        </FormField>
                        <Loader
                            loading={processing}
                            text='Logging in...'>
                                <LoginButton onClick={this.handleSubmit}/>
                        </Loader>
                    </Box>
                </Form>
            </Box>
        );
    }
}



function mapStateToProps(state) {
    const { user, isLoggedIn } = state.user;
    return {
        user,
        isLoggedIn
    };
}

export default connect(mapStateToProps)(Login);
