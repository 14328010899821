// www/pages/Home.js
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
// import { withRouter } from 'next/router'
// import Layout from '../containers/Layout'
// import { withAuthSync } from '../utils/auth'
import AppsFlyerAdnActions, {
  getInitialData,
} from "../../actions/AppsFlyerAdnActions";
import { connect } from "react-redux";
import ConfigView from "./adn_config";
import EventsView from "./adn_app_events";
import ADNReportsView from "./adn_reports";
import ReportsView from "../../components/appsflyer/reports";

import Moment from "moment";
import Loader from "../../components/Loader";
import SearchBox from "../../components/SearchBox";
import PageHeader from "../../components/xComponents/PageHeader";
import ButtonX from "../../components/xComponents/Button";

import { Box, Nav, Header, Button, Text, Heading } from "grommet";
const items = [];

const getFilteredData = (searchData, searchProps, searchValue) => {
  if (searchValue.length < 1) {
    return searchData;
  }

  // console.info({searchValue, searchProps, searchData})
  const searchResult = searchData.filter((o) => {
    return searchProps
      .map((prop) => {
        const value = String(o[prop]).toUpperCase();
        const search = String(searchValue).toUpperCase();
        // console.info({value, search, res: _.includes(value, search)})
        return _.includes(value, search);
      })
      .filter((prop) => prop).length;
  });
  // console.info({searchResult})
  return searchResult;
};

function MenuButton(props) {
  const navigate = useNavigate();
  console.log(navigate.location);
  return (
    <Button
      // type="submit"

      label={props.label}
      href={`/appsflyer/${props.label}`}
      // key={props.label}
      onClick={async (e) => {
        const activeView = e.target.innerText;
        props.onClick();
        e.preventDefault();
        navigate(`/appsflyer-adn/${activeView}`);
      }}
      style={{
        border: "none",
        color: `${
          navigate.location.pathname.split("/")[2] === props.label
            ? "green"
            : "white"
        }`,
      }}
    />
  );
}

class AppsFlyer extends Component {
  constructor(props) {
    super(props);
    // console.info({ props })

    this.state = {
      activeView: props.match.params.activeView || "config",
      searchValue: "",
      searchSuggestions: [],
      searchOnChange: ({ target }) =>
        this.setState({ searchValue: target.value }),
      searchOnSelect: (select) => console.info({ select }),
    };
    this.renderView = this.renderView.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }
  // const { name, login, bio, avatarUrl } = props.data
  // console.info({props})
  renderView() {
    const { config, events, reports, configMap, ...restProps } = this.props;
    // console.info({configMap}, this.props)
    const {
      searchValue,
      searchSuggestions,
      searchOnChange,
      searchOnSelect,
    } = this.state;

    const searchBoxProps = {
      searchValue,
      searchSuggestions,
      searchOnChange,
      searchOnSelect,
    };

    switch (this.state.activeView) {
      case "config": {
        const searchFields = ["id", "app_bundle", "app_name"];
        const data = getFilteredData(config, searchFields, searchValue);
        return (
          <Box>
            <ConfigView data={data} events={events} {...restProps} />
          </Box>
        );
      }
      // return <ConfigView data={config}{...restProps} />
      case "events": {
        const searchFields = ["id", "app_bundle", "event_name", "event_key"];
        const data = getFilteredData(events, searchFields, searchValue);
        // console.info(events)
        return (
          <Box>
            <SearchBox {...searchBoxProps} {...{ searchFields }} />
            <EventsView
              data={data}
              configMap={configMap}
              eventId={restProps.eventId}
              {...restProps}
            />
          </Box>
        );
      }
      case "reports": {
        const searchFields = ["id", "app_bundle", "campaign", "country"];
        const enrichData = (data) =>
          (data || []).map((o) => {
            // console.info({ o });
            return Object.assign({}, o, {
              profit: o.revenue - o.cost,
              revenue: +o.revenue,
              cost: +o.cost,
              app_name: configMap[o.app_bundle.toUpperCase()]
                ? String(
                    configMap[o.app_bundle.toUpperCase()].app_name
                  ).replace(/\s/g, "")
                : "",
            });
          });

        const data = getFilteredData(reports, searchFields, searchValue);
        // console.info({ configMap, reports, data });

        return (
          <Box flex fill>
            <ReportsView data={enrichData(data)}>
              <SearchBox {...searchBoxProps} {...{ searchFields }} />
            </ReportsView>
          </Box>
        );
      }
      // return <ADNReportsView data={reports} />
      default:
        return <Box>VIEW NOT DEVELOPED!</Box>;
    }
  }

  componentDidMount() {
    // console.info("APPS_FLYER_DID", this.props)
    const { loading, config, getInitialData } = this.props;

    if (!loading && !config) {
      getInitialData();
    }
  }

  onMenuClick(e) {
    const activeView = e.target.innerText;
    e.preventDefault();

    // console.info({ activeView });
    //  this.setState({
    //   activeView
    // })
    this.props.router.push(`/appsflyer/${activeView}`);
  }

  render() {
    const { activeView } = this.state;
    const { menu, loading, lastUpdateText } = this.props;
    return (
      //   <Layout>
      <Box {...this.props} fill flex>
        <Box
          direction="row"
          align="center"
          justify="between"
          background="dark-1"
          pad={{ horizontal: "small" }}
        >
          <Box>{`AppsFlyer Network API - ${_.capitalize(activeView)}`}</Box>
          <Box justify="center" direction="row">
            {menu.map((item) => (
              <Button
                size="small"
                margin="small"
                primary
                onClick={() => this.setState({ activeView: item.label })}
                key={item.label}
                label={item.label}
              />
            ))}
          </Box>
        </Box>
        {/* HEADER  */}

        <Box fill flex pad="small">
          <Loader loading={loading} text="Loading AppsFlyer AdNetwork data...">
            {this.renderView()}
          </Loader>
        </Box>
      </Box>
      //   </Layout>
    );
  }
}

// AppsFlyer.getInitialProps = ({ store, pathname, req, res, ...rest }) => {
//     // console.log('2. Page.getInitialProps uses the store to dispatch things');
//     // console.log("SSSS", store, req)
//     store.dispatch(getInitialData(req));
// };

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AppsFlyerAdnActions, dispatch);
}

function mapStateToProps(state) {
  const { appsflyer_adn } = state;

  const menu = Object.keys(appsflyer_adn)
    .filter((l) => ["config", "events", "reports"].indexOf(l) > -1)
    .map((label) => ({ label }));
  const { config, events, reports, configMap, loading } = appsflyer_adn;
  const [lastUpdate] = _.sortBy(config, "modified").reverse();
  const { modified } = lastUpdate || {};
  const lastUpdateText = `Last Update: ${
    modified ? Moment(modified).format("YYYY-MM-DD HH:mm") : "N/A"
  } `;
  // console.info({ lastUpdateText })
  // MomentTZ(calendarInput[0])
  // .tz(timezone)
  return {
    menu,
    lastUpdateText,
    loading,
    config,
    events,
    configMap,
    reports,
    publishers: [],
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsFlyer);
