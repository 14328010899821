import React, { useEffect } from 'react';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
    Box,
    Text,
    Tag,
    Grommet,
    Select,
    TextArea,
    DateInput,
    FormField,
    Button,
    Spinner,
} from "grommet";
import { DateSelect, getDateMaps } from "../../components/FiltersV2";
import { CellBox } from './AlertsHelpers';
import { DEFAULT_FILTERS } from './AlertsHelpers';
import Moment from 'moment';

import { postDispatcher, useGetDispatch } from '../../utils/fetch';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

export const AlertsGroupBy = (props) => {

    const {
        options,
        value,
        onChange
    } = props;

    return (
        <Box flex='grow' size='small' style={{ maxWidth: 500 }}>
            <FormField label='Group By'>

                <Select
                    options={options}
                    clear
                    messages={
                        { multiple: `${value && value.map(g => g.label).join(", ")}` }
                    }
                    multiple
                    labelKey='label'
                    valueKey='value'
                    placeholder={"Report Group params"}
                    value={value}
                    onChange={onChange}

                />
            </FormField>
        </Box>
    )

}


const DateInputButton = styled(Box)`
// padding: 10px;
min-height: 40px;
font-weight: 600;
`

export const AlertsCustomTimeframe = (props) => {
    const {
        timeframeVal,
        isCustomDate,
        defaultRange,
        // defaultRangeParams,
        onChange
    } = props

    useEffect(() => {
        // console.info({timeframeVal})

    }, [timeframeVal])
    
    return (
        <Box style={{ minWidth: 250 }}>
            <FormField label='Custom Timeframe'>

                <DateInput
                    calendarProps={{
                        size: 'small',
                        fill: true
                    }}
                    onChange={({value}) => {
                        const [fromDate, toDate] = value;
                        onChange({
                            fromDate,
                            toDate
                        })
                        console.info("date", fromDate, toDate)
                    }}
                    value={timeframeVal.fromDate ? [timeframeVal.fromDate, timeframeVal.toDate]: undefined}
                    // value={value}
                    buttonProps={{
                        plain: true,
                        label:
                            <DateInputButton 
                            align='center' 
                            justify='center'
                            style={{fontWeight: isCustomDate ? 700 : 500}}
                            >
                                {`${Moment(timeframeVal.fromDate).format('DD/MM/YY')} - ${Moment(timeframeVal.toDate).format('DD/MM/YY')}`}
                            </DateInputButton>
                        //   label: `${dateFormat.format(
                        //     new Date(value[0]),
                        //   )} - ${dateFormat.format(new Date(value[1]))}`,
                    }}
                // onChange={onChange}
                />
            </FormField>

        </Box>
    )
}
export const AlertsTimeframe = (props) => {
    const {
        defaultRange,
        isCustomDate,
        // defaultRangeParams,
        onChange
    } = props

    console.info({isCustomDate})
    return (
        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
            <DateSelect
                isCustom={isCustomDate}
                withCustom
                formFieldLabel='Timeframe'
                defaultRange={defaultRange}
                handleChange={onChange} />
        </Box>
    )
}

export const AppBundlesSelect = (props) => {
    const {
        value,
        onChange,
        options
    } = props;

    const [appBundlesOpts, setAppBundlesOpts] = React.useState(options || [])

    useEffect(() => {
        setAppBundlesOpts(options)
    }, [options])

    return (
        <Box>
            {/* <CreateAlertModal/> */}
            <FormField label='App Bundle'>

                <Select
                    options={appBundlesOpts}
                    clear
                    messages={
                        { multiple: value ? `${value.length} Bundles` : '' }
                    }
                    onSearch={(text) => {
                        if (!text) {
                            setAppBundlesOpts(options)
                            return true;
                        }
                        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                        const exp = new RegExp(escapedText, "i");
                        setAppBundlesOpts(options.filter((o) => exp.test(o.app_name) || exp.test(o.app_bundle)));
                    }}
                    multiple
                    labelKey={(option) => (
                        <CellBox>
                            {option.app_name}
                            <div>
                                {option.app_bundle}
                            </div>
                        </CellBox>
                    )}
                    valueLabel={option => option.app_bundle}
                    valueKey='app_bundle'
                    value={value}
                    onChange={({ value, option }) => {

                        setAppBundlesOpts(_.uniqBy([
                            ...value,
                            option,
                            ...options
                        ], 'app_bundle').filter(o => o))

                        onChange({ value, option })
                    }}

                />
            </FormField>
        </Box>
    )
}


export const PublishersSelect = (props) => {

    const { options, value, onChange } = props;
    const [publishersOpts, setPublishersOpts] = React.useState(options)


    useEffect(() => {
        setPublishersOpts(options)
    }, [options])

    return (
        <Box>
            <FormField label='Publisher'>

                <Select
                    options={publishersOpts}
                    clear
                    key={value.length}
                    messages={
                        { multiple: value ? `${value.length} Publishers` : '' }
                    }
                    onSearch={(text) => {
                        if (!text) {
                            setPublishersOpts(options)
                            return true;
                        }
                        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                        const exp = new RegExp(escapedText, "i");
                        // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

                        setPublishersOpts(options.filter((o) => exp.test(o.publisher)));
                    }}
                    multiple
                    valueKey='publisher'
                    labelKey='publisher'
                    // // placeholder={"Report Group params"}
                    value={value}
                    // // value={groupByVal === "None" ? "" : groupByVal}
                    onChange={({ value, option }) => {
                        setPublishersOpts(_.uniqBy([
                            ...value,
                            option,
                            ...options
                        ], 'publisher').filter(o => o))

                        onChange({ value, option })
                    }}

                />
            </FormField>
        </Box>
    )
}


export const AlertCard = (props) => {
    const { alert } = props;
    const { hash, summary, summary_date } = alert;

    const [processing, setProcessing] = React.useState(false);
    const username = useSelector(state => state.user.username)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Box key={`alert-${hash}`}>
            <Box

                flex
                pad='6px'
                style={{ borderRadius: 6 }}
                background="light-2"
            >
                <Text weight='bold' size='small' color='brand' style={{ letterSpacing: '0.5px' }}>{hash.toUpperCase()}</Text>
                <Box>
                    <Box
                        direction='row'
                        gap='small'
                        align='center'
                        pad={{ vertical: '4px' }}
                    >
                        <Text size='xsmall' style={{
                            letterSpacing: '0.5px', fontSize: 10, lineHeight: '2px'

                        }}>FILTERS:</Text>
                        {alert.settings.filterBy.map(o => {
                            const val = DEFAULT_FILTERS.find(f => f[0] === o[0]);
                            return (<Tag
                                size='xsmall'
                                style={{
                                    border: 'none',
                                    color: 'rgba(0,0,0,0.65)'
                                }}
                                value={`${val[1]} ${val[2]} ${o[1]}`}
                            />)
                        })}
                    </Box>
                    <Box>
                        <Text size='xsmall' style={{
                            letterSpacing: '0.5px', fontSize: 10, lineHeight: '2px'
                        }}>SUMMARY:</Text>
                        <Box direction='row' justify='center'>

                            {summary.map(({ key, name, results, acked }) => (
                                <Box
                                    key={key}
                                    align='center'
                                    flex
                                    pad='2px'
                                    onClick={() => { navigate(`/alerts/alerts-dashboard/${hash}?tf=${key}`) }}
                                    hoverIndicator
                                    column>
                                    <Text
                                        weight='bold'
                                        style={{
                                            fontSize: 10,
                                            letterSpacing: '0.2px'
                                        }}
                                    >{`${results} (${acked})`}</Text>
                                    <Text
                                        weight='bold'
                                        style={{
                                            fontSize: 8,
                                            textAlign: 'center',
                                            lineHeight: '54p',
                                            // paddingLeft: '24px',
                                            letterSpacing: '0.4px',
                                            minWidth: 100
                                        }}
                                    >{`${name}`}</Text>



                                </Box>
                            ))}
                        </Box>
                        <Box pad='4px'>
                            <Text
                                style={{
                                    fontSize: 10,
                                    textAlign: 'center',
                                    lineHeight: '4px',
                                    // paddingLeft: '24px',
                                    letterSpacing: '0.4px',
                                    minWidth: 100
                                }}
                            >{`${Moment(summary_date).format('YYYY-MM-DD HH:mm')}`}</Text>
                        </Box>
                        {/* <Box direction='row' justify='center' flex>
                            <Button
                                plain
                                pad='2px'
                                color='brand'
                                hoverIndicator
                                label='dismiss'
                                disabled={processing}
                                onClick={async () => {
                                    setProcessing(true)
                                    await postDispatcher(
                                        '/api/alerts/dismiss',
                                        { username, target: hash },
                                        dispatch
                                    );

                                    setProcessing(false)
                                }}
                            />
                        </Box> */}



                    </Box>
                </Box>

            </Box>
        </Box>
    )
}