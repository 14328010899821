import React from "react";
import { Box, Text } from "grommet";
import { User as UserIcon, RadialSelected } from "grommet-icons";

export default function User(props) {
  const { user, isDesktop } = props;
  return (
    <Box
      gap="small"
      align="center"
      direction="row"
      border="bottom"
      justify="between"
    >
      {/* <Collapsible open={userBoxVisible}> */}
      <Box
        gap="small"
        align="center"
        direction="row"
        margin={{ top: "small" }}
        pad={isDesktop ? "medium" : "xlarge"}
        style={{ width: "80vw", zIndex: 100 }}
      >
        <UserIcon
          style={{
            borderRadius: "50%",
            border: "3px solid white",
          }}
          size={isDesktop ? "medium" : "large"}
        />

        <Box fill>
          <Text size="17px">{user.firstName}</Text>
          <Box direction="row" align="center" gap="xsmall">
            <Text size="10px" color="#E6E6E6">
              Online
            </Text>
            <RadialSelected color="#6BF061" size="9px" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
