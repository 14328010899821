import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigationLinks } from "../../containers/MainSidebar/navigationLinks";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Box, Slide } from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import SidebarsActions from "../../actions/SidebarsActions";

import VersionText from "../VersionInfo";
import { Anchor } from "grommet";

import { Logout as LogoutIcon } from "grommet-icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='right' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    // "& div > div": {
    //   minWidth: "80%",
    //   minHeight: "60%",
    // },
  },
  //
  title: { backgroundColor: "#3c8dbc", padding: "3% 0px" },
  //
  content: {
    padding: "2% 0 0 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    backgroundColor: "#3c8dbc",
    color: "white",
    fontFamily: "roboto",
  },
  //
  routeContainer: {
    width: "100%",
    borderBottom: "1px solid rgb(23, 94, 122)",
    // padding: "2% 0",
  },
  routeContainerSelected: {
    backgroundColor: "#347aa3",
    // margin: "10px 0",
  },
  routeIconName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "90%",
    margin: "2% 0",
  },
  //
  linkWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  //
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "white",
    fontWeight: "500",
    fontSize: "14px",
  },
  //
  collapse: {
    // backgroundColor: "red",
    padding: "3% 0 1%",
    "& div:last-child ": {
      margin: "3% 0 3%",
      // backgroundColor: "green",
    },
  },
  //
  innerLinkWrapper: {
    // marginTop: "2%",
    marginTop: "3%",
  },
  //
  innerLink: {
    fontWeight: "300",
    textDecoration: "none",
    // color: "rgb(23, 94, 122)",
    paddingLeft: "20%",
    fontSize: "14px",
    color: "white",
    // backgroundColor: "white",
  },
  linkSelected: {
    color: "white",
  },
  routeName: {
    // color: "black",
    // backgroundColor: "blue",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "3% 0",
    // margin: "5% 0 0 0",
    textTransform: "uppercase",
  },
  routeNameSelected: {
    textTransform: "uppercase",
    boxShadow: "0px 10px 5px 0px rgba(0,0,0,0.1);",
  },
  name: {
    marginTop: "5%",
    textTransform: "uppercase",
  },
  ref: {},
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "10%",
  },
  routeIcon: {
    maxWidth: "20%",
    minWidth: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingLeft: "10px",
  },
}));

function ScrollDialog(props) {
  const classes = useStyles();
  const { router, browser, mainSidebar } = props;

  const [selected, setSelected] = React.useState();

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRouteSelected = (index) => {
    if (index != selected) {
      setSelected(index);
    } else setSelected("");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        fullScreen
        className={classes.root}
        maxWidth='xl'
        open={mainSidebar.visible}
        onClose={props.toggleMainSidebar}
        TransitionComponent={Transition}
        scroll={"paper"}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        {
          // Dialog Header - optional
          <DialogTitle
            id='scroll-dialog-title'
            style={{}}
            className={classes.title}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ color: "white" }}>
                <span style={{ marginLeft: "12px" }}>TackX</span>
                <VersionText />
              </div>
              <Button
                onClick={props.toggleMainSidebar}
                style={{
                  color: "white",
                  fontSize: "18px",
                }}
              >
                X
              </Button>
            </div>
          </DialogTitle>
        }
        <DialogContent className={classes.content} ref={descriptionElementRef}>
          {navigationLinks.map((l, index) => (
            <div>
              {l.child ? (
                <div
                  className={clsx(classes.routeContainer, {
                    [classes.routeContainerSelected]: selected === index,
                  })}
                  onClick={() => handleRouteSelected(index)}
                >
                  <div
                    className={clsx(classes.routeName, {
                      [classes.routeNameSelected]: selected === index,
                    })}
                  >
                    <div className={classes.routeIconName}>
                      <div className={classes.routeIcon}>{l.icon}</div>
                      <div
                        className={clsx(classes.link, {
                          [classes.linkSelected]: selected === index,
                        })}
                      >
                        {l.name || l.src}
                      </div>
                    </div>
                    <div className={classes.iconWrapper}>
                      <KeyboardArrowDownIcon />
                    </div>
                  </div>
                  <Collapse in={selected === index}>
                    <div className={classes.collapse}>
                      {l.child.map((c) => {
                        return (
                          <div className={classes.innerLinkWrapper}>
                            <Link
                              onClick={props.toggleMainSidebar}
                              to={"/" + c.src}
                              className={classes.innerLink}
                            >
                              {c.name || c.src}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              ) : (
                <div className={classes.routeContainer}>
                  <div
                    className={clsx(classes.routeName, {
                      [classes.routeNameSelected]: selected === index,
                    })}
                  >
                    <div className={classes.routeIconName}>
                      <div className={classes.routeIcon}>{l.icon}</div>
                      <div className={classes.linkWrapper}>
                        <Link
                          onClick={props.toggleMainSidebar}
                          className={classes.link}
                          to={"/" + l.src}
                        >
                          {l.name || l.src}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

          <Box onMouseDown={props.onLogout} style={{ marginTop: "auto" }}>
            <Anchor
              icon={<LogoutIcon />}
              label={"Logout"}
              color='white'
              style={{
                backgroundColor: "#2a6283",
                zIndex: "999",
                padding: "15px",
                height: "100%",
                display: "flex",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(SidebarsActions, dispatch);
}
// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const router = state.router;
  const mainSidebar = state.mainSidebar;
  const browser = state.browser;
  return { router, browser, mainSidebar };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollDialog);
