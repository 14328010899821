import styled from 'styled-components';
import { Box, Button } from 'grommet';
import { Filter as FilterIcon } from 'grommet-icons';


const FlexBox = styled(Box)`
display: flex;
flex-grow: 1;
align-items: flex-end;
`;

const StyledButton = styled(Button)`
border-radius: 5px;
min-height: 40px;
`;
export default function FilterButton(props) {



    return (
        <FlexBox >
            <StyledButton
                disabled={props.disabled}
                primary
                label="Filter"
                icon={<FilterIcon size="24px" />}
                onClick={props.onClick}
            />
        </FlexBox>

    )
}