import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
// Grommet Imports
import { Heading, Paragraph, Button, Box } from "grommet";
import SummaryByDateChart from '../Charts/SummaryByDate/SummaryByDateChart';
import ActivePieChart from '../Charts/ActivePieChart/ActivePieChart';
import Numeral from 'numeral';

// Action Imports
import DashboardActions, { getDashboardData } from "../../actions/DashboardActions";

function HomePage(props) {
  const {
    summary,
    profitPub,
    profitBundle,
    getDashboardData,
  } = props;

  useEffect(() => {
    // console.info({summary})
    if (!summary.length)
      getDashboardData()
  }, [summary, getDashboardData])

  // console.info({profitPub})
  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* <Heading level={3} margin="none">
          Welcome {this.props.username}!
        </Heading> */}
        <Box direction='row' fill>
          <ActivePieChart profitPub={profitPub}/>
          <ActivePieChart profitBundle={profitBundle}/>

          </Box>
      <Box
        fill
        align='center'
        justify='center'>

        <SummaryByDateChart data={summary} />
      </Box>
    </Box>
  );

}

const mapStateToProps = (state, props) => {
  const { user, dashboard } = state;
  const username = user.name;
  const summary = dashboard.summary.joined;
  const profitPub = dashboard.profitPub.joined;
  const profitBundle = dashboard.profitBundle.joined;

  return { username, summary, profitPub, profitBundle };
};

const mapDispatchToProps = {
  ...DashboardActions
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
