import Numeral from "numeral";
import Moment from "moment";
// grommet
import { Box, Text, Select, Tip, Button } from "grommet";
import Cell from "../../components/Cell";
import ActionButtons from "../../components/ActionButtons";
import PopupDialog from "../../components/xComponents/PopupDialog";
import FieldsGrouper from "../../components/xComponents/FieldsGrouper";

// Get Offers Table Columns
export function getTableColumns() {
  const defaultRenderer = (propName, type = "", color = "") => (props) => {
    let value = props[propName.toLowerCase()];
    if (type === "numeral") {
      value = Numeral(value).format("0,0");
    } else if (type === "currency") {
      value = Numeral(value).format("0,0$");
    } else if (type === "date") {
      value = Moment(value).format("DD/MM/YY HH:mm");
    }

    return (
      <Cell
        start={"true"}
        flex
        color={color}
        type={type}
        // background={'background'}
      >
        <Tip
          plain
          content={
            <Box border round="small" background={"#E6E6E6"} pad="xsmall">
              <Text size="xsmall">
                {propName} - {value}
              </Text>
            </Box>
          }
        >
          <Box>{value}</Box>
        </Tip>
      </Cell>
    );
  };

  const columns = [
    {
      property: "id",
      header: "ID",
      render: (props) => (
        <Box
          padding={"none"}
          align="center"
          justify="end"
          gap="8px"
          direction="row"
        >
          {props.adv_offer_id && (
            <Box round="5px" pad="xsmall" align="center" background="brand">
              <Text size="8px">API</Text>
            </Box>
          )}

          <Text size="xsmall">{props.id}</Text>
        </Box>
      ),
    },
    {
      property: "advertiser",
      header: "Advertiser",
      render: defaultRenderer("advertiser"),
    },
    {
      property: "name",
      header: "Name",
      render: defaultRenderer("name"),
    },
    {
      property: "platform",
      header: "OS",
      render: defaultRenderer("platform"),
    },
    {
      property: "adv_offer_id",
      header: "Ad Id",
      render: defaultRenderer("adv_offer_id"),
    },
    {
      property: "placments",
      header: "Placments",
      render: defaultRenderer("placements"),
    },
    {
      property: "payout",
      header: "Payout",
      render: defaultRenderer("payout"),
    },
    {
      property: "rank",
      header: "Rank",
      render: defaultRenderer("rank"),
    },
    {
      property: "country",
      header: "GEOS",
      render: defaultRenderer("country"),
    },
    {
      property: "conversion_cap",
      header: "Conv Cap",
      render: defaultRenderer("conversion_cap"),
    },
    {
      property: "click_cap",
      header: "Click Cap",
      render: defaultRenderer("click_cap"),
    },
    {
      property: "status",
      header: "Status",
      render: defaultRenderer("status"),
      // primary: true,
    },
    {
      property: "internal",
      header: "Internal",
      render: defaultRenderer("internal"),
    },
    {
      property: "created",
      header: "created",
      render: defaultRenderer("created", "date"),
    },
    {
      property: "actions",
      header: "",
      render: (props) => (
        <ActionButtons
          direction={"row"}
          buttons={[
            {
              name: "Edit offer",
              icon: "Edit",
              triger: "",
            },
            {
              name: "Select offer",
              icon: "Link",
              triger: "",
            },
            {
              name: "Remove offer",
              icon: "Trash",
              triger: "",

              disabled: true,
            },
          ]}
        />
      ),
    },
  ];

  return columns;
}

//
export function getRowProps(data) {
  const internals = data
    .filter((o) => o.internal !== "active")
    .map((o) => o.id);
  const closed = data
    .filter((o) => o.status === "Closed" || o.status === "Not Active")
    .map((o) => o.id);
  // console.info({ internals, closed })

  let rowProps = {};

  internals.forEach(
    (oid) => (rowProps[oid] = { background: "rgb(236, 208, 75, 0.9)" })
  );
  closed.forEach((oid) => (rowProps[oid] = { background: "#f2dedd" }));

  return rowProps;
}

// POPUP DIALOG
export function getPopup(open, onClose) {
  return (
    <PopupDialog
      visible={open}
      // header={this.setPopupDialogHeader(this.state.popupDialogContent)}
      onEsc={() => onClose()}
      // onSubmit={() => this.setPopupDialogSubmitAction()}
    >
      {/* {this.setPopupDialogContent()} */}
    </PopupDialog>
  );
}
export function setPopupContent(e) {
  const val = e.target.parentNode.id;
  switch (val) {
    case "ADD":
      return {
        header: "Add Offer",
        content: (
          <FieldsGrouper
            fieldsGroup={this.getFields()}
            onChange={this.handleFieldsChange}
          />
        ),
      };

    case "EDIT":
      return "edit advertiser";
      break;
    case "PIXEL":
      return "generate pixel";

    default:
      break;
  }
}

export default {
  getTableColumns,
  getRowProps,
  getPopup,
  setPopupContent,
};
