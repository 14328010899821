import React, { Component } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Box,
  Text,
  Layer,
  Heading,

  // Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Collapsible,
  Button,
} from "grommet";
import { AlertCard } from "./Alerts/AlertsComponents";


function RightSidebar(props) {
  const { visible, content, alerts } = props;
  const dispatch = useDispatch();
  if (!visible) return null;

  return (
    <Collapsible direction="horizontal" open={visible}>
      <Layer
        flex
        // width={content ? "40vw" : "50vw"}
        height={'100vh'}
        style={{
          width: '500px',
          // marginTop: '50px',
          height: 'calc(100vh)',
          borderRadius: '0',
          zIndex: 10,
        }}
        onClickOutside={() => dispatch({ type: "RIGHT_SIDEBAR_TOGGLE" })}
        modal={false}
        position={'right'}
        background={'rgba(33,33,33,0.95)'}
        // elevation="small"
        align="center"
        justify="center"
      >
        <Box pad='small'>
          <Box direction='row' align='center'>
            <Box flex>

              <Heading
                margin={'none'}
                level={3}
                style={{ fontWeight: '300', letterSpacing: '0.5px' }}
                color={'rgba(255,255,255,0.8)'}
              > ALERTS</Heading>
            </Box>

            <Button
              plain
              color='brand'
              hoverIndicator
              style={{ fontWeight: 300 }}
            >DISMISS ALL</Button>
          </Box>


          <Box pad={{ vertical: 'small' }} gap='small'>
            {alerts.map((alert) => {
              return (
                <AlertCard alert={alert} />

              )
            })}
          </Box>
        </Box>
      </Layer>
    </Collapsible>
  );

}

function mapStateToProps({ rightSidebar, user, alerts: alertsReducer }) {
  const { isLoggedIn } = user;

  // console.info({alertsReducer})
  const alerts = alertsReducer.config.length && alertsReducer.config.filter(c => c.enabled && c.summary);

  const visible = rightSidebar.visible && isLoggedIn;
  // pass a content when you want to change the content for the right sidebar
  // to inject the content - get the sidebarsAction Obj and use   injectContent func
  const content = rightSidebar.content;
  return { visible, content, alerts };
}

export default connect(mapStateToProps, null)(RightSidebar);
