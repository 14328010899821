import Ax from "axios";

// Fetch skadnRedirects data
export const fetchExtOffersData = (params = "") => {
  return async (dispatch, getState) => {
    dispatch({
      type: "EXT_OFFERS_DATA_LOADING",
      payload: {
        loading: 1,
      },
    });
    const { advertisers, publishers } = getState()

    const hasAdvertisers = advertisers.advertisers.length;
    const hasPublishers = publishers.publishers.length;

    try {
      const apiUrl = `/api/external-offers`;
      let parameters = {
        ...params,
        hasAdvertisers,
        hasPublishers
      }

      const toPatch = ['advertiser', 'countries']

      toPatch.forEach(key => {
        console.info({ key }, parameters[key])
        if (!parameters[key]) {
          parameters[key] = []
        } else if (typeof parameters[key] !== 'object') {
          parameters[key] = [parameters[key]].filter(o => o)
        }

      })

      const patch2 = ['affilitestStatus', 'affilitestNumRedirects']
        .forEach(k => {
          if (typeof parameters[k] === 'string') {
            if (!parameters[k])
              parameters[k] = -1
            else 
              parameters[k]= +parameters[k]
          }
        })



      const response = await Ax.post(apiUrl, parameters);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
    }
  };
};

//
export const filterOffers = (params) => {
  console.log(params);
  return async (dispatch) => {
    dispatch({
      type: "EXT_OFFERS_FILTERED",
      payload: {
        ...params,
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/offers`;
      const response = await Ax.post(apiUrl, params);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
      console.info(e.name);
    }
  };
};

export default {
  fetchExtOffersData,
  filterOffers,
};
