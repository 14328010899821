import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppBarActions from "../../actions/AppBarActions";
import Moment from "moment";
// import MomentTZ from 'moment-timezone';
import { DataTableV2 as DataTable } from "../../components/DataTable";
import ActionButtons from "../../components/ActionButtons";
import FiltersV3 from "../../components/Filters/Filters";
import { Text, Box, CheckBox, Tip } from "grommet";

import _ from "lodash";
import Loader from "../../components/Loader";
import Numeral from "numeral";
import Cell from "../../components/Cell";

const defaultRenderer = (propName, type = "", color = "") => (props) => {
  let value = props[propName.toLowerCase()];
  if (type === "numeral") {
    value = Numeral(value).format("0,0");
  } else if (type === "currency") {
    value = Numeral(value).format("0,0$");
  } else if (type === "date") {
    value = Moment(value).format("DD/MM/YY HH:mm");
  }

  return (
    <Cell
      start={"true"}
      flex
      color={color}
      type={type}
      // background={'background'}
    >
      <Tip
        plain
        content={
          <Box border round="small" background={"#E6E6E6"} pad="xsmall">
            <Text size="xsmall">
              {propName} - {value}
            </Text>
          </Box>
        }
      >
        <Box>{value}</Box>
      </Tip>
    </Cell>
  );
};

const fields = [
  // 'id',
  "publisher",
  "event_name",
  "event_key",
  "event_type",
  "event_value",
  "event_geos",
  // 'gen_event_slot'
];

const columns = [
  {
    property: "id_",
    header: "ID",
    render: defaultRenderer("id"),
  },
  {
    property: "app_bundle",
    header: "App Bundle",
    render: defaultRenderer("app_bundle"),
  },
  ...fields.map((property) => {
    return {
      property,
      header: property.split("_").join(" "),
      render: defaultRenderer(property),
    };
  }),
  {
    property: "actions",
    header: "",
    render: (props) => (
      <ActionButtons
        direction="row"
        buttons={[
          {
            name: "Edit event",
            icon: "Edit",
            triger: "",
            backgroundColor: "brand",
          },
        ]}
      />
    ),
  },
];

function Events(props) {
  const { fetchAppsFlyerAgencyData, appsflyer } = props;
  const { loading, reports, config, events } = appsflyer;

  const [firstLoad, setFirstLoad] = React.useState(false);
  const [filteredEvents, setFilteredEvents] = React.useState(events);
  const [queryCriteria, setQueryCriteria] = React.useState({
    searchForEvent: "",
  });

  React.useEffect(() => {
    async function getData() {
      await fetchAppsFlyerAgencyData({});
      setFirstLoad(true);
    }
    // console.info({events, firstLoad})

    if (!events.length && !firstLoad) {
      getData();
    }

    const { searchForEvent } = queryCriteria;
    console.info({ searchForEvent });
    if (searchForEvent) {
      setFilteredEvents(
        events.filter((e) => {
          return (
            e.app_bundle_name.toLowerCase().indexOf(searchForEvent) > -1 ||
            e.event_name.indexOf(searchForEvent) > -1 ||
            e.event_key.indexOf(searchForEvent) > -1
          );
        })
      );
    } else {
      setFilteredEvents(events);
    }
  }, [events, fetchAppsFlyerAgencyData, firstLoad, queryCriteria]);

  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading Appsflyer Events data...">
        <FiltersV3
          fields={[
            [
              {
                name: "searchForEvent",
                type: "search",
                width: "40%",
                placeholder: `Search`,
              },
              {
                name: "addBundleEvent",
                type: "button",
                label: "Add App Bundle Event",
              },
            ],
          ]}
          handleSubmit={setQueryCriteria}
          // firstLoad={firstLoad}
          // onFirstLoad={() => setFirstLoad(false)}
        />
        <Box
          style={{
            flex: 13,
            overflowX: "auto",
            paddingBottom: "5vh",
            // overflowY: "hidden",
          }}
        >
          <DataTable
            pin
            fill
            // key={groupBy + reportsData.length}
            // rowProps={getRowProps(reportsData)}
            background={{
              header: "dark-3",
              // body: ["#dff0d8"],
              footer: "light-3",
            }}
            sortable
            // onSort={({ direction, property }) =>
            //   setSortSettings({
            //     property,
            //     direction,
            //   })
            // }
            primaryKey={"id"}
            // {...(["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {})}
            data={filteredEvents}
            columns={columns}
            // sort={sortSettings}
            wideColumns={[2]}
          />
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const { appsflyer } = state;

  return { appsflyer, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AppsFlyerActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
