import React, { useEffect, useState } from "react";
import Moment from "moment";
import MomentTZ from "moment-timezone";

import Numeral from "numeral";
import _ from 'lodash';
import { connect, useDispatch } from "react-redux";
import {
    postFetcher,
    postDispatcher,
} from '../../utils/fetch'
import {
    useSearchParams,
    useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import FiltersV4 from "../../components/FiltersV4";
// import ConvertActions from '../../actions/AdjustDashboardActions';
import {
    Box,
    Grommet,
    DataTable,
    Tabs,
    Tab,
} from "grommet";
import { minimalTheme } from "../../themes/main-theme";

import { getFormattedNumeral, TableCell } from './TableHelpers'
// const DEFAULT_TIMEFRAME = "Yesterday"
// export const DEFAULT_GROUP_BYS = [
    //   { label: 'Date', value: 'date' },
    //   { label: 'Campaign', value: 'campaign' },
    //   { label: 'Publisher', value: 'publisher' },
    //   { label: 'App Bundle', value: 'app_bundle' },
    // ];



function getTableColumns() {


    const nameMap = {
        // id: {},
        date: {
            n: 'Date', search: true, render: ({ date }) => {
                return <TableCell primary={MomentTZ(date).format("YYYY-MM-DD")} />
            }
        },
        app_token: {
            n: 'App Name', search: true, render: ({ app_name, app_token }) => {
                return <TableCell primary={app_name} secondary={app_token} />
            }
        },
        publisher: {
            n: 'Publisher', search: true, render: ({ publisher, pubid }) => {
                return <TableCell primary={publisher} secondary={pubid} />
            }
        },
        campaign: {
            n: 'Campaign', search: true, render: ({ campaign }) => {
                return <TableCell primary={campaign} />
            }

        },
        country: {
            n: 'GEO', search: true, render: ({ country }) => {
                return <TableCell primary={country} />
            }
        },
        platform: {
            n: 'Platform', search: true, render: ({ platform }) => {
                return <TableCell primary={platform} />
            }
        },
        imps: { n: 'Imps', agg: true, render: ({ imps }) => <TableCell primary={getFormattedNumeral(imps)} /> },
        clicks: { n: 'Clicks', agg: true, render: ({ clicks }) => <TableCell primary={getFormattedNumeral(clicks)} /> },
        installs: { n: 'Installs', agg: true, render: ({ installs }) => <TableCell primary={getFormattedNumeral(installs)} /> },
        reg: { n: 'Reg', agg: true, render: ({ reg }) => <TableCell primary={getFormattedNumeral(reg)} /> },
        ftd: { n: 'FTD', agg: true, render: ({ ftd }) => <TableCell primary={getFormattedNumeral(ftd)} /> },
        revenue: {
            n: 'Revenue', agg: true, render: ({ revenue }) =>
                <TableCell primary={getFormattedNumeral(revenue, "$", ".0")} color="green" />
        },
        cost: {
            n: 'Cost', agg: true, render: ({ cost }) =>
                <TableCell primary={getFormattedNumeral(cost, "$", ".0")} color="red" />
        },
        net: {
            n: 'Net', agg: true, render: ({ net }) =>
                <TableCell primary={getFormattedNumeral(net, "$", ".0")} color="blue" />
        },

    }


    return Object.keys(nameMap)
        .map(property => {
            const cfg = nameMap[property]
            return {
                property,
                header: cfg.n,
                render: cfg.render,
                search: cfg.search,
                aggregate: cfg.agg ? "sum" : undefined,
                footer: cfg.agg ?
                    { aggregate: true }
                    : undefined
            }
        })
}




function useSyncAdjustFilters(filtersState, dispatch) {
    const [search, setSearch] = useSearchParams();
    const {
        dateFrom,
        dateTo,
        dateCustom,
        datePreset
    } = filtersState;





    useEffect(() => {
        console.info("preset", filtersState.datePreset)
        const allowedFilters = {
            groupBy: '',
            datePreset: ''
        };

        let query = {}

        for (const [param, val] of search) {
            // Check allowed params
            if (
                (allowedFilters[param] !== undefined) &&
                filtersState[param] !== val
            ) {
                query[param] = val
            }
            // Sync query
        }

        if (Object.keys(query).length) {
            dispatch({
                type: "ADJUST_FILTERS_SYNC",
                filters: query
            })
        }

        // console.info({existingParams})


    }, [
        search,
        dispatch,
        setSearch,
        filtersState
    ])
}


function AdjustDashboard(props) {
    const { adjust } = props;
    const {
        dateFrom,
        dateTo,
        publishers,
        countries,
        platforms,
        campaigns,
        apps
    } = adjust.filters;
    const dispatch = useDispatch();


    useEffect(() => {
        // Run! Like go get some data from an API.
        postDispatcher("/api/adjust/entity-data", {}, dispatch)
        console.info("======= ONLY ONCE ======")
    }, [dispatch]);


    // useSyncAdjustFilters(adjust.filters, dispatch)



    const { data, error } = useSWR(['/api/adjust/reports', {
        dateFrom,
        dateTo,
        publishers,
        countries,
        platforms,
        campaigns,
        apps
    }], postFetcher, {
        revalidateOnFocus: false,
        revalidateIfStale: false,
    })


    return (
        <Grommet theme={{
            ...minimalTheme,
            text: {
                extend: 'font-size:12px'
            }
        }}
            style={{ width: '100%', maxHeight: '100%', overflow: 'scroll' }}
        >


            {/* <Heading level="3" size='large' margin={{ vertical: "small" }}>Jobs Placements</Heading> */}

            <Box>
                <FiltersV4
                    state={adjust.filters}
                    onFilterChange={(filters) => {
                        dispatch({
                            type: "ADJUST_FILTERS_CHANGE",
                            filters
                        })
                    }}

                    dynamicFilters={[
                        { name: "Publishers", opts: adjust.publishers },
                        { name: "Campaigns", opts: adjust.campaigns },
                        { name: "Platforms", opts: adjust.platforms },
                        { name: "Countries", opts: adjust.countries },
                        { name: "Apps", opts: adjust.apps },

                    ]}
                />

                {data && data.reports && <DataTable
                    pin
                    key={"adjust_table"}
                    primaryKey={'id'}
                    data={data.reports}
                    sort={{
                        direction: "desc",
                        property: "revenue"
                    }}
                    groupBy={adjust.filters.groupBy || undefined}
                    columns={
                        getTableColumns()
                    }
                />}



            </Box>
        </Grommet >
    );
}


const mapStateToProps = (state, props) => {
    const { adjust } = state;

    return {
        adjust
    };
};

export default connect(mapStateToProps, {})(AdjustDashboard);

