const notificationInitial = { display: false, dismissMs: 20000 };

const getNotification = ({ message, title, status, dismissMs }) =>
  Object.assign(
    {},
    {
      display: true,
      dismissMs: dismissMs || 5000,
      title,
      status: status || "ok",
      message,
    }
  );
export default function (state = notificationInitial, action) {
  switch (action.type) {
    case "AUTHENTICATE": {
      const message = `Hello ${action.payload.name} :)`;
      const title = "Login Successfull";
      return getNotification({ message, title });
    }

    case "NEW_USER_CREATED": {
      const message = `User ${action.payload.user[0].username} created :)`;
      const title = "New User";
      return getNotification({ message, title });
    }

    case "USER_UPDATED": {
      const message = `User ${action.payload.user[0].username} updated :)`;
      const title = "User Updated";
      return getNotification({ message, title });
    }

    case "DEAUTHENTICATE": {
      const message = ``;
      const title = "Logout Successfull";
      return getNotification({ message, title });
    }
    case "AF_APP_BUNDLE_NAME_UPDATED":
    case "AF_ADN_APP_BUNDLE_NAME_UPDATED":
    case "AF_APP_BUNDLE_CREATED":
    case "AF_ADN_APP_BUNDLE_CREATED":
    case "AF_APP_BUNDLE_EVENT_UPDATED":
    case "AF_ADN_APP_BUNDLE_EVENT_UPDATED":
    case "AF_APP_BUNDLE_EVENT_CREATED":
    case "AF_ADN_APP_BUNDLE_EVENT_CREATED":
    case "AF_ADN_APP_BUNDLE_EVENT_NOT_UPDATED":
    case "AF_ADN_APP_BUNDLE_EVENT_NOT_CREATED":
    case "AF_ADN_BUNDLE_DUPLICATED":
    case "AF_BUNDLE_DUPLICATED":
    case "AF_APP_BUNDLE_CREATE_ERROR":
    case "UPDATE_ADJUST_EVENT":
    case "CREATE_ADJUST_EVENT":
    case "NEW_ADJUST_CONFIG":
    case "TRACKRC_CONN_LOST": {
      const { notification } = action;
      return getNotification(notification);
    }
    case "AF_APP_BUNDLE_DATA_RESET": {
      const { notification } = action.payload;
      return getNotification(notification);
    }
    case "AF_AGENCY_FETCH_ALL_DATA": {
      const title = "AppsFlyer Data Loaded";
      return getNotification({ title, dismissMs: 2000 });
    }
    case "DISMISS_NOTIFICATION":
      return Object.assign({}, notificationInitial);
    case "NOTIFICATION":
      return Object.assign(
        {},
        notificationInitial,
        { display: true },
        action.payload
      );
    default:
      return state;
  }
}
