import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Moment from "moment";
// import { DataTableV2 as DataTable } from "../../components/DataTable";
import ReadSelect from "../../components/ReadSelectField";
import CountryJSON from "../../constants/country-codes.json";
import { Add, Search, Subtract } from "grommet-icons";
import ObjHash from "object-hash";

import AdjustActions from "../../actions/AdjustActions";
import {
  Box,
  Layer,
  DataTable,
  Heading,
  Select,
  Button,
  // Grommet,
  // Button,
  // Form,
  // List,
  // Tip,
  // Text,
  // Spinner,
  // CheckBox,
  TextInput,
  // Select,
  // TextArea,
  // FormField,
  // Stack,
  // RadioButtonGroup,
} from "grommet";
import { NumberInput } from "grommet-controls";

import { getFormattedNumeral, TableCell } from "./TableHelpers";
import ReadField from "../../components/ReadField";

const GEO_OPTS = CountryJSON.map((o) => o["alpha-2"]);

function isEventValid(event) {
  if (!event) return false;
  return (
    event.event_type && event.event_name && event.event_key && event.app_token
  );
}

function EditEventLayer({
  event,
  config,
  publishers,
  close,
  onUpdate,
  onCreate,
}) {
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentConfig, setCurrentConfig] = useState(null);
  const [eventIsValid, setValidEvent] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(false);

  useEffect(() => {
    if (event) {
      if (!currentEvent || (currentEvent && event.id !== currentEvent.id)) {
        setCurrentEvent({
          ...event,
          publishers: event.publishers.split(","),
          event_geos: event.event_geos.split(",").map((s) => s.toUpperCase()),
        });
        const currentConfig =
          config.find((c) => c.app_token === event.app_token) || {};
        setCurrentConfig(currentConfig);
        if (!event.id) {
          setIsNewEvent(true);
        }
      }
    }
    setValidEvent(isEventValid(currentEvent));
  }, [event, currentEvent, config]);

  if (!currentEvent || !event) {
    return null;
  }

  const appBundleOpts = config.map(({ app_token, app_name }) => ({
    app_token,
    app_name,
  }));

  // console.info({eventIsValid})

  return (
    <Layer onClickOutside={close}>
      <Box pad='medium'>
        {/* <Heading level='6' pad='none' margin='none'>
          Edit Event {event.id}
        </Heading> */}
        <Heading
          level='3'
          margin={{ vertical: "small" }}
          style={{ fontWeight: "bold" }}
        >
          {!isNewEvent
            ? `Edit Adjust Event #${event.id}`
            : "Create New Adjust Event"}
        </Heading>
        <ReadField
          name='Event ID'
          //  value={isDuplicate ? "00" : id}
          value={event.id}
        />
        <ReadField
          name='App Name'
          //  value={isDuplicate ? "00" : id}
          value={event.app_name}
        />
        <ReadField
          name='App Token'
          //  value={isDuplicate ? "00" : id}
          value={event.app_token}
        />
        <ReadSelect
          name='App Bundle'
          options={appBundleOpts}
          labelKey='app_name'
          valueKey='app_token'
          multi={false}
          clear={false}
          value={appBundleOpts.find(
            (a) => currentEvent.app_token === a.app_token
          )}
          onChange={({ option }) => {
            const newConfig =
              config.find((c) => c.app_token === option.app_token) || {};
            setCurrentEvent((e) => ({
              ...e,
              app_token: option.app_token,
              app_name: newConfig.app_name,
            }));
            setCurrentConfig(newConfig);
          }}
          // value={event_name}
        />
        <ReadSelect
          name='Event Type'
          clear={false}
          options={["revenue", "cost", "general", "ftd", "registration"]}
          value={currentEvent.event_type}
          onChange={({ option: event_type }) => {
            let update = { event_type };
            if (["registration", "ftd"].indexOf(event_type) > -1) {
              update.event_value = 0;
            }
            setCurrentEvent((e) => ({
              ...e,
              ...update,
            }));
          }}
          // value={event_name}
        />
        <ReadField
          name='Event Name'
          // value={event_name}
        >
          <TextInput
            // options={['revenue', 'cost', 'general']}
            value={currentEvent.event_name}
            onChange={({ target }) => {
              setCurrentEvent((e) => ({ ...e, event_name: target.value }));
            }}
          />
        </ReadField>
        <ReadSelect
          name='Event Key'
          clear={false}
          value={currentEvent.event_key}
          options={[
            ...(currentConfig.in_app_events_json || []).map((o) =>
              o.name.toUpperCase()
            ),
          ]}
          onChange={({ option: event_key }) => {
            // console.info(event_key)
            const { token: event_token } =
              (currentConfig.in_app_events_json || []).find(
                (o) => o.name.toUpperCase() === event_key
              ) || {};
            setCurrentEvent((e) => ({ ...e, event_key, event_token }));
          }}
          // value={event_name}
        />
        <ReadField name='Event Value'>
          <NumberInput
            step={0.1}
            // disabled={
            //   disabled || ["registration", "ftd"].indexOf(event_type) > -1
            // }
            decimals={3}
            value={
              ["registration", "ftd"].indexOf(currentEvent.event_type) > -1
                ? 0
                : currentEvent.event_value
            }
            onChange={({ target }) => {
              console.info("number", target.value);
              if (target.value >= 0) {
                setCurrentEvent((e) => ({
                  ...e,
                  event_value:
                    ["registration", "ftd"].indexOf(currentEvent.event_type) >
                    -1
                      ? 0
                      : target.value,
                }));
              }
            }}
            itemProp=''
            addIcon={<Add size='small' />}
            subtractIcon={<Subtract size='small' />}
          />
        </ReadField>
        <ReadSelect
          multi
          closeOnChange={false}
          name='Publishers'
          labelKey='name'
          valueKey={"id"}
          value={currentEvent.publishers.map((pid) =>
            publishers.find((p) => p.id.toString() === pid)
          )}
          options={[
            ...currentEvent.publishers.map((pid) =>
              publishers.find((p) => p.id.toString() === pid)
            ),
            ...publishers.filter(
              (p) => !currentEvent.publishers.includes(`${p.id}`)
            ),
          ].filter((o) => o)}
          onChange={({ value: publishers }) => {
            setCurrentEvent((e) => ({
              ...e,
              publishers: publishers ? publishers.map((p) => `${p.id}`) : [],
            }));
          }}
        />

        <ReadSelect
          multi
          closeOnChange={false}
          name='GEOS'
          value={currentEvent.event_geos}
          options={[
            ...currentEvent.event_geos,
            ...GEO_OPTS.filter((geo) => !currentEvent.event_geos.includes(geo)),
          ].filter((o) => o)}
          onChange={({ value: event_geos }) => {
            setCurrentEvent((e) => ({ ...e, event_geos }));
          }}
        />
        <ReadSelect
          multi
          closeOnChange={false}
          name='Platforms'
          value={currentEvent.event_platforms}
          options={["ios", "android"].filter((o) => o)}
          onChange={({ value: event_platforms }) => {
            setCurrentEvent((e) => ({ ...e, event_platforms }));
          }}
        />

        <Button
          primary
          margin={"small"}
          label='Save'
          disabled={!eventIsValid}
          // disabled={!(app_name && app_bundle)}
          onClick={async () => {
            let fmtEvent = { ...currentEvent };

            const toFormat = ["publishers", "event_geos", "platforms"];

            toFormat.forEach((key) => {
              if (typeof fmtEvent[key] === "object") {
                fmtEvent[key] = fmtEvent[key].join(",");
              }
            });

            const hasDiff = ObjHash(fmtEvent) !== ObjHash(event);
            console.info("FMT EVENT", fmtEvent, event);
            console.info("hash", ObjHash(fmtEvent), ObjHash(event));

            if (!hasDiff) {
              alert("NO Changes");
            } else {
              if (eventIsValid) {
                if (isNewEvent) {
                  onCreate(fmtEvent);
                } else {
                  // on Update
                  // console.info(fmtEvent)
                  onUpdate(fmtEvent);
                }
              }
              close();
            }
          }}
        />
      </Box>
    </Layer>
  );
}

function AdjustEvents(props) {
  const {
    events,
    config,
    all_publishers,
    getAdjustEvents,
    updateAdjustEvent,
    createAdjustEvent,
  } = props;

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filterText, setFilterText] = useState(""); // Search key for filter the events table

  useEffect(() => {
    getAdjustEvents();
  }, []);

  // if (!events) return null;
  //
  return (
    <Box
      flex
      fill
      width='100%'
      margin='none'
      overflowY='auto'
      alignSelf='center'
    >
      <Box direction='row' justify='between' align='center' pad='small'>
        <Box direction='row' flex>
          <TextInput
            onChange={(e) => setFilterText(e.target.value)}
            placeholder='search ...'
            value={filterText}
            icon={<Search />}
          />
        </Box>
        <Button
          primary
          margin={"small"}
          label='Create New Event'
          disabled={false}
          // disabled={!(app_name && app_bundle)}
          onClick={async () => {
            setSelectedEvent({
              id: 0,
              app_token: "",
              event_key: "",
              event_platforms: "",
              event_value: "",
              event_type: "",
              event_geos: "",
              publishers: "",
            });
          }}
        />
      </Box>
      <EditEventLayer
        close={() => setSelectedEvent(null)}
        event={selectedEvent}
        config={config}
        publishers={all_publishers}
        onUpdate={updateAdjustEvent}
        onCreate={createAdjustEvent}
      />
      <Box>
        {events && (
          <DataTable
            columns={getTableColumns()}
            data={
              //
              // HERE THE EVENTS DATA IS FILTERED
              //
              events.filter(
                (event) =>
                  event.app_name
                    .toLowerCase()
                    .includes(filterText.toLowerCase()) ||
                  event.app_token
                    .toLowerCase()
                    .includes(filterText.toLowerCase())
              )
            }
            sort={{ direction: "desc", property: "id" }}
            onClickRow={({ datum }) => setSelectedEvent(datum)}
            // groupBy={"event_name"}
          />
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state, props) => {
  const { adjust } = state;
  const { events, config, all_publishers } = adjust;

  return {
    config,
    events,
    all_publishers,
  };
};

export default connect(mapStateToProps, { ...AdjustActions })(AdjustEvents);

function getTableColumns() {
  const nameMap = {
    // id: {},
    id: {
      n: "ID",
      search: true,
      render: ({ id }) => {
        return <TableCell primary={id} />;
      },
    },
    app_token: {
      n: "App Name",
      search: true,
      render: ({ app_name, app_token }) => {
        return <TableCell primary={app_name} secondary={app_token} />;
      },
    },
    event_name: {
      n: "Event Name",
      search: true,
      render: ({ event_name, event_type }) => {
        return (
          <TableCell
            primary={event_name}
            secondary={`${event_type}`.toUpperCase()}
          />
        );
      },
    },
    event_key: {
      n: "Event Key",
      search: true,
      render: ({ event_key, event_token }) => {
        return <TableCell primary={event_key} secondary={event_token} />;
      },
    },
    event_value: {
      n: "Event Value",
      search: true,
      render: ({ event_value }) => {
        return (
          <TableCell
            primary={
              +event_value ? getFormattedNumeral(event_value, "$", ".0") : "-"
            }
          />
        );
      },
    },
    publisher: {
      n: "Publisher",
      search: true,
      render: ({ publishers }) => {
        return <TableCell primary={publishers} />;
      },
    },
    country: {
      n: "GEO",
      search: true,
      render: ({ event_geos }) => {
        return <TableCell primary={event_geos} />;
      },
    },
    platform: {
      n: "Platform",
      search: true,
      render: ({ event_platforms }) => {
        return <TableCell primary={event_platforms} />;
      },
    },
  };

  return Object.keys(nameMap).map((property) => {
    const cfg = nameMap[property];
    return {
      property,
      header: cfg.n,
      render: cfg.render,
      search: cfg.search,
    };
  });
}
