import Ax from "axios";

// Fetch skadnRedirects data
export const getInitialStatisticsData = (params = "") => {
  return async (dispatch) => {
    dispatch({
      type: "STATISTICS_DATA_LOADING",
      payload: {
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/statistics`;
      const response = await Ax.post(apiUrl, params);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
    }
  };
};

//
export const filterStatistics = (params) => {
  return async (dispatch) => {
    console.log(params);
    dispatch({
      type: "STATISTICS_DATA_FILTERED",
      payload: {
        ...params,
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/statistics`;
      const response = await Ax.post(apiUrl, params);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
      console.info(e.name);
    }
  };
};

export default {
  getInitialStatisticsData,
  filterStatistics,
};
