import React from "react";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";

const Button = styled.button`
  display: inline-block;
  position: absolute;
  bottom: 0px;
  right: ${(props) => (props.mobile ? "0px" : "20px")};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  display: block;
  cursor: pointer;
  z-index: 10;
  border: none;
  outline: none;
  -webkit-box-shadow: 5px 5px 15px -7px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 5px 5px 15px -7px rgba(0, 0, 0, 0.7);
  box-shadow: 5px 5px 15px -7px rgba(0, 0, 0, 0.7);
  background-color: #3c8dbc;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #2b6482;
  }
  &:active {
    transform: scale(0.9);
  }
`;

export default function FloatingButton(props) {
  const { onClick, mobile } = props;

  return (
    <Button onClick={onClick} mobile={mobile}>
      <AddIcon style={{ color: "white" }} />
    </Button>
  );
}
