import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // height: "85%",
    margin: (props) => (props.browser.width < 1201 ? "4% 1%" : "1%"),
    borderRadius: "5px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
  },
  overview: {
    // margin: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
    overflowY: "",
    minHeight: (props) =>
      props.browser.width < 1201
        ? "20%"
        : props.browser.width > 1601
        ? "18%"
        : "25%",
    overflowX: "auto",
    borderBottom: "1px solid lightgray",
    "&::-webkit-scrollbar": {
      //   display: "none",
      background: "transparent",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        background: "rgb(0,0,0,0.1)",
      },
    },
  },
  overviewChart: {
    display: "flex",
    minWidth: "30%",
    // height: "80%",
    // backgroundColor: "green",
    margin: "0 1%",
    border: "1px solid lightgray",
    borderRadius: "5px",
  },
  info: {
    fontSize: "100%",
    color: "#595959",
    position: "absolute",
    top: "2%",
    right: "1.5%",
  },
  box: {
    display: "flex",
    position: "relative",
    height: "78%",
  },
  summeryBox: {
    minWidth: "17ch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  boxName: {
    fontWeight: "400",
    color: "#595959",
    marginLeft: "10%",
    marginTop: "7%",
  },
  boxValue: {
    fontSize: "150%",
    fontWeight: "500",
    margin: "7% 0",
    marginLeft: "10%",
  },
  boxSub: {
    marginLeft: "10%",
    marginBottom: "7%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "22%",
    borderTop: "1px solid lightgray",
  },
  title: {
    margin: "2% 0",
    fontWeight: "400",
  },
});

function NewsPaper(props) {
  const {
    classes,
    newsPaperColumns,
    title,
    onTitleClick,
    onInfoHover,
    onInfoClick,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        {newsPaperColumns &&
          newsPaperColumns.map((box, i) => {
            let border;
            border =
              newsPaperColumns.length === i + 1 && newsPaperColumns.length > 1
                ? "1px dashed lightgray"
                : "none";
            return (
              <div
                className={classes.summeryBox}
                style={{ borderLeft: border }}
              >
                <div className={classes.boxName}>{box.name}</div>
                <div className={classes.boxValue}>{box.value}</div>
                <div className={classes.boxSub}>{box.sub}</div>
              </div>
            );
          })}

        <InfoIcon className={classes.info} onClick={() => onInfoClick()} />
      </div>
      <div className={classes.titleContainer} onClick={() => onTitleClick()}>
        <div className={classes.title}>{title}</div>
      </div>
    </div>
  );
}

function NewsPapers(props) {
  const { newsPapers, browser } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.overview}>
      {newsPapers &&
        newsPapers.map((newsPaper) => {
          return (
            <NewsPaper
              classes={classes}
              title={newsPaper.title}
              newsPaperColumns={newsPaper.newsPaperColumns}
            />
          );
        })}
    </div>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  return { browser };
}

export default connect(mapStateToProps)(NewsPapers);
