import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import CanvasJSReact from "../../../assets/canvasjs.react";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    padding: "3% 0",
    margin: "7% 0%",
  },
}));

export default function Chart() {
  const classes = useStyles();

  const options = {
    animationEnabled: true,
    theme: "light2",
    title: {
      //   text: "Stock Price of BMW - March 2018",
    },
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: "column", //change type to bar, line, area, pie, etc
        //indexLabel: "{y}", //Shows y value on all Data Points
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: [
          { x: 10, y: 71 },
          { x: 20, y: 55 },
          { x: 30, y: 50 },
          { x: 40, y: 65 },
          { x: 50, y: 71 },
          { x: 60, y: 68 },
          { x: 70, y: 38 },
          { x: 80, y: 92, indexLabel: "Highest" },
          { x: 90, y: 54 },
          { x: 100, y: 60 },
          { x: 110, y: 21 },
          { x: 120, y: 49 },
          { x: 130, y: 36 },
        ],
      },
    ],
  };
  const CanvasJS = CanvasJSReact.CanvasJS;
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  return (
    <div className={classes.chartContainer}>
      <div
        style={{
          height: "90%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          //   borderBottom: "1px solid lightgray",
        }}
      >
        <div
          style={{
            margin: "0% 1%",
            color: "#595959",
            fontSize: "120%",
          }}
        >
          Chart Name
        </div>
        <InfoIcon
          style={{
            fontSize: "100%",
            color: "#595959",
            margin: "0% 1%",
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "5px",
          backgroundColor: "white",
          height: "90%",
          width: "98%",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "0.5% 0",
        }}
      >
        <CanvasJSChart
          options={options}
          /* onRef = {ref => this.chart = ref} */
        />

        <div></div>
      </div>
    </div>
  );
}
