import React, { Component } from "react";
import Moment from "moment";
import {
    Box,
    TextInput,
    DateInput,
    Text,
    Collapsible,
    Select,
    CheckBox,
} from "grommet";

import {
    Filter as FilterIcon,
    DocumentDownload,
    TableAdd,
    LinkNext,
    Sort,
    Download,
} from "grommet-icons";
import XButton from "../../components/xComponents/Button";
import SearchInput from "../../components/xComponents/SearchInput";

class Filters extends Component {
    constructor(props) {
        super(props);
        // Component State
        this.state = {
            criteria: {
                level: "level1",
                groupByEntity: "advertisers",
                timezoneOffset: "+2",
                api: "",
                rank: "",
                subpub: "",
                showSplit: false,
                status: "all",
                internalStatus: "all",
                fromDate: Moment(new Date().toString()).format("YYYY-MM-DD"),
                toDate: Moment(new Date().toString()).format("YYYY-MM-DD"),
                hasConversions: false,
                entityFilters: {},
                entityName: undefined, // advertiser/publisher/offer...
            },
        };

        // this.onFilterData = this.onFilterData.bind(this);
        this.handleFiltersChange = this.handleFiltersChange.bind(this);
    }

    // handle fields change on add advertiser layer
    handleFiltersChange(e) {
        const { criteria } = this.state;
        const { value, name, type, checked } = e.target;

        console.log(name);
        console.log(type);
        console.log(e.value);

        let newValue = (e.option && e.option) || e.value ? e.value.join() : value;
        console.log(newValue);

        if (type === "checkbox") {
            newValue = checked;
        }

        const ENTITIY_FILTERS = [
            "advertiser_id",
            "publisher_id",
            "placement_id",
            "offer_id",
            "tag_id",
        ];

        // update new values
        let newValues = {};

        if (ENTITIY_FILTERS.findIndex((e) => e === name) >= 0) {
            newValues = {
                ...criteria,
                entityFilters: {
                    ...criteria.entityFilters,
                    [name]: newValue,
                },
            };
        } else {
            newValues = {
                ...criteria,
                ...{ [name]: newValue },
            };
        }

        this.setState({ criteria: newValues });
    }

    render() {
        // function for handling filter/submit button
        const { handleSubmit, open, data } = this.props;
        // fields values
        const {
            hasConversions,
            fromDate,
            toDate,
            groupByEntity,
            level,
            status,
            internalStatus,
            timezoneOffset,
            api,
            rank,
            subpub,
            showSplit,
        } = this.state.criteria;

        const TIMEZONE_OFFSETS = [
            "-12",
            "-11",
            "-10",
            "-9",
            "-8",
            "-7",
            "-6",
            "-5",
            "-4",
            "-3",
            "-2",
            "-1",
            "0",
            "+1",
            "+2",
            "+3",
            "+4",
            "+5",
            "+6",
            "+7",
            "+8",
            "+9",
            "+10",
            "+11",
            "+12",
        ];

        const readFieldTheme = {
            fontWeight: "400",
            fontSize: "10px",
            minWidth: "55px",
        };
        console.info({ open })
        return (
            <Box pad='small'>
                <Collapsible
                    open={open}
                >
                    <Box
                        direction="row"
                        pad="small"
                        gap="medium"
                        justify="center"
                        background=" #f5f5f5"
                        // alignSelf="center"

                        margin="xsmall"
                        //   height="20%"

                        border
                    //   style={{
                    //     boxShadow: "inset 0px 0px 5px 0px rgba(0,0,0,0.55)",
                    //   }}
                    >
                        <Box gap="medium" align="center">
                            <Box direction="row" gap="small" align="center">
                                <Box direction="row" gap="small" align="center">
                                    <Text size="12px">Date</Text>
                                    <DateInput
                                        // disabled
                                        name="fromDate"
                                        format="mm/dd/yyyy"
                                        value={fromDate}
                                        onChange={({ value: date }) => {
                                            this.handleFiltersChange({
                                                target: {
                                                    value: Moment(date).format("YYYY-MM-DD"),
                                                    name: "fromDate",
                                                    type: "date",
                                                },
                                            });
                                        }}
                                        style={{
                                            height: "4vh",
                                            backgroundColor: "white",
                                            fontWeight: "100",
                                            fontSize: "10px",
                                        }}
                                    />
                                    <DateInput
                                        // disabled
                                        name="toDate"
                                        format="mm/dd/yyyy"
                                        value={toDate}
                                        onChange={({ value: date }) => {
                                            this.handleFiltersChange({
                                                target: {
                                                    value: Moment(date).format("YYYY-MM-DD"),
                                                    name: "toDate",
                                                    type: "date",
                                                },
                                            });
                                        }}
                                        style={{
                                            height: "4vh",
                                            backgroundColor: "white",
                                            fontWeight: "100",
                                            fontSize: "10px",
                                        }}
                                    />
                                    <Select
                                        name="timezoneOffset"
                                        value={timezoneOffset}
                                        placeholder="gmt zone..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        options={TIMEZONE_OFFSETS}
                                    />
                                </Box>
                                <Box direction="row" gap="small" align="center">
                                    <Text size="12px">ID</Text>

                                    <SearchInput
                                        style={{ width: "100%" }}
                                        name="offer_id"
                                        placeholder="offer id..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        options={data.map((d) => d.offer_id.toString())}
                                    />

                                    {/* Placement name  */}

                                    <TextInput
                                        name="tag_id"
                                        placeholder="tag id..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                    />

                                    <TextInput
                                        name="publisher_id"
                                        placeholder="publisher id..."
                                        // suggestions={data.map((r) => r.publisher_id)}
                                        onChange={(e) => {
                                            this.handleFiltersChange(e);
                                        }}
                                        suggestions={data.map((d) => d.publisher_id)}
                                    />
                                    <TextInput
                                        name="advertiser_id"
                                        placeholder="advertiser id..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                    />
                                    <TextInput
                                        placeholder="placement id..."
                                        name="placement_id"
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        suggestions={data.map((d) => d.placement_id)}
                                    />

                                    <TextInput
                                        disabled
                                        name="subpub"
                                        placeholder="subpub..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                    />
                                </Box>
                            </Box>
                            <Box
                                fill="horizontal"
                                direction="row"
                                gap="medium"
                                align="center"
                            >
                                <Box direction="row" gap="small" align="center">
                                    <Text size="12px">Group by</Text>
                                    <Select
                                        // disabled
                                        name="groupByEntity"
                                        placeholder="group by..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        value={groupByEntity}
                                        options={[
                                            "advertisers",
                                            "publishers",
                                            "placements",
                                            "offers",
                                        ]}
                                        style={{ height: "4vh", backgroundColor: "white" }}
                                    />
                                    <Select
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        name="level"
                                        value={level}
                                        options={["level1", "level2"]}
                                        placeholder="level"
                                    />
                                    <Select
                                        disabled
                                        // onChange={(event) => this.handleFiltersChange(event)}
                                        name="traffic_allowd"
                                        options={[
                                            "Content locking",
                                            "Display",
                                            "Incentive",
                                            "Non incentive",
                                            "Email",
                                        ]}
                                        placeholder="Has Placements"
                                    />

                                    <TextInput
                                        disabled
                                        name="country"
                                        placeholder="country..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                    />
                                    <TextInput
                                        disabled
                                        name="a5"
                                        placeholder="a5..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                    />

                                    <TextInput
                                        disabled
                                        name="rank"
                                        placeholder="rank..."
                                        onChange={(e) => this.handleFiltersChange(e)}
                                    />

                                    {/* CheckBoxes  */}
                                    <CheckBox
                                        label="Has conversions"
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        name="hasConversions"
                                        checked={hasConversions}
                                    />
                                    {/* showSplit  */}
                                    <CheckBox
                                        label="show split"
                                        onChange={(e) => this.handleFiltersChange(e)}
                                        name="showSplit"
                                        checked={showSplit}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {/* SUBMIT BUTTON  */}
                        <Box
                            align="center"
                            gap="small"
                            justify="around"
                        // background="brand"
                        >
                            <XButton
                                primary
                                icon={<LinkNext size="18px" />}
                                height="100%"
                                //   style={{ width: "100px", height: "50%" }}
                                onClick={() => handleSubmit(this.state.criteria)}
                            />
                        </Box>
                    </Box>
                </Collapsible>
            </Box>
        );
    }
}

export default Filters;