import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Text, Select, Tip, Button } from "grommet";
import Pagination from "../../components/PaginationComponent";
import PaginationButtons from "../../components/PaginationButtons";

function PageFotter(props) {
  const {
    browser,
    totalPages,
    page,
    onChangeSelect,
    onChangePagination,
    pageSize,
    buttons,
  } = props;
  const isMobile = browser.greaterThan.medium;
  return (
    <div>
      {isMobile ? (
        <Box
          background={"light-1"}
          direction="row"
          justify="between"
          align={"center"}
          pad={{ right: "30px", top: "8px", bottom: "8px" }}
        >
          <Box direction="row" align="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, nextPage) => onChangePagination(nextPage)}
            />
            <Box style={{ maxWidth: "100px" }}>
              <Select
                size={"small"}
                options={[50, 100, 200, 250, 500]}
                value={pageSize}
                onChange={({ option: nextPageSize }) =>
                  onChangeSelect(nextPageSize)
                }
              />
            </Box>
          </Box>

          <Box alignSelf="end" alignContent="end">
            <PaginationButtons
              buttons={buttons}
              browser={browser.greaterThan.medium}
            />
          </Box>
        </Box>
      ) : (
        <Box
          background={"light-1"}
          direction="row"
          justify="between"
          align={"center"}
          height="50px"
          pad="5px"
          // style={{ position: "absolute", bottom: 0 }}
        >
          <Box direction="row" align="center">
            <Pagination
              showFirstButton
              showLastButton
              siblingCount={0}
              // boundaryCount={0}
              count={totalPages}
              page={page}
              size="small"
              onChange={(e, nextPage) => onChangePagination(nextPage)}
            />
          </Box>
          <Box style={{ maxWidth: "20%" }}>
            <Select
              size={"small"}
              options={[50, 100, 200, 250, 500]}
              value={pageSize}
              onChange={({ option: nextPageSize }) =>
                onChangeSelect(nextPageSize)
              }
            />
          </Box>

          <PaginationButtons buttons={buttons} />
        </Box>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { browser } = state;
  return { browser };
}

export default connect(mapStateToProps)(PageFotter);
