import React, { useEffect, useState,useCallback } from "react";
import useSWR from "swr";
import Ax from "axios";
import ObjHash from "object-hash";
import _ from "lodash";
import { debounce } from "lodash";

// import ConvertActions from '../../actions/AlertsDashboardActions';
import {
  Box,
  Grommet,
  Button,
  Form,
  List,
  Tip,
  Text,
  Layer,
  Spinner,
  Heading,
  TextInput,
  DataTable,
  Select,
  FormField,
} from "grommet";
import { FormEdit, FormAdd, Search } from "grommet-icons";
import { minimalTheme } from "../../themes/main-theme";
import Loader from "../../components/Loader";

const fetcher = (url, payload = {}) =>
  Ax.post(url, payload).then(({ data }) => {
    return data;
  });

const AppsFlyerPublishers = () => {
  let { data, error, mutate } = useSWR(
    ["/api/appsflyer/publisher-groups", { action: "get" }],
    fetcher
  );

  const [edit, setEdit] = useState(null);
  const [filterText, setFilterText] = useState("");


  if (data && data.publisherGroups) {
    let groups = _.groupBy(data.publisherGroups, "publisher_group_id");
    // console.info(groups)
    groups = Object.values(groups).map((group) => ({
      id: group[0].publisher_group_id,
      name: group[0].publisher_group,
      publishers: group.map((g) => ({
        id: g.id,
        publisher: g.publisher,
      })),
    }));

    data.groups = groups;
    // console.info({ groups });
  }

  return (
    <Grommet
      theme={{
        ...minimalTheme,
        text: {
          extend: "font-size:12px",
        },
      }}
      style={{ width: "100%", maxHeight: "100%", overflow: "scroll" }}
    >
      <Loader text='Loading data...' processing={!data}>
        <Box direction='row' justify='between' align='center' pad='medium'>
          <TextInput
            onChange={(e) => setFilterText(e.target.value)}
            placeholder='search ...'
            value={filterText}
            icon={<Search />}
            style={{ width: "Auto" }}
          />

          <Button
            primary
            icon={<FormAdd />}
            size='small'
            label='New Publisher Group'
            // color="accent"
            // secondary
            onClick={() => setEdit("__NEW__")}
            style={{ whiteSpace: "nowrap" }}
          />
        </Box>

        <EditPublisher
          data={data}
          edit={edit}
          onCancel={() => setEdit(null)}
          onDone={() => {
            setEdit(null);
            mutate();
          }}
        />
        <PublishersTable
          data={data}
          filterText={filterText}
          onEdit={(publisherGroup) => {
            setEdit(publisherGroup);
          }}
          setEdit={setEdit}
        />
      </Loader>
    </Grommet>
  );
};

const EditPublisher = ({ edit, data = {}, onCancel, onDone }) => {
  const [publisherGroup, setPg] = useState(null);
  const [publisherOpts, setPubOpts] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [selectedPubs, setSelectedPubs] = useState(null);
  const [hash, setHash] = useState({ original: null, last: null });
  const [searchText, setSearch] = useState("");
  const [asyncSearchResults, setAsyncSearchResults] = useState([]);
  
  const isNew = edit === "__NEW__";


  const asyncPubSearch = async (search) => {
    const asyncPubs = await fetcher("/api/appsflyer/publisher-groups", { action: "pubsearch", search })
    setAsyncSearchResults(asyncPubs);
  };

  const debouncedPubSearch = useCallback(debounce(asyncPubSearch, 300), [asyncPubSearch]);


  console.info({publisherOpts})
  console.info({asyncSearchResults})


  useEffect(() => {
    if (edit && data.groups) {
      const pg = !isNew
        ? data.groups.find((g) => g.name === edit)
        : {
          id: 0,
          name: "",
          publishers: [],
        };

      const ho = {
        original: ObjHash(pg),
      };
      const pubs = _.uniqBy([...data.publishers], "publisher");
      setPg(pg);
      setHash(ho);
      setSelectedPubs(
        data.publishers.filter((p) => p.publisher_group_id === pg.id)
      );
      setPubOpts(pubs);
    }
  }, [edit, setPg, data, isNew, asyncSearchResults]);

  useEffect(() => {
    if (data.publishers && selectedPubs) {
      const pubs = _.uniqBy([...asyncSearchResults,...selectedPubs, ...data.publishers], "id");
      const exp = new RegExp(searchText, "i");

      const val = !searchText
        ? pubs
        : pubs.filter(({ publisher }) => exp.test(publisher));

      setPubOpts(val);
    }
  }, [searchText, selectedPubs, data, asyncSearchResults]);

  
  // useEffect(() => {
  //   setPubOpts(p =>{
  //     console.info({asyncSearchResults, p})
  //      return _.uniqBy([...asyncSearchResults, ...p], "id")
  //     });
  // }, [asyncSearchResults]);

  useEffect(() => {
    setHash((h) => ({
      ...h,
      last: ObjHash(publisherGroup),
    }));
  }, [publisherGroup]);

  if (!edit || !publisherGroup) return null;

  console.info("OPTS", publisherOpts);
  return (
    <Layer
      onEsc={() => onCancel()}
      onClickOutside={() => onCancel()}
      style={{ marginTop: -100 }}
      pad='small'
    >
      <Box style={{ minWidth: "50vw" }} size='large' pad='medium'>
        <Heading level={3} margin={{ top: "small" }}>
          {isNew ? "Create" : "Edit"} Publisher Group{" "}
          <i>{publisherGroup.name}</i>
        </Heading>

        <Box pad='small' gap='small'>
          <FormField label='PG Name'>
            <TextInput
              value={publisherGroup.name}
              onChange={({ target }) => {
                const pg = {
                  ...publisherGroup,
                  name: target.value,
                };
                setPg(pg);
              }}
            />
          </FormField>

          <FormField label='PG Publishers'>
            <Select
              valueKey={"id"}
              labelKey={"publisher"}
              options={publisherOpts}
              multiple
              clear
              dropHeight={"medium"}
              messages={{
                multiple: `${selectedPubs.length} publishers`,
              }}
              onChange={({ value, option }) => {
                const val = !value ? [] : value.map(v => 
                  !v && option ? option : v

                ).filter(v => v);
                setSelectedPubs(val);
                setSearch("");
                setPg((pg) => ({
                  ...pg,
                  publishers: val,
                }));
              }}
              // children={
              //   (option, index, options, { active, disabled, selected }) =>{
              //     return (
              //     <Box pad="small" background={active ? 'active' : undefined}>
              //     {option.publisher} 
              //     </Box>
              //   )}
              // }
              onSearch={(text) => {
                setSearch(text);
                if (text.length > 3) {
                  debouncedPubSearch(text);
                }
              }}
              value={selectedPubs}
            />
              
     
          </FormField>
          <Button
            disabled={processing || hash.original === hash.last}
            label={processing ? "Processing..." : isNew ? "Create" : "Update"}
            icon={processing ? <Spinner /> : null}
            onClick={async () => {
              const { id, name, publishers } = publisherGroup;

              const payload = {
                action: isNew ? "create" : "update",
                id,
                name,
                publishers: publishers.map((p) => p.id).join(","),
              };

              console.log(payload.publishers.length);
              console.log({ selectedPubs });

              setProcessing(true);
              await fetcher("/api/appsflyer/publisher-groups", payload);

              setProcessing(false);
              onDone();
            }}
            primary
          />
        </Box>
      </Box>
    </Layer>
  );
};

const PublishersTable = ({ data, onEdit, setEdit, filterText }) => {
  if (!data) return null;

  const filteredData = [];

  data.publisherGroups.forEach((group) => {
    const { publisher_group } = group;
    // console.log(group);
    if (!publisher_group.toLowerCase().includes(filterText.toLowerCase())) {
      return;
    }
    filteredData.push(group);
  });

  console.info({ filteredData })
  return (
    <Box>
      <DataTable
        data={filteredData}
        primaryKey={"id"}
        sort={{
          direction: "asc",
          property: "publisher_group_id",
        }}
        columns={[
          {
            property: "publisher_group_id",
            header: "GID",
            render: (props) => {
              if (!props.publisher_group_id) {
                return data.groups.find((g) => g.name === props.publisher_group)
                  ?.id;
              }

              return props.publisher_group_id;
            },
          },
          {
            property: "id",
            header: "PID",
            render: (props) => {
              // console.info('id', props)
              if (!props.id) return "-";
              return props.id;
            },
          },
          { property: "publisher_group", header: "GROUP" },
          {
            property: "publisher",
            header: "PUBLISHER",

            render: (props) => {
              if (!props.publisher_group_id) {
                // if grouped by publisher group
                const count = data.publisherGroups.filter(pg => pg.publisher_group === props.publisher_group).length;
                return `${count} publishers`;
              } else if (props.name === "EMPTY_GROUP") {
                return "0 publishers";
              }

              return props.publisher;
            },
          },
          {
            header: "EDIT",

            property: "actions",
            render: (props) => {
              // console.info("actions",props);
              if (
                !props.publisher_group_id ||
                // || props.name === 'EMPTY_GROUP'
                data.groups.find(({ name }) => name === props.publisher_group)
                  ?.publishers?.length < 2
              ) {
                return (
                  <Box direction='row'>
                    <Button
                      primary
                      icon={<FormEdit />}
                      size='small'
                      //   label='EDIT'
                      color='light-2'
                      onClick={() => onEdit(props.publisher_group)}
                    />
                  </Box>
                );
              }
            },
          },
          // {property: 'publishers', header: 'publishers', render: ({publishers}) => publishers.length},
        ]}
        groupBy={{
          property: "publisher_group",
        }}
      // rowDetails={(row) => {
      //     return <Box>Blah {row.name} </Box>;
      //   }}
      />{" "}
    </Box>
  );
};
export default AppsFlyerPublishers;
