import Ax from "axios";

import { fetchAppsFlyerAgencyData } from "./AppsFlyerActions";

import { fetchAppsFlyerAdnData } from "./AppsFlyerAdnActions";

export const fetchCombinedData = (queryObj) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCH_AF_COMBINED_DATA",
      payload: {
        loading: 1,
      },
    });

    try {
      const dataRequest = await Promise.all([
        dispatch(fetchAppsFlyerAgencyData(queryObj)),
        dispatch(fetchAppsFlyerAdnData(queryObj)),
      ]);
      return dataRequest;
    } catch (e) {
      console.info("COMBINED_ERROR", e);
      return Promise.resolve();
    }
  };
};

const getPath = (isAdn) => (isAdn ? "appsflyer-adn" : "appsflyer");

export const updateAppBundleConfig = (updateData, isAdn = false) => {
  return async (dispatch) => {
    try {
      const apiUrl = `/api/${getPath(isAdn)}/app-bundles/update`;

      const response = await Ax.post(apiUrl, updateData);
      const payload = response.data;

      dispatch(payload);
      return payload;
    } catch (e) {
      console.error(e);
    }
  };
};

export const resetAppBundleData = (appData, isAdn = false) => {
  return async (dispatch) => {
    try {
      const apiUrl = `/api/${getPath(isAdn)}/app-bundles/reset`;

      const response = await Ax.post(apiUrl, appData);
      const payload = response.data;

      dispatch(payload);
      return payload;
    } catch (e) {
      console.error(e);
    }
  };
};

export const createAppBundle = (actionData, remove = false, isAdn = false) => {
  return async (dispatch) => {

    try {

      const { action, ...data } = actionData;
      const command = action;
      const apiUrl = `/api/${getPath(isAdn)}/app-bundles/${command}`;

      const response = await Ax.post(apiUrl, data);
      const payload = response.data;

      dispatch(payload);
      return payload;
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteAppBundle = (data) => {
  //createAppBundle(data, true)
};

export const createAppBundleEvent = (
  data,
  update = false,
  isAdn = false,
  isDuplicate = false
) => {
  return async (dispatch) => {
    try {
      let payloadData = null;
      // console.info({ data, update, isAdn, isDuplicate })
      if (isDuplicate) {
        const { id, key, created, modified, ...duplicateData } = data;
        payloadData = duplicateData;
      } else {
        const { key, ...eventData } = data;
        payloadData = eventData;
      }

      const command = update ? "update" : "create";
      const apiUrl = `/api/${getPath(isAdn)}/app-bundle-events/${command}`;

      console.info(payloadData, apiUrl);
      const response = await Ax.post(apiUrl, payloadData);
      const payload = response.data;
      dispatch(payload);
      return payload;
    } catch (e) {
      console.error(e);
    }
  };
};


export const duplicateBundleConfig = (
  data,
  isAdn = true
) => {
  return async (dispatch) => {
    try {

      // console.info("duplicateBundleConfig")
      // console.info(data)
      const apiUrl = isAdn ? 
      `/api/appsflyer-adn/duplicate-app-bundle`
      :`/api/appsflyer/duplicate-app-bundle`
      ;
      const response = await Ax.post(apiUrl, data);
      // console.info({response})
      const payload = response.data;
      dispatch(payload);
      // return payload;
      return true;
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateAppBundleEvent = (data, isAdn = false) =>
  createAppBundleEvent(data, true, isAdn);



const exports =  {
  fetchCombinedData,
  fetchAppsFlyerAdnData,
  fetchAppsFlyerAgencyData,
  updateAppBundleConfig,
  resetAppBundleData,
  createAppBundle,
  deleteAppBundle,
  updateAppBundleEvent,
  createAppBundleEvent,
  duplicateBundleConfig
};

export default exports