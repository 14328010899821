import _ from "lodash";

const initialState = {
  loading: 0,
  startDate: null,
  endDate: null,
  colors: [],
  defaultColors: [
    { name: "revenue", color: "#6bc460" },
    { name: "cost", color: "#d10f0f" },
    { name: "profit", color: "#2c699a" },
    { name: "ftd", color: "#efea5a" },
    { name: "installs", color: "#54478c" },
    { name: "clicks", color: "#048ba8" },
    { name: "registrations", color: "#f29e4c" },
  ],
  summary: {
    adn: [],
    agency: [],
    joined: [],
  },
  profitPub: {
    adn: [],
    agency: [],
    joined: [],
  },
  profitBundle: {
    adn: [],
    agency: [],
    joined: [],
  },
  allPublishers: {
    adn: [],
    agency: [],
    joined: [],
  },
  allBundles: {
    adn: [],
    agency: [],
    joined: [],
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_SUMMARY":
      return {
        ...action.payload,
        colors: initialState.defaultColors,
        defaultColors: initialState.defaultColors,
      };

    case "DASHBOARD_COLORS_CHANGED":
      console.log(state.defaultColors);
      console.log({ ...state, colors: action.payload });
      return { ...state, colors: action.payload };

    case "DASHBOARD_COLORS_CHANGED_DEFAULT":
      return { ...state, colors: state.defaultColors };

    default:
      return state;
  }
};

export default dashboardReducer;
