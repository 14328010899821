import Ax from "axios";

// Action Creator
export const updateStatusOption = ({ option: statusOption }) => {
  return async (dispatch) => {
    dispatch({
      type: "NETWORK_STATUS_OPTION_CHANGE",
      payload: {
        statusOption,
      },
    });
  };
};

// Add New Network
export const addNewNetwork = (updateData) => {
  console.log(updateData);
  return async (dispatch) => {
    try {
      const apiUrl = `/api/integrations/networks/add-network`;

      const response = await Ax.post(apiUrl, updateData);
      const payload = response.data;

      dispatch(payload);
      return payload;
    } catch (e) {
      console.error(e);
    }
  };
};

export const getInitialData = (req) => {
  return async (dispatch) => {
    dispatch({
      type: "NETWORKS_DATA_LOADING",
      payload: {
        loading: 1,
        // networks: []
      },
    });
    try {
      const apiUrl = `/api/integrations/networks`;
      const response = await Ax.get(apiUrl);
      const payload = response.data;

      // console.info(payload);
      dispatch(payload);
    } catch (e) {
      // dispatch({
      //   type: 'NETWORKS_LOADING_FAILED'
      // })
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
      console.info(e.name);
    }
  };
};

export default {
  addNewNetwork,
  getInitialData,
  updateStatusOption,
};
