const toggleSidebar = (sidebarName = "MAIN") => () => async (dispatch) => {
  dispatch({
    type: `${sidebarName}_SIDEBAR_TOGGLE`,
  });
};

const toggleMainSidebar = toggleSidebar();

const toggleRightSidebar = toggleSidebar("RIGHT");

const injectContentRightSidebar = (content) => async (dispatch) => {
  dispatch({
    type: `RIGHT_SIDEBAR_CONTENT_INJECTED`,
    payload: content,
  });
};

export default {
  toggleMainSidebar,
  toggleRightSidebar,
  injectContentRightSidebar,
};
