import _ from "lodash";


const mockState = {
    placementResults: [
        // {
        //     id: 952219,
        //     name: 'Yohoads - ExplorAds - eToro - US - iOS - IMP'
        // },
        // {
        //     id: 952039,
        //     name: 'Yohoads - Batmobi - eToro - US - iOS - IMP'
        // }
    ],
    queryPlacements: [
        {
            id: 952219,
            name: 'Yohoads - ExplorAds - eToro - US - iOS - IMP',
            clicks: 5
        },
        {
            id: 952039,
            name: 'Yohoads - Batmobi - eToro - US - iOS - IMP',
            clicks: 5
        }
    ],
    placements: [
        '952219',
        '952039'
    ]
}
const initialState = {
    loading: false,
    convertInProgress: false,
    placementResults: [],
    queryPlacements: [],


};

const convertClicksReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CONVERT_CLICK_PLACEMENTS":
            return {
                ...state,
                ...action.payload
            }
        case "CC_ADD_ALL_PLACEMENTS": {
            return {
                ...state,
                queryPlacements: [
                    ...state.queryPlacements,
                    ...state.placementResults
                        .filter(p => p.total_pclicks 
                            // && +p.payout < 90
                            )
                        .map(p => ({
                            ...p,
                            clicks: 1
                        }))
                ],
                placements: [],
                placementResults: [],


            }
        }

        case "CC_UPDATE_CLICKS_NUM": {
            const { placement, clicks } = action.payload
            return {
                ...state,
                queryPlacements: state.queryPlacements.map(qp => {
                    if (qp.id === placement) {
                        return { ...qp, clicks }
                    }
                    return qp
                })
            }
        }

        case "CC_NEW_JOB": {
            return {
                ...state,
                placements: [],
                placementResults: [],
                queryPlacements: []
            }
        }

        case "CC_DATA_FETCH": {
            return {
                ...state,
                ...action.payload
            }
        }

        case "CC_CLICKS_CONVERT_START": {
            return {
                ...state,
                convertInProgress: true
            }
        }

        case "CC_CLICKS_CONVERT_DONE": {
            return {
                ...state,
                convertInProgress: false
            }
        }
        
        default:
            return state;
    }
};

export default convertClicksReducer;
