import React from "react";
import { Button, Box, TextInput } from "grommet";
import { Search, LinkNext } from "grommet-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FiltersMobile from "./FiltersMobile";

function Filters(props) {
  const {
    handleFiltersChange,
    open,
    direction,
    setOpen,
    fieldsRows,
    onChange,
    buttons,
    browser,
    onSubmit,
  } = props;
  const isMobile = !browser.greaterThan.medium;

  return (
    <Box
      gap="16px"
      align="start"
      fill="horizontal"
      pad="8px"
      background="light-3"
      direction={isMobile ? "column" : "row"}
    >
      {!isMobile ? (
        <Box
          gap="16px"
          align="start"
          fill="horizontal"
          pad="8px"
          background="light-3"
        >
          {fieldsRows.map((fieldsRow) => {
            return (
              <Box direction="row" gap="6px" align="center" fill="horizontal">
                {fieldsRow.map((field) => {
                  //
                  const { gap, align, width, margin } = field.fieldContainer;
                  //
                  return (
                    <Box
                      direction={direction || "row"}
                      gap={gap || 0}
                      align={align}
                      width={width}
                      margin={margin}
                    >
                      {field.field}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      ) : (
        <FiltersMobile fieldsRows={fieldsRows} />
      )}
      {/* SUBMIT BUTTON  */}
      <Box
        align="center"
        gap="10px"
        justify="around"
        height={isMobile ? "10%" : "100%"}
        width={isMobile ? "100%" : "3%"}
        pad="10px"
        background="brand"
        direction="row"

        // gap="10px"
        // background="brand"
      >
        <Button
          //   primary
          plain
          icon={<LinkNext size="18px" />}
          height="100%"
          //   style={{ width: "100px", height: "50%" }}
          onClick={() => onSubmit()}
        />
      </Box>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;

  return { browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
