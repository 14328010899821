import React, { useEffect, useState } from "react";
import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import defaultTheme from "../../../themes/main-theme";
import {
  Box,
  Layer,
  Heading,
  DataTable,
  Select,
  Grommet,
  Spinner,
  Button,
} from "grommet";
import { Duplicate } from "grommet-icons";

// Theme for small datatable
const { global } = defaultTheme;
const theme = {
  global: {
    colors: {
      ...global.colors,
    },
    font: {
      ...global.font,
      // size: "10px",
    },
  },
  dataTable: {},
  box: {
    fontSize: 8,
  },
};

export default function DupeBundleConfigLayer(props) {
  const {
    adnBundleToDupe,
    onCancel,
    duplicateBundleConfig,
    events,
    config,
    adnNetworks,
    agencies,
  } = props;

  const [bundleEvents, setBundleEvents] = useState([]);
  const [eventsToDupe, setSelectedBundleEvents] = useState([]);
  const [networksToDupe, setSelectedNetworks] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [bundleConfig, setBundleConfig] = useState({});
  // const [bundleEventsByType, setBundleTypeEvents] = useState([])
  const [app_bundle, entityId, isAdn] = adnBundleToDupe || [];
  const [networkOpts, setNetworkOpts] = useState([]);

  // console.log(
  //   "config",
  //   config.filter((c) => c.app_bundle !== app_bundle)
  // );

  // console.info(networkOpts, adnNetworks)
  useEffect(() => {
    if (app_bundle) {
      let entityFilter = isAdn
        ? (e) => e.app_bundle === app_bundle && e.adn_id === entityId
        : (e) => e.app_bundle === app_bundle && e.agency_id === entityId;

      const bundleEvents = events.filter((e) => entityFilter(e));
      const bundleConfig = config.filter((e) => entityFilter(e))[0] || {};

      const allBundleConfigs = config.filter(
        (c) => c.app_bundle === app_bundle
      );

      setBundleEvents(bundleEvents);
      setBundleConfig(bundleConfig);
      // setBundleTypeEvents(_groupBy(bundleEvents, 'event_type'))
      setSelectedBundleEvents(bundleEvents.map((e) => e.id));
      if (isAdn && adnNetworks.OPTS) {
        const networkOpts = adnNetworks.OPTS.filter(
          ({ adnId }) =>
            !allBundleConfigs.map((bc) => bc.adn_id).includes(adnId)
        );
        setNetworkOpts(networkOpts);
      }
      if (!isAdn && agencies.OPTS) {
        const networkOpts = agencies.OPTS.filter(
          ({ agencyId }) =>
            !allBundleConfigs.map((bc) => bc.agency_id).includes(agencyId)
        );
        setNetworkOpts(networkOpts);
      }
    }
  }, [app_bundle, entityId, isAdn, events, agencies, config, adnNetworks]);

  if (
    !app_bundle ||
    !Object.keys(adnNetworks).length ||
    !bundleConfig.app_bundle
  ) {
    return null;
  }

  return (
    <Layer
      onEsc={onCancel}
      onClickOutside={onCancel}
      position={"top"}
      margin={{ top: "medium" }}
      animation={"fadeIn"}
    >
      <Box style={{ minHeight: 500, minWidth: 500 }} size='xlarge' pad='medium'>
        <Heading
          margin={{ vertical: "small" }}
          level={3}
          gap={"small"}
          style={{ maxWidth: "100%" }}
        >{`Duplicate ${isAdn ? "AdNetwork" : "Agency"} Bundle`}</Heading>
        <Box
          pad={{ vertical: "small" }}
          style={{ fontSize: 16, fontWeight: 500 }}
        >
          <Box background='light-1' pad={"small"} border='light-2'>
            <Box>Bundle Details:</Box>
            <Box
              pad={"small"}
              style={{ fontWeight: 600 }}
              direction='row'
              gap='small'
            >
              <span>
                {(isAdn ? adnNetworks : agencies).ID_MAP[entityId].name}
              </span>
              <span>{`${bundleConfig.app_name}`}</span>
              <span>{`${app_bundle}`}</span>
            </Box>
          </Box>
          <Box pad={{ horizontal: "small" }}>
            {/* Selection Wrapper */}
            <Box pad={{ vertical: "small" }}>{`${
              isAdn ? "Networks:" : "Agencies:"
            }`}</Box>
            <Grommet theme={theme}>
              <Box flex>
                <Select
                  style={{ minWidth: "100%" }}
                  size={"12px"}
                  name='Adn ID'
                  options={networkOpts}
                  labelKey='label'
                  valueKey={{ key: "id", reduce: true }}
                  placeholder={"..."}
                  multiple
                  messages={{
                    multiple: networksToDupe.map(({ name }) => name).join(", "),
                  }}
                  closeOnChange={false}
                  onChange={({ value: nextNetworkIds }) => {
                    console.info(nextNetworkIds);
                    setSelectedNetworks(
                      nextNetworkIds.map(
                        (n) => (isAdn ? adnNetworks : agencies).ID_MAP[n]
                      )
                    );
                  }}
                />
              </Box>

              <Box pad={{ vertical: "small" }}>Events:</Box>

              <DataTable
                pin
                // size='small'
                primaryKey={"id"}
                background={"light-0"}
                columns={[
                  { property: "event_type", header: "Type" },
                  { property: "event_key", header: "Key" },
                  { property: "event_name", header: "Name" },
                  {
                    property: "event_value",
                    header: "Value",
                    render: ({ event_value }) =>
                      +event_value ? +event_value : null,
                  },
                ]}
                select={eventsToDupe}
                onSelect={(nextEvents) => setSelectedBundleEvents(nextEvents)}
                data={_sortBy(bundleEvents, "event_type")}
              />
            </Grommet>
            <Box pad={{ top: "medium" }} align='center' flex>
              <Button
                style={{ width: 250 }}
                size='large'
                label={processing ? "Please wait..." : "Duplicate"}
                primary
                disabled={
                  processing || !eventsToDupe.length || !networksToDupe.length
                }
                icon={processing ? <Spinner /> : <Duplicate />}
                hoverIndicator
                onClick={() => {
                  const data = {
                    eventsToDupe,
                    sourceConfigId: bundleConfig.id,
                    [isAdn ? "networksToDupe" : "agenciesToDupe"]:
                      networksToDupe,
                  };
                  setProcessing(true);
                  duplicateBundleConfig(data, isAdn).then(() => {
                    setProcessing(false);
                    onCancel();
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
}
