import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
// Grommet Imports
import {
  Heading,
  Paragraph,
  Button,
  TextInput,
  Text,
  Box,
  DateInput,
  Select,
  Grommet,
} from "grommet";
import { Down, Up } from "grommet-icons";
import _ from "lodash";
import SummaryByDateChart from "../Charts/SummaryByDate/SummaryByDateChart";
import ActivePieChart from "../Charts/ActivePieChart/ActivePieChart";

import SimplePieChart from "../Charts/SimplePieChart/SimplePieChart";

import ChartContainer from "../Charts/ChartContainer";

import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";

import DatePresets from "../DatePresets";
// Action Imports
import DashboardActions from "../../actions/DashboardActions";

const THEME = {
  global: {
    colors: {
      brand: "#3c8dbc" /*main color code*/,
      color: "gray",
    },
    font: {
      family: "Roboto",
      size: "15px",
      height: "20px",
    },
  },
  textInput: {
    extend: {
      backgroundColor: "white",
      fontWeight: 500,
      color: "gray",
    },
  },
  button: {
    default: {
      font: {
        weight: 500,
      },
      padding: {
        vertical: "8px",
        horizontal: "0px",
      },
      border: {
        color: {
          light: "rgba(0,0,0,0.33)",
        },
        width: "1px",
        radius: "5px",
      },
      extend: {
        backgroundColor: "white",
        color: "gray",
      },
    },
  },
};
const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "long",
  day: "numeric",
});

const ValueLabel = ({ label }) => {
  return <Box>
    <TextInput 
    disabled
     style={{ 
       border: 0,
       background: 'white', 
       fontWeight: 500,
       color: 'black',
       fontSize: '15px' 
      }}
      value={label} />
  </Box>
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    backgroundColor: " rgba(0,0,0,0.04)",
    // padding: "0 2%",
    // "& .MuiTextField-root": {
    //   margin: theme.spacing(1),
    //   width: "25ch",
    // },
  },
  filters: {
    display: "flex",
    // backgroundColor: "red",
    alignItems: "center",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
    // height: "8%",
    // padding: "1px 0 ",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  piesRow: {
    // backgroundColor: "green",
    padding: "15px 0",
    // height: "46%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
    },
  },
  pieChart: {
    // padding: "100px 0",
    width: "30%",
    // height: "100%",
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  bottomChart: {
    //  backgroundColor: "yellow",
    // minHeight: "46%",
    width: "100%",
    padding: "15px 0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
}));

// const orderedOptions =
//   // (allOptions, selectedOptions) => {
//   //   return [
//   //     selected,
//   //     ...unorderedOptions.filter((o) => valuesLabels.indexOf(o.label) === -1),
//   //   ];
//   // }

function HomePage(props) {
  const classes = useStyles();
  const {
    summary,
    profitPub,
    performance,
    profitBundle,
    getDashboardData,
    allPublishers,
    allBundles,
    browser,
    loading,
  } = props;

  const isMobile = browser.width < 800;
  const maxWidth = browser.width;
  const [publisherOpts, setPublisherOpts] = React.useState(allPublishers);
  const [publisherSelected, setPublisherSelected] = React.useState([]);

  const [appBundlesOpts, setAppBundlesOpts] = React.useState(allBundles);

  const [bundlesSelected, setBundlesSelected] = React.useState([]);

  const [filters, setFilters] = React.useState({
    // startDate: Moment.utc().subtract(14, "day").format("YYYY-MM-DD"),
    // endDate: Moment.utc().format("YYYY-MM-DD"),
    startDate: Moment().startOf("day").format("YYYY-MM-DD"),
    endDate: Moment().endOf("day").format("YYYY-MM-DD"),
    datePreset: false,
    groupBy: "publisher",
    publishers: [],
    bundles: [],
  });

  const [filterFields, setFiltersFields] = React.useState(!isMobile);

  // Populate `publishers` select
  useEffect(() => {
    setPublisherOpts(allPublishers);
    setAppBundlesOpts(allBundles);
  }, [allPublishers, allBundles]);

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      publishers: publisherSelected,
      bundles: (bundlesSelected || [])
        .filter((b) => b)
        .map((b) => b.app_bundle),
    }));
  }, [publisherSelected, bundlesSelected, setFilters]);

  useEffect(() => {
    // setFilters({
    //   ...filters,
    //   publishers: publisherSelected
    // })
    // console.info({summary})
    // if (!summary.length)
    getDashboardData(filters);
  }, [filters, getDashboardData]);

  console.info({ filters, bundlesSelected, publisherSelected });
  // console.info({profitPub})
  return (
    <div className={classes.root}>
      <Grommet theme={THEME} className={classes.filters}>
        <Box fill direction={isMobile ? "column" : "row"}>
          <Box
            flex
            pad="small"
            align="center"
            width="medium"
            direction="row"
            justify="between"
            fill
          >
            <Box fill>
              <DateInput
                // key={`${filters.startDate}${filters.endDate}`}
                value={[filters.startDate, filters.endDate]}
                // inline={true}
                // format="yyyy/mm/dd-yyyy/mm/dd"
                buttonProps={{
                  label: `${dateFormat.format(
                    new Date(filters.startDate)
                  )} - ${dateFormat.format(new Date(filters.endDate))}`,
                }}
                onChange={({ value }) =>
                  setFilters({
                    ...filters,
                    startDate: value[0],
                    endDate: value[1],
                    datePreset: false,
                  })
                }
              />
            </Box>
            {isMobile &&
              (filterFields ? (
                <Up
                  style={{
                    padding: "7px",
                    backgroundColor: "lightgray",
                    color: "white",
                    border: "1px solid gray",
                    marginLeft: "2%",
                    borderRadius: "2px",
                  }}
                  onClick={() => setFiltersFields(!filterFields)}
                />
              ) : (
                  <Down
                    style={{
                      marginLeft: "2%",
                      padding: "7px",
                      backgroundColor: "lightgray",
                      color: "white",
                      border: "1px solid gray",
                      borderRadius: "2px",
                    }}
                    onClick={() => setFiltersFields(!filterFields)}
                  />
                ))}
          </Box>
          {filterFields && (
            <Box align={isMobile ? "stretch" : "center"} pad="small">
              <DatePresets
                // key={`${filters.startDate}${filters.endDate}`}
                width="100%"
                datePreset={filters.datePreset}
                handleChange={(dates) =>
                  setFilters((f) => ({
                    ...f,
                    ...dates,
                    datePreset: true,
                  }))
                }
              />
            </Box>
          )}

          {/* Select Publishers  */}
          {filterFields && (
            <Box align={isMobile ? "stretch" : "center"} pad="small">
              <Select
                clear
                options={_.orderBy(
                  publisherOpts,
                  (publisher) => {
                    return publisherSelected.indexOf(publisher) > -1;
                  },
                  "desc"
                )}
                value={publisherSelected.length ? publisherSelected : ""}
                placeholder={"Publishers"}
                onChange={({ value }) => setPublisherSelected(value)}
                closeOnChange={false}
                onSearch={(text) => {
                  if (!text) {
                    setPublisherOpts(allPublishers);
                    return true;
                  }
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );
                  const exp = new RegExp(escapedText, "i");
                  // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

                  setPublisherOpts(allPublishers.filter((o) => exp.test(o)));
                }}
                multiple
                messages={{
                  multiple: `${publisherSelected.length} publishers`,
                }}
              />
            </Box>
          )}

          {/* Select App Bundle  */}
          {filterFields && (
            <Box align={isMobile ? "stretch" : "center"} pad="small">
              <Select
                clear
                // options={appBundlesOpts}
                options={_.orderBy(
                  appBundlesOpts,
                  ({ app_bundle }) => {
                    return (bundlesSelected || []).find(
                      (b) => b.app_bundle === app_bundle
                    );
                  },
                  "asc"
                )}
                value={bundlesSelected.length ? bundlesSelected : ""}
                placeholder={"App bundle"}
                onChange={({ value }) => setBundlesSelected(value)}
                closeOnChange={false}
                onSearch={(text) => {
                  if (!text) {
                    setAppBundlesOpts(allBundles);
                    return true;
                  }
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );
                  const exp = new RegExp(escapedText, "i");
                  // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

                  setAppBundlesOpts(
                    allBundles.filter(
                      (o) => exp.test(o.app_name) || exp.test(o.app_bundle)
                    )
                  );
                }}
                multiple
                messages={{
                  multiple: `${bundlesSelected.length} bundles`,
                }}
                valueKey={({ app_bundle }) => {
                  // console.info("value", args)
                  return app_bundle;
                }}
                valueLabel={<ValueLabel label={
                  bundlesSelected.length === 1 ? bundlesSelected[0].app_name : 
                  `${bundlesSelected.length ? bundlesSelected.length : '' } Bundles`
                } />}
                labelKey={({ app_name, app_bundle }) => {
                  return (
                    <Box>
                      <Box>{app_name}</Box>
                      <Box style={{ fontSize: "12px", fontWeight: 500 }}>
                        {app_bundle}
                      </Box>
                    </Box>
                  );
                  // console.info('label', args)
                  // return args
                }}
              // valueLabel={<Box pad='small'>{
              //   bundlesSelected.length === 1 ? bundlesSelected[0].app_name : 'multi'
              //   }</Box>}
              ></Select>
            </Box>
          )}
        </Box>
      </Grommet>

      <div className={classes.piesRow}>
        <div className={classes.pieChart}>
          <ChartContainer name={"Profit Bundle"}>
            <ActivePieChart
              loading={loading}
              maxWidth={maxWidth}
              profitBundle={profitBundle}
            />
          </ChartContainer>
        </div>
        <div className={classes.pieChart}>
          <ChartContainer name={"Performance"}>
            <SimplePieChart data={performance} maxWidth={maxWidth} />
          </ChartContainer>
        </div>
        <div className={classes.pieChart}>
          <ChartContainer name={"Profit Publisher"}>
            <ActivePieChart maxWidth={maxWidth} profitBundle={profitPub} />
          </ChartContainer>
        </div>
      </div>

      <div className={classes.bottomChart}>
        <div style={{ width: isMobile ? "100%" : "96%" }}>
          <ChartContainer name={"Summary by date"}>
            <SummaryByDateChart
              loading={loading}
              data={summary}
              maxWidth={maxWidth}
            />
          </ChartContainer>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { user, dashboard, browser } = state;
  const username = user.name;
  const summary = dashboard.summary.joined;
  const profitPub = dashboard.profitPub.joined;
  const profitBundle = dashboard.profitBundle.joined;
  const allPublishers = dashboard.allPublishers.joined;
  const allBundles = dashboard.allBundles.joined;
  const loading = dashboard.loading;

  const performance = [];

  const reducedSummary = summary.reduce(
    (a, b) => ({
      cost: a.cost + b.cost,
      revenue: a.revenue + b.revenue,
      profit: a.profit + b.profit,
    }),
    { cost: 0, revenue: 0, profit: 0 }
  );

  Object.keys(reducedSummary).forEach((name) =>
    performance.push({ name, value: reducedSummary[name] })
  );

  return {
    username,
    summary,
    performance,
    profitPub,
    profitBundle,
    allPublishers,
    browser,
    allBundles,
    loading,
  };
};

const mapDispatchToProps = {
  ...DashboardActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
