import _ from "lodash";

const advertisers = (state = { loading: 0, advertisers: [] }, action) => {
  switch (action.type) {
    case "PLACEMENTS_DATA_LOADED":
    case "OFFERS_RESPONSE_DATA_LOADED":
    case "CONVERSIONS_RESPONSE_DATA_LOADED":
    case "EXT_OFFERS_RESPONSE_DATA_LOADED": {
      const { advertisers } = action.payload;
      if (action.payload.advertisers) {
        return {
          ...state,
          advertisers
        }
      }
      return state
    }
    case "ADVERTISERS_DATA_LOADING":
    case "ADVERTISERS_DATA_LOADED": {
      // const reports = (raw || []).map(o => _.pick(o, _.keys(reportModel)))
      return { ...state, ...action.payload };
    }
    case "ADD_NEW_ADVERTISER_INPUTS_SUBMITTED": {
      // const reports = (raw || []).map(o => _.pick(o, _.keys(reportModel)))
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default advertisers;
