import React from "react";
import { Box, TextInput, Text } from "grommet";

function Footer(props) {
  const { page, pageSize, totalRows, handleChange, name } = props;
  const total = totalRows || 0;

  function hndleInputsChange(e) {
    const { name, value, type } = e.target;

    if (name === "pageSize") {
      if (parseInt(value) < totalRows) {
        handleChange(e);
      }
    } else {
      handleChange(e);
    }
  }

  function setOptions() {}

  return (
    <Box
      pad="small"
      border="top"
      align="center"
      direction="row"
      justify="between"
    >
      <Box direction="row" align="center" gap="small">
        rows
        <select
          value={pageSize}
          name="pageSize"
          onChange={(e) => {
            hndleInputsChange(e);
          }}
        >
          <option>50</option>
          <option>100</option>
          <option>200</option>
          <option>250</option>
          <option>500</option>
        </select>
      </Box>
      <Box
        justify="center"
        direction="row"
        gap="small"
        align="center"
        alignContent="center"
      >
        page
        <TextInput
          name="page"
          value={page}
          type="number"
          placeholder={page}
          max={Math.ceil(total / pageSize)}
          style={{
            width: "60px",
            //   backgroundColor: "rgb(0,0,0,0.1)",
            textAlign: "center",
            fontSize: "15px",
            //   fontWeight: "500",
          }}
          onChange={(event) => handleChange(event)}
        />
        {Math.ceil(total / pageSize)}
      </Box>

      <Box direction="row" gap="small">
        <Text size="small">{`showing ${
          page === 1 ? page : (page - 1) * pageSize
        } - ${
          total < pageSize * page ? total : pageSize * page
        } of ${total} ${name}`}</Text>
      </Box>
    </Box>
  );
}

export default Footer;
