import React, { useEffecrt, useEffect, useState } from "react";
import { connect } from "react-redux";
import ConvertActions from '../../actions/ConvertClicksActions';
import { debounce } from 'lodash';
import { NumberInput } from 'grommet-controls'
import styled from 'styled-components';
import {
    Box,
    Grommet,
    Button,
    Form,
    List,
    Tip,
    Text,
    Layer,
    Spinner,
    Heading,
    CheckBox,
    TextInput,
    DataTable,
    Select,
    TextArea,
    FormField,
} from "grommet";
import { Search, Add, Send } from 'grommet-icons';
import { minimalTheme } from "../../themes/main-theme";


function getPlacementProps(placementResults) {
    let itemProps = {

    };

    placementResults.forEach((p, i) => {
        if (!p.total_pclicks) {
            itemProps[i] = { background: 'dark-2' }
        }

        // console.info('placement', p)
        // if (+p.payout > 90) {
        //     // console.info('critical', p)
        //     itemProps[i] = { background: 'status-error' }
        // }
    })

    // console.info(itemProps)

    return itemProps
}


function ConvertClicks(props) {
    const {
        getPlacements,
        placementResults,
        sendSearchQuery,
        queryPlacements,
        includeAllPlacements,
        updatePlacementClicksNum
    } = props;
    const [placementSearch, setPlacementSearch] = React.useState('');
    const [isSearching, setSearching] = React.useState(false);
    useEffect(() => {
        async function searchPlacements() {
            setSearching(true)
            await getPlacements(placementSearch);
            setSearching(false)
        }
        if (placementSearch.length > 4) {
            searchPlacements()
        }
    }, [placementSearch, getPlacements]);




    // console.info({ queryPlacements })
    return (
        <Grommet theme={minimalTheme} full>

            <Box pad={{ horizontal: "medium" }} fill flex>
                <Heading level="3" size='large' margin={{ vertical: "small" }}>Add Placements</Heading>
                <Box pad='xsmall' style={{ overflow: 'hidden' }}>
                    <TextInput
                        icon={isSearching ? <Spinner /> : <Search />}
                        placeholder="Type `placement_ids` here... 1112,3333..."
                        value={placementSearch}
                        onChange={event => setPlacementSearch(event.target.value.replace(/[^\d\n,.]/g, ""))}
                    />

                    {placementResults.length ? <Box pad={{ vertical: 'small' }} flex style={{ maxHeight: '600px', overflow: 'hidden' }}>
                        <Box align='center' direction="row" >
                            <Heading level="3" margin={{ vertical: 'medium' }}>
                                <Box>

                                    Placement results
                                    <Box direction="row" gap='small'>

                                    <Text color='dark-2'>{`gray = no clicks`}</Text>
                                    {/* <Text color='status-critical'>{`red = payout > 10`}</Text> */}
                                    </Box>

                                </Box>
                            </Heading>
                            <Box align='end' flex>
                                <Button label="Add all" primary icon={<Add />} hoverIndicator onClick={() => includeAllPlacements()} />
                            </Box>
                        </Box>

                        <List
                            data={placementResults}
                            primaryKey={item => {
                                return (
                                    <Box>
                                        [{item.id}] {item.name}
                                    </Box>
                                )
                            }}
                            itemProps={getPlacementProps(placementResults)}
                            defaultItemProps={{ pad: 'small' }}
                            secondaryKey={(item) => {
                                if (item.total_pclicks)
                                    return <Box direction="row"><strong>{item.total_pclicks}+ </strong><i>{`${item.pref}`}</i></Box>
                            }}
                        >

                        </List>
                    </Box> : null}
                </Box>
                {queryPlacements.length ? <Box>

                    <Heading level="3" size='large' margin={{ vertical: "large" }}>Search Settings</Heading>
                    <Box pad='small'>
                        <List
                            data={queryPlacements}
                            primaryKey={item => {
                                return (
                                    <Box>
                                        [{item.id}] {item.name}
                                    </Box>
                                )
                            }}
                            secondaryKey={(item) => {
                                return (<Box>
                                    <TextInput plain
                                        value={item.clicks}
                                        onChange={({ target }) => {
                                            updatePlacementClicksNum({
                                                placement: item.id,
                                                clicks: +target.value.replace(/[^\d\n]/g, "")
                                            })
                                        }} />
                                </Box>)
                            }}
                        >

                        </List>
                        <Box align='end' flex pad={{ vertical: 'small' }}>
                            <Button label="Send Search" primary icon={<Send />} hoverIndicator onClick={() => queryPlacements.length && sendSearchQuery()} />
                        </Box>
                    </Box>
                </Box> : null}

            </Box>
        </Grommet>
    );
}


const mapStateToProps = (state, props) => {
    const { convertClicks } = state;
    const { placementResults, queryPlacements } = convertClicks

    return {
        placementResults,
        queryPlacements
    };
};

export default connect(mapStateToProps, { ...ConvertActions })(ConvertClicks);

