import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Redux imports
import { Provider } from "react-redux";
// import {store} from "./store";

import configureStore, { history } from './store'
// import { ConnectedRouter } from 'connected-react-router'

const store = configureStore(/* provide initial state if any */)

ReactDOM.render(
    <Provider store={store}>
        {/* <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */} 
            <App />
        {/* </ConnectedRouter> */}
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
