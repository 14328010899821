
import React, { useEffect } from "react";
import ReadField from './ReadField';
import { Select, Box } from 'grommet';

export const ReadSelect = (props) => {
  const { disabled, value, name, onChange, options, clear, multi, closeOnChange,
    ...restProps } = props;

  const [searchVal, setSearchVal] = React.useState("");
  const [currentOpts, setCurrentOpts] = React.useState(options);


  useEffect(() => {
    setCurrentOpts(options)
  }, [options])

  return (
    <ReadField name={name}>
      <Select
        clear={clear !== undefined ? clear : true}
        closeOnChange={closeOnChange !== undefined ? closeOnChange : true}

        multiple={multi ? true : false}

        disabled={disabled}
        value={value}
        key={`${name}-select`}
        name={name}
        onSearch={(searchVal) => {
          if (searchVal) {
            setCurrentOpts(
              options
                .filter((o) =>
                  !(restProps && restProps.valueKey) ?
                    // options aren't object
                    o.toLowerCase().includes(searchVal.toLowerCase()) :
                    // options are objects filter
                    `${o[restProps.valueKey]}`.toLowerCase().includes(searchVal.toLowerCase())
                    || `${o[restProps.labelKey]}`.toLowerCase().includes(searchVal.toLowerCase())


                )
            );
          } else {
            setCurrentOpts(options);
          }
        }}
        onChange={(args) => {
          setCurrentOpts(options)
          onChange(args)
        }}
        options={[...currentOpts]}
        children={
          restProps && restProps.valueKey ? (option, index, options, { active, disabled, selected }) => {
            return (
              <Box pad='small' background={selected ? "brand" : undefined} >
                {option[restProps.labelKey]}
                <Box style={{ color: selected ? 'lightgray' : "gray", fontSize: 12 }}>
                  {option[restProps.valueKey]}
                </Box>
              </Box>)
          } : undefined}
        {...restProps}
      />
    </ReadField>
  );
};


export default ReadSelect