import React, { useCallback, useState, useRef } from "react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import useClickOutside from "../../../hooks/useClickOutside";
import DashboardActions from "../../../actions/DashboardActions";
import { Catalog as PaintIcon } from "grommet-icons";

// const isDisabled = (colorName) => {
//   const index = ["cost", "revenue", "profit"].findIndex(
//     (disabledColorName) => disabledColorName === colorName
//   );
//   return index > -1 ? true : false;
// };

const ColorRow = (props) => {
  const { name, color } = props.color;
  // const colorDisabled = isDisabled(name);

  return (
    <Row
      onClick={() => props.onClickColorRow({ name, color })}
      //   disabled={colorDisabled}
      selected={props.selected}
    >
      <ColorName>{_.capitalize(name)}</ColorName>
      <Color color={color}></Color>
    </Row>
  );
};

const ColorPickerButton = (props) => {
  const { colors, defaultColors } = props;
  const [panelOpen, setPanelOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  // const [newColors, setNewColors] = useState([...colors]);
  const [selectedColor, setSelectedColor] = useState({
    name: "ftd",
    color: "#53a4d4",
  });
  const close = useCallback(() => setColorPickerOpen(false), []);
  const popover = useRef();
  useClickOutside(popover, close);
  //
  const onClickColorRow = (color) => {
    if (!colorPickerOpen) {
      setColorPickerOpen(true);
      setSelectedColor(color);
    }
  };

  //
  const onColorPick = (color) => {
    // update the selected color under the property name
    const newColor = { ...selectedColor, color };
    setSelectedColor(newColor);

    // find the color's index in the colors array
    // and then replace it with the new selected color
    const temp = [...colors];
    const index = colors.findIndex(
      (newColor) => newColor.name === selectedColor.name
    );
    temp.splice(index, 1, newColor);

    console.log(temp);
    // set colors on store
    props.setColors(temp);
  };

  //
  const onCancelColorsPick = () => {
    // set colors on store
    setPanelOpen(false);
    const temp = [...defaultColors];
    props.setColorsDefault();
  };

  return (
    <>
      <ButtonCustom onClick={() => setPanelOpen(!panelOpen)}>
        <PaintIconCustom size='18px' />
      </ButtonCustom>
      {panelOpen && (
        <Layer>
          <Panel>
            <PanelHeader>Colors adjustment </PanelHeader>
            <ColorsList>
              {colors &&
                colors.map((color) => (
                  <ColorRow
                    selected={color.name === selectedColor.name}
                    key={color.name}
                    onClickColorRow={onClickColorRow}
                    color={color}
                  />
                ))}
              {/* {colorPickerOpen && ( */}
              {/* )} */}
            </ColorsList>
            <Popover ref={popover}>
              <PopoverHead>{`${selectedColor.name} - ${selectedColor.color}`}</PopoverHead>
              <HexColorPicker
                color={selectedColor.color}
                onChange={onColorPick}
              />
            </Popover>
            <Buttons>
              <Button onClick={onCancelColorsPick}>Cancel</Button>
              <Button onClick={() => props.setColorsDefault()}>Default</Button>
              <Button
                onClick={() => setPanelOpen(false)}
                color='rgb(59, 130, 173,0.85)'
              >
                Set colors
              </Button>
            </Buttons>
          </Panel>
        </Layer>
      )}
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { dashboard } = state;
  const colors = dashboard.colors;
  const defaultColors = dashboard.colors;

  return {
    colors,
    defaultColors,
  };
};

const mapDispatchToProps = {
  ...DashboardActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPickerButton);

const Layer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Panel = styled.div`
  width: 20%;
  height: 100%;
  background-color: ${({ theme }) => theme.panel};
  // padding: 0px;
  //   border-radius: 5px;
  box-shadow: -5px 0px 5px 3px rgb(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   margin: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) =>
    props.selected && !props.disabled ? props.theme.hover : ""};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    background-color: ${(props) =>
      props.disabled || props.selected ? "" : "lightgray"};
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
  }
`;

const ColorName = styled.div`
  margin: 15px;
  font-weight: 400;
`;

const ColorsList = styled.div`
  margin: 10px 0;
`;

const Color = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  margin: 15px;
  border: 1px solid ${({ theme }) => theme.border};
`;

const PanelHeader = styled.div`
  background-color: ${({ theme }) => theme.body};
  //   color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  //   box-shadow: 0px 3px 2px 2px rgb(0, 0, 0, 0.2);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.border};
  padding: 15px 10px;
  background-color: ${({ theme }) => theme.body};
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  margin: 0 8px;
  padding: 6px 10px;
  //   opacity: ${(props) => (props.selected ? 1 : 0.4)};
  font-weight: 300;
  background-color: ${(props) => props.color || ""};
  color: ${(props) => (props.color ? "white" : "")};
  font-size: 12px;
`;

const Popover = styled.div`
  //   margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .react-colorful {
    width: 100%;
  }
  & .react-colorful__saturation {
    border-radius: 0;
  }
  & .react-colorful__hue {
    border-radius: 0;
  }
`;

const PopoverHead = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const PaintIconCustom = styled(PaintIcon)`
  stroke: ${({ theme }) => theme.icon2};
`;

const ButtonCustom = styled.button`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  font-weight: 300;
  padding: 7px 11px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border};
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 10px;
`;
