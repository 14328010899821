import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// Actions
import AdvertisersActions from "../../actions/AdvertisersActions";
import OffersActions from "../../actions/OffersActions";
import AppBarActions from "../../actions/AppBarActions";
import _ from "lodash";
// grommet
import { Box } from "grommet";
import { Search, LinkNext } from "grommet-icons";
// components
import OffersFilters from "./OffersFiltersC";
import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import PageFotter from "../../components/PageFooter/PageFotter";
import PopupDialog from "../../components/xComponents/PopupDialog";
import Filters2 from "../../components/Filters/Filters";
// services
import services from "./offers.services";
import COUNTRY_CODES from "../../constants/country-codes.json";

const DEFAULT_SORT = { property: "id", direction: "desc" };

function Offers(props) {
  // props
  const { browser, advertisers, fetchOffersData } = props;
  const { offers, loading, totalPages, advertiser } = props.offers;
  // component state
  const [filtersVisible, toggleFilterVisibility] = useState(true);
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.page || 1);
  const [pageSize, setCurrentPageSize] = useState(props.pageSize || 50);
  const [queryCriteria, setQueryCriteria] = useState({});
  //
  const columns = services.getTableColumns();
  const rowProps = services.getRowProps(offers);
  //
  const isMobile = !browser.greaterThan.medium;
  //
  const tableColors = {
    header: "dark-3",
    body: ["#dff0d8"],
    footer: "dark-3",
  };
  //
  const buttons = [
    {
      name: "add offer",
      id: "btn-add",
      icon: "Add",
      onClick: (e) => {
        setPopupIsVisible(!popupIsVisible);
        services.setPopupContent(e, popupIsVisible);
      },
    },
  ];

  const INTERNAL_STATUS_OPTS = [
    { label: "All Active", value: 1 },
    { label: "All Inactive", value: 2 },
    { label: "Inactive Clicks", value: 3 },
    { label: "Inactive Conversion", value: 4 },
  ];

  const PLACEMENT_OPTS = [
    { label: "Connected", value: 1 },
    { label: "Not Connected", value: 2 },
  ];

  const COUNTRY_OPTS = COUNTRY_CODES.map((c) => c["alpha-2"]);

  const fields = [
    // First Row
    [
      {
        name: "offerSearch",
        type: "search",
        icon: <Search size="18" />,
        placeholder: "Search for Offer ID, Name",
        width: "55%",
      },
      {
        name: "hideInactive",
        type: "checkbox",
        value: false,
      },
    ],
    // Second  Row
    [
      {
        name: "placements",
        type: "select",
        options: PLACEMENT_OPTS,
        placeholder: "Has Placements",
        value: 1,
        key: true,
      },
      {
        name: "internalStatus",
        type: "select",
        options: INTERNAL_STATUS_OPTS,
        placeholder: "Internal Status",
        value: 1,
        key: true,
      },
      {
        name: "advertiser",
        type: "selectSearch",
        options: _.uniq(advertisers.map((a) => `${a.id}::${a.name}`)),
        placeholder: "Advertisers",
      },
      {
        name: "countries",
        type: "selectSearch",
        options: COUNTRY_OPTS,
        placeholder: "Countries",
      },
      {
        name: "rank",
        type: "select",
        options: [1, 2, 3, 4, 5, 6],
        multiple: true,
        value: [],
      },
    ],
  ];

  let sort = sortSettings;
  if (["id", "payout", "name"].indexOf(sort.property) === -1) {
    sort = DEFAULT_SORT;
  }

  useEffect(() => {
    // Fetch data from db
    async function getData() {
      await fetchOffersData({
        ...queryCriteria,
        page,
        pageSize,
        sort,
      });
    }
    // Fetch data
    getData();
  }, [page, pageSize, sort, queryCriteria, fetchOffersData]);

  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}
      <Filters2
        fields={fields}
        handleSubmit={setQueryCriteria}
        disableSubmit={loading}
      />
      {/* <OffersFilters
        data={offers}
        buttons={buttons}
        sort={sortSettings}
        open={filtersVisible}
        advertiser={advertiser}
        advertisers={advertisers}
        // onSubmit={() => getData()}
        handleSubmit={setQueryCriteria}
        setOpen={toggleFilterVisibility}
        direction={!isMobile && "column"}
      /> */}

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading Offers data...">
        <Box style={{ flex: 12, overflowX: "auto" }}>
          <DataTable
            pin
            sortable
            data={offers}
            columns={columns}
            sort={sortSettings}
            rowProps={rowProps}
            key={offers.length}
            background={tableColors}
            onSort={({ direction, property }) =>
              setSortSettings({
                property,
                direction,
              })
            }
          />
        </Box>
        {/* FOTTER  */}
        <PageFotter
          page={page}
          buttons={buttons}
          pageSize={pageSize}
          totalPages={totalPages}
          onChangePagination={(nextPage) => setCurrentPage(nextPage)}
          onChangeSelect={(nextPageSize) => setCurrentPageSize(nextPageSize)}
        />
      </Loader>

      {/* POPUP DIALOG  */}
      {services.getPopup(popupIsVisible, () =>
        setPopupIsVisible(!popupIsVisible)
      )}
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const offers = state.offers;
  const browser = state.browser;
  const mainSidebar = state.mainSidebar;
  const advertisers = state.advertisers.advertisers;
  return { offers, mainSidebar, advertisers, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AdvertisersActions, OffersActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
