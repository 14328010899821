import { getCookie, setCookie, removeCookie } from '../utils/cookie';
import Ax from 'axios';

let initialState;
if (typeof localStorage !== "undefined") {
    const authCookie = getCookie('auth');
    if (authCookie) {
        initialState = JSON.parse(decodeURIComponent(authCookie));
    } else {
        initialState = {
            isLoggedIn: false,
            user: {}
        }
    }
} else {
    initialState = {
        isLoggedIn: false,
        user: {}
    };
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'DEAUTHENTICATE':
            removeCookie("auth");
            removeCookie("token")

            return {
                isLoggedIn: false
            };


        case 'AUTHENTICATE': {
            const authObj = {
                isLoggedIn: true,
                ...action.payload
            };
            setCookie("auth", authObj);
            setCookie("token", authObj.token);
            Ax.defaults.headers.common['Authorization'] = authObj.token;
            return authObj;
        }

        case 'RESTORE_AUTH_STATE':
            return {
                isLoggedIn: true,
                user: action.payload.user,
            };
        default: return state
    }
}
