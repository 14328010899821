import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import { Button, Avatar, Typography } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { FormatLineSpacingRounded } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  pageRouting: {
    height: "10%",
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "red",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRoute: {
    display: "flex",
    border: "1px solid lightgray",
    width: "5%",
    justifyContent: "center",
    padding: "1% 2%",
  },
  filters: {
    height: "10%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "red",
    width: "100%",
  },
  dataTable: {
    height: "90%",
    width: "100%",
    backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
  },
}));

const pageRoutes = [];

function AppsFlyer(props) {
  // styles
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.pageRouting}>
        <div className={classes.buttonRoute}>Config</div>
        <div className={classes.buttonRoute}>Events</div>
        <div className={classes.buttonRoute}>Reports</div>
        <div className={classes.buttonRoute}>Adn</div>
        <div className={classes.buttonRoute}>Combined</div>
      </div>
      {/* Filters  */}
      <div className={classes.filters}>s</div>

      {/* Table  */}
      <div className={classes.dataTable}>s</div>
    </div>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  const mainSidebar = state.mainSidebar;
  const advertisers = state.advertisers;
  const browser = state.browser;
  return { mainSidebar, advertisers, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsFlyer);
