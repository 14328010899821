import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  ResponsiveContainer,
  Line,
  Brush,
  CartesianGrid,
} from "recharts";
import moment from "moment";
import _ from "lodash";
import styled from "styled-components";
import {
  BarChart as BarChartIcon,
  LineChart as LineChartIcon,
  More as MoreVerticalIcon,
} from "grommet-icons";
import { connect } from "react-redux";

//
function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

//
const CustomLineChart = (props) => {
  const { entities, data, theme, isMobile } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 410,
      }}
    >
      <ResponsiveContainer width={"95%"} height={360}>
        <LineChart data={data}>
          <XAxis
            dataKey='date'
            tick={<CustomizedAxisTick theme={theme} isMobile={isMobile} />}
          />
          {!isMobile && (
            <YAxis
              dataKey='revenue'
              tick={<CustomizedYAxisTick theme={theme} isMobile={isMobile} />}
            />
          )}
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid strokeDasharray='3 3' />
          {entities.map((entity) => (
            <Line
              dataKey={entity.name}
              stroke={entity.color}
              strokeWidth={2.5}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

//
const CustomBarsChart = (props) => {
  const { entities, data, theme, isMobile } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 410,
      }}
    >
      <ResponsiveContainer width={isMobile ? "95%" : "95%"} height={360}>
        <BarChart data={data}>
          <XAxis
            dataKey='date'
            tick={<CustomizedAxisTick theme={theme} isMobile={isMobile} />}
          />
          {!isMobile && (
            <YAxis
              dataKey='revenue'
              tick={<CustomizedYAxisTick theme={theme} isMobile={isMobile} />}
            />
          )}
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip content={<CustomTooltip />} />
          {entities.map((entity) => (
            <Bar dataKey={entity.name} fill={entity.color} />
          ))}
          <Brush
            dataKey='date'
            height={isMobile ? 32 : 22}
            stroke='#72ABCC'
            // startIndex={1}
            // endIndex={70}
            fill={theme === "dark" ? "#303030" : "#ededed"}
            y={328}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

//
const CustomizedYAxisTick = ({ x, y, stroke, payload, theme, isMobile }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={isMobile ? -5 : -10}
        y={0}
        dy={0}
        textAnchor='end'
        fill={theme === "dark" ? "#efefef" : "#323232"}
        // stroke={"lightgray"}
        fontSize={isMobile ? 8 : 10}
        fontWeight={400}
      >
        {convertToInternationalCurrencySystem(payload.value)}
      </text>
    </g>
  );
};

//
const CustomizedAxisTick = ({ x, y, stroke, payload, theme, isMobile }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={12}
        textAnchor='middle'
        fill={theme === "dark" ? "#efefef" : "#323232"}
        fontSize={isMobile ? 8 : 10}
      >
        {`${moment(payload.value).format("MMM D")}`}
      </text>
    </g>
  );
};

//
const CustomTooltip = ({ active, payload, label }) => {
  const dateName = moment(label).format("D MMMM YYYY");

  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <p
          className='label'
          style={{
            fontWeight: 500,
            fontSize: 18,
            textAlign: "center",
          }}
        >
          {dateName}
        </p>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#72ABCC",
            margin: "10px 0",
          }}
        />
        {payload.map((p) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <DotColor color={p.color} />
              <p className='label' style={{ fontSize: 12 }}>{`${_.capitalize(
                p.dataKey
              )}`}</p>
            </div>
            <p
              className='label'
              style={{ fontWeight: 500 }}
            >{`${convertToInternationalCurrencySystem(p.value)}`}</p>
          </div>
        ))}
      </ToolTipContainer>
    );
  }

  return null;
};

//
function ActiveBars(props) {
  const [entities, setEntities] = React.useState(props.colors); // data keys to display on chart
  const [dataDisplay, setDataDisplay] = React.useState("bars");
  const [mobileButtonsDisplay, setMobileButtonsDisplay] = React.useState(false);

  // On Datakey button click
  // push it or pull it from the Datakeys for display list
  const onButtonClick = (entity) => {
    const index = entities.findIndex((e) => e.name === entity.name);
    // console.log({ index: index, entity: entity });
    if (index > -1) {
      const temp = [...entities];
      temp.splice(index, 1);
      setEntities(temp);
    } else {
      const temp = [...entities];
      temp.push(entity);
      setEntities(temp);
    }
  };

  // Property is selected ?
  const isSelected = (entity) => {
    return entities.findIndex((e) => e.name === entity.name) > -1;
  };
  //
  // Bars or Lines Chart
  const setChartView = (entity) => {
    setDataDisplay(dataDisplay === "bars" ? "line" : "bars");
  };

  // icon for the chart display button
  // bars or lines
  const buttonIcon =
    dataDisplay === "bars" ? (
      <BarChartIconCustom size='18px' />
    ) : (
      <LineChartIconCustom size='18px' />
    );

  return (
    <Container>
      {!props.isMobile ? (
        <ButtonsContainer>
          <Buttons>
            {
              // all the entities represnts a datakey on the chart
              props.colors.map((entity) => (
                <Button
                  selected={isSelected(entity)}
                  onClick={() => onButtonClick(entity)}
                >
                  <DotColor color={entity.color} />
                  <div>{_.capitalize(entity.name)}</div>
                </Button>
              ))
            }
          </Buttons>
          <Button selected={true} onClick={() => setChartView()}>
            {buttonIcon}
          </Button>
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          <Button
            selected={true}
            onClick={() => setMobileButtonsDisplay(!mobileButtonsDisplay)}
          >
            <MoreVerticalIconCustom size='18px' />
          </Button>
          <Button selected={true} onClick={() => setChartView()}>
            {buttonIcon}
          </Button>
        </ButtonsContainer>
      )}
      <div
        style={{
          width: "100%",
          // margin: 10,
        }}
      >
        {dataDisplay !== "bars" ? (
          <CustomLineChart
            data={props.data}
            entities={entities}
            theme={props.theme}
            isMobile={props.isMobile}
          />
        ) : (
          <CustomBarsChart
            data={props.data}
            entities={entities}
            theme={props.theme}
            isMobile={props.isMobile}
          />
        )}
      </div>
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  const { dashboard } = state;
  const colors = dashboard.colors;

  return {
    colors,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveBars);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  align-items: end;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.primary};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
`;

const DotColor = styled.div`
  height: 8px;
  width: 8px;
  // border-radius: 50%;
  margin-right: 10px;
  margin-left: -5px;
  background-color: ${(props) => props.color};
  border: 1px solid ${({ theme }) => theme.border};
`;

const Button = styled.div`
  display: flex;
  justifey-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  padding: 6px 10px;
  opacity: ${(props) => (props.selected ? 1 : 0.9)};
  background-color: ${(props) =>
    !props.selected ? props.theme.contrast : props.theme.body};
  color: ${({ theme }) => theme.text};
  margin: 10px;
  box-shadow: ${(props) =>
    props.selected ? "0px 3px 5px 2px rgba(0, 0, 0, 0.13)" : "none"};
  font-weight: 400;

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.selected ? props.theme.panel : "inherit"};
  }

  &:active {
    box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.17);
    background-color: ${({ theme }) => theme.body};
  }
`;

const ToolTipContainer = styled.div`
  background-color: ${({ theme }) => theme.body};
  border: 1px solid ${({ theme }) => theme.border};
  padding: 4px 16px;
  border-radius: 0px;
  min-width: 12vw;
  margin-top: -200px;
  box-shadow: 0px 5px 12px 0px rgb(0, 0, 0, 0.5);
`;

const BarChartIconCustom = styled(BarChartIcon)`
  stroke: ${({ theme }) => theme.icon1};
`;

const LineChartIconCustom = styled(LineChartIcon)`
  stroke: ${({ theme }) => theme.icon1};
`;

const MoreVerticalIconCustom = styled(MoreVerticalIcon)`
  stroke: ${({ theme }) => theme.icon1};
`;
