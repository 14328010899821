import React, { Component } from "react";
import _ from "lodash";
import { Box, TextInput, Collapsible, Select, CheckBox } from "grommet";

import { Search } from "grommet-icons";
import SelectSearchInput from "../../components/xComponents/SearchInput";
import Filters from "../../components/Filters";
import PageHeader from "../../components/PageHeader";
import COUNTRY_CODES from "../../constants/country-codes.json";

const COUNTRY_OPTS = COUNTRY_CODES.map((c) => c["alpha-2"]);

const PLACEMENT_OPTS = [
  { label: "Connected", value: 1 },
  { label: "Not Connected", value: 2 },
];

const INTERNAL_STATUS_OPTS = [
  { label: "All Active", value: 1 },
  { label: "All Inactive", value: 2 },
  { label: "Inactive Clicks", value: 3 },
  { label: "Inactive Conversion", value: 4 },
];

// handle fields change on add advertiser layer

function OffersF(props) {
  const {
    open,
    advertisers,
    advertiser,
    direction,
    setOpen,
    handleSubmit,
    onSubmit,
  } = props;

  const [criteria, setCriteria] = React.useState({
    advertiser: "",
    internalStatus: 0,
    placements: 0,
    rank: [],
    countries: "",
    media: "",
    hideInactive: false,
  });

  const fieldsRows = [
    //row #1
    [
      // field #1
      {
        fieldContainer: {
          width: "55%",
          gap: "6px",
          align: "start",
          margin: { right: "35px" },
        },
        field: (
          <TextInput
            icon={<Search size="18" />}
            name="offerSearch"
            type="search"
            size="small"
            onChange={(e) => handleFiltersChange(e)}
            placeholder="Search for Offer ID, Name"
          />
        ),
      },
      // field #2
      {
        fieldContainer: {},
        field: (
          <CheckBox
            label={"Hide Inactive"}
            name="hideInactive"
            checked={criteria.hideInactive}
            onChange={(event) => handleFiltersChange(event)}
            style={{ backgroundColor: "green" }}
          />
        ),
      },
    ],
    //row #2
    [
      // field #1 - placements
      {
        fieldContainer: {
          gap: "6px",
          align: "start",
          // margin: { right: "35px" },
        },
        field: (
          <Select
            value={
              PLACEMENT_OPTS.filter(
                (p) => p.value === criteria.placements
              )[0] || ""
            }
            labelKey="label"
            clear
            size="small"
            valueKey={{ key: "value" }}
            onChange={(event) => handleFiltersChange(event)}
            name="placements"
            options={PLACEMENT_OPTS}
            placeholder="Has Placements"
          />
        ),
      },
      // field #2
      {
        fieldContainer: {},
        field: (
          <Select
            placeholder={"Internal Status"}
            value={
              INTERNAL_STATUS_OPTS.filter(
                (p) => p.value === criteria.internalStatus
              )[0] || ""
            }
            labelKey="label"
            valueKey={{ key: "value" }}
            clear
            size="small"
            onChange={(event) => handleFiltersChange(event)}
            name="internalStatus"
            options={INTERNAL_STATUS_OPTS}
          />
        ),
      },
      // field #3
      {
        fieldContainer: {},
        field: (
          <SelectSearchInput
            name="advertiser"
            size="small"
            value={advertiser}
            placeholder="Advertisers"
            onChange={(e) => handleFiltersChange(e)}
            options={_.uniq(advertisers.map((a) => `${a.id}::${a.name}`))}
          />
        ),
      },
      // field #4
      {
        fieldContainer: {},
        field: (
          <SelectSearchInput
            name="countries"
            size="small"
            value={criteria.countries}
            placeholder="Countries"
            onChange={(e) => handleFiltersChange(e)}
            options={COUNTRY_OPTS}
          />
        ),
      },
      // field #5
      {
        fieldContainer: {},
        field: (
          <Select
            placeholder={"Rank"}
            value={criteria.rank || []}
            name="rank"
            clear
            size="small"
            flex
            options={[1, 2, 3, 4, 5, 6]}
            multiple
            onChange={(e) => handleFiltersChange(e)}
            messages={{
              multiple: [...criteria.rank].join(","),
            }}
          />
        ),
      },
    ],
  ];

  function debouncedHandleSubmit() {
    _.debounce(handleSubmit, 500);
  }

  function handleFiltersChange(e) {
    const { value, name, type, checked } = e.target;

    let newValue = (e.option && e.option) || e.value ? e.value : value;

    if (type === "checkbox") {
      newValue = checked;
    }

    if (typeof newValue === "object" && newValue.label) {
      newValue = newValue.value;
    }

    if (name === "rank") {
      newValue = e.value || [];
    }

    if (["placements", "internalStatus"].indexOf(name) > -1) {
      newValue = e.value.value || 0;
    }

    if (
      ["advertiser", "countries"].indexOf(name) > -1 &&
      typeof newValue !== "object"
    ) {
      newValue = [];
    }

    let newValues = {};

    newValues = {
      ...criteria,
      ...{ [name]: newValue },
    };

    if (name === "offerSearch") {
      debouncedHandleSubmit(newValues);
      return setCriteria(newValues);
    }

    handleSubmit(newValues);
    setCriteria(newValues);
  }

  return (
    <Filters
      fieldsRows={fieldsRows}
      onChange={handleFiltersChange}
      onSubmit={onSubmit}
    />
  );
}

export default OffersF;
