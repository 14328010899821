import { Text, Box, Collapsible } from "grommet";
import React, { useState } from "react";
import SidebarsActions from "../../actions/SidebarsActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function PageHeader(props) {
  const [fullScreen, setFullScreen] = useState(false);
  const { label, actionBar } = props;

  return (
    <Box border="bottom">
      <Box
        fill
        background="#333333"
        style={{ height: "5vh" }}
        justify="between"
        pad="small"
        direction="row"
        align="center"
        gap="small"
      >
        <Text
          size="15px"
          style={{
            textTransform: "capitalize",
            fontWeight: "500",
            minWidth: "fit-content",
          }}
        >
          {label}
        </Text>
        <Box fill>
          {actionBar && (
            <Box justify="end" direction="row">
              {actionBar}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  return state.mainSidebar;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, SidebarsActions), dispatch);
  //   return bindActionCreators(OffersActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
