import _ from "lodash";

const offers = (state = { loading: 0, offers: [], totalPages: 0, advertiser: [] }, action) => {
  switch (action.type) {
    case "OFFERS_DATA_LOADING":
    case "OFFERS_RESPONSE_DATA_LOADED": {
      return { ...state, ...action.payload };
    }
    case "OFFERS_FILTERED": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default offers;
