
import Ax from 'axios';
import Moment from 'moment';

export const fetchAppsFlyerAdnData = (queryObj) => {
  return async (dispatch) => {
    dispatch({
      type: "AF_ADN_LOADING_DATA",
      payload: {
        loading: 1
      }
    })
    try {

      if (!queryObj.fromDate && !queryObj.toDate) {
        queryObj = {
          ...queryObj,
          ...{
            fromDate: Moment.utc().startOf("day").format(),
            toDate: Moment.utc().format()
          }
        }
      }

      const apiUrl = `/api/appsflyer-adn-new/all`;
      const response = await Ax.post(apiUrl, queryObj);
      const payload = response.data;


      dispatch(payload);
      //   Router.push("/");


    } catch (e) {
      // dispatch({
      //   type: 'APPS_FLYER_LOADING_FAILED'
      // })
      if (e.name=== 'Error') {
        dispatch({
          type: 'TRACKRC_CONN_LOST',
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later", 
            status: 'critical',
            dismissMs: 60000
          }
        })
      }
      console.info(e.name)
    }
    

  }
}



export const getInitialData = (req) => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_AF_ADN_DATA",
      payload: {
        loading: 1
      }
    })
    try {

      const apiUrl = `/api/appsflyer-adn/all`;
      const response = await Ax.get(apiUrl);
      const payload = response.data;


      // console.info(payload)
      dispatch(payload);
      //   Router.push("/");


    } catch (e) {
      // dispatch({
      //   type: 'AF_ADN_LOADING_FAILED'
      // })
      if (e.name=== 'Error') {
        dispatch({
          type: 'TRACKRC_CONN_LOST',
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later", 
            status: 'critical',
            dismissMs: 60000
          }
        })
      }
      console.info(e.name)
    }
  };
};


export const updateAppBundleConfig = (updateData) => {
  return async (dispatch) => {
    try {

      const apiUrl = `/api/appsflyer-adn/app-bundles/update`;

      const response = await Ax.post(apiUrl, updateData);
      const payload = response.data;

      dispatch(payload)
      return payload;

    } catch (e) {
      console.error(e)
    }
  };
};

export const resetAppBundleData = (appData) => {
  return async (dispatch) => {
    try {

      const apiUrl = `/api/appsflyer-adn/app-bundles/reset`;

      const response = await Ax.post(apiUrl, appData);
      const payload = response.data;

      dispatch(payload)
      return payload;

    } catch (e) {
      console.error(e)
    }
  };
};


export const createAppBundle = (actionData, remove = false) => {
  return async (dispatch) => {
    try {
      // console.info({ actionData })

      const { action, ...data } = actionData;
      const command = action;
      const apiUrl = `/api/appsflyer-adn/app-bundles/${command}`;

      const response = await Ax.post(apiUrl, data);
      const payload = response.data;

      dispatch(payload)
      return payload;
    } catch (e) {
      console.error(e)
    }
  };
};

export const deleteAppBundle = (data) => {
  //createAppBundle(data, true)
};


export const createAppBundleEvent = (data, update = false) => {
  return async (dispatch) => {
    try {
      // console.info({ updateData })
      const command = update ? 'update' : 'create';
      const apiUrl = `/api/appsflyer-adn/app-bundle-events/${command}`;


      const response = await Ax.post(apiUrl, data);
      const payload = response.data;
      dispatch(payload)
      return payload;
    } catch (e) {
      console.error(e)
    }
  };
};


export const updateAppBundleEvent = (data) => createAppBundleEvent(data, true);



export default {
  getInitialData,
  updateAppBundleConfig,
  resetAppBundleData,
  createAppBundle,
  deleteAppBundle,
  updateAppBundleEvent,
  createAppBundleEvent
}