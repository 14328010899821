import _ from "lodash";

const skadnRedirects = (state = { loading: 0, skadnRedirects: [] }, action) => {
  switch (action.type) {
    case "SKADN_REDIRECTS_DATA_LOADING":
    case "SKADN_REDIRECTS_DATA_LOADED": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default skadnRedirects;
