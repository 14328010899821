import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import CanvasJSReact from "../../../assets/canvasjs.react";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    padding: "3% 0",
    margin: "7% 0%",
  },
}));

export default function Chart() {
  const classes = useStyles();

  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    title: {
      //   text: "Stock Price of BMW - March 2018",
    },
    axisY: {
      title: "Temperature (°F)",
      suffix: " °F",
    },
    axisX: {
      valueFormatString: "MMM YYYY",
    },
    data: [
      {
        type: "rangeArea",
        xValueFormatString: "MMM YYYY",
        yValueFormatString: "#0.## °F",
        toolTipContent:
          ' <span style="color:#6D78AD">{x}</span><br><b>Min:</b> {y[0]}<br><b>Max:</b> {y[1]}',
        dataPoints: [
          { x: new Date("2017-01"), y: [37, 55] },
          { x: new Date("2017-02"), y: [37, 57] },
          { x: new Date("2017-03"), y: [43, 63] },
          { x: new Date("2017-04"), y: [46, 68] },
          { x: new Date("2017-05"), y: [55, 75] },
          { x: new Date("2017-06"), y: [63, 84] },
          { x: new Date("2017-07"), y: [66, 90] },
          { x: new Date("2017-08"), y: [64, 86] },
          { x: new Date("2017-09"), y: [61, 81] },
          { x: new Date("2017-10"), y: [54, 73] },
          { x: new Date("2017-11"), y: [46, 64] },
          { x: new Date("2017-12"), y: [39, 59] },
        ],
      },
    ],
  };

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  return (
    <div className={classes.chartContainer}>
      <div
        style={{
          height: "90%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          //   borderBottom: "1px solid lightgray",
        }}
      >
        <div
          style={{
            margin: "0% 1%",
            color: "#595959",
            fontSize: "120%",
          }}
        >
          Chart Name
        </div>
        <InfoIcon
          style={{
            fontSize: "100%",
            color: "#595959",
            margin: "0% 1%",
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "5px",
          backgroundColor: "white",
          height: "90%",
          width: "98%",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "0.5% 0",
        }}
      >
        <div>
          <CanvasJSChart
            options={options}
            /* onRef = {ref => this.chart = ref} */
          />
        </div>
        <div></div>
      </div>
    </div>
  );
}
