import React, { useEffecrt, useEffect, useState } from "react";
import { debounce } from "debounce";
import { Unlock, Key } from 'grommet-icons';

import Ax from 'axios';
import useSWR from 'swr';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "moment";
import UserManagementActions from "../../actions/UserManagementActions";
import AppBarActions from "../../actions/AppBarActions";
import _ from "lodash";
import { minimalTheme } from "../../themes/main-theme";
import {
    Box,
    Grommet,
    Button,
    Form,
    Tip,
    Text,
    Layer,
    CheckBox,
    Heading,
    Spinner,
    TextInput,
    DataTable,
    Select,
    TextArea,
    FormField,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableRow,
} from "grommet";
import { Edit } from "grommet-icons";
import { validate } from "numeral";
import Loader from '../../components/Loader';

const fetcher = (url, payload = {}) =>
    Ax.post(url, payload).then(({ data }) => {
        return data;
    });


const columns = [
    { name: 'id', label: 'ID' },
    { name: 'username', label: "Username" },
    { name: 'last_login', label: "Last Login" },
    { name: 'failed_attempts', label: "Failed Attempts" },
    { name: 'blocked', label: "Blocked" },
    { name: 'actions', label: "Actions" },


]

function UserMangement(props) {
    const {
        users,
        getUsersData,
        updateTrackRCUser
    } = props;

    const [search, setSearch] = useState("");
    const [passLayer, setPassLayer] = useState("");
    const [newPass, setNewPass] = useState("");

    const [inProgress, setInProgress] = useState(false);

    const { data, error, mutate } = useSWR(
        ["/api/users/track-users-search", { search }],
        fetcher
    );

    return (
        <Grommet theme={minimalTheme} full>
            <Box pad={{ horizontal: "medium", vertical: "small" }} fill flex>

                <Box direction="column" align="start">
                    <Box flex fill="horizontal" direction='row' align='center' gap="small">
                        <Box>
                            <Heading level="2" >TrackRC Users
                            </Heading>
                        </Box>
                        <Box flex align="end">
                            {inProgress ? <Spinner size="medium" /> : null}
                        </Box>
                    </Box>
                    <Box align="center">
                        <TextInput value={search} onChange={({ target }) => setSearch(target.value)} />
                    </Box>
                </Box>
                {passLayer ? <Layer onEsc={() => setPassLayer("")}>
                    <Box align="start" pad={{ horizontal: "medium", bottom: "medium" }} gap="small">
                        <Box flex fill="horizontal" direction='row' align='center' gap="small">
                            <Box>
                                <Heading level={3}>Reset Password</Heading>
                            </Box>
                            <Box flex align="end">
                                {inProgress ? <Spinner size="medium" /> : null}
                            </Box>
                        </Box>
                        <Box direction="row" gap="small">
                            <Text>Username</Text>
                            <Text color="brand">{passLayer}</Text>
                        </Box>
                        <TextInput value={newPass} onChange={({ target }) => setNewPass(target.value)} placeholder="new password" />
                        <Button primary
                            label="Update"
                            disabled={false}
                            onClick={async () => {
                                setInProgress(true)
                                await updateTrackRCUser({
                                    password: newPass,
                                    username: passLayer,
                                })
                                mutate()
                                setInProgress(false)
                                setPassLayer("")
                            }} />
                    </Box>
                </Layer> : null}
                <Table>
                    <TableHeader>
                        <TableRow>
                            {columns.map((c) => (
                                <TableCell key={c.name} scope="col" align={c}>
                                    <Text>{c.label}</Text>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data && data.list && data.list.map((datum) => (
                            <TableRow key={datum.id}>
                                {columns.map((c) => c.name === "actions" ?
                                    <TableCell key={`${datum.id}_${c.name}`}>
                                        <Box direction='row'>
                                            {/* UNBLOCK BUTTON */}
                                            <Button
                                                plain
                                                icon={<Unlock />}
                                                disabled={!datum["blocked"]}
                                                onClick={async () => {
                                                    setInProgress(true)
                                                    await updateTrackRCUser({ unblock: datum.username })
                                                    mutate()
                                                    setInProgress(false)
                                                }}
                                            />
                                            {/* PASSWORD RESET BUTTON */}

                                            <Button icon={<Key />} onClick={() => setPassLayer(datum.username)} />

                                        </Box>
                                    </TableCell>
                                    : (
                                        <TableCell key={`${datum.id}_${c.name}`}>
                                            <Text>{datum[c.name]}</Text>
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>

            </Box>
        </Grommet>
    );
}
// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
    // const { loading, networks } = state.networks;
    return state.users;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, UserManagementActions, AppBarActions),
        dispatch
    );
    //   return bindActionCreators(OffersActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMangement);
