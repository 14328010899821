import React, { useEffecrt, useEffect, useState } from "react";
import { connect } from "react-redux";
import ConvertActions from '../../actions/ConvertClicksActions';
import { debounce } from 'lodash';
import { NumberInput } from 'grommet-controls'
import styled from 'styled-components';
import {
  Box,
  Grommet,
  Button,
  Form,
  List,
  Tip,
  Text,
  Layer,
  Spinner,
  Heading,
  CheckBox,
  TextInput,
  DataTable,
  Select,
  TextArea,
  FormField,
} from "grommet";
import { Search, Add, Send } from 'grommet-icons';
import { minimalTheme } from "../../themes/main-theme";
import Moment from 'moment';



const GROUP_MAP = {
  UUID: 'uuid',
  Placement: 'placement_id'
}

function ConvertClicks(props) {
  const [groupValue, setGroupValue] = React.useState('');
  const [currentSelection, setSelected] = React.useState([]);

  const {
    sendConvertClicks,
    syncConvertData,
    convertInProgress,
    clicks,
    lastDataFetch
  } = props;


  useEffect(() => {
    syncConvertData()
  }, [lastDataFetch, syncConvertData])


  // useEffect(() => {
  //   if (currentSelection.length && convertInProgress) {
  //     setSelected([])
  //   }
  // }, [currentSelection.length, convertInProgress])

  console.info({ currentSelection })
  // console.info({currentSelection})
  return (
    <Grommet theme={minimalTheme} style={{ width: '100%', maxHeight: '100%' }}>

      <Box pad={'small'}
      style={{  maxHeight: '100%' }}
      >
        {/* <Heading level="3" size='large' margin={{ vertical: "small" }}>Jobs Placements</Heading> */}
        <Box direction='row' align='center' gap='small' pad='small' style={{ paddingBottom: 6 }}>
          <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
            <Select
              options={['None', 'UUID', 'Placement']}
              placeholder={"Group By"}
              value={groupValue === "None" ? "" : groupValue}
              onChange={({ option }) => setGroupValue(option === "None" ? "" : option)}

            />
          </Box>

          <Box flex='shrink' size='small' >
            <Button
              primary
              icon={<Send />}
              disabled={!currentSelection.length}
              onClick={() => {
                sendConvertClicks(currentSelection).then(() => {
                  setSelected([])
                })
              }}
              label={currentSelection.length ? `Convert Selected Clicks ${currentSelection.length}` : "No Clicks"} />
          </Box>
          {convertInProgress && (
            <Layer>
              <Box
                align="center"
                justify="center"
                gap="small"
                direction="row"
                alignSelf="center"
                pad="large"
              >
                <Spinner size='medium' />
                <Text size='large'>Conversion in progress...  ({currentSelection.length} clicks) </Text>
              </Box>
            </Layer>
          )}
        </Box>


        <Box
                style={{  maxHeight: '85vh',  overflowY: 'scroll', overflowX: 'hidden'  }}

        >

          <DataTable
            columns={[
              {
                property: 'id',
                header: "ID",
                // primary: true,
                // footer: 'Total',
              },
              {
                property: 'uuid',
                header: "UUID",
                render: ({ uuid }) => {
                  if (uuid)
                    return uuid.split("-")[0]
                }
              },
              {
                property: 'placement_id',
                header: "Placement",
                render: ({ placement_id }) => {
                  if (placement_id)
                    return placement_id
                }
              },
              {
                property: 'click_id',
                header: "Click ID",
                primary: true,

              },
              {
                property: 'converted',
                header: "Converted",
                render: ({ converted }) => {
                  if (typeof converted !== 'undefined')
                    return Boolean(converted) ? "YES" : "NO"
                }


              },
              {
                property: 'clicks_server',
                header: "Server",
                render: ({ clicks_server }) => {
                  if (clicks_server)
                    return clicks_server.toUpperCase()
                }

              },
              {
                property: 'created',
                header: "Created",
                render: ({ created }) => { return Moment(created).format("MM-DD HH:mm") }
              },
              {
                property: 'updated',
                header: "Updated",
                render: ({ updated }) => { return Moment(updated).format("MM-DD HH:mm") }
              },

            ]}
            groupBy={GROUP_MAP[groupValue] ? { property: GROUP_MAP[groupValue] } : null}
            onSelect={(newSelect) => {
              const filtered = newSelect.filter(s => clicks.find(c => c.click_id === s && !c.converted))
              setSelected(v => v.length === filtered.length ? [] : filtered)
            }}
            select={currentSelection}
            sortable
            data={clicks}

          />
        </Box>

      </Box>
    </Grommet>
  );
}


const mapStateToProps = (state, props) => {
  const { convertClicks } = state;
  const { jobs, clicks, lastDataFetch, convertInProgress } = convertClicks

  return {
    jobs,
    clicks,
    lastDataFetch,
    convertInProgress,
  };
};

export default connect(mapStateToProps, { ...ConvertActions })(ConvertClicks);

