import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";

//
function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

function ActivePieChartPanel(props) {
  const { overed, isMobile } = props;

  return (
    <Container isMobile={isMobile}>
      <div
        style={{
          width: "100%",
          height: isMobile ? 50 : 120,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          fontSize: 13,
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: 18 }}>{overed && overed.publisher}</div>
        <div style={{ fontSize: 18 }}>{overed && overed.app_name}</div>
        <div style={{ fontSize: 14, fontWeight: 300 }}>
          {overed && overed.app_bundle}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          // height: "100%",
          // backgroundColor: "red",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          // padding: 15,
        }}
      >
        {props.colors
          .filter(
            (c) =>
              c.name === "revenue" || c.name === "cost" || c.name === "profit"
          )
          .map((e) => (
            <div
              style={{
                // height: "100%",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-between",
                // alignItems: "start",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: "300",
                  // color: e.color,
                }}
              >
                <span
                  style={{
                    backgroundColor: e.color,
                    width: 6,
                    height: 6,
                    // borderRadius: "50%",
                    marginRight: 10,
                  }}
                />
                <div>{_.capitalize(e.name)}</div>
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "end",

                  // WebkitTextStroke: "0.00001rem lightgray",
                }}
              >
                {`${convertToInternationalCurrencySystem(overed[e.name])} `}
                {/* <span
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    marginLeft: 1,
                    //   color: "gray",
                    //   opacity: 0.7,
                  }}
                >
                  $
                </span> */}
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  const { dashboard } = state;
  const colors = dashboard.colors;

  return {
    colors,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivePieChartPanel);

const Container = styled.div`
  width: ${(props) => (props.isMobile ? "80%" : "25%")};
  word-break: break-all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isMobile ? props.theme.panel : props.theme.body};
  // border-top: ${(props) => (props.isMobile ? "0" : "1px")} solid
  //   ${({ theme }) => theme.border};
  //   min-height: 65%;
  padding: 12px;
  border-radius: 5px;
  margin: ${(props) => (props.isMobile ? "0  0 5px 0" : "0 10px")};
`;
