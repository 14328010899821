import React, { useEffecrt, useEffect, useState } from "react";
import { connect } from "react-redux";
import useSWR from "swr";
import Ax from "axios";

import { CSVLink } from "react-csv";

import ConvertActions from "../../actions/ConvertClicksActions";
import { debounce } from "lodash";
import { NumberInput } from "grommet-controls";
import styled from "styled-components";
import {
  Box,
  Grommet,
  Button,
  Form,
  List,
  Tip,
  Text,
  Layer,
  Spinner,
  Heading,
  CheckBox,
  TextInput,
  Select,
  TextArea,
  FormField,
  DataTable,
  DateInput,
} from "grommet";
import { Search, Add, Send, Copy, View, Download } from "grommet-icons";
import { minimalTheme } from "../../themes/main-theme";
import Moment from "moment";
// import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";
import { useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";

const TableCell = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  & > .desc {
    font-size: 5px;
  }
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "normal"};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  color: ${(props) => (props.type ? props.color : "black")};
  text-overflow: ${(props) => (props.type ? "inherit" : "ellipsis")};
  margin: 0px;
  padding: 0;
  text-align: ${(props) => (props.start ? "start" : "center")};
  & > p {
    margin: 4px 0;
  }
`;

const RAW_FIELDS = [
  "attributed_time",
  "install_time",
  "event_time",
  "event_name",
  "partner",
  "publisher",
  "channel",
  "campaign",
  "site_id",
  "country_code",
  "afid",
  "platform",
  "app_bundle",
  "oclickid",
  "agency_id",
];

const timeFrames = {
  today: { startingDate: Moment(new Date()).format("YYYY-MM-DD") },
  yestrday: {
    startingDate: Moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
    endingDate: Moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
  },
  week: {
    startingDate: Moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
  },
  monthToDate: {
    startingDate: Moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  },
};

const fetcher = (url, payload = {}) =>
  Ax.get(url, payload).then(({ data }) => {
    return data;
  });

const agencies = {
  ALL: -1,
  CYGOBELMEDIA: 0,
  MOBLICA: 1,
  CLEARPIERPER: 2,
  KPMBROMEDIA: 3,
  VIDOMO: 4,
  LOPOEMEDIA: 5,
};

function AfapiRawData(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { data, error, mutate, isValidating } = useSWR(
    [
      `/api/appsflyer/agency-raw-data${window.location.search}`,
      { action: "get", test: 1 },
    ],
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    searchParams.set("withEventValue", "true");
    setSearchParams(searchParams);
  }, []);

  // useEffect(() => {
  //   if (data && data[0]?.event_value) {
  //     if (tableColumns[6].property !== "event_value") {
  //       tableColumns.splice(6, 0, {
  //         property: "event_value",
  //         header: "Value",
  //         align: "center",
  //         render: ({ event_value }) => {
  //           return <div>{+event_value}</div>;
  //         },
  //       });
  //     }
  //   } else {
  //     if (tableColumns[6].property === "event_value") {
  //       tableColumns.splice(6, 1);
  //     }
  //   }
  // }, [data]);

  console.info(data);

  const updateFilters = (name, value) => {
    if (!value) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    setSearchParams(searchParams);
  };

  return (
    // <Grommet
    //   theme={minimalTheme}
    //   style={{ width: "100%", maxHeight: "100%", fontSize: 12 }}
    // >
    <Box
      flex
      fill
      // width="100%"
      margin='none'
      overflowY='auto'
      alignSelf='center'
    >
      <Box
        direction='row'
        align='center'
        gap='small'
        fiil
        pad={{ vertical: "small", horizontal: "small" }}
        border='bottom'
        // style={{ paddingBottom: 6 }}
      >
        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
          <Select
            options={Object.keys(timeFrames)}
            placeholder={"Time Frame"}
            value={searchParams.get("timeFrame")}
            onChange={({ option }) => {
              updateFilters("startingDate", timeFrames[option].startingDate);
              updateFilters("endingDate");

              if (timeFrames[option].endingDate) {
                updateFilters("endingDate", timeFrames[option].endingDate);
              }
            }}
          />
        </Box>

        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
          <Select
            options={Object.keys(agencies)}
            placeholder={"Agency"}
            value={agencies[searchParams.get("agency")]}
            onChange={({ option }) => {
              if (option === "ALL") {
                updateFilters("agency");
              } else {
                updateFilters("agency", agencies[option]);
              }
            }}
          />
        </Box>

        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
          <TextInput
            placeholder={"Bundle"}
            value={searchParams.get("bundle") || ""}
            onChange={({ target: { value } }) => updateFilters("bundle", value)}
          />
        </Box>

        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
          <TextInput
            placeholder={"Event Name"}
            value={searchParams.get("event") || ""}
            onChange={({ target: { value } }) => updateFilters("event", value)}
          />
        </Box>

        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
          <TextInput
            placeholder={"Publisher"}
            value={searchParams.get("publisher") || ""}
            onChange={({ target: { value } }) =>
              updateFilters("publisher", value)
            }
          />
        </Box>
        <Box flex='shrink' size='small' style={{ maxWidth: 250 }}>
          <TextInput
            placeholder={"Channel"}
            value={searchParams.get("channel") || ""}
            onChange={({ target: { value } }) =>
              updateFilters("channel", value)
            }
          />
        </Box>

        <Box
          direction='raw'
          flex='shrink'
          size='small'
          style={{ maxWidth: 200 }}
          align='center'
        >
          <Select
            valueLabel={
              (option) =>
                // <Text size='9px' margin='xsmall'>{
                `${option} results `
              // }</Text>
            }
            options={[100, 200, 300]}
            placeholder={"Results per page"}
            value={+searchParams.get("limit") || 100}
            onChange={({ option }) => updateFilters("limit", option)}
          />
        </Box>

        <Box flex='shrink' size='small'>
          <CheckBox
            checked={Boolean(searchParams.get("withEventValue"))}
            label='With Event Value'
            onChange={(event) =>
              updateFilters("withEventValue", Boolean(event.target.checked))
            }
          />
        </Box>

        <Box
          // flex='grow'
          // size='small'
          // fiil
          alignSelf='end'
        >
          {data && (
            <CSVLink
              data={data}
              filename={`raw-data${window.location.search}.csv`}
            >
              <Button
                primary
                disabled={!!!data?.length}
                icon={<Download size='small' />}
                style={{ borderRadius: 5 }}
              />
            </CSVLink>
          )}
        </Box>
      </Box>

      <Loader loading={isValidating || !data} text='Loading data...'>
        <Box style={{ flex: 13, overflowX: "auto" }}>
          {data?.length > 0 ? (
            <DataTable
              columns={tableColumns}
              paginate
              pad={{
                horizontal: "medium",
              }}
              background={{
                header: "dark-2",
                body: ["white", "light-2"],
                footer: { dark: "light-2", light: "dark-3" },
              }}
              sortable
              data={data}
            />
          ) : (
            <Box>No Results</Box>
          )}
        </Box>
      </Loader>
    </Box>
    // </Grommet>
  );
}

const mapStateToProps = (state, props) => {
  const { convertClicks } = state;
  const { jobs, clicks, lastDataFetch, convertInProgress } = convertClicks;

  return {
    jobs,
    clicks,
    lastDataFetch,
    convertInProgress,
  };
};

export default connect(mapStateToProps, { ...ConvertActions })(AfapiRawData);

/*
Raw data report 

Date = Event Time
Filter: Event type = Reg or FTD
Partner = Agency name 
Media Source = Publisher (PID) 
Channel = Channel (PubID_cost)
Column - Publisher - take the PubID from the Channel. check TrackRC who is the Pub -> write the pub name
Campaign = Campaign
Country Code
Platform (IOS & Android)
ClickID Starts with 11e

 Events: Reg & FTD 
Revenue 
Cost 
Profit 


*/
const tableColumns = [
  // {
  //   property: 'id',
  //   header: "ID",
  //   // primary: true,
  //   // footer: 'Total',
  // },
  {
    property: "event_time",
    header: "Event",
    render: ({ event_time }) => {
      return (
        <Cell style={{ width: "max-content" }}>
          {Moment(event_time).format("MM-DD-YY HH:mm")}
        </Cell>
      );
    },
  },
  {
    property: "event_name",
    header: "Event Key",
    render: ({ event_name }) => {
      return (
        <Cell start style={{ width: "max-content" }}>
          {event_name}
        </Cell>
      );
    },
  },
  {
    property: "event_original_name",
    header: "Event Name",
    render: ({ event_original_name }) => {
      return <Cell start>{event_original_name}</Cell>;
    },
  },
  {
    property: "app_bundle",
    header: "App Bundle",
    render: ({ app_bundle }) => {
      return <Cell start>{app_bundle}</Cell>;
    },
  },
  {
    property: "partner",
    header: "Agency",
    render: ({ partner }) => {
      return <Cell start>{partner}</Cell>;
    },
  },
  {
    property: "publisher",
    header: "Publisher",
    render: ({ publisher }) => {
      return <Cell start>{publisher}</Cell>;
    },
  },
  {
    property: "publisher_group",
    header: "Publisher Group",
    align: "center",
    render: ({ publisher_group }) => {
      return (
        <Cell style={{ textTransform: "uppercase" }}>{publisher_group}</Cell>
      );
    },
  },
  {
    property: "event_value",
    header: "Value",
    align: "center",
    render: ({ event_value }) => {
      return (
        <Cell type color='blue'>
          {event_value ? +event_value : "-"}
        </Cell>
      );
    },
  },
  {
    property: "cost",
    header: "Cost",
    align: "center",
    render: ({ cost }) => {
      return (
        <Cell style={{ color: cost ? "red" : "" }}>{cost ? +cost : "-"}</Cell>
      );
    },
  },
  {
    property: "profit",
    header: "Profit",
    align: "center",
    render: ({ profit }) => {
      return (
        <Cell style={{ color: profit ? "green" : "" }}>
          {profit ? +profit : "-"}
        </Cell>
      );
    },
  },
  {
    property: "channel",
    header: "Channel",
    render: ({ channel }) => {
      return (
        <div>
          <Cell start style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
            {channel}
          </Cell>
        </div>
      );
    },
  },
  {
    property: "campaign",
    header: "Campaign",
    render: ({ campaign }) => {
      return <Cell start>{campaign}</Cell>;
    },
  },
  {
    property: "site_id",
    header: "Site ID",
    render: ({ site_id }) => {
      return (
        <Cell start style={{ width: "max-content" }}>
          {site_id}
        </Cell>
      );
    },
  },
  {
    property: "country_code",
    header: "Country",
    align: "center",
    render: ({ country_code }) => {
      return <Cell>{country_code}</Cell>;
    },
  },
  {
    property: "platform",
    header: "Platform",
    align: "center",
    render: ({ platform }) => {
      return <Cell style={{ textTransform: "uppercase" }}>{platform}</Cell>;
    },
  },
  {
    property: "attributed_time",
    header: "Att.",
    render: ({ attributed_time }) => {
      return (
        <Cell style={{ width: "max-content" }}>
          {Moment(attributed_time).format("MM-DD-YY HH:mm")}
        </Cell>
      );
    },
  },
  {
    property: "install_time",
    header: "Install",
    render: ({ install_time }) => {
      return (
        <Cell style={{ width: "max-content" }}>
          {Moment(install_time).format("MM-DD-YY HH:mm")}
        </Cell>
      );
    },
  },
  {
    property: "afid",
    header: "AFID",
    render: ({ afid }) => {
      return <Cell style={{ width: "max-content" }}>{afid}</Cell>;
    },
  },
  {
    property: "oclickid",
    header: "CID",
    align: "center",
    render: ({ oclickid }) => {
      return (
        <div style={{ display: "flex" }}>
          <Tip content={oclickid}>
            <Button icon={<View size='small' />} />
          </Tip>
          <Button
            icon={<Copy size='small' />}
            onClick={() => navigator.clipboard.writeText(oclickid)}
          />
        </div>
      );
    },
  },
  // {
  //   property: 'click_id',
  //   header: "Click ID",
  //   primary: true,

  // },
  // {
  //   property: 'converted',
  //   header: "Converted",
  //   render: ({ converted }) => {
  //     if (typeof converted !== 'undefined')
  //       return Boolean(converted) ? "YES" : "NO"
  //   }

  // },
  // {
  //   property: 'clicks_server',
  //   header: "Server",
  //   render: ({ clicks_server }) => {
  //     if (clicks_server)
  //       return clicks_server.toUpperCase()
  //   }

  // },
  // {
  //   property: 'created',
  //   header: "Created",
  //   render: ({ created }) => { return Moment(created).format("MM-DD HH:mm") }
  // },
  // {
  //   property: 'updated',
  //   header: "Updated",
  //   render: ({ updated }) => { return Moment(updated).format("MM-DD HH:mm") }
  // },
];
