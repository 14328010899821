import Ax from "axios";
import Moment from "moment";
import { throttle } from "lodash";
import { push } from "connected-react-router";

// Get events.
const getAdjustEvents = () => async (dispatch, getState) => {
  // console.info(dispatch, getState())
  const { adjust } = getState();

  let initialLoad = false;
  if (!adjust.config.length) {
    initialLoad = true;
  }

  const url = `/api/adjust/events`;
  const payload = {
    action: "get",
    initialLoad,
  };
  const { data: action } = await Ax.post(url, payload);
  dispatch(action);
};

// Get all adjust config.
const getAdjustConfig = (enabled) => async (dispatch) => {
  const url = `/api/adjust/config`;
  const payload = {
    enabled,
    action: "get",
  };
  const { data: action } = await Ax.post(url, payload);
  dispatch(action);
};

// Create a new adjust config
const createAdjustConfig = (newAppConfig) => async (dispatch) => {
  console.log(newAppConfig);
  const url = `/api/adjust/config`;
  const payload = {
    action: "create",
    newAppConfig,
  };
  const { data: action } = await Ax.post(url, payload);
  dispatch(action);
};

const updateAdjustEvent = (event) => async (dispatch) => {
  const url = `/api/adjust/events`;
  const payload = {
    action: "update",
    event,
  };
  const { data: action } = await Ax.post(url, payload);
  dispatch(action);
};

const createAdjustEvent = (event) => async (dispatch) => {
  const url = `/api/adjust/events`;
  const payload = {
    action: "create",
    event,
  };
  const { data: action } = await Ax.post(url, payload);
  dispatch(action);
};


const exports = {
  getAdjustConfig,
  createAdjustConfig,
  getAdjustEvents,
  updateAdjustEvent,
  createAdjustEvent
};

export default exports;
