import React, { Component } from "react";
import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import { CSVLink } from "react-csv";
import Numeral from 'numeral';
// import ObjectHash from 'object-hash';
import {
    Text,
    TextInput,
    Box,
    DataTable as GrommetDataTable,
    CheckBox,
    Select,
    Button,
    DropButton,
    Calendar,
    Nav,
    Anchor
} from "grommet";
import _ from 'lodash';
import { FormDown, Download } from 'grommet-icons'

import styled from 'styled-components';


// import VirtualTable from '../VirtualTable'

import DataTable from '../../components/DataTable';
import Cell from '../../components/Cell';
import Chart from '../../components/Chart';


let TIMEZONE = 'Etc/GMT'

let TIMEZONE_OPTS = [TIMEZONE];

[...new Array(8)].forEach((s, i) => {
    TIMEZONE_OPTS = [`Etc/GMT-${i + 1}`, ...TIMEZONE_OPTS, `Etc/GMT+${i + 1}`]
})

function ReportDownloadButton({ reportData }) {
    const now = Moment().format()
    const rows = reportData.length;
    // const hash = ObjectHash(reportData);
    let buttonProps = {
        primary: true,
        icon: <Download />,
        label: 'Download Report'
    };

    if (!reportData.length) {
        return <Button {...buttonProps} label={'No Data'} disabled={true} />
    }
    return (
        <CSVLink
            data={reportData}
            filename={`${now}-${rows}.csv`}
        >
            <Button {...buttonProps} />
        </CSVLink>
    )
}

function GroupByFilter(props) {
    const {
        fieldName,
        value,
        options,
        // groupByFilters,
        onChange,
        onClose
    } = props;

    const values = (value || [])
    const valuesLabels = values.map(o => o.label);

    const orderedOptions = (unorderedOptions) => {
        return [...values, ...unorderedOptions.filter(o => valuesLabels.indexOf(o.label) === -1)]
    }

    const [opts, setOpts] = React.useState(orderedOptions(options));
    // console.info({opts})
    const placeholder = fieldName === 'app_bundle' ? 'App Bundle' : _.capitalize(fieldName)
    // console.info({ value })
    return (
        <Select
            id={`select-${fieldName}`}
            name={`group-by-filter-${fieldName}`}
            placeholder={placeholder}
            value={value}
            closeOnChange={false}

            onSearch={text => {
                // console.info(text)
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                const exp = new RegExp(escapedText, "i");
                // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

                setOpts((options.filter(o => exp.test(o.label))));
            }}
            searchPlaceholder={`Search ${placeholder}`}
            emptySearchMessage={`No ${placeholder} found`}
            onClose={() => {
                onClose()
                // setOpts(=(options))
            }}
            multiple
            size='xsmall'
            valueKey='value'
            labelKey='label'
            options={orderedOptions(opts)}
            onChange={(...e) => {
                onChange(...e)
                // setOpts((options))
            }}
        />
    )
}

const DATE_FILTERS_OPTS = [
    {
        label: 'All',
        func: (data, timezone) => data
    },
    {
        label: 'Today',
        func: (data, timezone) => data.filter(({ date }) => {
            // console.info(date, MomentTZ.utc(date).isAfter(MomentTZ.utc().startOf('day')))
            return MomentTZ(date).tz(timezone)
                .isSameOrAfter(MomentTZ().startOf('day'))
        }
        )
    },
    {
        label: 'Yesterday',
        func: (data, timezone = 'GMT') => data.filter(({ date }) => {
            const moment = MomentTZ(date).tz(timezone)
            return moment.isSameOrAfter(
                MomentTZ()
                    // .tz(timezone)
                    .subtract(1, 'days')
                    .startOf('day')
            ) && moment.isSameOrBefore(
                MomentTZ()
                    // .tz(timezone)
                    .subtract(1, 'days')
                    .endOf('day')
            )
        })
    },
    {
        label: 'This Month',
        func: (data, timezone = 'GMT') => data.filter(({ date }) => {
            const moment = MomentTZ(date).tz(timezone)
            return moment.isSameOrAfter(
                MomentTZ()
                    // .tz(timezone)
                    // .subtract(1, 'days')
                    .startOf('month')
            )
        })
    },
    {
        label: 'Last 7 Days',
        func: (data, timezone) => data.filter(({ date }) => {
            const moment = MomentTZ(date);

            return moment.isSameOrAfter(
                MomentTZ().tz(timezone).subtract(8, 'days').startOf('day'))
                && moment.isSameOrBefore(
                    MomentTZ()
                        .tz(timezone)
                        // .subtract(1, 'days')
                        .startOf('day')
                )

        })
    },
    {
        label: 'Last 30 Days',
        func: (data, timezone) => data.filter(({ date }) => {
            const moment = MomentTZ(date)
            return moment.isSameOrAfter(
                MomentTZ()
                    .tz(timezone)
                    .subtract(31, 'days')
                    .startOf('day')
            ) && moment.isSameOrBefore(
                MomentTZ()
                    .tz(timezone)
                    // .subtract(1, 'days')
                    .startOf('day')
            )
        })
    },
    {
        label: 'Last Week',
        func: (data, timezone) => data.filter(({ date }) => {
            const moment = MomentTZ(date)
            return moment.isSameOrAfter(
                MomentTZ()
                    .tz(timezone)
                    .subtract(1, 'week')
                    .startOf('week')
            ) && moment.isSameOrBefore(
                MomentTZ()
                    .tz(timezone)
                    .subtract(1, 'week')
                    .endOf('week')
            )
        })
    },
    {
        label: 'Last Month',
        func: (data, timezone) => data.filter(({ date }) => {
            const moment = MomentTZ(date)

            // console.info(moment.format(),                 MomentTZ()
            // // .tz(timezone)
            // .subtract(1, 'month')
            // .startOf('month').format())

            return moment.isSameOrAfter(
                MomentTZ()
                    // .tz(timezone)
                    .subtract(1, 'month')
                    .startOf('month')
            ) && moment.isSameOrBefore(
                MomentTZ()
                    // .tz(timezone)
                    .subtract(1, 'month')
                    .endOf('month')
            )
        })
    },
    {
        label: 'YTD',
        func: (data, timezone) => data.filter(({ date }) => {
            const moment = MomentTZ.utc(date)
            return moment.isSameOrAfter(
                MomentTZ()
                    .tz(timezone)
                    .subtract(1, 'year')
                    .startOf('year')
            ) && moment.isSameOrBefore(
                MomentTZ()
                    .tz(timezone)
                    .subtract(1, 'year')
                    .endOf('year')
            )
        })
    }
    // {
    //     label: 'Custom',
    //     disabled: true,
    //     func: data => data
    // }
].map((o, value) => Object.assign(o, { value }))

const fields = [
    // 'id',

    'date',
    'app_bundle',
    'app_name',
    'publisher',
    'campaign',
    'country',
    'impressions',
    'clicks',
    'installs',
    'registrations',
    'ftd'
    // 'ctr',
    // 'cr',
    // 'sessions',
    // 'loyal_users',
    // 'loyal_users_installs',
    // 'roi',
    // 'arpu',
    // 'avg_ecpi',
    // 'rev_event_id',
    // 'gen1_event_id',
    // 'gen2_event_id',
    // 'gen3_event_id',
    // 'cost',
    // 'revenue'
]

function applyGroupBy(filteredData, groupBy) {

    if (groupBy === 'none') {
        return { groupByData: null }
    }

    const groupByData = _.chain(filteredData)
        .groupBy(
            (obj) => obj[groupBy]

        )
        .map((objs) => {
            const [sample] = objs;
            const { date, publisher, campaign, app_bundle, agency, country, ...toReduce } = sample;
            let reduced = {
                date,
                publisher,
                campaign,
                app_bundle,
                agency,
                country
            };

            Object.keys(toReduce)
                .forEach(key => reduced[key] = _.sumBy(objs, key))
            return reduced

        })
        .value()

    return { groupByData }

}

function getUTCMoment(date) {
    return MomentTZ.utc(date)
};

function getTimezoneMoment(date, timezone) {
    // if (timezone !== 'Etc/GMT') {
    return MomentTZ(date).tz(timezone)
    // }
    return MomentTZ.utc(date)
}

function applyDateFilters(data, dateFilter, timezone, calendarInput) {

    const dateFormattedData = data.map(obj => Object.assign({}, obj, {
        date: getTimezoneMoment(obj.date, timezone).format()
    }));

    if (calendarInput) {
        if (Array.isArray(calendarInput) && calendarInput.length) {
            // const [calendarValues] =  calendarInput
            // console.info({ calendarInput })

            const [calendarStart, calendarEnd] = calendarInput;

            // console.info(calendarEnd, getTimezoneMoment(calendarEnd, timezone).format(), timezone )

            const formattedCalendarStart = getTimezoneMoment(calendarStart, timezone).startOf('day');
            const formattedCalendarEnd = getTimezoneMoment(calendarEnd, timezone).endOf('day');
            // console.infno({formattedCalendarStart})
            const filteredData = dateFormattedData.filter(({ date }) => {
                const momentDate = MomentTZ(date)
                // console.info(date, formattedCalendarStart.format(), formattedCalendarEnd.format())
                // console.info('isSameOrAfter', momentDate.isSameOrAfter(
                //     formattedCalendarStart
                // ), 'isSameOrBefore', momentDate.isSameOrBefore(
                //     formattedCalendarEnd
                // ))
                return momentDate.isSameOrAfter(
                    formattedCalendarStart
                ) && momentDate.isSameOrBefore(
                    formattedCalendarEnd
                )
            })
            const updateObj = {
                filteredData,
                dateFilter: {
                    label: 'Custom =>',
                    value: 'custom'
                }
            };
            return updateObj
        }
    }

    const { func } = dateFilter;




    const filteredData = func ? func(dateFormattedData, timezone) : dateFormattedData
    const updateObj = { filteredData, dateFilter, timezone };
    return updateObj
}

function applyDataFilters(doUpdate = true, props, state) {
    // console.info({state}, this)
    const { data } = props || this.props;
    const { timezone, dateFilter, groupByFilters, calendarInput, groupBy } = state || this.state;
    // console.info('apply', { groupByFilters })
    const hasGroupByFilters = groupByFilters && Object.values(groupByFilters).filter(g => g && g.length);
    let filterMap = {};

    Object.values(groupByFilters)
        .filter(g => g && g.length)
        .forEach(f => {
            const [sample] = f;
            const { type } = sample
            filterMap[type] = f.map(f => f.value)
        })


    // console.info(hasGroupByFilters, filterMap)
    let groupFilteredData = hasGroupByFilters.length ? data.filter(r => {
        return (
            (filterMap.app_bundle ? filterMap.app_bundle.indexOf(r.app_bundle) > -1 : true)
            && (filterMap.publisher ? filterMap.publisher.indexOf(r.publisher) > -1 : true)
            && (filterMap.country ? filterMap.country.indexOf(r.country) > -1 : true)
            && (filterMap.campaign ? filterMap.campaign.indexOf(r.campaign) > -1 : true)

        )
    }) : data;
    const filteredDataObj = applyDateFilters(groupFilteredData, dateFilter, timezone, calendarInput);
    // const { filteredData } = filteredDataObj;
    const updateObj = {
        data,
        ...filteredDataObj
    };
    if (doUpdate) {
        this.setState(updateObj)
    }
    // console.info('apply', { updateObj })
    // console.info({ groupByFilters, groupBy })
    return updateObj;

}


class ReportsComponent extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            // INITIAL STATE
            displayCharts: false,
            timezone: 'Etc/GMT+3',
            data: props.data,
            dateFilter: DATE_FILTERS_OPTS[1],
            startDateOpen: false,
            endDateOpen: false,
            groupBy: 'id',
            calendarInput: [],
            groupByData: null,
            filteredData: props.data || [],
            groupByFilters: {}
        };

        this.state = Object.assign(
            {},
            this.initialState,
            applyDataFilters(false, props, this.initialState)
        )


        this.onGroupBy = this.onGroupBy.bind(this);
        this.onGroupByFilter = this.onGroupByFilter.bind(this);
        this.applyDataFilters = applyDataFilters.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        if (
            props.data !== state.data
        ) {
            return applyDataFilters(false, props, state)
        }
        return null;
    }




    applyDateFilters(passedDateFilter, passedTimeZone, calendarInput, noUpdate = false) {
        const { data } = this.props;

        const timezone = passedTimeZone || this.state.timezone;
        if (timezone !== TIMEZONE) {
            TIMEZONE = timezone // hack for columns
        }
        const dateFilter = passedDateFilter || this.state.dateFilter;
        const updateObj = applyDateFilters(data, dateFilter, timezone, calendarInput)
        if (noUpdate) {
            return updateObj
        }
        this.setState(updateObj)
    }

    onDateFilterChange(passedDateFilter, passedTimeZone, passedCalendarInput, noUpdate = false) {

        const timezone = passedTimeZone || this.state.timezone;
        const dateFilter = passedDateFilter || this.state.dateFilter;
        const calendarInput = passedDateFilter ? [] : passedCalendarInput || this.state.calendarInput;
        // console.info({ calendarInput })
        const updateObj = {
            timezone,
            dateFilter,
            calendarInput
        };

        if (noUpdate) {
            return updateObj
        }
        // console.info({ updateObj })
        this.setState(updateObj, this.applyDataFilters)
        // console.info(this.state)
    }

    applyGroupBy() {
        const { groupBy, filteredData } = this.state;


        const updateObj = applyGroupBy(filteredData, groupBy)

        this.setState(updateObj)
    }
    onGroupBy({ option }) {
        const groupBy = option !== 'none' ? option : 'id';
        let updateObj = {
            groupBy,
        };
        // this.setState(updateObj, this.applyDataFilters)
        this.setState(updateObj)

    }

    onGroupByFilter({ option, value: nextValue }) {
        let { groupByFilters } = this.state;

        // console.info({ option, nextValue })
        const { type } = option
        // console.info( {nextValue, option})
        // const { value: existingValue } = groupByFilters[type] || {}

        // const currentOptions = groupByFilters[type] || [];

        // const exists = _.find(currentOptions, _.matchesProperty('value', value));
        // console.info({ exists });
        // const updatedOptions = exists ? currentOptions.filter(o => o.value !== value) :
        //     [...currentOptions, option];
        // console.info({ existingValue })
        let updateObj = {
            groupByFilters: Object.assign({}, groupByFilters, {
                [type]: nextValue // updatedOptions
            })
        };


        // console.info({ updateObj })
        // this.setState(updateObj, this.applyDataFilters)
        this.setState(updateObj
            // , this.applyDataFilters
        )

    }
    getDateFilters() {
        const { data } = this.props;
        const { dateFilter, timezone, filteredData, calendarInput } = this.state;
        const [calendarStart, calendarEnd] = calendarInput;
        const filteredDataDates = filteredData.map(o => o.date);
        const [firstDate] = filteredDataDates;
        const [lastDate] = [...filteredDataDates].reverse();

        // const fmCalVal = (datestring) => MomentTZ(datestring).tz(timezone).format('YYYY-MM-DD')
        const fmtFirstDate = firstDate ? Moment.utc(firstDate).format('YYYY-MM-DD') : null;
        const fmtLastDate = lastDate ? Moment(lastDate).format('YYYY-MM-DD') : null;

        // console.info({ firstDate, fmtFirstDate, calendarStart })
        // console.info({ lastDate, fmtLastDate, calendarEnd })

        const dataDates = data.map(o => o.date);
        const [firstBound] = dataDates;
        const [lastBound] = [...dataDates].reverse();
        // console.info(firstDate, lastDate)
        const fmtFirstBound = firstBound ? Moment(firstBound).format('YYYY-MM-DD') : null;
        const fmtLastBound = lastBound ? Moment(lastBound).format('YYYY-MM-DD') : null;
        const bounds = [fmtFirstBound, fmtLastBound]
        // console.info({ bounds })
        return (
            <Box
                pad='small'
                direction='row'
                justify='center'
                align='center'
                gap='small'
            // height={{min: '46px'}}
            >
                <Text weight='bold' style={{ paddingRight: '6px', minWidth: '120px' }}>DATE FILTERS</Text>
                <Select
                    id="select-tz"
                    name="Timezones"
                    // placeholder="Date Ranges"
                    value={timezone}
                    options={TIMEZONE_OPTS}
                    size='xsmall'
                    onChange={({ option }) => {
                        // console.info({option})
                        // this.setState({ dateFilter: option })
                        this.onDateFilterChange(undefined, option)
                    }}
                />
                <Select
                    id="select-date"
                    name="Date Range"
                    placeholder="Date Ranges"
                    value={dateFilter}
                    labelKey="label"
                    multiple={false}
                    valueKey="value"
                    options={DATE_FILTERS_OPTS}
                    size='xsmall'
                    onChange={({ option }) => {
                        // console.info({option})
                        // this.setState({ dateFilter: option })
                        this.onDateFilterChange(option)
                    }}
                />
                <DropButton
                    open={this.state.startDateOpen}
                    onClose={() => { this.setState({ startDateOpen: false }) }}
                    onOpen={() => { this.setState({ startDateOpen: true, dateFilter: DATE_FILTERS_OPTS[0] }) }}
                    size='small'
                    dropContent={
                        <Calendar
                            size='small'
                            // dates={[[fmtFirstDate, fmtLastDate]]} 
                            // range
                            date={calendarStart || fmtFirstDate}
                            bounds={bounds}
                            onSelect={
                                // ( ) => this.onDateFilterChange(undefined, undefined,  )
                                (calendarStart) => {
                                    // console.info(fmtFirstDate, calendarStart, MomentTZ(calendarStart).tz(timezone).format('YYYY-MM-DD'))
                                    this.onDateFilterChange(undefined, undefined, [
                                        Moment(calendarStart).format('YYYY-MM-DD'), calendarEnd || fmtLastDate]
                                    )
                                    this.setState({ startDateOpen: false })
                                }
                            } />
                    }
                >
                    <Box direction="row" gap="medium" align="center" pad="small">
                        <Text>{
                            calendarStart ? `${calendarStart || fmtFirstDate}` : 'Select Start Date'}</Text>
                        <FormDown color="brand" />
                    </Box>
                </DropButton>
                <DropButton
                    open={this.state.endDateOpen}
                    onClose={() => { this.setState({ endDateOpen: false }) }}
                    onOpen={() => { this.setState({ endDateOpen: true, dateFilter: DATE_FILTERS_OPTS[0] }) }}
                    size='small'
                    dropContent={
                        <Calendar
                            size='small'
                            // dates={[[fmtFirstDate, fmtLastDate]]} 
                            // range
                            bounds={bounds}

                            date={calendarEnd || fmtLastDate}
                            onSelect={
                                // ( ) => this.onDateFilterChange(undefined, undefined,  )
                                (calendarEnd) => this.onDateFilterChange(undefined, undefined, [calendarStart || fmtFirstDate,
                                Moment(calendarEnd).format('YYYY-MM-DD')

                                ])
                            } />
                    }
                >
                    <Box direction="row" gap="medium" align="center" pad="small">
                        <Text>{
                            calendarEnd ? `${calendarEnd || fmtLastDate}` : 'Select End Date'}</Text>
                        <FormDown color="brand" />
                    </Box>
                </DropButton>
                {/* <DateInput
                    // bounds={bounds}
                    value={fmCalVal(calendarStart || fmtFirstDate)}
                    onChange={({target}) =>  {
                        this.setState({calendarStart: MomentTZ(target.value.replace('/','-'))
                            .tz(timezone).format('YYYY-MM-DD')})
                    }}
                    maskFormat='yyyy-mm-dd'
                    // locale={'en-EN'}
                />
                                <DateInput
                    // bounds={bounds}
                    value={(calendarEnd || fmtLastDate)}
                    onChange={({target}) =>  {
                        this.setState({calendarEnd: MomentTZ(target.value.replace('/','-'))
                            .tz(timezone).format('YYYY-MM-DD')})
                    }}
                    maskFormat='dd mm yyyy'
                    // autocorrect
                    // locale={'en-EN'}
                /> */}
                {/* <Nav direction='row' align='end'>
                    {DATE_FILTERS_OPTS.map((o) => (
                        <Button
                            size='small'
                            label={o.label} />
                    ))}
                </Nav> */}
            </Box>
        )
    }



    getGroupByFilters() {
        const { groupBy, groupByFilters } = this.state;
        // const { }
        const { data } = this.props;

        const appBundleOpts = _.uniqBy(data, 'app_bundle')
            .map(o => ({
                value: o.app_bundle,
                label: `${o.app_bundle} - ${o.app_name}`,
                type: 'app_bundle'
            }))

        // const publisherOpts = _.uniq(data.map(o => o.publisher))
        //     .map(value => ({ value, label: value, type: 'publisher' }))


        // const countryOpts = _.uniq(data.map(o => o.country))
        //     .map(value => ({ value, label: value, type: 'country' }))

        const [
            // appBundleOpts,
            publisherOpts,
            countryOpts,
            campaignOpts
        ] = [
            // 'app_bundle',
            'publisher',
            'country',
            'campaign'
        ].map(selectName => _.uniq(data.filter(o => o).map(o => o[selectName]))
            .map(value => ({ value, label: value, type: selectName })))

        const options = {
            app_bundle: appBundleOpts,
            publisher: publisherOpts,
            country: countryOpts,
            campaign: campaignOpts
        }

        // console.info(JSON.stringify(campaignOpts.map(o => o.value)))
        // const publisherOpts = _.uniq(data.map(o => o.publisher))
        //     .map(value => ({ value, label: value, type: 'publisher' }))

        // console.info({ appBundleOpts }, groupByFilters.app_bundle)

        // const [appBundleOpts, setAppBundleOpts] = React.useState(appBundleOpts);
        // console.info({ get: groupByFilter })
        const groupByOpts = [...[groupBy !== 'id' ? 'none' : undefined], 'app_bundle', 'app_name', 'campaign', 'publisher', 'date', 'country'].filter(s => s);


        return (
            <Box
                pad='small'
                direction='row'
                justify='center'
                align='center'
                gap='small'
            // height={{min: '46px'}}
            >


                <Text weight='bold' style={{ paddingRight: '6px', minWidth: '120px' }}>{'GROUP BY'}</Text>
                <Select
                    id="select"
                    name="Group By"
                    placeholder="Select..."
                    value={groupBy}
                    size='xsmall'
                    options={groupByOpts}
                    onChange={this.onGroupBy}
                />
                {

                }
                <Text weight='bold' style={{ paddingRight: '6px' }}>{'GROUP FILTERS'}</Text>
                {
                    Object.keys(options)
                        .map(fieldName =>
                            <GroupByFilter
                                {...{
                                    fieldName,
                                    onClose: this.applyDataFilters,
                                    options: options[fieldName],
                                    value: groupByFilters[fieldName],
                                    onChange: this.onGroupByFilter
                                }}
                            />
                        )
                }

            </Box>
        )
    }

    getTableColumns() {

        const defaultRenderer = (propName, type = '', color = '') => (props) => {
            // console.info({props})
            let value = props[propName.toLowerCase()];

            if (type === 'numeral') {
                value = Numeral(value)
                    .format('0,0')
            } else if (type === 'currency') {
                value = Numeral(value)
                    .format('0,0$')
            }


            return <Cell flex color={color} type={type}>{value}</Cell>
        }

        const namer = propName => {
            switch (propName) {
                case 'impressions':
                    return 'Imps'
                case 'registrations':
                    return 'Regs'
                case 'revenue':
                    return 'Rev'
                case 'country':
                    return 'GEOs'
                case 'ftd':
                    return 'FTD'
                default:
                    return _.capitalize(propName)
            }

        }

        const simpleColumns = [
            'publisher',
            'campaign',
            'country',
        ];

        const numeralColumns = [
            'impressions',
            'clicks',
            'installs',
            'registrations',
            'ftd',
        ];

        const currencyColumns = [
            {
                prop: 'revenue',
                color: 'green'
            },
            {
                prop: 'cost',
                color: 'red'
            },
            {
                prop: 'profit',
                color: 'blue'
            }
        ]

        const columnDefs = [
            {
                property: 'date',
                header: 'Date',
                render: ({ date }) => <Cell> {date && MomentTZ(date).format('YYYY-MM-DD')}</Cell>
            },
            {
                property: 'app_bundle',
                header: 'App Bundle',
                render: ({ app_bundle, app_name }) =>
                    <Cell style={{ whiteSpace: 'pre' }}>
                        {app_name}
                        <Cell className='desc'>
                            {app_bundle}
                        </Cell>
                    </Cell>
            },
            ...simpleColumns.map(propName => (
                {
                    property: propName,
                    header: namer(propName),
                    render: defaultRenderer(propName),
                    // footer: "x"
                }
            )),
            ...numeralColumns.map(propName => (
                {
                    property: propName,
                    header: namer(propName),
                    render: defaultRenderer(propName, 'numeral'),
                    aggregate: 'sum',
                    footer: { aggregate: true }
                }
            )),
            ...currencyColumns.map(({ prop, color }) => (
                {
                    property: prop,
                    header: namer(prop),
                    render: defaultRenderer(prop, 'currency', color),
                    aggregate: 'sum',
                    footer: { aggregate: true }
                }
            ))
        ]
        // const model = {}
        // columnDefs.forEach(o => model[o.property] = 1 / columnDefs.length);
        // console.info(JSON.stringify(model))
        return columnDefs
    }

    render() {
        let { data, children: SearchBox } = this.props;
        const { groupBy, filteredData, groupByData, groupByFilters } = this.state;
        // const [groupBy, setGroupBy] = React.useState('none');
        // const c = this.getTableColumns()
        const reportData = groupByData || filteredData;

        // console.info({ groupBy, groupByFilters, filteredData, hasData:Boolean(data), groupByData })

        if (!data) {
            return null
        }

        // console.info({ reportData })
        // console.info({ SearchBox })


        const dataTableKey = groupBy + reportData.length;
        const groupByProp = ['id', 'none'].indexOf(groupBy) === -1 ? { groupBy } : {}
        // console.info({ groupByProp })
        return (
            <Box align="start" gap='small' flex>
                {/* <Box>
                    {SearchBox}
                </Box> */}
                <Box
                    align='center'
                    direction='row'>

                    {this.getDateFilters()}
                    <Box align='end' flex>
                        {/* {this.getReportDownloadButton(reportData)} */}
                        <ReportDownloadButton reportData={reportData} />
                    </Box>
                    <Box pad='small'>
                        {/*
                        <CheckBox
                            primary
                            label='Charts'
                            toggle
                            checked={Boolean(this.state.displayCharts)}
                            onClick={
                                ({ target }) => this.setState({
                                    displayCharts: !this.state.displayCharts
                                })
                            } />
                        */}
                    </Box>
                </Box>
                {this.getGroupByFilters()}

                <Box pad='small' flex fill>
                    {
                        this.state.displayCharts ?
                            <Box align='center'>
                                {/*   <Chart
                                    data={data}
                                    {...groupByProp}

                             />*/}
                            </Box>

                            :
                            // <Box style={{display: 'block', width: '100%'}}>

                            <DataTable
                                key={dataTableKey}
                                columns={this.getTableColumns()}
                                // onClickRow={event => alert(JSON.stringify(event.datum, null, 2))}
                                {...groupByProp}
                                data={reportData}
                                sortable
                                // resizeable
                                sort={{ property: 'revenue', direction: 'desc' }}
                                step={10}
                                primaryKey={'id'}
                                size="medium"
                            // onSort={(onSortt) => console.info({ onSortt })}
                            />
                        // </Box>

                    }
                </Box>

            </Box>
        );
    }
}

export default ReportsComponent


