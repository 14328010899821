import React from "react";
import { useStyles } from "./style";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FormField from "./FormField";
import Field from "../FiltersDialog/Field";
import { connect } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import Slide from "@material-ui/core/Slide";
import Tabs from "../DialogTabs/Tabs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Fields group
function FieldsGroup(props) {
  const { group, isMobile, onFieldChange, fieldsFullWidth } = props;
  // style
  const classes = useStyles();

  return (
    <div className={classes.groupWrapper}>
      <Typography className={classes.groupTitle}>{group.groupName}</Typography>
      <div
        className={clsx(classes.group, {
          [classes.groupMobile]: isMobile || fieldsFullWidth,
        })}
      >
        {_.chunk(
          group.fields,
          isMobile ? group.fields.length : group.fields.length / 2
        ).map((column, i) => {
          return (
            <div
              key={`group-column-${group.groupName}#${i}`}
              className={classes.groupColumn}
            >
              {column.map((field, fieldIndex) => (
                <div
                  key={`field-${field.name}#${fieldIndex}`}
                  className={classes.fieldWrapper}
                  style={{
                    width: isMobile ? "100%" : "",
                  }}
                >
                  <FormField field={field} onFieldChange={onFieldChange} />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
// Fields group
function FieldsGroup2(props) {
  const { group, isMobile, onFieldChange, fieldsFullWidth } = props;
  // style
  const classes = useStyles();

  console.log(group);

  return (
    <Tabs
      group={group}
      tabs={group.map((g) => g.groupName)}
      panels={group.map((g) =>
        g.fields.map((f) => {
          return <FormField field={f} onFieldChange={onFieldChange} />;
        })
      )}
    />
  );
}

export function FormDialog(props) {
  // style
  const classes = useStyles();
  // component props
  const {
    header,
    fields: fieldsGroups,
    open,
    handleClose,
    handleSubmit,
    browser,
    fieldsFullWidth,
  } = props;

  // Is mobile ?
  const isMobile = !browser.greaterThan.medium;
  // component state
  const [criteria, setCriteria] = React.useState();

  React.useEffect(() => {
    //
    //
    // Create criteria
    //
    let newCriteria = {};

    // For every field row
    fieldsGroups.forEach((group) => {
      // For every field def in field row...
      group.fields.forEach((field) => {
        newCriteria[field.name] = field.value;
      });
    });

    if (Object.keys(newCriteria).length) {
      newCriteria = {
        ...criteria,
        ...newCriteria,
      };
      setCriteria(newCriteria);
    }
    // console.log(newCriteria);
  }, []);

  // Handle fields change
  function handleCriteriaChange(e, valueObj) {
    let newValues = {};
    let newValue = null;

    if (valueObj) {
      // DIRECT STATE UPDATE (Value Obj)
      newValues = {
        ...criteria,
        ...valueObj,
      };
    } else {
      //  HANDLE EVENT
      const { value, name, type, checked } = e.target;

      newValue = (e.option && e.option) || e.value ? e.value : value;

      if (type === "checkbox") {
        newValue = checked;
      }

      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };
    }

    setCriteria(newValues);
  }

  // On dialog submit
  const onSubmit = () => {
    // Return criteria values
    handleSubmit(criteria);
    // Close dialog
    handleClose(!open);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        maxWidth="xl"
        scroll={"paper"}
        className={classes.root}
        TransitionComponent={Transition}
        onClose={() => handleClose(!open)}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        {
          // Dialog Header - optional
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            <div className={classes.title}>
              <Typography style={{ color: "white", fontWeight: "500" }}>
                {header.toUpperCase()}
              </Typography>
              <Button
                onClick={() => handleClose(!open)}
                style={{ color: "white", fontSize: "15px" }}
              >
                X
              </Button>
            </div>
          </DialogTitle>
        }
        <DialogContent className={classes.content} dividers>
          <FieldsGroup2
            group={fieldsGroups}
            fieldsFullWidth={fieldsFullWidth}
            isMobile={isMobile}
            onFieldChange={handleCriteriaChange}
            // key={`fields-group-wrapper-${group.groupName}#${i}`}
          />

          {/* {fieldsGroups.map((group, i) => {
            return (
              <FieldsGroup
                group={group}
                fieldsFullWidth={fieldsFullWidth}
                isMobile={isMobile}
                onFieldChange={handleCriteriaChange}
                key={`fields-group-wrapper-${group.groupName}#${i}`}
              />
            );
          })} */}
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            style={{ color: "#3c8dbc" }}
            onClick={() => handleClose(!open)}
          >
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} style={{ color: "#3c8dbc" }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapDispatchToProps(dispatch) {}

function mapStateToProps(state) {
  const { browser } = state;
  return { browser };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
