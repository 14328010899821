import React from "react";
import { Box, TextInput, Select, CheckBox, DateInput, Button, FormField } from "grommet";
import { Search, LinkNext } from "grommet-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FiltersMobile from "./FiltersMobile";
import SelectSearchInput from "./xComponents/SearchInput";
import FilterButton from "./FilterButtonV2";
import Moment from "moment";
import _ from "lodash";
import { CSVLink } from "react-csv";
import { FormDown, Download } from "grommet-icons";
import styled from "styled-components";

const DownloadButton = styled(CSVLink)`
  display: flex;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 4px;
  justify-content: center;
  & > button {
    border-radius: 0;
    max-height: 40px;
    font-size: 12px;

    & > div {
      letter-spacing: 1px;
      & > svg {
        width: 16px;
      }
    }
  }
`;
function ReportDownloadButton({ reportData }) {
  if (!reportData) return null;
  const now = Moment().format();
  const rows = reportData.length;

  const formattedDates = reportData.map((o) => {
    return Object.assign({}, o, {
      date: Moment.utc(o.date).add("5", "hours").format("YYYY-MM-DD"),
    });
  });
  const data = _.orderBy(formattedDates, ["revenue"], ["desc"]);

  // const hash = ObjectHash(reportData);
  let buttonProps = {
    primary: true,
    icon: <Download />,
    label: "Download Report",
  };

  if (!reportData.length) {
    return <Button {...buttonProps} label={"No Data"} disabled={true} />;
  }
  return (
    <DownloadButton data={data} filename={`${now}-${rows}.csv`}>
      <Button {...buttonProps} />
    </DownloadButton>
  );
}

function MobileButton(props) {
  const MButton = styled(Button)`
    border-radius: 0;
  `;
  return (
    <Box>
      <MButton style={{ height: 40 }} onClick={props.onClick} primary>
        <Box direction="row" pad="6px" gap="6px">
          <Box style={{ fontSize: 14, fontWeight: 700 }}>{"FILTERS"}</Box>
          <Box style={{ fontSize: 12, fontStyle: "italic" }}>{props.desc}</Box>
        </Box>
      </MButton>
    </Box>
  );
}

function SelectFilterData(props) {
  const {
    name: fieldName,
    value,
    options,
    // groupByFilters,
    onChange,
    onClose,
  } = props;

  // console.info(props, {value}, {values})

  const orderedOptions = React.useCallback(
    (unorderedOptions) => {
      const values = value || [];
      const valuesLabels = values.map((o) => o.label);

      return [
        ...values,
        ...unorderedOptions.filter((o) => valuesLabels.indexOf(o.label) === -1),
      ];
    },
    [value]
  );

  const [opts, setOpts] = React.useState([...orderedOptions(options)]);

  React.useEffect(() => {
    setOpts(orderedOptions(options));
  }, [options, orderedOptions]);

  const placeholder =
    fieldName === "app_bundle" ? "App Bundle" : _.capitalize(fieldName);

  // console.info(opts, options, fieldName)
  return (
    <Select
      key={fieldName}
      id={`select-${fieldName}`}
      name={`${fieldName}`}
      placeholder={placeholder}
      value={value}
      closeOnChange={false}
      onSearch={(text) => {
        console.info({ text });
        if (!text) return true;
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
        const exp = new RegExp(escapedText, "i");
        // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

        setOpts(options.filter((o) => exp.test(o.label)));
      }}
      searchPlaceholder={`Search ${placeholder}`}
      emptySearchMessage={`No ${placeholder} found`}
      onClose={() => {
        if (onClose) onClose();
        // setOpts(=(options))
      }}
      multiple
      messages={{
        multiple: `${value && value.length} ${placeholder}s`,
      }}
      // size="xsmall"
      valueKey="value"
      labelKey="label"
      options={opts}
      onChange={(...e) => {
        onChange(...e);
        // setOpts((options))
      }}
    />
  );
}

export const getDateMaps = (timeframe) => {
  const dateMap = {
    Today: {
      fromDate: Moment().utc().startOf("day").format(),
      toDate: Moment().utc().startOf("day").format(),
    },
    Yesterday: {
      fromDate: Moment().utc().subtract("1", "day").startOf("day").format(),
      toDate: Moment().utc().subtract("1", "day").startOf("day").format(),
    },
    "Last 7 Days": {
      fromDate: Moment().utc().subtract("7", "day").startOf("day").format(),
      toDate: Moment().utc().startOf("day").format(),
    },
    "Last 8 Days": {
      fromDate: Moment().utc().subtract("8", "day").startOf("day").format(),
      toDate: Moment().utc().startOf("day").format(),
    },
    "Last 9 Days": {
      fromDate: Moment().utc().subtract("9", "day").startOf("day").format(),
      toDate: Moment().utc().startOf("day").format(),
    },
    "Month To Date": {
      fromDate: Moment().utc().startOf("month").format(),
      toDate: Moment().utc().startOf("day").format(),
    },
    "Last Month": {
      fromDate: Moment().utc().subtract("1", "month").startOf("month").format(),
      toDate: Moment().utc().subtract("1", "month").endOf("month").format(),
    },
    "This Year": {
      fromDate: Moment().utc().startOf("year").format(),
      toDate: Moment().utc().startOf("day").format(),
    },
  };


  if (dateMap[timeframe]) {
    return dateMap[timeframe]
  }

  return dateMap
}
export function DateSelect(props) {
  const { 
    handleChange, 
    formFieldLabel, 
    isCustom,
    withCustom 
  } = props;

  let options = [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 8 Days",
    "Last 9 Days",
    "Month To Date",
    "Last Month",
    "This Year",
  ]

  if (withCustom) {
    options = [
      "Custom",
      ...options
    ]
  }

  const dateMap = getDateMaps()
  const [defaultRange, setDefaultRange] = React.useState(props.defaultRange || "Last 7 Days")


  const [defaultOption, setDefaultOption] = React.useState(
    options.find((o) => o.toLowerCase() === defaultRange.toLowerCase())
  )

  React.useEffect(() => {
    console.info("DS", props.defaultRange)
    setDefaultRange(props.defaultRange)
    setValue(props.defaultRange)
  }, [props.defaultRange])

  // console.info({ defaultOption }, defaultRange);



  const [value, setValue] = React.useState(defaultOption);


  const select = (<Select
    options={options}
    value={isCustom ? "Custom" : value}
    disabled={withCustom ? [0] : undefined}
    onChange={({ option }) => {
      if (dateMap[option]) {
        // console.info({ option }, dateMap[option]);
        // console.info()
        handleChange(null, dateMap[option], option);
      }
      setValue(option);
    }}
  />)

  if (formFieldLabel) {
    return (
      <FormField label={formFieldLabel}>
        {select}
      </FormField>
    )
  }
  return select
}

const MOBILE_SHORT_KEYS = {
  fromDate: "from",
  toDate: "to",
};

function Filters(props) {
  const {
    browser,
    queryCriteria,
    handleSubmit,
    fields,
    firstLoad,
    onFirstLoad,
  } = props;

  const [mobileMenu, toggleMobileMenu] = React.useState(false);
  const [direction, setDirection] = React.useState("row");
  const [fiedldsRows, setFieldsRows] = React.useState(fields);
  const [criteria, setCriteria] = React.useState({
    fromDate: Moment.utc().startOf("day").format(),
    toDate: Moment.utc().format(),
    reportsSource: "Both (combined)",
  });

  // Mobile ?
  const isMobile = !browser.greaterThan.medium;

  // Component did mount
  React.useEffect(() => {
    let newCriteria = {};
    // For every field row
    fields.forEach((fieldRow) => {
      // For every field def in field row...
      fieldRow
        .filter((f) => !f.external)
        .forEach((field) => {
          if (field.value) {
            newCriteria[field.name] = field.value;
          }
        });
    });

    if (Object.keys(newCriteria).length) {
      newCriteria = {
        ...criteria,
        ...newCriteria,
      };
      setCriteria(newCriteria);
    } else if (firstLoad) {
      handleSubmit({
        ...(queryCriteria || {}),
        ...criteria,
      });
      onFirstLoad();
    }

    // in case view on mobile
    // we want to display to fields in every row
    if (isMobile) {
      const finalFields = fields.flatMap((fieldRow) => {
        return fieldRow;
      });
      setFieldsRows(_.chunk(finalFields, 2));
      setDirection("column");
    } else {
      setFieldsRows(fields);
      setDirection("row");
    }
  }, [
    fields,
    criteria,
    setCriteria,
    handleSubmit,
    firstLoad,
    onFirstLoad,
    isMobile,
  ]);

  if (isMobile && !mobileMenu) {
    return (
      <MobileButton
        onClick={() => toggleMobileMenu(!mobileMenu)}
        desc={`from: ${Moment(criteria.fromDate).format(
          "YYYY-MM-DD"
        )} | to: ${Moment(criteria.toDate).format("YYYY-MM-DD")}`}
      />
    );
  }

  // Handle filters change
  function handleFiltersChange(e, valueObj) {
    let newValues = {};
    let newValue = null;

    if (valueObj) {
      // DIRECT STATE UPDATE (Value Obj)
      newValues = {
        ...criteria,
        ...valueObj,
      };
    } else {
      //  HANDLE EVENT
      const { value, name, type, checked } = e.target;

      newValue = (e.option && e.option) || e.value ? e.value : value;

      if (type === "checkbox") {
        newValue = checked;
      }

      if (typeof newValue === "object" && newValue.label) {
        newValue = newValue.value;
      }

      if (name === "rank") {
        newValue = e.value || [];
      }

      if (["placements", "internalStatus"].indexOf(name) > -1) {
        newValue = e.value.value || 0;
      }

      if (
        ["advertiser", "countries"].indexOf(name) > -1 &&
        typeof newValue !== "object"
      ) {
        newValue = [];
      }

      if (name === "offerSearch") {
        return setCriteria(newValues);
      }
      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };
    }

    setCriteria(newValues);
  }

  //
  function getField(field) {
    switch (field.type) {
      case "search":
        return (
          <TextInput
            size="small"
            icon={field.icon}
            name={field.name}
            type={field.type}
            placeholder={field.placeholder || field.name}
            onChange={(e) => handleFiltersChange(e)}
          />
        );
      case "checkbox":
        return (
          <CheckBox
            size="small"
            icon={field.icon}
            name={field.name}
            label={field.name}
            type={field.type}
            checked={criteria[field.name]}
            placeholder={field.placeholder || field.name}
            onChange={(e) => handleFiltersChange(e)}
          />
        );
      case "select":
        // console.info("SELECT", field, field.options, criteria[field.name])
        return (
          <Select
            size="small"
            // labelKey={field.key ? "label" : false}
            name={field.name}
            type={field.type}
            multiple={field.multiple}
            options={field.options}
            value={
              field.options.filter((p) => p === criteria[field.name])[0] ||
              "Both (combined)"
            }
            // valueKey={field.key ? { key: "value" } : false}
            placeholder={field.placeholder || field.name}
            onChange={(event) => handleFiltersChange(event)}
          // disabledKey={false}
          />
        );
      case "selectSearch":
        return (
          <SelectSearchInput
            value={criteria[field.name]}
            name={field.name}
            type={field.type}
            placeholder={field.placeholder || field.name}
            onChange={(e) => handleFiltersChange(e)}
            options={field.options}
          />
        );
      case "date":
        return (
          <Box width="208px">
            <DateInput
              key={`${criteria[field.name]}`}
              size="small"
              name={field.name}
              value={criteria[field.name]}
              format="dd/mm/yyyy"
              clear
              // type={field.type}
              inputProps={{
                value: criteria[field.name],
                placeholder: field.placeholder ? field.placeholder : "",
              }}
              onChange={({ value }) => {
                if (value) {
                  return handleFiltersChange(null, { [field.name]: value });
                }
              }}
              style={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: 5,
                fontWeight: 100,
                fontSize: "12px",
                height: "4vh",
                padding: "15px",
              }}
            />
          </Box>
        );
      case "dateSelect":
        return (
          <Box width="208px">
            <DateSelect
              // disabled={disableSubmit}
              defaultRange={field.defaultRange}
              handleChange={handleFiltersChange}
            />
          </Box>
        );
      case "afGroupByFilter": {
        return (
          <Box width="208px">
            <Select
              id="select"
              name="Group By"
              placeholder={"Group Bys"}
              value={field.value}
              options={field.options}
              onChange={field.onChange}
            // valueLabel={
            //   () => field.value === "id" ? "Group By" : `By ${field.value}`
            // }
            />
          </Box>
        );
      }
      case "selectFilterData": {
        return (
          <Box width="208px">
            <SelectFilterData {...field} />
          </Box>
        );
      }
      case "reportDownloadButton": {
        return (
          <Box width="208px">
            <ReportDownloadButton {...field} />
          </Box>
        );
      }

      default:
        break;
    }
  }
  // console.info(fiedldsRows)

  return (
    <Box gap="6px" direction={direction} pad="6px" background="light-1">
      <Box align="start" gap="6px" width="100%">
        {criteria &&
          fiedldsRows.map((fieldRow) => {
            return (
              <Box direction="row" align="center" gap="6px" fill="horizontal">
                {fieldRow.map((field) => (
                  <Box width={field.width}>{getField(field)}</Box>
                ))}
              </Box>
            );
          })}
      </Box>
      <Box width="5%">
        <FilterButton
          onClick={() => {
            handleSubmit(criteria);
            toggleMobileMenu(false);
          }}
        //  disabled={disableSubmit}
        />
      </Box>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;

  return { browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) { }

export default connect(mapStateToProps, null)(Filters);
