import * as Immu from "object-path-immutable";

const sidebarInitial = { visible: true };

const mainSidebar = (state = sidebarInitial, action) => {
  switch (action.type) {
    case "MAIN_SIDEBAR_TOGGLE":
      return Immu.set(state, "visible", !state.visible);
    case "redux-responsive/CALCULATE_RESPONSIVE_STATE":
      const mainSidebarVisible = action.innerWidth > 999 ? true : false;
      return Immu.set(state, "visible", mainSidebarVisible);

    default:
      return state;
  }
};

export default mainSidebar;
