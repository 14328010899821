import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import SkadnRedirectsActions from "../actions/SkadnRedirectsActions";
import AppBarActions from "../actions/AppBarActions";
import { Box, Text } from "grommet";
import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import Cell from "../components/Cell";

class SkadnRedirects extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      filters: {
        pageSize: 50,
        page: 1,
      },
      tableColumns: this.getTableColumns(),
    };
  }

  componentDidMount() {
    const { loading, skadnRedirects, getInitialData } = this.props;

    if (!loading && !skadnRedirects.length) {
      //  Load data from API
      getInitialData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { skadnRedirects } = this.props;
    console.log(this.props);

    // inhect content into the app bar
    this.props.injectContentAppBar(
      <Box fill align="center" direction="row" justify="between">
        <Text>
          Skadn Redirects
          {skadnRedirects && (
            <Text
              color="#c9e3f2"
              margin={{ left: "xsmall" }}
              size="10px"
              weight="300"
            >{`${skadnRedirects.length} results `}</Text>
          )}
        </Text>
      </Box>
    );
  }

  // handle fields change on add advertiser layer
  handleFiltersChange({ target, option }) {
    const { filters } = this.state;
    const { value, name, type, checked } = target;

    let newValue = (option && option) || value;

    // Checkbox
    if (type === "checkbox") {
      newValue = checked;
    }

    // update new values
    let newValues = {
      ...filters,
      ...{ [name]: newValue },
    };

    // set up state
    this.setState({ filters: newValues });
  }

  getTableColumns() {
    const defaultRenderer = (propName, type = "", color = "") => (props) => {
      let value = props[propName.toLowerCase()] || props[propName];
      // 05/08/2020 13:38
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell start={"true"} flex color={color} type={type}>
          {value}
        </Cell>
      );
    };

    const columns = [
      {
        property: "id",
        header: "ID",
        render: defaultRenderer("id"),
      },
      {
        property: "offerId",
        header: "Offer ID",
        render: defaultRenderer("offerId"),
      },
      {
        property: "timestamp",
        header: "Timestamp",
        render: defaultRenderer("timestamp", "date"),
      },
      {
        property: "ua",
        header: "UA",
        render: defaultRenderer("ua"),
      },
      {
        property: "ip",
        header: "IP",
        render: defaultRenderer("ip"),
      },
      {
        property: "bdi",
        header: "BDI",
        render: defaultRenderer("bdi"),
      },
      {
        property: "skadn",
        header: "Skadn",
        render: defaultRenderer("skadn"),
      },
      {
        property: "env",
        header: "Env",
        render: defaultRenderer("env"),
      },
    ];

    return columns;
  }

  render() {
    const { tableColumns } = this.state;

    const {
      // Redux State
      skadnRedirects,
      loading,
    } = this.props;

    return (
      <Box
        flex
        fill
        width="100%"
        margin="none"
        overflowY="auto"
        alignSelf="center"
      >
        <Loader loading={loading} text="Loading Skadn redirects data...">
          <Box style={{ overflowY: "auto", flex: 13 }}>
            <DataTable
              pin
              fill
              sortable
              step={50}
              primaryKey={"id"}
              data={skadnRedirects}
              columns={tableColumns}
              sort={{ property: "id" }}
            />
          </Box>
        </Loader>
      </Box>
    );
  }
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  return state.skadnRedirects;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, SkadnRedirectsActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SkadnRedirects);
