import React from 'react'
import {
    Box,
    Text,
    Button
} from 'grommet';
import { connect } from "react-redux";
// import { Spinning } from 'grommet-controls'
import styled from "styled-components";

import {
    StatusGood,
    StatusInfo,
    StatusUnknown,
    StatusCritical,
    StatusWarning,
    Close
} from 'grommet-icons'


const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const NotificationText = styled(Text)`
  font-size: 14px;
  color: grey;
  font-weight: bold;
`
const NotificationBox = styled(Box)`
    position: absolute;
    z-index: 10;
    width: 98%;
    bottom: 0;
    opacity: 0.95 !important;
    margin: 1%;
`

const TitleText = styled(Text)`
    font-weight: bold;
`

const getIcon = (status) => {
    switch (status) {
        case '':
        case 'unknown':
            return StatusUnknown;

        case 'critical':
            return StatusCritical;
        case 'warning':
            return StatusWarning

        case 'info':
            return StatusInfo
        case 'ok':
            return StatusGood
        default:
            return StatusUnknown
    }
}

const Notification = (props) => {
    const { display, dismissMs, title, message, status, delayedDismiss, closeNotification } = props;
    const Icon = getIcon(status);
    const color = `status-${status}`;
    if (!Object.keys(props).length) {
        // dismiss = true;
    }
    // console.info({ display, dismissMs })

    const animation = display ? "fadeIn" : "fadeOut"
    if (display) {
          delayedDismiss(dismissMs)
    }



    return (
        <NotificationBox
            align='start'
            pad={display ? 'small' : 'none'}
            gap='small'
            direction='row'
            animation={animation}
            background={color}
            justify='center'
        // display={display}
        >
            <Box>
                {display && <Icon size='large' />}
            </Box>
            <Box flex justify='center'>
                {display && <Box style={{ minHeight: '50px' }} justify='center' flex>

                    <Box justify='center'><TitleText>{title}</TitleText></Box>
                    {message && <Box><Text>{message}</Text></Box>}
                </Box>}
            </Box>
            <Box>
                {display &&
                    <Button icon={<Close />} onClick={() => closeNotification()} />
                }
                {/* <Icon size='large' /> */}
            </Box>
        </NotificationBox>
    )

}



function mapDispatchToProps(dispatch) {

    const dismiss = { type: 'DISMISS_NOTIFICATION' };

    return {
        // dispatching plain actions
        closeNotification: () => dispatch(dismiss),
        delayedDismiss: async (dismissMs) => {
            await sleep(dismissMs);
            dispatch(dismiss)
        },
    }

}
function mapStateToProps(state) {
    const { title, message, status, error, display, dismissMs } = state.notification;

    return {
        dismissMs,
        display,
        status,
        title,
        message,
        error
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
