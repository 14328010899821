import React from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
// import MomentTZ from 'moment-timezone';
import {
  Text,
  Box,
  DataTable,
  CheckBox,
  Button,
  Layer,
  Heading,
  TextInput,
} from "grommet";

import {
  Alert,
  Add,
  Edit,
  History,
  StatusInfo,
  StatusUnknown,
  StatusWarning,
} from "grommet-icons";
import { Spinning } from "grommet-controls";
import _ from "lodash";
import Loader from "../Loader";
import ReadField from "../ReadField";

// Source code for the data can be found here
// https://github.com/grommet/grommet/blob/master/src/js/components/DataTable/stories/data.js
// import { DATA } from "./co nstants";

import styled from "styled-components";

const StyledDataTable = styled(DataTable)`
  tbody {
    max-height: 100%;
  }
`;
// advertiser_id(pin):0
// rev_event_id(pin):0
// cost_event_id(pin):0
// publisher(pin):""

export const columns = [
  {
    property: "app_bundle",
    header: <Text>App Bundle</Text>,
    primary: true,
    render: ({ app_bundle }) => {
      return (
        <Box width="400px" pad={{ horizontal: "small" }}>
          <Text size="small">{app_bundle}</Text>
        </Box>
      );
    },
  },
  {
    property: "app_name",
    header: <Text>App Name</Text>,
    render: ({ app_name }) => {
      return (
        <Box width="400px" pad={{ horizontal: "small" }}>
          <Text size="small">{app_name}</Text>
        </Box>
      );
    },
  },
  {
    property: "modified",
    header: <Text>Modified At</Text>,
    render: ({ modified, created }) => {
      return (
        <Box width="400px" pad={{ horizontal: "small" }}>
          <Text size="small">
            {Moment(modified || created).format("YYYY-MM-DD HH:mm")}
          </Text>
        </Box>
      );
    },
  },
  {
    property: "created",
    header: <Text>Created At</Text>,
    render: ({ created }) => {
      return (
        <Box width="400px" pad={{ horizontal: "small" }}>
          <Text size="small">{Moment(created).format("YYYY-MM-DD HH:mm")}</Text>
        </Box>
      );
    },
  },
  {
    property: "last_to",
    header: <Text>Updated Till</Text>,
    render: ({ last_to }) => {
      return (
        <Box width="400px" pad={{ horizontal: "small" }}>
          <Text size="small">
            {last_to
              ? Moment(last_to).format("YYYY-MM-DD HH:mm")
              : "Not Synced"}
          </Text>
        </Box>
      );
    },
  },
];

const controlledColumns = columns; //.map(col => ({ ...col }));

const checkMinimumEvents = (app_bundle, events) => {
  // console.info({ app_bundle, events })
  let minimumEvents = {
    revenue: null,
    cost: null,
    // ftd: null,
    // registration: null,
  };

  events
    .filter((event) => event.app_bundle === app_bundle && !event.event_geos)
    .forEach((event) => {
      if (minimumEvents[event.event_type] !== undefined) {
        minimumEvents[event.event_type] = event;
      }
    });

  const existingGlobalEvents = Object.keys(minimumEvents);
  const hasMinimumEvents =
    Object.values(minimumEvents).filter((e) => e).length > 1;

  // console.info(minimumEvents)
  return { minimumEvents, hasMinimumEvents };
};

const AddBundleLayer = (props) => {
  const { createAppBundle, appBundleLayerData, setAppBundleLayerData } = props;

  // if (!appBundleLayerData) {
  //     return null
  // }
  const { isEdit, app_bundle: editAppBundle, app_name: editAppName } =
    appBundleLayerData || {};
  const [processing, setProcessing] = React.useState(false);
  const [state_app_bundle, setAppBundle] = React.useState(editAppBundle || "");
  const [state_app_name, setAppName] = React.useState(editAppName || "");

  const app_bundle = state_app_bundle || editAppBundle;
  const app_name = state_app_name || editAppName;

  console.info({isEdit, app_bundle})
  if (!appBundleLayerData) {
    return null;
  }
  const title = `${isEdit ? "Edit" : "Add"} App Bundle`;
  const actionText = isEdit ? "Updating App Bundle" : "Adding a new App Bundle";
  const buttonProps = {
    label: !isEdit ? "Create" : "Update",
    app_bundle,
    app_name,
    action: isEdit ? "update-name" : "create",
  };

  return (
    <Layer>
      <Box pad="medium" size="large">
        <Heading level="3" margin={{ vertical: "small" }}>
          {title}
        </Heading>
        <Box pad="medium">
          <ReadField name="App Bundle ID" value={isEdit ? app_bundle : null}>
            <TextInput
              placeholder="i.e. id346080608"
              value={app_bundle}
              onChange={({ target }) => {
                setAppBundle(target.value);
              }}
            />
          </ReadField>
          <ReadField name="App Bundle Name">
            <TextInput
              placeholder="i.e. Fiver "
              value={app_name}
              onChange={({ target }) => {
                setAppName(target.value);
              }}
            />
          </ReadField>
        </Box>
        <Box align="center" flex>
          <Loader text={actionText} processing={processing}>
            <Box direction="row" flex pad="small" fill>
              <Box align="start" flex>
                <Button
                  label="Cancel"
                  onClick={() => setAppBundleLayerData(false)}
                />
              </Box>
              <Box align="end">
                <Button
                  primary
                  label={buttonProps.label}
                  disabled={!(app_name && app_bundle)}
                  onClick={async () => {
                    const { label, ...actionProps } = buttonProps;

                    setProcessing(true);
                    await createAppBundle(actionProps);
                    setProcessing(false);
                    setAppBundleLayerData(false);
                  }}
                />
              </Box>
            </Box>
          </Loader>
        </Box>
      </Box>
    </Layer>
  );
};

function MinimumEventsLayer(props) {
  const navigate = useNavigate();

  const { minimumEventsData, onCancel } = props;

  if (!minimumEventsData) {
    return null;
  }

  const { minimumEvents } = minimumEventsData;
  // console.info(minimumEvents)
  return (
    <Layer>
      <Box pad="medium">
        <Box direction="row" justify="start" align="center" gap="small">
          <Alert />
          <Heading level="3" margin={"none"}>
            {"App Bundle Global Events Missing"}
          </Heading>
        </Box>
        <Box pad={{ vertical: "small" }}>
          <Text>
            App Bundle requires a minimum of 4 global events to be enabled.
          </Text>
          <Box pad="small" gap="small">
            {Object.keys(minimumEvents).map((eventName) => {
              return (
                <Box direction="row" align="center" gap="small" key={eventName}>
                  <CheckBox disabled checked={!!minimumEvents[eventName]} />
                  <Text>{_.capitalize(eventName)}</Text>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box direction="row" flex pad="small" fill>
          <Box align="start" flex>
            <Button label="Cancel" onClick={() => onCancel()} />
          </Box>
          <Box align="end">
            <Button
              primary
              label="Go To Events Page"
              onClick={async (e) => {
                // console.info("PUSH");
                e.preventDefault();
                navigate(`/appsflyer/events`);

                // onCancel()
              }}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
}

const getIcon = (status, size = "large") => {
  switch (status) {
    case "warning":
      return <StatusWarning color="status-warning" size={size} />;
    case "info":
      return <StatusInfo color="status-info" size={size} />;
    default:
      return <StatusUnknown color="status-unknown" size={size} />;
  }
};
const ConfirmLayer = (props) => {
  const [processing, setProcessing] = React.useState(false);

  const { title, message, onConfirm, onCancel, visible, status } = props;
  if (!visible) {
    return null;
  }
  return (
    <Layer>
      <Box pad="medium">
        <Box direction="row" align="center" justify="start">
          <Box pad="small">{getIcon(status || "unknown")}</Box>
          <Heading level="3" margin={{ vertical: "small" }}>
            {title || "Confirmation"}
          </Heading>
        </Box>
        <Box pad="medium">
          <Text>{message}</Text>
        </Box>
        <Box align="center" flex>
          {processing ? (
            <Spinning />
          ) : (
            <Box direction="row" flex pad="small" fill>
              <Box align="start" flex>
                <Button label="No" onClick={onCancel} />
              </Box>
              <Box align="end">
                <Button
                  primary
                  label="Yes"
                  onClick={async () => {
                    setProcessing(true);
                    await onConfirm();
                    setProcessing(false);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layer>
  );
};

const Config = (props) => {
  const [checked, setChecked] = React.useState([]);
  // const [enabled, setEnabled] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState({});
  const [minimumEventsLayerData, setMinimumEventLayerData] = React.useState(
    false
  );
  const [appBundleLayerData, setAppBundleLayerData] = React.useState(false);

  const {
    updateAppBundleConfig,
    resetAppBundleData,
    createAppBundle,
    deleteAppBundle,
  } = props;

  const addAppBundleProps = {
    appBundleLayerData,
    setAppBundleLayerData,
    createAppBundle,
  };

  // const onCheck = (event, value) => {
  //     if (event.target.checked) {
  //         setChecked([...checked, value]);
  //     } else {
  //         setChecked(checked.filter(item => item !== value));
  //     }
  // };

  // const onCheckAll = event =>
  //     setChecked(event.target.checked ? (props.data || DATA).map(datum => datum.name) : []);

  const onToggleEnabled = (event, app_bundle, first_setup, props) => {
    const { events } = props;

    const title = "Update Confirmation";
    const isEnabled = !event.target.checked;

    if (!isEnabled) {
      // console.info({ first_setup })
      const { hasMinimumEvents, minimumEvents } = checkMinimumEvents(
        app_bundle,
        events
      );
      if (!hasMinimumEvents) {
        setMinimumEventLayerData({ hasMinimumEvents, minimumEvents });
        return true;
      }
    }

    const word = isEnabled ? "disable" : "enable";
    const message = `Do you want to ${word} App Bundle '${app_bundle}' ?`;
    const update = { enabled: !event.target.checked, app_bundle, first_setup };

    setConfirmation({
      title,
      message,
      visible: true,
      onConfirm: async () => {
        // setConfirmation({ processing: true, ...confirmation })
        await updateAppBundleConfig(update);
        setConfirmation({});
      },
      onCancel: () => setConfirmation({}),
    });
  };

  const onEditAppBundle = (event, { app_bundle, app_name }) => {
    // console.info({ event, app_bundle, app_name });
    setAppBundleLayerData({ app_bundle, app_name, isEdit: true });
    // onClick={() => setAppBundleLayerData(true)}
  };

  const onResetAppBundleHistory = (event, { app_bundle, app_name }) => {
    // console.info({ reset: event, app_bundle, app_name });
    const { events } = props;

    const title = "Reset Data Confirmation";

    const message = `Do you want to reset App Bundle '${app_bundle}' ?`;
    // const update = { enabled: !event.target.checked, app_bundle, first_setup };

    setConfirmation({
      title,
      message,
      status: "warning",
      visible: true,
      onConfirm: async () => {
        // setConfirmation({ processing: true, ...confirmation })
        // console.info({ reset: true });
        await resetAppBundleData({ app_bundle, app_name });
        setConfirmation({});
      },
      onCancel: () => setConfirmation({}),
    });
    // setAppBundleLayerData({ app_bundle, app_name, isEdit: true })
    // onClick={() => setAppBundleLayerData(true)}
  };
  // const onDeleteBundle = (event, app_bundle) => {

  //     const title = 'Delete Confirmation';
  //     const message = `Are you sure you want delete App Bundle '${app_bundle}' ?`

  //     setConfirmation({
  //         title,
  //         message,
  //         visible: true,
  //         onConfirm: async () => {
  //             // setConfirmation({ processing: true, ...confirmation })
  //             await deleteAppBundle({ app_bundle })
  //             setConfirmation({})
  //         },
  //         onCancel: () => setConfirmation({})

  //     })
  // }

  return (
    <Box align="center" pad={{ horizontal: "medium" }} fill flex>
      <MinimumEventsLayer
        minimumEventsData={minimumEventsLayerData}
        onCancel={() => {
          setMinimumEventLayerData(false);
        }}
        // navigateToEvents={() => navigate()}
        {...props}
      />
      <AddBundleLayer {...addAppBundleProps} />
      <ConfirmLayer {...confirmation} />
      <Box align="start" pad="small" fill="horizontal">
        <Button
          primary
          label="Add Bundle"
          icon={<Add />}
          onClick={() => setAppBundleLayerData({ isEdit: false })}
        />
      </Box>
      <Box flex style={{ overflow: "scroll" }}>
        <StyledDataTable
          columns={[
            {
              property: "id",
              header: <Text>ID</Text>,
              // primary: true,
            },
            {
              property: "enabled",
              header: <Text>Enabled</Text>,
              render: ({ enabled, app_bundle, first_setup }) => {
                return (
                  <CheckBox
                    toggle
                    checked={Boolean(enabled)}
                    onClick={(e) =>
                      onToggleEnabled(
                        e,
                        app_bundle,
                        Boolean(first_setup),
                        props
                      )
                    }
                  />
                );
              },
            },
            ...controlledColumns,
            {
              property: "actions",
              header: <Text>Actions</Text>,
              primary: true,
              render: ({ app_bundle, app_name }) => {
                return (
                  <Box direction="row">
                    {/* <Button
                                            icon={<Trash />}
                                            onClick={(e) => onDeleteBundle(e, app_bundle)}
                                        /> */}
                    <Button
                      icon={<Edit />}
                      onClick={(e) =>
                        onEditAppBundle(e, { app_bundle, app_name })
                      }
                    />
                    <Button
                      icon={<History color="status-critical" />}
                      onClick={(e) =>
                        onResetAppBundleHistory(e, { app_bundle, app_name })
                      }
                    />
                  </Box>
                );
              },
            },
          ].map((col) => ({ ...col }))}
          // onClickRow={event => alert(JSON.stringify(event.datum, null, 2))}

          data={props.data}
          sortable
          size="medium"
        />
      </Box>
    </Box>
  );
};

export default Config;
