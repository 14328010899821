import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      minWidth: "80%",
      minHeight: "60%",
    },
  },
  dialogTitle: {
    backgroundColor: "#3c8dbc",
  },
  title: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    padding: 0,
    // backgroundColor: "red",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    // height: "100%",
  },
  link: {
    color: "black",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  innerLink: {
    textDecoration: "none",
  },
  route: {
    // backgroundColor: "blue",
    width: "100%",
  },
  routeName: {
    color: "black",
    // borderBottom: "1px solid black",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // backgroundColor: "purple",
    padding: "0 10px",
    marginTop: "5%",
    textTransform: "uppercase",
  },
  name: {
    marginTop: "5%",
    textTransform: "uppercase",
  },
  actions: { color: "#3c8dbc" },
  //
  //
  //
  //
  groupWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    // backgroundColor: "blue",
    margin: "20px 0",
  },
  group: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    // backgroundColor: "#f5f5f5",
    marginBottom: 20,
  },
  groupMobile: {
    // backgroundColor: "brown",
    display: "Block",
  },
  groupTitle: {
    fontSize: "25px",
    fontWeight: "400",
  },
  groupColumn: {
    minWidth: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "purple",
  },
  fieldWrapper: {
    width: "80%",

    // backgroundColor: "pink",
    margin: "10px",
  },
}));
