import React, { Component, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import CronLogsActions from "../actions/CronLogsActions";
import AppBarActions from "../actions/AppBarActions";
import Header from "../components/xComponents/PageHeader";
import ButtonX from "../components/xComponents/Button";
import Footer from "../components/xComponents/Footer";

import { Box, Text, CheckBox } from "grommet";

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import Cell from "../components/Cell";

class CronLogs extends Component {
  constructor(props) {
    super(props);
    // Component State
    this.state = {
      filters: {
        pageSize: 50,
        page: 1,
      },
      tableColumns: this.getTableColumns(),
    };
  }

  //
  //
  componentDidMount() {
    const { loading, cronLogs, getInitialData } = this.props;

    if (!loading && !cronLogs.length) {
      //  Load data from API
      getInitialData();
    }
  }

  //
  //
  componentDidUpdate(prevProps, prevState, snapshot) {
    // inject content (Header + message + actions ) into AppBar
    this.injectContentIntoAppBar();
  }

  // inject content (Header + message + actions ) into AppBar
  injectContentIntoAppBar() {
    const { cronLogs } = this.props;
    // page title
    const title = "Crons";
    // extra msg
    const message = `${cronLogs.length} results `;
    // Actions
    const buttons = [];

    // inject content into AppBar
    this.props.updateAppBarContent({
      title,
      message,
      buttons,
    });
  }

  // handle fields change on add advertiser layer
  handleFiltersChange({ target, option }) {
    const { filters } = this.state;
    const { value, name, type, checked } = target;

    let newValue = (option && option) || value;

    // Checkbox
    if (type === "checkbox") {
      newValue = checked;
    }

    // update new values
    let newValues = {
      ...filters,
      ...{ [name]: newValue },
    };

    // set up state
    this.setState({ filters: newValues });
  }

  getTableColumns() {
    const defaultRenderer = (propName, type = "", color = "") => (props) => {
      let value = props[propName.toLowerCase()];
      // 05/08/2020 13:38
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell start={"true"} flex color={color} type={type}>
          {value}
        </Cell>
      );
    };

    const columns = [
      {
        property: "id",
        header: "ID",
        render: defaultRenderer("id"),
      },
      {
        property: "cron_name",
        header: "Cron name",
        render: defaultRenderer("cron_name"),
        search: true,
      },
      {
        property: "last_run",
        header: "Last run",
        render: defaultRenderer("last_run", "date"),
      },
      {
        property: "duration_ms",
        header: "Duration (ms)",
        render: defaultRenderer("duration_ms"),
      },
      {
        property: "env",
        header: "Environment",
        render: defaultRenderer("env"),
      },
      {
        property: "success",
        header: "Success",
        render: (props) => (
          <Box gap="small" align="center" direction="row" justify="around">
            <CheckBox disabled checked={props.success ? true : false} />
          </Box>
        ),
      },
      {
        property: "message",
        header: "Message",
        render: defaultRenderer("message"),
      },
    ];

    return columns;
  }

  render() {
    const { tableColumns, filters } = this.state;

    const {
      // Redux State
      cronLogs,
      loading,
    } = this.props;

    return (
      <Box
        flex
        fill
        width="100%"
        margin="none"
        overflowY="auto"
        alignSelf="center"
      >
        <Loader loading={loading} text="Loading Cron Logs data...">
          <Box style={{ overflowY: "auto", flex: 13 }}>
            <DataTable
              pin
              fill
              sortable
              step={50}
              primaryKey={"id"}
              data={cronLogs}
              columns={tableColumns}
              sort={{ property: "id" }}
            />
          </Box>
        </Loader>
      </Box>
    );
  }
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  return state.cronLogs;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, CronLogsActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CronLogs);
