import React, { useState } from "react";

import { Box, Button, TextInput } from "grommet";
import { FormView, Hide } from "grommet-icons";

function PasswordInput(props) {
  const [passwordFieldVisible, setPasswordFieldVisible] = useState("password");

  const { name, value, onChange } = props;

  return (
    <Box
      fill
      direction="row"
      align="center"
      round="5px"
      border
      pad="none"
      style={{ height: "fit-content", padding: "0" }}
    >
      <TextInput
        style={{ margin: "0" }}
        plain
        required
        size="small"
        name={name}
        value={value}
        type={passwordFieldVisible}
        onChange={(event) => onChange(event)}
      />
      <Button
        style={{ padding: "0 12px 0 12px" }}
        icon={
          passwordFieldVisible === "text" ? (
            <Hide size="15%" />
          ) : (
            <FormView size="15%" />
          )
        }
        onClick={() =>
          setPasswordFieldVisible(
            passwordFieldVisible === "text" ? "password" : "text"
          )
        }
      />
    </Box>
  );
}

export default PasswordInput;
