import React, { useEffect } from "react";
import Moment from "moment";

import {
    Box,
    Grommet,
    DataTable,
    DateInput,
    Select,
    Tabs,
    Tab,
} from "grommet";


function DateSelect(props) {
    const { handleChange } = props;
    const options = [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Month To Date",
        "Last Month",
        "This Year",
    ];

    const dateMap = {
        Today: {
            dateFrom: Moment().startOf("day").format("YYYY-MM-DD"),
            dateTo: Moment().endOf("day").format("YYYY-MM-DD"),
        },
        Yesterday: {
            dateFrom: Moment().subtract("1", "day").startOf("day").format("YYYY-MM-DD"),
            dateTo: Moment().subtract("1", "day").endOf("day").format("YYYY-MM-DD"),
        },
        "Last 7 Days": {
            dateFrom: Moment().subtract("7", "day").startOf("day").format("YYYY-MM-DD"),
            dateTo: Moment().endOf("day").format("YYYY-MM-DD"),
        },
        "Month To Date": {
            dateFrom: Moment().startOf("month").format("YYYY-MM-DD"),
            dateTo: Moment().endOf("day").format("YYYY-MM-DD"),
        },
        "Last Month": {
            dateFrom: Moment().subtract("1", "month").startOf("month").format("YYYY-MM-DD"),
            dateTo: Moment().subtract("1", "month").endOf("month").format("YYYY-MM-DD"),
        },
        "This Year": {
            dateFrom: Moment().startOf("year").format("YYYY-MM-DD"),
            dateTo: Moment().endOf("day").format("YYYY-MM-DD"),
        },
    };

    const dateCustom = props.dateCustom;
    const defaultRange = props.defaultRange || "Today";

    const defaultOption = options.find(
        (o) => o.toLowerCase() === defaultRange.toLowerCase()
    );

    const [value, setValue] = React.useState(defaultOption);



    return (
        <Select
            options={options}
            placeholder={"Custom"}
            value={dateCustom ? "Custom" : value}
            onChange={({ option }) => {
                if (dateMap[option]) {
                    handleChange(dateMap[option], option);
                }
                setValue(option);
            }}
        />
    );

}

function FiltersV4Dates(props) {
    const { state, onFilterChange, noDateInputs } = props;
    const dateToday = Moment.utc().format();
    const dateYearAgo = Moment.utc().subtract(1, 'year').format();


    const {
        dateFrom,
        dateTo,
        datePreset,
        dateCustom
    } = state


    return (

        <Box direction='row' gap='small'>
            {/* DATES */}
            {
                noDateInputs ? null :
            <Box>
                <DateInput
                    format="mm/dd/yyyy"
                    value={dateFrom}
                    onChange={({ value: dateFrom }) => {
                        onFilterChange({
                            dateFrom,
                            dateCustom: true
                        })
                    }}
                    calendarProps={{
                        bounds: [dateYearAgo, dateToday]
                    }}
                />
            </Box>
}
            {
                noDateInputs ? null :
                    <Box>
                        <DateInput
                            format="mm/dd/yyyy"
                            value={dateTo}
                            onChange={({ value: dateTo }) => {
                                onFilterChange({
                                    dateTo,
                                    dateCustom: true
                                })
                            }}
                            calendarProps={{
                                bounds: [dateFrom, dateToday]
                            }}
                        />
                    </Box>
            }

            <Box>
                <DateSelect
                    dateCustom={dateCustom}
                    defaultRange={datePreset}
                    handleChange={({ dateFrom, dateTo }, datePreset) => {
                        onFilterChange({
                            dateCustom: false,
                            datePreset,
                            dateFrom,
                            dateTo
                        })
                    }}
                />
            </Box>
        </Box>
    )
}

export default FiltersV4Dates;