// www/pages/Home.js
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import _ from "lodash";
// import { withRouter } from 'next/router'
// import Layout from '../containers/Layout'
import { push } from "connected-react-router";

// import { withAuthSync } from '../utils/auth'
import AppsFlyerActions from "../actions/AppsFlyerActions";
import { connect } from "react-redux";
import ConfigView from "../components/appsflyer/config";
import EventsView from "../components/appsflyer/app_events";
import ReportsView from "../components/appsflyer/reports";
import Moment from "moment";
import Loader from "../components/Loader";
import SearchBox from "../components/SearchBox";
import PageHeader from "../components/xComponents/PageHeader";
import ButtonX from "../components/xComponents/Button";

import { Box, Button } from "grommet";
const items = [];

const getFilteredData = (searchData, searchProps, searchValue) => {
  if (searchValue.length < 1) {
    return searchData;
  }

  // console.info({searchValue, searchProps, searchData})
  const searchResult = searchData.filter((o) => {
    return searchProps
      .map((prop) => {
        const value = String(o[prop]).toUpperCase();
        const search = String(searchValue).toUpperCase();
        // console.info({value, search, res: _.includes(value, search)})
        return _.includes(value, search);
      })
      .filter((prop) => prop).length;
  });
  // console.info({searchResult})
  return searchResult;
};

function MenuButton(props) {
  console.log(props.label);
  // const navigate = useNavigate();
  return (
    <Button
      // type="submit"
      primary
      margin="small"
      label={props.label}
      href={`/appsflyer/${props.label}`}
      // key={props.label}
      onClick={async (e) => {
        console.log(e.target.innerText);
        const activeView = e.target.innerText;
        props.onClick();
        e.preventDefault();
        push(`/appsflyer/${activeView}`);
      }}
    />
  );
}

class AppsFlyer extends Component {
  constructor(props) {
    super(props);
    // console.info({ props })

    this.state = {
      activeView: props.match.params.activeView || "config",
      searchValue: "",
      searchSuggestions: [],
      searchOnChange: ({ target }) =>
        this.setState({ searchValue: target.value }),
      searchOnSelect: (select) => console.info({ select }),
    };
    this.renderView = this.renderView.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }
  // const { name, login, bio, avatarUrl } = props.data
  // console.info({props})
  renderView() {
    const { config, events, reports, configMap, ...restProps } = this.props;
    // console.info({configMap}, this.props)
    const {
      searchValue,
      searchSuggestions,
      searchOnChange,
      searchOnSelect,
    } = this.state;

    const searchBoxProps = {
      searchValue,
      searchSuggestions,
      searchOnChange,
      searchOnSelect,
    };

    switch (this.state.activeView) {
      case "config": {
        const searchFields = ["id", "app_bundle", "app_name"];
        const data = getFilteredData(config, searchFields, searchValue);
        return (
          <Box>
            <SearchBox {...searchBoxProps} {...{ searchFields }} />

            <ConfigView data={data} events={events} {...restProps} />
          </Box>
        );
      }
      // return <ConfigView data={config}{...restProps} />
      case "events": {
        const searchFields = ["id", "app_bundle", "event_name", "event_key"];
        const data = getFilteredData(events, searchFields, searchValue);
        // console.info(events)
        return (
          <Box>
            <SearchBox {...searchBoxProps} {...{ searchFields }} />
            <EventsView
              data={data}
              configMap={configMap}
              eventId={restProps.eventId}
              {...restProps}
            />
          </Box>
        );
      }
      case "reports": {
        const searchFields = ["id", "app_bundle", "campaign", "country"];
        // console.info({ configMap, reports })
        const enrichData = (data) =>
          (data || []).map((o) =>
            Object.assign({}, o, {
              profit: o.revenue - o.cost,
              revenue: +o.revenue,
              cost: +o.cost,
              app_name: configMap
                ? String(configMap[o.app_bundle].app_name).replace(/\s/g, "")
                : "",
            })
          );

        const data = getFilteredData(reports, searchFields, searchValue);
        return (
          <Box flex fill>
            <ReportsView data={enrichData(data)}>
              <SearchBox {...searchBoxProps} {...{ searchFields }} />
            </ReportsView>
          </Box>
        );
      }
      // return <ReportsView data={reports} />
      default:
        return <Box>VIEW NOT DEVELOPED!</Box>;
    }
  }

  componentDidMount() {
    // console.info("APPS_FLYER_DID", this.props)
    const { loading, config, getInitialData } = this.props;

    if (!loading && !config) {
      getInitialData();
    }
  }

  onMenuClick(e) {
    const activeView = e.target.innerText;
    e.preventDefault();

    // console.info({ activeView });
    //  this.setState({
    //   activeView
    // })
    this.props.router.push(`/appsflyer/${activeView}`);
  }

  render() {
    const { activeView } = this.state;
    const { menu, loading, lastUpdateText } = this.props;
    return (
      //   <Layout>
      <Box {...this.props} fill flex>
        {/* HEADER  */}
        <Box
          direction="row"
          align="center"
          justify="between"
          background="dark-1"
          pad={{ horizontal: "small" }}
        >
          <Box>{`AppsFlyer Agency API - ${_.capitalize(activeView)}`}</Box>
          <Box justify="center" direction="row">
            {menu.map((item) => (
              <Button
                size="small"
                margin="small"
                primary
                onClick={(e) => {
                  console.log(e.target.innerText);
                  const activeView = e.target.innerText;
                  // this.props.onClick();
                  console.info(this.props);
                  e.preventDefault();
                  this.props.navigate(`/appsflyer/${activeView}`);
                  this.setState({
                    activeView: item.label,
                  });
                }}
                key={item.label}
                label={item.label}
              />
            ))}
          </Box>
        </Box>
        <Box fill flex pad="small">
          <Loader loading={loading} text="Loading AppsFlyer data...">
            {this.renderView()}
          </Loader>
        </Box>
      </Box>
      //   </Layout>
    );
  }
}

// AppsFlyer.getInitialProps = ({ store, pathname, req, res, ...rest }) => {
//     // console.log('2. Page.getInitialProps uses the store to dispatch things');
//     // console.log("SSSS", store, req)
//     store.dispatch(getInitialData(req));
// };

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AppsFlyerActions, dispatch);
}

function mapStateToProps(state) {
  const { appsflyer } = state;

  const menu = Object.keys(appsflyer)
    .filter((l) => ["config", "events", "reports"].indexOf(l) > -1)
    .map((label) => ({ label }));
  const { config, events, reports, configMap, loading, publishers } = appsflyer;
  const [lastUpdate] = _.sortBy(config, "modified").reverse();
  const { modified } = lastUpdate || {};
  const lastUpdateText = `Last Update: ${
    modified ? Moment(modified).format("YYYY-MM-DD HH:mm") : "N/A"
  } `;
  // console.info({ lastUpdateText })
  // MomentTZ(calendarInput[0])
  // .tz(timezone)
  return {
    menu,
    lastUpdateText,
    loading,
    config,
    events,
    configMap,
    reports,
    publishers,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsFlyer);
