
export const CONFIG_TYPE_OPTS = [
    { label: "Both", value: 2 },
    { label: "AdNetwork", value: 1 },
    { label: "Agency", value: 0 },
  ];
  
 export const ADN_ID_MAP = {
    0: {
      name: "CYGOBEL_INT",
      adnId: 0
    },
    1: {
      name: "KEYBALI_INT",
      adnId: 1
    },
    100: {
      name: "CYGOBEL_DUPE",
      adnId: 100
    }
  }
  
export const ADN_OPTS = [
    { label: "CYGOBEL_INT", adnId: 0 },
    { label: "KEYBALI_INT", adnId: 1 },
    { label: "CYGOBEL_DUPE", adnId: 100 }
  
  ]

  export const EVENTS_TYPE_OPTS = [
    { label: "Both", value: 2 },
    { label: "AdNetwork", value: 1 },
    { label: "Agency", value: 0 },
  ];
  const constants = {
      ADN_OPTS,
      ADN_ID_MAP,
      CONFIG_TYPE_OPTS,
      EVENTS_TYPE_OPTS
  }


  export default constants