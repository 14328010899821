import Numeral from "numeral";
import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { Box, Text } from "grommet";
import CountUp from "react-countup";

const COLORS = {
  profit: "#2c699a", //'#4F6272',
  cost: "#EF3054",
  revenue: "#43AA8B",
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class Example extends PureComponent {
  static demoUrl =
    "https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj";

  render() {
    const { data, maxWidth } = this.props;

    return (
      <Box
        flex
        fill
        align="center"
        style={{
          position: "relative",
          right: "0",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            border: "1px dashed lightgray",
            borderRadius: "5px",
            padding: maxWidth < 1500 ? "3px 7px" : "4px 6px",
            position: "absolute",
            right: 5,
            bottom: 5,
            zIndex: 10,
            width: maxWidth < 1500 ? "25%" : "25%",
            fontWeight: maxWidth < 1500 ? "500" : "500",
          }}
        >
          {["Revenue", "Cost", "Profit"].map((name) => {
            const entry = data.find((d) => d.name === name.toLowerCase());

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Text
                    color={COLORS[entry.name]}
                    style={{ fontWeight: "400", marginRight: "20px" }}
                    size={maxWidth < 1500 ? "7px" : "10px"}
                  >
                    {name.toUpperCase()}
                  </Text>
                </div>
                <div>
                  <CountUp
                    start={entry.value * 0.9}
                    end={entry.value}
                    duration={1}
                    separator=","
                    // decimals={4}
                    // decimal=","
                    prefix="$ "
                    // suffix=" left"
                    // onEnd={() => console.log('Ended! 👏')}
                    // onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <Text
                        ref={countUpRef}
                        color={COLORS[entry.name]}
                        // weight="bold"
                        size={maxWidth < 1500 ? "7px" : "10px"}
                      >
                        {`${Numeral(entry.value).format("$0,0")}`}
                      </Text>
                    )}
                  </CountUp>
                </div>
              </div>
            );
          })}
        </div>
        <PieChart width={340} height={maxWidth < 1500 ? 200 : 300}>
          <Pie
            data={this.props.data.filter((d) => d.name !== "revenue")}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={maxWidth < 1500 ? 70 : 90}
            fill="#8884d8"
            dataKey="value"
          >
            {["Cost", "Profit"].map((name, index) => {
              console.info({ name });
              const entry = data.find((d) => d.name === name.toLowerCase());
              return <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />;
            })}
          </Pie>
        </PieChart>
      </Box>
    );
  }
}
