const injectContentAppBar = (content) => async (dispatch) => {
  dispatch({
    type: `APPBAR_CONTENT_INJECTED`,
    payload: { content: content },
  });
};

const updateAppBarContent = (content) => async (dispatch) => {
  dispatch({
    type: `APPBAR_CONTENT_UPDATE`,
    payload: content,
  });
};

export default {
  injectContentAppBar,
  updateAppBarContent,
};
