import * as Immu from "object-path-immutable";

const sidebarInitial = { visible: false, content: undefined };

export default function (state = sidebarInitial, action) {
  switch (action.type) {
    case "RIGHT_SIDEBAR_TOGGLE":
      // in every time the rightbar is closed set content to default
      if (state.visible) {
        state = {
          ...state,
          content: undefined,
        };
      }
      return Immu.set(state, "visible", !state.visible);
    case "RIGHT_SIDEBAR_CONTENT_INJECTED":
      return Immu.set(state, "content", action.payload);
    default:
      return state;
  }
}
