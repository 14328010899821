import React, { useEffecrt, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "moment";
import UserManagementActions from "../../actions/UserManagementActions";
import AppBarActions from "../../actions/AppBarActions";
import _ from "lodash";
import { minimalTheme } from "../../themes/main-theme";
import {
  Box,
  Grommet,
  Button,
  Form,
  Tip,
  Text,
  Layer,
  CheckBox,
  Heading,
  TextInput,
  DataTable,
  Select,
  TextArea,
  FormField,
} from "grommet";
import { Edit } from "grommet-icons";
import { validate } from "numeral";
import Loader from '../../components/Loader';

const FIELDS = [
  {
    label: "First name",
    name: "firstName",
  },
  {
    label: "Last name",
    name: "lastName",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },
  {
    label: "Username",
    name: "username",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    editInfo: "Set a new password",
    editLabel: "Reset Password",
    editType: 'text'
  },
];

function CreateUser(props) {
  const {
    visible,
    onClose,
    publishers,
    agencies,
    user,
    updateUser,
    createUser
  } = props;

  const [pubsOptions, setPubOptions] = useState(publishers);
  const [agenciesOptions, setAgenciesOptions] = useState(agencies);
  const [agenciesSelected, setAgenciesSelected] = useState([]);

  const [processing, setProcessing] = useState(false);

  const [pubsSelected, setPubSelected] = useState([]);
  const [modalTitle, setModalTitle] = useState();
  const [values, setValues] = useState({});
  const [isValid, setIsValid] = useState(false);

  const isEdit = Object.keys(user).length;

  let title = 'Create a new User';
  let buttonText = 'Create';

  if (isEdit) {
    title = `Edit User - (\`${user.username}\` - id:${user.id})`;
    buttonText = 'Save Changes'
  }


  const validateForm = () => {
    const publishers = values.trackx || (!values.trackx && values.publishers && values.publishers.length)

    const password = isEdit || values.password;
    const required = !(values.firstName &&
      password &&
      values.email &&
      values.username &&
      publishers)

    console.info("VALIDATE", {isValid, required, publishers, values })
    return !required
  }


  React.useEffect(() => {
    // Set fields values
    // If the modal open on edit mode, the values is passed from the user prop
    let val = {
      trackx: user.trackx || 0,
      publishers: user.publishers || [],
      agencies: user.agencies || []

    };

    FIELDS.forEach((f) => (val[f.name] = user[f.name]));
    if (isEdit) {
      val.password = "";
      val.id = user.id;
    }
    // console.info(isEdit, val.password, val)
    setValues(val);
    console.info("USEEFFECT", user)
    setPubSelected(user.publishers || [])
    setAgenciesSelected(user.agencies || [])

    setPubOptions(publishers)
    setAgenciesOptions(agencies)
    
    // set modal title
  }, [user, isEdit, publishers,agencies]);

  if (!visible) {
    return null;
  }

  // Handle create or edit fields change
  const onChange = ({ target }) => {
    setValues((f) => ({
      ...values,
      [target.getAttribute("name")]: target.value || "",
    }));
    setIsValid(validateForm())

  };

  // Handle create or edit button trigger
  const handleSubmit = async () => {
    const fn = isEdit ? updateUser : createUser;
    // console.log({ ...values, pubsSelected });
    setProcessing(true)
    await fn(values)
    setProcessing(false)
    onClose();
  };


  return (
    <Layer style={{ minWidth: "500px" }} position={"center"}>
      <Box fill pad="small" flex width="500">
        <Box direction="row" align="center">
          <Box flex>
            <Heading level="3">{title}</Heading>
          </Box>
          <Box align="center">
            <Button size="small" secondary onClick={onClose} label="close" />
          </Box>
        </Box>
        <Box align="center">
          <Form autoComplete="new-password">
            <input type="hidden" value="prayer" />

            {_.chunk(FIELDS, 2).map((r) => {
              console.log(r);
              return (
                <Box key={`box_${r[0].name}`} direction="row">
                  {r.map((f) => {
                    const infoText = isEdit && f.editInfo ? f.editInfo : f.info;
                    const type = isEdit && f.editType ? f.editType : f.type;
                    return (
                      <Box key={f.name} pad="small">
                        <FormField
                          label={isEdit && f.editLabel ? f.editLabel : f.label}
                          info={!infoText ? null :
                            <Box direction='row'>
                              <Text size='small' weight='bold'>{infoText}</Text>
                            </Box>
                          }

                        >
                          <TextInput
                            autoComplete="new-password"
                            type={type}
                            name={f.name}
                            onChange={onChange}
                            value={values[f.name]}
                          />
                        </FormField>
                      </Box>
                    )
                  })}
                </Box>
              );
            })}
            <FormField
              label={`TrackX User`}
              info=
              {<Box align='start' direction='row'>
                <Text size='small' weight='bold'>{"Give full permissions for TrackX"}</Text>
              </Box>}
            >
              <CheckBox
                toggle
                checked={!!(values.trackx)}
                onChange={() => {
                  setValues(v => ({
                  ...v,
                  trackx: +(!v.trackx)
                }))
                setIsValid(validateForm())

              }}
              />
            </FormField>
            <FormField label="TrackX Agencies">
                  <Select
                    clear
                    multiple
                    options={agenciesOptions}
                    value={agenciesSelected || []}
                    onChange={({ value }) => {
                      setAgenciesSelected(value)
                      setValues(v => ({
                        ...v, 
                        agencies: value
                      }))
                      setIsValid(validateForm())

                    }}
                    closeOnChange={false}
                    messages={{
                      multiple: `${(agenciesSelected || []).length} selected`,
                    }}
                    onSearch={(text) => {
                      if (!text) {
                        return setAgenciesOptions(agencies);
                      }
                      const escapedText = text.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        "\\$&"
                      );
                      const exp = new RegExp(escapedText, "i");

                      setAgenciesOptions(agencies.filter((o) => exp.test(o)));
                    }}
                    dropHeight="medium"
                  />
                </FormField>
                <FormField label="DashboardX Publishers">
                  <Select
                    clear
                    multiple
                    options={pubsOptions}
                    value={pubsSelected || []}
                    onChange={({ value }) => {
                      setPubSelected(value)
                      setValues(v => ({
                        ...v, 
                        publishers: value
                      }))
                      setIsValid(validateForm())

                    }}
                    closeOnChange={false}
                    messages={{
                      multiple: `${(pubsSelected || []).length} selected`,
                    }}
                    onSearch={(text) => {
                      if (!text) {
                        return setPubOptions(publishers);
                      }
                      const escapedText = text.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        "\\$&"
                      );
                      const exp = new RegExp(escapedText, "i");

                      setPubOptions(publishers.filter((o) => exp.test(o)));
                    }}
                    dropHeight="medium"
                  />
                </FormField>
            
            <Text size="xsmall">{(pubsSelected || []).join(", ")}</Text>
            <Box align="center" flex>
              <Loader
                processing={processing}
                text={"Creating user..."}
              >
                <Button
                  // size="small"
                  disabled={!isValid}
                  primary
                  onClick={handleSubmit}
                  label={buttonText}
                />
              </Loader>
            </Box>
          </Form>
        </Box>
      </Box>
    </Layer>
  );
}

function UserMangement(props) {
  const {
    users,
    getUsersData,
  } = props;

  const [createModal, setCreateModal] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  return (
    <Grommet theme={minimalTheme} full>
      <Box pad={{ horizontal: "medium", vertical: "small" }} fill flex>
        <CreateUser
          visible={createModal}
          onClose={() => {
            setUserData({});
            setCreateModal(() => false);
          }}
          user={userData}
          {...props}
        />
        <Box direction="row" align="center">
          <Box flex>
            <Heading level="2">Users</Heading>
          </Box>
          <Box align="center">
            <Button
              size="small"
              primary
              disabled={createModal}
              label="Create New User"
              onClick={() => {
                setUserData({});
                setCreateModal(() => true);
              }}
            />
          </Box>
        </Box>
        <DataTable
          flex
          primaryKey={true}
          data={users}
          key={users.length}
            
          columns={[
            ...[
              "id",
              "active",
              "trackx",
              "username",
              "email",
              "firstName",
              "lastName",
            ].map((property) => ({
              property,
              header: property,
              primary: property === 'id',
              render: (props) => {
                if (["active", "trackx"].indexOf(property) > -1) {
                  return <CheckBox key={property} checked={Boolean(props[property])} />;
                }
                return props[property];
              },
            })),
            ...["created", "last_login"].map((property) => ({
              property,
              header: property,
              render: (props) => {
                const val = props[property];
                const fmt = !val
                  ? "N/A"
                  : Moment.utc(val).format("YYYY-MM-DD HH:mm:ss");
                return fmt;
              },
            })),
            {
              render: (user) => (
                <Edit
                  onClick={() => {
                    setUserData(user);
                    setCreateModal(() => true);
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
    </Grommet>
  );
}
// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  return state.users;
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, UserManagementActions, AppBarActions),
    dispatch
  );
  //   return bindActionCreators(OffersActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMangement);
