import _ from "lodash";

const placements = (state = { loading: 0, placements: [] }, action) => {
  switch (action.type) {
    case "PLACEMENTS_DATA_LOADING":
    case "PLACEMENTS_DATA_LOADED": {
      return { ...state, ...action.payload };
    }
    case "PLACEMENTS_DATA_FILTERED": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default placements;
