import Ax from "axios";

// Fetch skadnRedirects data
export const getInitialPlacementsData = (params = "") => {
  return async (dispatch, getState) => {
    dispatch({
      type: "PLACEMENTS_DATA_LOADING",
      payload: {
        loading: 1,
      },
    });
    const { advertisers, publishers } = getState()

    const hasAdvertisers = Boolean(advertisers.advertisers.length);
    const hasPublishers = Boolean(publishers.publishers.length);

    try {
      
      const apiUrl = `/api/placements`;
      const response = await Ax.post(apiUrl, {
        ...params,
        hasAdvertisers,
        hasPublishers
      });
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
    }
  };
};

//
export const filterPlacemetns = (params) => {
  // console.log(params)
  return async (dispatch) => {
    dispatch({
      type: "PLACEMENTS_DATA_FILTERED",
      payload: {
        ...params,
        loading: 1,
      },
    });
    try {
      const apiUrl = `/api/placements`;
      const response = await Ax.post(apiUrl, params);
      const payload = response.data;
      dispatch(payload);
    } catch (e) {
      if (e.name === "Error") {
        dispatch({
          type: "TRACKRC_CONN_LOST",
          notification: {
            title: "TrackRC DB Connection Error",
            message: "Please report and try again later",
            status: "critical",
            dismissMs: 60000,
          },
        });
      }
      console.info(e.name);
    }
  };
};

export default {
  getInitialPlacementsData,
  filterPlacemetns,
};
