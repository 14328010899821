import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  useSearchParams,
  useParams,
  useLocation
} from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment';
import Ax from 'axios';
import _ from 'lodash';
import useSWR from 'swr';
import { AckModal } from "./AlertsHelpers";
import { AckModalRowMock } from "./AlertsHelpers";
// import ConvertActions from '../../actions/AlertsDashboardActions';
import {
  Box,
  Grommet,
  // Button,
  // Form,
  // List,
  // Tip,
  // Text,
  // Layer,
  // Spinner,
  // Heading,
  // CheckBox,
  // TextInput,
  // DataTable,
  Tabs,
  Tab,
  Accordion,
  AccordionPanel,
  Select,
  TextArea,
  FormField,
} from "grommet";
import {
  postFetcher,
  useGetDispatch
} from '../../utils/fetch'
import { DateSelect, getDateMaps } from "../../components/FiltersV2";
import { Search, Add, Send } from 'grommet-icons';
import { minimalTheme } from "../../themes/main-theme";

import {
  CellBox,
  AlertsTable,
  getTableColumns,
  FiltersModal,
  getRowProps,
  CreateAlertModal,
  NewAlertButton
} from "./AlertsHelpers";

import { AlertsGroupBy, AlertsTimeframe, AlertsCustomTimeframe, AppBundlesSelect, PublishersSelect } from "./AlertsComponents";

const StyledTabs = styled(Tabs)`
& > div:nth-child(2) {
  overflow-y:scroll;
  overflow-x:hidden;
  height: 100vh;
}
& > div > button > div > span {
  font-size: 14px;
  font-weight: 600;
}
`;

// function useQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }


const DEFAULT_TIMEFRAME = "Yesterday"
export const DEFAULT_GROUP_BYS = [
  { label: 'Date', value: 'date' },
  { label: 'Campaign', value: 'campaign' },
  { label: 'Publisher', value: 'publisher' },
  { label: 'App Bundle', value: 'app_bundle' },
];



export const tfFromSearch = (searchParams) => {
  if (searchParams) {
    const searchObj = Object.fromEntries(searchParams);
    const searchMap = {
      yesterday: 'Yesterday',
      last7: "Last 7 Days",
      mtd: "Month To Date"
    }
    if (searchObj.tf) {
      return searchMap[searchObj.tf]

    }
  }

  return 'Yesterday'
}


export const getGroupBys = (groupByOpts) => ({
  groupBys: groupByOpts.map(g => g.value)
})

const setCurrentSearchParams = (filterBy) => {
  let queryParams = {};
  filterBy.forEach((fb) => {
    queryParams[fb[0]] = fb[1]
  });

  return queryParams
}

function AlertsDashboard(props) {
  const { options, config, username } = props;
  const [searchParams, setSearchParams] = useSearchParams({});
  const [groupByVal, setGroupBy] = React.useState(DEFAULT_GROUP_BYS.filter(v => v.value !== 'date'));
  const [timeframeVal, setTimeframe] = React.useState(getDateMaps(tfFromSearch(searchParams) || DEFAULT_TIMEFRAME));
  const [filterBy, setFilterBy] = React.useState([])

  const [appBundles, setAppBundles] = React.useState([]);

  const [publishersOpts, setPublishersOpts] = React.useState(options.publisher)
  const [publishers, setPublishers] = React.useState([]);
  const [alertConfig, setAlertConfig] = React.useState({});
  const [currentSelection, setSelected] = React.useState([]);
  const [createModal, toggleCreateModal] = React.useState(true)
  const [isCustomDate, setCustomDate] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { alertHash } = useParams();



  const [queryState, setQueryState] = React.useState({
    alertHash,
    ...timeframeVal,
    ...getGroupBys(groupByVal)
  })
  // Load app_bundle, publisher options to Redux
  // useGetDispatch("/api/alerts/options")
  useEffect(() => {
    setPublishersOpts(options.publisher)

    if (alertHash && config) {
      const conf = config.find(c => c.hash === alertHash)
      // console.info({ conf })
      if (conf) {
        setAlertConfig(conf)
        setFilterBy(conf.settings.filterBy)

      }
    }
  }, [options, alertHash, config])

  useEffect(() => {
    // console.info(Object.entries(searchParams))
    setTimeframe(getDateMaps(tfFromSearch(searchParams)))

  }, [searchParams])

  useEffect(() => {
    setQueryState(s => {
      const newQS = {
        ...s,
        ...timeframeVal,
        ...getGroupBys(groupByVal),
        filterBy,
        publishers,
        appBundles
      }
      // console.info({ newQS })
      // setSearchParams(setCurrentSearchParams(filterBy), { replace: true })
      return (newQS)
    })


  }, [timeframeVal, groupByVal, setSearchParams, filterBy, publishers, appBundles])


  console.info({queryState, alertHash})
  const { data, error } = useSWR(['/api/alerts/routes', queryState], postFetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  })


  // console.info({ queryState, timeframeVal })



  return (
    <Grommet theme={{
      ...minimalTheme,
      text: {
        extend: 'font-size:12px'
      }
    }} style={{ width: '100%', maxHeight: '100%' }}>

      <Box pad={'small'}
        style={{ maxHeight: '100%' }}
      >
        {/* <Heading level="3" size='large' margin={{ vertical: "small" }}>Jobs Placements</Heading> */}
        <Box direction='row' align='center' gap='small' pad='small' style={{ paddingBottom: 6 }}>
          <AlertsGroupBy
            options={DEFAULT_GROUP_BYS}
            value={groupByVal}
            onChange={({ value: option }) => {
              setGroupBy(option || DEFAULT_GROUP_BYS)
            }}

          />
          <AlertsTimeframe
            defaultRange={tfFromSearch(searchParams) || DEFAULT_TIMEFRAME}
            isCustomDate={isCustomDate}
            onChange={
              (arg, val) => {
                setTimeframe(val)
                setCustomDate(false)
              }
            } />
          <AlertsCustomTimeframe
            timeframeVal={timeframeVal}
            isCustomDate={isCustomDate}
            onChange={(val) => {
              setTimeframe(val)
              setCustomDate(true)

            }
            }
          />
          <AppBundlesSelect
            value={appBundles}
            options={options.app_bundle}
            onChange={({ value, option }) => {
              setAppBundles(value || [])
            }}
          />
          <PublishersSelect
            value={publishers}
            options={options.publisher}
            onChange={({ value, option }) => {
              setPublishers(value || [])
            }}
          />
        </Box>

        <FiltersModal alertConfig={alertConfig} onChange={(newFilterBy) => setFilterBy(newFilterBy)} />
        {/* <NewAlertButton onClick={() => toggleCreateModal(v => !v)} /> */}

        <Box size='small' margin='small'>

          {data && data.rows && `Total Rows: ${data.rows.length} (${data.rows.filter(d => d.ack_to).length})`}
        </Box>
        <Box
          style={{ maxHeight: 'calc(80vh - 80px)', overflow: 'hidden' }}

        >
          <StyledTabs>
            <Tab title="All Rows">
              <Box pad="none" background="light-2">
                {data && <AlertsTable
                  key={"mix_table"}
                  pin
                  columns={
                    getTableColumns({
                      data: data.rows,
                      ...getGroupBys(groupByVal),
                      alertConfig,
                      queryState,
                      selectedRows,
                      username
                    })}
                  primaryKey={'id'}
                  // groupBy={GROUP_MAP[groupByVal] ? { property: GROUP_MAP[groupByVal] } : null}
                  select={selectedRows}
                  {...(alertHash ? {
                    onSelect: (newSelect) => {
                      // console.info(selectedRows, newSelect)
                      setSelectedRows(newSelect)
                    }
                  } : {})
                  }
                  rowProps={getRowProps(data, alertHash)}
                  // select={currentSelection}
                  sortable
                  data={data.rows}

                />}
              </Box>
            </Tab>
            <Tab title="Open Rows" >
              <Box pad="none" background="light-2" >

                {data && <AlertsTable
                  pin
                  key={"open_table"}
                  columns={
                    getTableColumns({
                      data: data.rows,
                      ...getGroupBys(groupByVal),
                      alertConfig,
                      queryState,
                      selectedRows,
                      username
                    })}
                  primaryKey={'id'}
                  // groupBy={GROUP_MAP[groupByVal] ? { property: GROUP_MAP[groupByVal] } : null}
                  select={selectedRows}
                  {...(alertHash ? {
                    onSelect: (newSelect) => {
                      // console.info(selectedRows, newSelect)
                      setSelectedRows(newSelect)
                    }
                  } : {})
                  }
                  rowProps={getRowProps(data, alertHash)}
                  // select={currentSelection}
                  sortable
                  data={data.rows.filter(r => r.no_ack)}

                />}
              </Box>
            </Tab>
            <Tab title="Acked Rows">
              <Box>
                {data && <AlertsTable
                  pin
                  key={"ack_table"}

                  columns={
                    getTableColumns({
                      data: data.rows,
                      ...getGroupBys(groupByVal),
                      alertConfig,
                      queryState,
                      selectedRows,
                      username
                    })}
                  primaryKey={'id'}
                  // groupBy={GROUP_MAP[groupByVal] ? { property: GROUP_MAP[groupByVal] } : null}
                  select={selectedRows}
                  {...(alertHash ? {
                    onSelect: (newSelect) => {
                      // console.info(selectedRows, newSelect)
                      setSelectedRows(newSelect)
                    }
                  } : {})
                  }
                  rowProps={getRowProps(data, alertHash)}
                  // select={currentSelection}
                  sortable
                  data={data.rows.filter(r => !r.no_ack)}

                />}
              </Box>
            </Tab>
            {/* <AckModal {...AckModalRowMock}/> */}
          </StyledTabs>







        </Box>

      </Box>
    </Grommet >
  );
}


const mapStateToProps = (state, props) => {
  const { alerts, user } = state;
  const { config, options } = alerts
  const { username } = user;
  return {
    options,
    config,
    username
  };
};

export default connect(mapStateToProps, {})(AlertsDashboard);

