import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Numeral from "numeral";
import Moment from "moment";
import AdvertisersActions from "../../actions/AdvertisersActions";
import AppBarActions from "../../actions/AppBarActions";
import Pagination from "../../components/PaginationComponent";
import { debounce } from "debounce";
// import FormField from "../components/xComponents/FormField";
import _ from "lodash";

import { Box, Button, Select, Layer, Tip, TextInput, Text } from "grommet";
import { Collapsible, CheckBox } from "grommet";
import { Search } from "grommet-icons";
import ActionButtons from "../../components/ActionButtons";
import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import Cell from "../../components/Cell";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import FormDialog from "../../components/Dialog/FormDialog/FormDialog";
import MenuDialog from "../../components/Dialog/MenuDialog";
import FiltersDialog from "../../components/Dialog/FiltersDialog/FiltersDialog";
import SpeedDial from "../../components/SpeedDial/SpeedDial";
import {
  fields as advertiserFields,
  generatePixelFields,
} from "./advertisersFields";

function AdvertisersFilters(props) {
  // function for handling filter/submit button
  const { open, direction, setOpen, browser, toggleFilterVisibility } = props;
  // Is mobile ?
  const isMobile = !browser.greaterThan.medium;
  function handleSerchInput({ target }) {}

  return (
    <Box>
      <Box
        direction={direction || "row"}
        pad="6px"
        justify="between"
        background=" #f5f5f5"
      >
        <Box gap="6px" align="center" fill="horizontal">
          {/*  ROW  */}
          <Box direction="row" gap="6px" align="center" fill="horizontal">
            {/*  COLUMN */}
            <Box
              direction={!browser.greaterThan.medium ? "column" : "row"}
              gap="6px"
              align="start"
              // fill="horizontal"
              width={!browser.greaterThan.medium ? "100%" : "55%"}
              margin={{ right: "35px" }}
            >
              <TextInput
                icon={<Search size="18" />}
                // reverse
                name="offerSearch"
                type="search"
                size="small"
                // onChange={(e) => handleSerchInput(e)}
                placeholder="Search for Advertiser ID, Name"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const DEFAULT_SORT = { property: "id", direction: "desc" };

function Advertisers(props) {
  const { browser, getInitialData } = props;
  const { advertisers, loading, totalPages, advertiser } = props.advertisers;

  // add advertiser fields default values
  const defaultValues = {
    name: undefined,
    username: undefined,
    password: undefined,
    timezone: undefined,
    contact_name: undefined,
    contact_phone: undefined,
    contact_email: undefined,
    contact_im: undefined,
    default_click_cap: undefined,
    default_conversion_cap: undefined,
    affilitest_frequency: undefined,
    affilitest_group: undefined,
  };

  // add advertiser fields default values
  const pixelFields = {
    cid: undefined,
    advertiserOfferId: undefined,
    httpRequest: undefined,
    iframe: undefined,
    image: undefined,
    javascript: undefined,
    events: undefined,
  };

  // local state
  const [filtersVisible, toggleFilterVisibility] = useState(false);
  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.page || 1);
  const [pageSize, setCurrentPageSize] = useState(props.pageSize || 50);
  const [queryCriteria, setQueryCriteria] = useState({});
  // Dialog visiblity
  const [dialogVisible, setDialogVisible] = useState(false);
  // Dialog Header & Fields
  const [dialogContent, setDialogContent] = useState(false);

  // mobile ?
  const isMobile = !browser.greaterThan.medium;
  // Popup dialog fields

  let sort = sortSettings;
  if (["id", "payout", "name"].indexOf(sort.property) === -1) {
    sort = DEFAULT_SORT;
  }

  useEffect(() => {
    async function getData() {
      await getInitialData({
        ...queryCriteria,
        page,
        pageSize,
        sort,
      });
    }
    const debounced = debounce(getData, 1000);
    debounced();
  }, [page, pageSize, queryCriteria, sort, getInitialData]);

  // TABLE COLUMNS
  function getTableColumns() {
    const defaultRenderer = (propName, type = "", color = "") => (props) => {
      let value = props[propName.toLowerCase()];
      // 05/08/2020 13:38
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell start={"true"} flex color={color} type={type}>
          {value}
        </Cell>
      );
    };

    const columns = [
      {
        property: "id",
        header: "ID",
        render: defaultRenderer("id"),
      },
      {
        property: "name",
        header: "Name",
        render: defaultRenderer("name"),
      },
      {
        property: "contact_name",
        header: "Contact Name",
        render: defaultRenderer("contact_name"),
      },
      {
        property: "contact_phone",
        header: "Contact Phone",
        render: defaultRenderer("contact_phone"),
      },
      {
        property: "contact_email",
        header: "Contact email",
        render: defaultRenderer("contact_email"),
      },
      {
        property: "username",
        header: "Username",
        render: defaultRenderer("username"),
      },
      // Actions buttons
      {
        property: "actions",
        header: "",
        render: (props) => (
          <ActionButtons
            direction="row"
            buttons={[
              {
                name: "Edit offer",
                icon: "Edit",
                triger: (e) => {
                  setDialog("edit", props);
                  setDialogVisible(true);
                },
                backgroundColor: "brand",
              },
              {
                name: "Generate pixel",
                icon: "Link",
                triger: (e) => {
                  setDialog("pixel", props);
                  setDialogVisible(true);
                },
                backgroundColor: "brand",
              },
              {
                name: "Remove offer",
                icon: "Trash",
                backgroundColor: "brand",
                disabled: true,
              },
            ]}
          />
        ),
      },
    ];

    return columns;
  }

  // Here is the call to the database
  function setAdvertiser(criteria) {
    // console.log(criteria);
  }

  // set dialog content header + inner content
  function setDialog(header, props) {
    switch (header.toLowerCase()) {
      case "add":
        setDialogContent({
          header: "add new advertiser",
          formFields: advertiserFields(),
        });
        break;
      case "edit":
        setDialogContent({
          header: `edit advertiser: ${props.name}`,
          formFields: advertiserFields(props),
        });
        break;
      case "pixel":
        setDialogContent({
          header: `advertiser Tracking Info: ${props.name}`,
          formFields: generatePixelFields(pixelFields),
          fieldsFullWidth: true,
        });
        break;

      default:
        break;
    }
  }

  //
  //
  //
  const speedDialActions = [
    {
      name: "add",
      action: () => {
        setDialog("add");
        setDialogVisible(true);
      },
    },
  ];

  //
  //
  if (isMobile) {
    speedDialActions.unshift({
      name: "filters",
      action: () => {
        toggleFilterVisibility(true);
      },
    });
  }

  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}
      <AdvertisersFilters
        browser={browser}
        data={advertisers}
        sort={sortSettings}
        advertiser={advertiser}
        advertisers={advertisers}
        handleSubmit={setQueryCriteria}
        setOpen={toggleFilterVisibility}
        open={filtersVisible}
        toggle={toggleFilterVisibility}
      />

      {/*  */}
      <FiltersDialog
        fields={[
          [
            {
              name: "fromDate",
              type: "search",
              placeholder: `from date`,
            },
          ],
        ]}
        open={filtersVisible}
        toggle={toggleFilterVisibility}
      />

      {/* MODAL  */}
      {
        // Component wiil not mount until is trrigerd
        dialogVisible && (
          <FormDialog
            open={dialogVisible}
            handleSubmit={setAdvertiser}
            header={dialogContent.header}
            handleClose={setDialogVisible}
            fields={dialogContent.formFields}
            fieldsFullWidth={dialogContent.fieldsFullWidth}
          />
        )
      }

      {/*  */}
      {/* <MenuDialog /> */}

      {/*  */}
      <SpeedDial actions={speedDialActions} />

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading Advertisers data...">
        <Box fill style={{ overflowY: "auto", flex: 13 }} border>
          <DataTable
            background={{
              header: "dark-3",
            }}
            pin
            fill
            sortable
            step={50}
            primaryKey={"id"}
            data={advertisers}
            columns={getTableColumns()}
            sort={{ property: "id", direction: "desc" }}
          />
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  // const { loading, networks } = state.networks;
  const mainSidebar = state.mainSidebar;
  const advertisers = state.advertisers;
  const browser = state.browser;
  return { mainSidebar, advertisers, browser };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, AdvertisersActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Advertisers);
