import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// reducer imports
import homepageReducer from "./homepageReducer";
import user from "./userReducer";
import users from "./usersReducer";

import notification from "./notificationReducer";
import appsflyer from "./appsflyerReducer";
import appsflyer_adn from "./appsflyerAdnReducer";
import appsflyer_combined from "./appsflyerCombinedReducer";
import mainSidebar from "./mainSidebarReducer";
import rightSidebar from "./rightSidebarReducer";

import networks from "./networksReducer";
import advertisers from "./advertisersReducer";
import cronLogs from "./cronLogsReducer";
import offers from "./offersReducer";
import extOffers from "./extOffersReducer";
import conversions from "./conversionsReducer";

import publishers from "./publishersReducer";
import placements from "./placementsReducer";
import statistics from "./statisticsReducer";
import alerts from "./alertsReducer";

import skadnRedirects from "./skadnRedirectsReducer";
import appbar from "./appbarReducer";
import { createResponsiveStateReducer } from "redux-responsive";
import dashboard from './dashboardReducer';
import convertClicks from './convertClicksReducer';
import adjust from './adjustReducer';
import afRawAcq from './appsflyerRawAcqReducer';
import afRawFinance from './appsflyerRawFinanceReducer';
export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    alerts,
    dashboard,
    adjust,
    afRawAcq,
    afRawFinance,
    convertClicks,
    user,
    users,
    mainSidebar,
    rightSidebar,
    appsflyer,
    appsflyer_adn,
    appsflyer_combined,
    networks,
    advertisers,
    cronLogs,
    skadnRedirects,
    notification,
    offers,
    conversions,
    extOffers,
    publishers,
    placements,
    statistics,
    appbar,
    homepage: homepageReducer,
    browser: createResponsiveStateReducer(null, {
      extraFields: () => ({
        width: window.innerWidth,
      }),
    }),
  });
