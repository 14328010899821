import React from "react";
import PropTypes from "prop-types";
import {
  MoreVertical,
  Close,
  Code,
  Select as SelectIcon,
  Edit,
  Duplicate,
  Trash,
  Link,
} from "grommet-icons";
import _ from "lodash";

import { Box, DropButton } from "grommet";
import ActionButtons from "./ActionButtons";

// get the proper icon for the button
function getIcon(icon) {
  switch (icon) {
    case "Edit":
      return <Edit size="18px" color="white" />;
    case "Duplicate":
      return <Duplicate size="18px" color="white" />;
    case "Code":
      return <Code size="18px" color="white" />;
    case "Select":
      return <SelectIcon size="18px" color="white" />;
    case "Trash":
      return <Trash size="18px" color="white" />;
    case "Link":
      return <Link size="18px" color="white" />;
    default:
      break;
  }
}

// drop buttons content (buttons)
const DropContent = (props) => {
  const { onClose, buttons } = props;

  // Set Icon for each button
  const injectedButtons = buttons.map((button) => {
    return {
      ...button,
      icon: getIcon(_.capitalize(button.icon)),
    };
  });

  // Add close button
  const newDropButtons = [
    {
      name: "Toggle api",
      icon: <Close size="18px" color="white" />,
      triger: () => onClose(),
      backgroundColor: "brand",
    },
    ...injectedButtons,
  ];

  // return drop buttons
  return (
    <Box pad="none">
      <ActionButtons direction="column" buttons={newDropButtons} />
    </Box>
  );
};

DropContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const DropButtonActions = (props) => {
  // injected buttons
  const { buttons } = props;
  // open drop buttons flag
  const [open, setOpen] = React.useState();
  // open drop buttons
  const onOpen = () => {
    setOpen(true);
  };
  // close drop buttons
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Box align="center" pad="none">
      <DropButton
        icon={<MoreVertical size="small" />}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        dropContent={<DropContent onClose={onClose} buttons={buttons} />}
        dropProps={{ align: { top: "bottom" } }}
      />
    </Box>
  );
};

export default DropButtonActions;
