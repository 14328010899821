import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Box, Slide, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import Field from "./Field";
import styled from "styled-components";
import Moment from "moment";

function MobileButton(props) {
  const MButton = styled(Button)`
    border-radius: 0;
  `;
  return (
    <Box fill="horizontal">
      <MButton style={{ height: 40 }} onClick={props.onClick} primary>
        <Box direction="row" pad="6px" gap="6px">
          <Box style={{ fontSize: 14, fontWeight: 700 }}>{props.label}</Box>
          <Box style={{ fontSize: 12, fontStyle: "italic" }}>{props.desc}</Box>
        </Box>
      </MButton>
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    // "& div > div": {
    //   minWidth: "80%",
    //   minHeight: "60%",
    // },
  },
  //
  title: {
    padding: "3% 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  //
  content: {
    padding: "2% 0 0 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    // backgroundColor: "#3c8dbc",
    // color: "white",
    fontFamily: "roboto",
  },
  field: {
    backgroundColor: "red",
    padding: "10%",
  },
}));

function ScrollDialog(props) {
  const classes = useStyles();
  const { router, browser, open, toggle, fields } = props;
  const [selected, setSelected] = React.useState();
  const [criteria, setCriteria] = React.useState({
    fromDate: Moment.utc().startOf("day").format(),
    toDate: Moment.utc().format(),
  });

  // Mobile ?
  const isMobile = !browser.greaterThan.medium;

  //   console.log("newCriteria");
  // Component did mount
  React.useEffect(() => {
    //
    //
    // Create criteria
    //
    let newCriteria = {};

    // For every field row
    fields
      .filter((f) => !f.external)
      .forEach((field) => {
        newCriteria[field.name] = field.value;
      });

    if (Object.keys(newCriteria).length) {
      newCriteria = {
        ...criteria,
        ...newCriteria,
      };
      setCriteria(newCriteria);
    }
  }, []);

  // Handle filters change
  function handleFiltersChange(e, valueObj) {
    let newValues = {};
    let newValue = null;

    if (valueObj) {
      // DIRECT STATE UPDATE (Value Obj)
      newValues = {
        ...criteria,
        ...valueObj,
      };
    } else {
      //  HANDLE EVENT
      const { value, name, type, checked } = e.target;

      newValue = (e.option && e.option) || e.value ? e.value : value;

      if (type === "checkbox") {
        newValue = checked;
      }

      if (typeof newValue === "object" && newValue.label) {
        newValue = newValue.value;
      }

      if (name === "rank") {
        newValue = e.value || [];
      }

      if (["placements", "internalStatus"].indexOf(name) > -1) {
        newValue = e.value.value || 0;
      }

      if (
        ["advertiser", "countries"].indexOf(name) > -1 &&
        typeof newValue !== "object"
      ) {
        newValue = [];
      }

      if (name === "offerSearch") {
        return setCriteria(newValues);
      }
      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };
    }

    setCriteria(newValues);
  }

  //   const isMobile = browser.la

  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const handleRouteSelected = (index) => {
    if (index != selected) {
      setSelected(index);
    } else setSelected("");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      {/* <Button onClick={handleClickOpen()}>Filters</Button> */}
      <Dialog
        fullScreen={isMobile ? true : false}
        className={classes.root}
        maxWidth="xl"
        open={open}
        onClose={toggle}
        TransitionComponent={Transition}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {
          // Dialog Header - optional
          <DialogTitle
            id="scroll-dialog-title"
            style={{ padding: "0 10px", backgroundColor: "#3c8dbc" }}
          >
            <div className={classes.title}>
              <Typography style={{ color: "white", fontWeight: "500" }}>
                Filters
              </Typography>
              <Button
                onClick={() => toggle(!open)}
                style={{ color: "white", fontSize: "15px" }}
              >
                X
              </Button>
            </div>
          </DialogTitle>
        }
        <DialogContent
          className={classes.content}
          ref={descriptionElementRef}
          dividers
        >
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              //   backgroundColor: "red",
              height: "100%",
            }}
          >
            {[
              {
                name: "reportsSource",
                type: "select",
                multiple: false,
                options: ["Both (combined)", "Agency", "Ad Network"],
                placeholder: `Reports Source`,
              },
              {
                name: "fromDate",
                type: "date",
                // value: fromDate,
                placeholder: `from date`,
              },
              {
                name: "toDate",
                type: "date",
                // value: toDate,
                placeholder: `to date`,
              },
            ].map((field) => {
              return (
                // <div className={classes.field} style={{ marginTop: "3%" }}>
                <Field field={field} criteria={criteria} />
                // </div>
              );
            })}
          </div>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button style={{ color: "#3c8dbc" }} onClick={() => toggle()}>
            Cancel
          </Button>
          <Button onClick={() => toggle()} style={{ color: "#3c8dbc" }}>
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const router = state.router;
  const browser = state.browser;
  return { router, browser };
}

export default connect(mapStateToProps)(ScrollDialog);
