/*
 * Yunus Emre Dilber
 * https://github.com/yunusemredilber
 * */

import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import MainTheme from "./themes/main-theme";

// Grommet Imports
import { Box, Grommet, ResponsiveContext } from "grommet";

import AppBar from "./containers/AppBar";
import MainSidebar from "./containers/MainSidebar/MainSidebar";
import RightSidebar from "./containers/RightSidebar";

import Routes from "./components/Routes";
import Notification from "./containers/Notification";
import { logout } from "./actions/AuthActions";
import useInterval from "./hooks/useInterval";

// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { hydrateAlertsData } from "./actions/AlertsActions";

export default function App() {
  const dispatch = useDispatch();  

  useEffect(() => {
    dispatch(hydrateAlertsData())
  })

  useInterval(() => {
    dispatch(hydrateAlertsData())
  }, 60 * 1000 * 30)

  return (
    <Router>
      <Grommet theme={MainTheme} full>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box fill>
              <AppBar />
              <Notification />
              <Box direction="row" flex overflow={{ horizontal: "hidden" }}>
                <MainSidebar
                  onLogout={() => dispatch(logout())}
                  // user={user}
                />
                <Routes />
                <RightSidebar />
              </Box>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    </Router>
  );

}
