import React from "react";

import { Text } from "grommet";
import Package from "../../package.json";

import styled from "styled-components";

const VersionText = styled(Text)`
  font-size: 10px;
  font-weight: 100;
  color: "grey";
`;

const VersionInfo = (props) => (
  <VersionText>{`v${Package.version}`}</VersionText>
);

export default VersionInfo;
