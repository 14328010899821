import React, { Component } from 'react';

import { Heading, Box } from "grommet";
import { Alert } from 'grommet-icons';



class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <Heading level={3} margin="none">
                    <Box 
                        justify='start' 
                        align='center'
                        direction='row'
                        gap='small'

                        >
                        <Alert color='black' />  Page Not Found!
            </Box>
                </Heading>

            </div>
        );
    }
}

export default NotFoundPage;