import React, { useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { useState } from "react";

const performanceColors = {
  revenue: "rgba(60, 141, 188, 0.5)",
  profit: "rgba(58, 189, 93, 0.5)",
  cost: "rgba(227, 57, 39, 0.5)",
};

function Performance(props) {
  const { performance, isMobile } = props;
  const [colors, setColors] = React.useState(props.colors);
  const indexes = [1, 0, 2];
  console.log(performance);
  useEffect(() => {
    setColors(props.colors);
  }, [props.colors]);

  return (
    <Container isMobile={isMobile}>
      {indexes
        .map((i) => performance[i])
        .map((p) => (
          <DataBox
            backgroundColor={
              colors.length &&
              colors.filter((per) => per.name === p.name)[0].color
            }
            isMobile={isMobile}
          >
            <Name isMobile={isMobile}>{_.capitalize(p.name)}</Name>
            <Value isMobile={isMobile}>{`${
              Math.round(p.value * 10) / 10
            }$`}</Value>
          </DataBox>
        ))}
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  const { dashboard, browser } = state;
  const colors = dashboard.colors;
  const isMobile = browser.width < 800;

  return {
    colors,
    isMobile,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Performance);

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  justify-content: space-between;
  width: 100%;
`;

const DataBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => (props.isMobile ? "8px 10px" : "0 5px")};
  width: ${(props) => (props.isMobile ? "auto" : "100%")};
  height: ${(props) => (props.isMobile ? "90px" : "120px")};
  border: 1px solid ${(props) => props.backgroundColor};
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    ${(props) => props.backgroundColor}
  );
`;

const Name = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: ${(props) => (props.isMobile ? "30px" : "45px")};
  margin: 0 15px;
  opacity: 0.5;
`;

const Value = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: ${(props) => (props.isMobile ? "35px" : "55px")};
  margin: 0 15px;
  webkit-text-stroke: 0.01rem gray;
  opacity: 0.8;
  font-weight: 600;
`;
