const theme = {
  global: {
    colors: {
      brand: "#3c8dbc" /*main color code*/,
      placeholder: "",
    },
    font: {
      family: "Roboto",
      size: "15px",
      height: "20px",
    },
  },
  textInput: {
    extend: {
      fontWeight: "100",
      fontSize: "9px",
      height: "3vh",
      backgroundColor: "white",
    },
  },
  checkBox: {
    extend: {
      fontSize: "10px",
      width: "100%",
      whiteSpace: "pre",
    },
  },
  collapsible: {
    minSpeed: "100",
    baseline: "1950",
  },
  accordion: {
    heading: {
      level: 5,
      margin: '6px',
    },
    border: {
      color: 'transparent'
    }
  },

  dateInput: {
    icon: { size: "12px" },
  },
  select: {
    control: {
      extend: `width:100%; border-radius: 5px; background-color: white`,
    },
    icons: {
      margin: 0,
    },
  },
  button: {
    // border: { radius: "1px" },
  },

  // Select
  // select: {
  //   options: {
  //     container: { background: "dark-2", size: "xsmall", fontWeight: "100" },
  //     text: { fontWeight: "100", size: "12px" },
  //   },
  // },
  // // Checkbox
  // checkBox: {
  //   extend: { fontWeight: "100" },
  //   border: { width: "1px" },
  //   container: {
  //     text: { fontWeight: "100", size: "12px" },
  //   },
  // },
  // button: {
  //   border: {},
  // },
  // textInput: {
  //   extend: {
  //     fontWeight: "100",
  //   },
  // },
  // anchor: {
  //   fontWeight: "600",
  // },
};

export const minimalTheme = {
  global: {
    colors: {
      brand: "#3c8dbc" /*main color code*/,
      placeholder: "",
    },
    font: {
      family: "Roboto",
      size: "15px",
      height: "20px",
    },
  },
  formField: {
    label: {
      color: 'dark-3',
      size: 'small',
      margin: 'none',
      weight: 600,
    },

    // border: {
    //   position: 'outer',
    //   side: 'all',
    // },
    // disabled: {
    //   background: {
    //     color: 'status-disabled',
    //     opacity: true,
    //   },
    // },
    // content: {
    //   pad: 'small',
    // },
    // error: {
    //   background: {
    //     color: 'status-critical',
    //     opacity: 'weak',
    //   },
    // },
    // margin: 'none',
  },
  dataTable: {
    fontSize: '2px',

    header: {
      extend: {
        background: 'white'
      }
    },
    body: {
      extend: `background: white; font-size: 10px;`

    },
    footer: {
      font: {
        size: '10px'
      },

    },
    pinned: {
      footer: {

        extend: `background: white; font-size: 10px;`
      }
    }
  }
}
export default theme;
