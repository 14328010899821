import Cell from "./Cell";

const GRADIENTS = {
    agency: [
        '#196594',
        '#4383B4',
        '#66A2D5',
        '#87C2F7',
        '#A9E3FF'
    ],
    adn: [

        '#D42626',
        '#F74A3F',
        '#FF6A58',
        '#FF8973',
        '#FFA88F',
        '#D17F68',
        '#A45843',
        '#793321',
        '#4F0E00',

    ]
}

export const AppsflyerEntityLabel = ({ isGroup = false, adnNetworks, agencies, isAdn, adn_id, agency_id }) => {

    if (!agencies.ID_MAP || !adnNetworks.ID_MAP) {
        return null
    }


    const label =

        isAdn ?
            // ADN NAME
            adnNetworks.ID_MAP[adn_id].name.toUpperCase() :
            !agency_id ?
                "CYGOBEL AG" :
                // AGENCY NAME
                `${agencies.ID_MAP[agency_id].name} AG`;

    const color = isAdn ? GRADIENTS.adn[adn_id] || "#D42626" : GRADIENTS.agency[agency_id] || "#196594";

    return (
        <Cell
            style={{
                color: "white",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <div
                style={{
                    ...(isGroup ? {
                        boxShadow:
                            `0 1px 1px rgba(0,0,0,0.15), -3px -3px 0 #eee`
                    } : {}),
                    backgroundColor: color,
                    borderRadius: "5px",
                    // height: "12px",
                    // padding: "4px",
                    fontSize: "10px",
                    fontWeight: 700,
                    // marginLeft: "2%",
                    letterSpacing: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "80px",
                    padding: "5px 2px",
                }}
            >
                {label}
            </div>
        </Cell>
    );
}

export default AppsflyerEntityLabel