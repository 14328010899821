import React from "react";

import { Text, Box } from "grommet";

import { Spinning } from "grommet-controls";
import styled from "styled-components";

const LoaderText = styled(Text)`
  font-size: 16px;
  color: "grey";
  font-weight: bold;
`;

const Loader = ({ loading, processing, text, children }) => {
  if (!loading && !processing) {
    return children;
  }

  return (
    <Box justify="center" align="center" gap="small" flex fill>
      <Box direction='row' gap='medium'>
        <Spinning />
        {text && <LoaderText>{text}</LoaderText>}
      </Box>
    </Box>
  );
};

export default Loader;
