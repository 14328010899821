import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { getRolesAllowedRoutes } from "../../components/Routes";
import SidebarsActions from "../../actions/SidebarsActions";
import { Box, Anchor, Accordion, AccordionPanel } from "grommet";

import User from "./User";
import { navigationLinks } from "./navigationLinks";

import { Link } from "react-router-dom";
import _ from "lodash";

import {
  CaretUpFill,
  CaretDownFill,
  Logout as LogoutIcon,
} from "grommet-icons";
import MenuDialog from "../../components/Dialog/MenuDialog";

function AccordionHeader(props) {
  const { link, open, isDesktop } = props;

  const linkStyle = {
    fontWeight: "500",
    fontSize: isDesktop ? "12px" : "18px",
  };

  return link.child ? (
    <Box
      background={open ? "#2a6283" : "brand"}
      direction='row'
      justify='between'
      pad={isDesktop ? "10px" : "large"}
    >
      <Anchor
        color='white'
        icon={link.icon}
        label={_.capitalize(link.name || link.src)}
        style={linkStyle}
      />
      {link.child && open ? (
        <CaretUpFill size='18px' />
      ) : (
        <CaretDownFill size='18px' />
      )}
    </Box>
  ) : (
    <Link to={"/" + link.src}>
      <Box
        direction='row'
        justify='between'
        pad={isDesktop ? "10px" : "large"}
        onClick={!isDesktop ? props.toggleMainSidebar : null}
      >
        <Box pad='none'>
          <Anchor
            color='white'
            icon={link.icon}
            label={_.capitalize(link.name || link.src)}
            style={linkStyle}
          />
        </Box>
      </Box>
    </Link>
  );
}

function MainSidebar(props) {
  const { mainSidebarVisible, user, onLogout, browser, isAgencyUser } = props;

  const [openNavLink, setOpenNavLink] = useState();
  const isDesktop = browser.greaterThan.medium;
  const isMobile = !browser.greaterThan.medium;

  const innerLinkStyle = {
    fontWeight: "400",
    fontSize: isDesktop ? "12px" : "18px",
  };
  const innerLinkWrapperStyle = {
    all: "unset",
    padding: isDesktop ? "5% 20%" : "6% 0px 6% 15%",
  };

  const animations = [
    { type: "fadeIn", duration: 300 },
    { type: "slideRight", size: "xlarge", duration: 150 },
  ];
  const agencyFilter = (o) => {
    if (isAgencyUser) {
      return o.agency;
    }
    return o;
  };


  const allowedRolesRoutes = getRolesAllowedRoutes(user?.roles?.split(",") || []);

  const rolesFilter = (route) => {
    if(!allowedRolesRoutes.length) return true;
    return (
      allowedRolesRoutes.includes("/" + route.src)
       ||
      (route.child && route.child.filter(r => allowedRolesRoutes.includes(`/${r.src}`)).length > 0)
    );
  }

  const location = useLocation();
  const path = location.pathname.substring(1).split("/")[0];

  if (!mainSidebarVisible) {
    return null;
  }
  return isDesktop ? (
    <Box
      gridArea='sidebar'
      background='brand'
      animation={animations}
      width={isDesktop ? "200px" : "100%"}
    >
      {/* NAVIGATION LINKS  */}
      <Box overflow='auto' flex>
        {/* USER  */}
        {isDesktop && <User user={user} isDesktop={isDesktop} />}{" "}
        <Accordion
          onActive={(activeIndexes) => {
            setOpenNavLink(activeIndexes[0]);
          }}
        >
          {/*Nav Buttons*/}
          {navigationLinks.filter(agencyFilter).filter(rolesFilter).map((link, index) => (
            <AccordionPanel
              key={link.src + index}
              label={link.src}
              style={{ textDecoration: "none" }}
              header={
                <AccordionHeader
                  link={link}
                  isDesktop={isDesktop}
                  open={openNavLink === index}
                  toggleMainSidebar={props.toggleMainSidebar}
                />
              }
            >
              {link.child && (
                <Box
                  // pad={{ horizontal: "small" }}
                  background='#3b82ad'
                  style={{
                    boxShadow: "inset 0px 3px 23px -8px rgba(0,0,0,0.52)",
                  }}
                >
                  {
                    // Inner Links
                    link.child.filter(agencyFilter).filter(rolesFilter).map((c, index) => {
                      return (
                        <Link
                          key={"navigationLink" + c.src}
                          to={"/" + c.src}
                          style={{
                            ...innerLinkWrapperStyle,
                            borderBottom:
                              link.child.length - 1 > index
                                ? "1px solid rgba(191, 191, 191,0.4)"
                                : "",
                            // backgroundColor: path === c.src ? "#5392b8" : "300",
                            // boxShadow:
                            //   path === c.src
                            //     ? "inset 0px 0px 15px 0px rgba(0,0,0,0.4)"
                            //     : "",
                          }}
                          // onMouseDown={!isDesktop && props.toggleMainSidebar}
                          onClick={!isDesktop ? props.toggleMainSidebar : null}
                        >
                          <Anchor
                            label={_.capitalize(c.name || c.src)}
                            color='white'
                            style={{
                              ...innerLinkStyle,
                              // color: path === c.src ? "#234254" : "",
                              // fontWeight: path === c.src ? "500" : "300",
                            }}
                          />
                        </Link>
                      );
                    })
                  }
                </Box>
              )}
            </AccordionPanel>
          ))}
        </Accordion>
      </Box>

      {/* LOG OUT  */}
      <Box onMouseDown={onLogout}>
        <Anchor
          icon={<LogoutIcon />}
          label={"Logout"}
          color='white'
          style={{
            backgroundColor: "#2a6283",
            zIndex: "999",
            padding: "15px",
            height: "100%",
            display: "flex",
          }}
        />
      </Box>
    </Box>
  ) : (
    <MenuDialog onLogout={onLogout} />
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(SidebarsActions, dispatch);
}
function mapStateToProps(state) {
  const { browser, user, mainSidebar, router } = state;
  const { visible } = mainSidebar;
  const { isLoggedIn, isAgencyUser } = user;

  const mainSidebarVisible = visible && isLoggedIn;
  return {
    router,
    user,
    mainSidebarVisible,
    isAgencyUser,
    mainSidebar,
    browser,
    state,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSidebar);
