import React, { useEffect } from "react";
import { Select, Box } from "grommet";
import styled from "styled-components";
import Moment from "moment";

const dateMap = {
  Today: {
    startDate: Moment().startOf("day").format("YYYY-MM-DD"),
    endDate: Moment().endOf("day").format("YYYY-MM-DD"),
  },
  Yesterday: {
    startDate: Moment()
      .subtract("1", "day")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: Moment().subtract("1", "day").endOf("day").format("YYYY-MM-DD"),
  },
  "Last 7 Days": {
    startDate: Moment()
      .subtract("7", "day")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: Moment().endOf("day").format("YYYY-MM-DD"),
  },
  "Month To Date": {
    startDate: Moment().startOf("month").format("YYYY-MM-DD"),
    endDate: Moment().endOf("day").format("YYYY-MM-DD"),
  },
  "Last Month": {
    startDate: Moment()
      .subtract("1", "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: Moment()
      .subtract("1", "month")
      .endOf("month")
      .format("YYYY-MM-DD"),
  },
  "This Year": {
    startDate: Moment().startOf("year").format("YYYY-MM-DD"),
    endDate: Moment().endOf("day").format("YYYY-MM-DD"),
  },
};

//   https://coolors.co/eccfc3-404e5c-43aa8b-4f6272-ef3054
export default function DateSelect(props) {
  const { handleChange, datePreset } = props;
  const options = [
    "Custom",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Month To Date",
    "Last Month",
    "This Year",
  ];

  const defaultRange = props.defaultRange || "Today";
  const defaultOption = options.find(
    (o) => o.toLowerCase() === defaultRange.toLowerCase()
  );
  // console.info({ defaultOption }, defaultRange);

  const [value, setValue] = React.useState(defaultOption);

  useEffect(() => {
    if (!datePreset && value !== "Custom") {
      setValue("Custom");
    }
  }, [datePreset, value]);

  return (
    <SelectCustom
      disabled={[0]}
      placeholder='Choose Date'
      options={options}
      value={value}
      onChange={({ option }) => {
        if (dateMap[option]) {
          // console.info({ option }, dateMap[option]);
          // console.info()
          handleChange
            ? handleChange(dateMap[option])
            : console.info("handleChange", dateMap[option]);
        }
        setValue(option);
      }}
    />
  );
}

const SelectCustom = styled(Select)`
  background-color: ${({ theme }) => theme.body};
  border: 1px solid ${({ theme }) => theme.border};
  &.StyledBox-sc-13pk1d4-0 jAWuzL {
    background-color: black;
  }
`;

const SelectCustomContainer = styled(Box)`
  & > button {
    background-color: ${({ theme }) => theme.body};
  }
`;
