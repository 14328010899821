import _ from "lodash";
import * as Immutable from "object-path-immutable";

const statusOptions = [
  {
    label: "All",
    value: 2,
    length: 0,
  },
  {
    label: "Active",
    value: 1,
    length: 0,
  },
  {
    label: "Inactive",
    value: 0,
    length: 0,
  },
];

const initialState = {
  loading: 0,
  networks: [],
  filteredData: [],
  statusOptions,
  statusOption: statusOptions[1],
};

const onNetworkData = (state, payload) => {
  // const {
  //     statusOption
  // } = state;
  const statusOption = payload.statusOption || state.statusOption;
  const networks = payload.networks || state.networks;
  // console.info({networks})
  let filteredData = networks;
  const statusOptions = state.statusOptions.map((option) => {
    const fn =
      option.value === 2
        ? networks
        : networks.filter((n) => n.status === option.value);
    const fnLength = fn.length;
    if (statusOption.value === option.value) {
      filteredData = fn;
    }
    return Immutable.wrap(option)
      .set("length", fnLength)
      .set("fullLabel", `${option.label} (${fnLength})`)
      .value();
  });

  return {
    ...payload,
    networks,
    statusOptions,
    filteredData,
    statusOption,
  };
};

const networks = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "NETWORKS_DATA_LOADING":
    case "NETWORKS_DATA_LOADED":
    case "NETWORK_STATUS_OPTION_CHANGE": {
      // const reports = (raw || []).map(o => _.pick(o, _.keys(reportModel)))
      return {
        ...state,
        ...onNetworkData(state, payload),
      };
    }
    case "NETWORKS_ADD_NETWORK_SUCCESS": {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default networks;
